import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import {
  MainWrapperDiv,
  TextWrapper,
  ContentWrapper,
  ButtonWrapper,
  FilterTextWrapper,
  searchLogoStyle,
  LogoTextWrapper,
  ImageContainer,
} from './NodataContent.style';
import { useNavigate, useLocation } from 'react-router';
import AddButton from 'components/AddButton/AddButton.react';
import { primaryButtonStyle } from 'components/FormComponents/FormStyles';
import searchIconLogo from '../../assets/searchicon.png';
import { ImageSx } from 'components/CalculationEmptyStateComp/CalculationEmptyStateComp.style';

const NodataComponent = ({
  addNewLabel,
  addbuttonLabel,
  addNewButtonOptions,
  toggleModal,
  setSelectedOption,
  isModalPresent,
  noDropdown,
  buttonBackgroundColor,
  disabledOption,
  hasMultipleModals,
  disableButton,
  modalHandler,
  borderTop,
  marginLeft,
  wrapperWidth,
  width,
  left,
  filterData,
  nodata,
  componentWidth,
  componentHeight,
  searchTermSuffix,
  buttonMarginTop,
  buttonPosition,
  buttonLeft,
  imageSource,
  addNewLabelFontSize,
  componentMarginBottom,
  addNewLabelLineHeight,
  addNewLabelFontWeight,
  addNewLabelColor,
  addNewLabelMarginBottom,
  customBtnSx,
  addButtonSx,
}) => {
  const navigation = useNavigate();
  const { pathname } = useLocation();
  return (
    <MainWrapperDiv
      borderTop={borderTop}
      marginLeft={marginLeft}
      width={wrapperWidth}
      className="curved-border">
      <ImageContainer>
        {imageSource && <img src={imageSource} alt="No Data" style={ImageSx} />}
      </ImageContainer>
      <ContentWrapper width={width} left={left}>
        {nodata && (
          <TextWrapper
            width={componentWidth}
            height={componentHeight}
            marginBottom={componentMarginBottom}>
            {nodata}
          </TextWrapper>
        )}
        {addNewLabel && (
          <TextWrapper
            fontSize={addNewLabelFontSize}
            lineHeight={addNewLabelLineHeight}
            fontWeight={addNewLabelFontWeight}
            color={addNewLabelColor}
            marginBottom={addNewLabelMarginBottom}>
            {addNewLabel}
          </TextWrapper>
        )}
        {filterData && (
          <LogoTextWrapper>
            <img
              data-testid="searchIcon"
              src={searchIconLogo}
              alt="Search Icon"
              style={searchLogoStyle}
            />
          </LogoTextWrapper>
        )}
        {searchTermSuffix && (
          <FilterTextWrapper>{searchTermSuffix}</FilterTextWrapper>
        )}

        <ButtonWrapper
          marginTop={buttonMarginTop}
          position={buttonPosition}
          left={buttonLeft}>
          {addNewButtonOptions ? (
            <AddButton
              customBtnSx={customBtnSx}
              disableButton={disableButton}
              buttonName={addbuttonLabel.name}
              options={addNewButtonOptions}
              toggleModal={toggleModal}
              setSelectedOption={setSelectedOption}
              isModalPresent={isModalPresent}
              noDropdown={noDropdown}
              backgroundColor={buttonBackgroundColor}
              disabledOption={disabledOption}
              hasMultipleModals={hasMultipleModals}
            />
          ) : (
            addbuttonLabel && (
              <Button
                disabled={disableButton}
                sx={{ ...primaryButtonStyle(), ...addButtonSx }}
                variant="contained"
                onClick={() =>
                  addbuttonLabel.link === ''
                    ? modalHandler({
                        flag: true,
                        id: 0,
                      })
                    : navigation(addbuttonLabel.link, {
                        state: { previousPath: pathname },
                      })
                }>
                {addbuttonLabel.name}
              </Button>
            )
          )}
        </ButtonWrapper>
      </ContentWrapper>
    </MainWrapperDiv>
  );
};

export default NodataComponent;

NodataComponent.propTypes = {
  addNewLabel: PropTypes.string,
  addbuttonLabel: PropTypes.shape({
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  addNewButtonOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggleModal: PropTypes.func.isRequired,
  setSelectedOption: PropTypes.func.isRequired,
  isModalPresent: PropTypes.bool.isRequired,
  noDropdown: PropTypes.bool.isRequired,
  buttonBackgroundColor: PropTypes.string,
  disabledOption: PropTypes.bool,
  addButtonSx: PropTypes.object,
  imageSource: PropTypes.string,
  addNewLabelFontSize: PropTypes.string,
  addNewLabelFontWeight: PropTypes.string,
  addNewLabelColor: PropTypes.string,
  hasMultipleModals: PropTypes.bool,
  customBtnSx: PropTypes.object,
  addNewLabelMarginBottom: PropTypes.string,
  addNewLabelLineHeight: PropTypes.string,
  componentMarginBottom: PropTypes.string,
  disableButton: PropTypes.bool,
  modalHandler: PropTypes.func.isRequired,
  borderTop: PropTypes.string,
  marginLeft: PropTypes.string,
  wrapperWidth: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.string,
  filterData: PropTypes.bool,
  nodata: PropTypes.string,
  componentWidth: PropTypes.string,
  componentHeight: PropTypes.string,
  searchTermSuffix: PropTypes.string,
  buttonMarginTop: PropTypes.string,
  buttonPosition: PropTypes.string,
  buttonLeft: PropTypes.string,
};
