import styled from 'styled-components';
import {
  COOL_GRAY,
  NEW_DARK_GREEN,
  NEW_FOCUS_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  QUILL_GRAY,
  TEAL_GREEN_HOVER,
  WHITE,
} from 'theme/GlobalColors';

export const MainWrapper = styled.div`
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${QUILL_GRAY}` : null};
  max-height: 550px;
`;

export const MainFieldWrapper = styled.div`
  max-height: 18.57rem;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${QUILL_GRAY};
    border-radius: 0.188rem;
  }
`;

export const MenuSubsectionHeading = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  padding: 1rem 1rem 1rem 0.5rem;
  border-bottom: 1px solid ${QUILL_GRAY};
  align-items: center;
  height: 1rem;
  padding-right: 14rem;
  gap: 1rem;
`;

export const FarmWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
  height: 16px;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 1px;
`;

export const AddFarmWrapper = {
  textTransform: 'none',
  border: `1px solid ${NEW_DARK_GREEN}`,
  backgroundColor: WHITE,
  padding: '6px 8px',
  color: NEW_DARK_GREEN,
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&:focus': {
    color: NEW_FOCUS_DARK_GREEN,
    backgroundColor: TEAL_GREEN_HOVER,
    border: `2px solid ${NEW_DARK_GREEN}`,
    padding: '5.25px 7.25px',
  },
};

export const NoFarmDefaultText = styled.div`
  color: ${COOL_GRAY};
  font-size: 0.875rem;
  padding: 1rem;
  text-align: center;
  line-height: 19.6px;
`;
