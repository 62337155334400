import styled from 'styled-components';
import { DARK_CHARCOAL, QUILL_GRAY, WHITE } from 'theme/GlobalColors';
export const Card = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.063rem;
  border: 0.063rem solid var(--Disabled-BG, ${QUILL_GRAY});
  background: ${WHITE};
`;

export const CardTitle = styled.div`
  color: ${({ color }) => color || DARK_CHARCOAL};
  font-family: 'Open Sans';
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4rem;
  width: 100%;
`;

export const TextWrapper = styled.div`
  font-family: 'Open Sans';
  justify-content: ${(props) => props.justifyContent};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color};
  width: ${({ width }) => width ?? 'auto'};
  gap: ${({ gap }) => gap};
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${({ height }) => height};
  cursor: ${({ cursor }) => cursor};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
`;
export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const DetailsSection = styled.div`
  border: ${({ border }) => border || ''};
  border-right: ${({ borderRight }) => borderRight || ''};
  padding: ${({ padding }) => padding || '0px'};
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
`;
export const DetailHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.188rem;
`;
export const DetailContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
`;
export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.125rem;
  align-items: center;
`;
export const TextAndTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;
