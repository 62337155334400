import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { COMPONENT_CONTENT } from './CustomDatePicker.content';
import {
  dayIsBetweenStyle,
  dayStyle,
  staticDatePickerStyles,
} from './CustomDatePicker.style';
import { newColorThemeContext } from 'contextAPI/newColorThemeContext';
const DEFAULT_HIGHLIGHTED_DAYS = [1, 2, 3, 4, 5, 6];

dayjs.extend(isBetweenPlugin);

const currentDate = new Date();

const pastThreeMonth = new Date(
  currentDate.setMonth(currentDate.getMonth() - 3),
);

const StyledDatePicker = styled(StaticDatePicker)(staticDatePickerStyles);

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== COMPONENT_CONTENT.day_is_between &&
    prop !== COMPONENT_CONTENT.is_first_day &&
    prop !== COMPONENT_CONTENT.is_last_day,
})(({ dayIsBetween, isFirstDay, isLastDay, newColorTheme }) => ({
  ...(dayIsBetween && dayIsBetweenStyle(newColorTheme)),
  ...(isFirstDay && dayStyle(newColorTheme)),
  ...(isLastDay && dayStyle(newColorTheme)),
}));

CustomDay.propTypes = {
  date: PropTypes.instanceOf(Date),
  isFromDate: PropTypes.bool,
  dateSetter: PropTypes.func,
  fromDate: PropTypes.instanceOf(Date),
  toDate: PropTypes.instanceOf(Date),
  updateFormattedDate: PropTypes.func,
};

export default function CustomDay({
  date,
  isFromDate,
  dateSetter,
  fromDate,
  toDate,
  updateFormattedDate,
}) {
  const { newColorTheme } = useContext(newColorThemeContext);
  const [value, setValue] = useState(
    isFromDate ? dayjs(fromDate) : dayjs(toDate),
  );
  const [highlightedDays] = useState(DEFAULT_HIGHLIGHTED_DAYS);
  const [firstDay, setFirstDay] = useState([
    {
      key: currentDate.getDate(),
      value: dayjs(currentDate.toJSON().slice(0, 10)),
    },
  ]);
  const [lastDay, setLastDay] = useState([
    {
      key: pastThreeMonth.getDate(),
      value: dayjs(pastThreeMonth.toJSON().slice(0, 10)),
    },
  ]);

  const handleChange = (newValue) => {
    const nextDay = new Date(
      new Date(newValue).getTime() + 24 * 60 * 60 * 1000,
    );
    setValue(newValue);
    dateSetter(new Date(nextDay).toISOString().split('T')[0]);
    updateFormattedDate(dayjs(newValue).format('MM/DD/YYYY'));
  };

  useEffect(() => {
    setValue(isFromDate ? dayjs(fromDate) : dayjs(toDate));
  }, [fromDate, toDate]);

  useEffect(() => {
    isFromDate
      ? setLastDay([{ key: date.date(), value: date }])
      : setFirstDay([{ key: date.date(), value: date }]);
  }, [date]);
  useEffect(() => {
    if (isFromDate) {
      setFirstDay([{ key: value.date(), value: value }]);
      setLastDay([{ key: date.date(), value: date }]);
    } else {
      setLastDay([{ key: value.date(), value: value }]);
      setFirstDay([{ key: date.date(), value: date }]);
    }
  }, [value]);

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }
    const isFirstDay =
      firstDay[0].key === date.date() &&
      firstDay[0].value.month() === date.month();
    const isLastDay =
      lastDay[0].key === date.date() &&
      lastDay[0].value.month() === date.month();

    const isSelected =
      highlightedDays.indexOf(date.date()) > 0 && date.month() === 0;

    const dayIsBetween = date.isBetween(
      firstDay[0].value,
      lastDay[0].value,
      null,
      '[]',
    );

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
        isSelected={isSelected}
        newColorTheme={newColorTheme}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        displayStaticWrapperAs={COMPONENT_CONTENT.desktop}
        disableHighlightToday={true}
        value={value}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        shouldDisableDate={(dateParam) => {
          return dateParam < dayjs(pastThreeMonth);
        }}
        disableFuture={true}
        renderDay={renderWeekPickerDay}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
