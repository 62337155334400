import { PAGE_CONTENT } from 'pages/ParticipantDataCollection/BaseLineReporting/BaseLineReportingMainPage.content';
import PropTypes from 'prop-types';
import {
  deletedModalsInitialData,
  GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE,
  initialDeleteState,
  ORIGINATION_DATA_CONTENT,
} from 'pages/ParticipantDataCollection/ReportingComponents/DetailedOperationsDataComp/DetailedOperationsDataComp.content';
import { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getcashCropSubmitBody,
  getFieldEditApiData,
} from 'pages/ParticipantDataCollection/ReportingUtilFunctions';
import { useReportingApis } from 'hooks/useReportingApis';
import {
  GET_BASELINE_FIELD_DATA,
  FETCH_EDGE_OF_FIELD_SPICES_TYPE,
  FETCH_TILLAGE_TYPE_DROPDOWN,
} from 'urls';
import { extractBaselineFieldData } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.functions';
import { baselineDropdownContext } from './baselineDropdownContext';
import axios from 'axios';
import { projectActivitiesUiToApiMapping } from 'pages/ParticipantDataCollection/ActivityReporting/ActivityReporting.content';
import {
  checkTernaryCondition,
  getUniqueObjectsByKey,
  isEmpty,
  isNullOrEmpty,
} from 'utils/helper';
import {
  canShowField,
  formatActivityAcreageData,
  getActivityError,
  getActivityTypeForDelete,
  getLargestCropAreaWithSoilAmendment,
} from 'pages/ParticipantDataCollection/ActivityReporting/components/ImplementedActivitiesV2/ImplementedActivitiesV2.functions';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import { ACTIVITY_CATEGORY, ACTIVITY_FIELDS } from 'utils/config';
import { ACTIVITY_CATEGORIES } from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';

export const implementedActivitiesWithOpsDataContext = createContext({});
export const ImplementedActivitiesWithOpsDataProvider = ({
  currentFieldId,
  currentFarmId,
  triggerFieldDataSubmit,
  dataSubmitCallback,
  triggerCurrentFieldDataFetch,
  setTriggerCurrentFieldDataFetch,
  children,
}) => {
  const {
    fertilizerTypeDropDown,
    animalTypeDropdown,
    tillageTypeDropdown,
    irrigationNoneId,
    residueTypeDropdown,
    managementTypeDropDown,
  } = useContext(baselineDropdownContext);
  const [inProgressApiCount, setInProgressApiCount] = useState(0);
  //Operations data related state mgmt
  const [operationsData, setOperationsData] = useState({
    nitrogenList: [],
    tillageTypeDtoList: [],
    fieldSampleCrops: [],
    cropList: [],
  });
  const [deleteData, setDeleteData] = useState(initialDeleteState);
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [deletePracticeModalsData, setDeletePracticeModalsData] = useState(
    deletedModalsInitialData,
  );
  const [cropKeys, setCropKeys] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
    currentYearProjectCycleId: null,
    currentYear: '',
    phase: null,
  });
  //======> ********** Start of implemented activities related states ********** <======//
  // Implemented activities related data
  const [activityAcreageList, setActivityAcreageList] = useState([]);
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [tillageType, setTillageType] = useState();
  const [activityList, setActivityList] = useState([]);
  const [edgeOfFieldSpeciesCategory, setEdgeOfFieldSpeciesCategory] = useState(
    [],
  );
  const [tillageDropdownList, setTillageDropdownList] = useState([]);
  const [fieldArea, setFieldArea] = useState(0);
  // This is just the list of soil amendments with id and value pairs -> [{value: 'Basalt', id: 1}]
  const [soilAmendmentsList, setSoilAmendmentsList] = useState([]);
  const [deletedActivities, setDeletedActivities] = useState([]);
  // Note: This is used when we open confirmation dialog for deleting activity.
  const [activityToBeDeleted, setActivityToBeDeleted] = useState(null);
  const [activityDeleteModalProps, setActivityDeleteModalProps] = useState({
    open: false,
    activityTitle: '',
  });
  const [isInBaselineYear, setIsInBaselineYear] = useState(false);

  //======> ********** End of implemented activities related states ********** <======//

  const [isCropDeleteModalOpen, setIsCropDeleteModalOpen] = useState({
    flag: false,
    id: null,
  });
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const {
    loading,
    submitBaselineField,
    editBaselineField,
    reportingFetchAPI,
    saveImplementedActivities,
    fetchFieldArea,
    fetchSoilAmendmentsDropdownList,
    fetchActivityAcreage,
    submitActivityField,
  } = useReportingApis();
  const startApiProgress = () => setInProgressApiCount((prev) => prev + 1);
  const endApiProgress = () => setInProgressApiCount((prev) => prev - 1);
  const apiLoading = inProgressApiCount > 0 || loading;

  //********** ======> Start of implemented activities related logic <====== **********//

  // Calculate the total crop area for operations with a production purpose of 'Cover crop'
  const totalOperationCropArea = operationsData.cropList.reduce((acc, item) => {
    const valueToUse = checkTernaryCondition(item.data, item.data, item);
    if (valueToUse.productionPurpose === ORIGINATION_DATA_CONTENT.COVER_CROP) {
      acc += +valueToUse.cropArea || 0;
    }
    return acc;
  }, 0);

  const closeActivityDeleteModal = () =>
    setActivityDeleteModalProps({ open: false, activityTitle: '' });
  const openActivityDeleteModal = (activityTitle) =>
    setActivityDeleteModalProps({ open: true, activityTitle });

  useEffect(() => {
    if (currentFieldId) {
      const apiParams = { fieldId: currentFieldId };
      fetchFieldArea(apiParams).then((fieldArea) => {
        if (fieldArea) {
          setFieldArea(fieldArea);
        }
      });
    }
  }, [currentFieldId]);

  const largestCoverCropArea = useMemo(() => {
    if (operationsData.cropList) {
      const coverCropData = operationsData.cropList?.filter(
        (item) => item?.data?.productionPurpose === 'Cover crop',
      );

      if (!coverCropData || coverCropData.length === 0) return 0;

      return coverCropData.reduce((maxArea, crop) => {
        const cropArea = Number(crop.data.cropArea);
        return checkTernaryCondition(cropArea > maxArea, cropArea, maxArea);
      }, 0);
    }
    return 0;
  }, [operationsData.cropList, submitData.sampleId]);

  const getSoilAmendmentTypeById = (id) => {
    if (id === '') return '';

    if (soilAmendmentsList) {
      const soilAmendment = soilAmendmentsList?.find((item) => item.id === id);

      if (soilAmendment) return soilAmendment.value;
    }
    return '';
  };

  const getTillageTypeById = (id) => {
    if (id === '') return '';

    if (tillageDropdownList) {
      const tillageType = tillageDropdownList?.find(
        (item) => item.value === id,
      );

      if (tillageType) return tillageType.label;
    }
    return '';
  };

  const getTillageOpsIdByType = (type) => {
    if (operationsData.tillageTypeDtoList.length > 0) {
      const tillage = operationsData.tillageTypeDtoList.find(
        (item) => getTillageTypeById(item.data?.tillagePractice) === type,
      );

      if (tillage) return tillage.data.id;
    }
    return '';
  };

  // Gets projectActivityId from activity list api data used in dropdown
  const getActivityIdByType = (type) => {
    if (activityList) {
      const activity = activityList.find(
        (item) => item.projectActivityType === type,
      );

      if (activity) return activity.projectActivityId;
    }
    return '';
  };

  const cropAreasWithSoilAmendments = useMemo(() => {
    if (operationsData.cropList) {
      const cropListWithSoilAmendments = operationsData.cropList.filter(
        (item) => item.data?.soilAmendmentList.length > 0,
      );

      if (
        !cropListWithSoilAmendments ||
        cropListWithSoilAmendments.length === 0
      )
        return [];

      return cropListWithSoilAmendments.flatMap((crop) => {
        return crop.data?.soilAmendmentList.map((soilAmendment) => {
          return {
            cropArea: Number(crop.data?.cropArea),
            soilAmendmentType: getSoilAmendmentTypeById(
              soilAmendment.soilAmendmentsType,
            ),
          };
        });
      });
    }

    return [];
  }, [operationsData.cropList, soilAmendmentsList, submitData.sampleId]);

  const missingImplementedActivities = useMemo(() => {
    let activitiesInOpsData = [];

    const coverCrops = operationsData.cropList.filter(
      (item) => item.data?.productionPurpose === 'Cover crop',
    );

    if (coverCrops?.length > 0) {
      activitiesInOpsData.push({
        projectActivityType: 'Cover crop',
        activityCategory: 'Cover crop',
        activityId: getActivityIdByType('Cover crop'),
        fieldProjectActivityId: 0,
        area: '',
      });
    }

    // Note: All crops -> Cash crop, cover crop, fallow
    const allCrops = operationsData.cropList;
    if (allCrops?.length > 0) {
      for (const crop of allCrops) {
        if (crop.data?.soilAmendmentList?.length > 0) {
          const soilAmendments = crop.data.soilAmendmentList
            ?.filter((soilAmendment) => soilAmendment.soilAmendmentsType !== '')
            ?.map((soilAmendment) => ({
              projectActivityType: getSoilAmendmentTypeById(
                soilAmendment.soilAmendmentsType,
              ),
              activityCategory: 'Soil carbon amendment',
              // getActivityIdByType func gets id from activity list api data used for dropdown
              activityId: getActivityIdByType(
                // This helper func gets name from dropdown list api data
                getSoilAmendmentTypeById(soilAmendment.soilAmendmentsType),
              ),
              fieldProjectActivityId: 0,
              area: '',
            }))
            .filter(
              (soilAmendment) =>
                soilAmendment.projectActivityType === 'Basalt' ||
                soilAmendment.projectActivityType === 'Biochar' ||
                soilAmendment.projectActivityType === 'Humic acid', // Considering only these 3 since they are in implemented activities
            );

          activitiesInOpsData.push(...soilAmendments);
        }
      }
    }

    // Tillage logic
    const tillageList = operationsData.tillageTypeDtoList;
    if (tillageList?.length > 0) {
      const tillageType = getTillageTypeById(
        tillageList[0].data?.tillagePractice,
      );

      if (tillageType === 'No tillage') {
        activitiesInOpsData.push({
          projectActivityType: 'No tillage',
          activityCategory: 'Residue + tillage management',
          activityId: getActivityIdByType('No tillage'),
          fieldProjectActivityId: 0,
          area: fieldArea,
        });
      }
    }

    activitiesInOpsData = getUniqueObjectsByKey(
      activitiesInOpsData,
      'projectActivityType',
    );

    let missingActivities = [];
    for (const activity of activitiesInOpsData) {
      const activityIndex = activityAcreageList.findIndex(
        (item) => item.projectActivityType === activity.projectActivityType,
      );

      if (activityIndex === -1) {
        missingActivities.push(activity);
      }
    }

    // Note: ActivityId is empty for activities that are not added during project creation, getActivityIdByType func gets id from activity list api data used for dropdown, it returns empty if not found
    return missingActivities.filter((activity) => activity.activityId !== '');
  }, [
    operationsData.cropList,
    operationsData.tillageTypeDtoList,
    activityAcreageList,
    activityList,
    tillageDropdownList,
    soilAmendmentsList,
    fieldArea,
    submitData.sampleId,
  ]);

  // True when cover crop is present in implemented activities but not in operations data
  const isCoverCropMissingInOpsData = useMemo(() => {
    return checkTernaryCondition(
      isInBaselineYear,
      false,
      (() => {
        const coverCrops = operationsData.cropList.filter(
          (item) => item.data?.productionPurpose === 'Cover crop',
        );

        const coverCropPresentInOpsData = coverCrops && coverCrops.length > 0;

        const coverCropPresentInImplementedActivities =
          activityAcreageList.some(
            (activity) => activity.projectActivityType === 'Cover crop',
          );
        return (
          coverCropPresentInImplementedActivities && !coverCropPresentInOpsData
        );
      })(),
    );
  }, [
    operationsData.cropList,
    activityAcreageList,
    submitData.sampleId,
    isInBaselineYear,
  ]);

  // To show error note on top of crop section
  const missingSoilAmendmentsInOpsData = useMemo(() => {
    return checkTernaryCondition(
      isInBaselineYear,
      [],
      (() => {
        const soilAmendmentsInImplementedActivities = activityAcreageList
          .filter((item) => item.activityCategory === 'Soil carbon amendment')
          .map((item) => item.projectActivityType);

        const cropList = operationsData.cropList;

        let soilAmendmentsInOpsData = [];
        if (cropList && cropList?.length > 0) {
          for (const crop of cropList) {
            if (crop.data?.soilAmendmentList?.length > 0) {
              const soilAmendments = crop.data.soilAmendmentList
                ?.filter(
                  (soilAmendment) => soilAmendment.soilAmendmentsType !== '',
                )
                ?.map((soilAmendment) =>
                  getSoilAmendmentTypeById(soilAmendment.soilAmendmentsType),
                );

              soilAmendmentsInOpsData.push(...soilAmendments);
            }
          }
        }

        return soilAmendmentsInImplementedActivities.filter(
          (soilAmendment) => !soilAmendmentsInOpsData.includes(soilAmendment),
        );
      })(),
    );
  });

  // Note: Here we are updating acres error validation if some data changes in operations data
  useEffect(() => {
    setActivityAcreageList((prevData) => {
      return prevData.map((activityData) => {
        const largestCropAreaWithSoilAmendment =
          getLargestCropAreaWithSoilAmendment(
            cropAreasWithSoilAmendments,
            activityData.projectActivityType,
          );

        return {
          ...activityData,
          acresError: getActivityError({
            activityCategory: activityData.activityCategory,
            activityAcres: activityData.area,
            fieldArea: fieldArea,
            largestCoverCropArea: largestCoverCropArea,
            largestCropAreaWithSoilAmendment: largestCropAreaWithSoilAmendment,
            totalOperationCropArea,
          }),
        };
      });
    });
  }, [
    largestCoverCropArea,
    cropAreasWithSoilAmendments,
    fieldArea,
    currentFieldId,
    currentFarmId,
    totalOperationCropArea,
  ]);

  const fetchEdgeOfFieldSpeciesTypeDropDown = () => {
    startApiProgress();
    axios
      .get(FETCH_EDGE_OF_FIELD_SPICES_TYPE)
      .then((response) => {
        setEdgeOfFieldSpeciesCategory(
          response.data.map((item) => ({
            label: item.speciesCategoryName,
            value: item.speciesCategoryId,
          })),
        );
      })
      .catch(() => {})
      .finally(endApiProgress);
  };

  const fetchTillageTypeDropdown = () => {
    startApiProgress();
    axios
      .get(FETCH_TILLAGE_TYPE_DROPDOWN)
      .then((response) => {
        setTillageDropdownList(
          response.data.map((data) => ({
            label: data.tillagePracticeType,
            value: data.tillagePracticeId,
          })),
        );
      })
      .catch(() => {})
      .finally(endApiProgress);
  };

  const fetchSoilAmendmentsList = () => {
    startApiProgress();
    fetchSoilAmendmentsDropdownList(projectId)
      .then((list) => {
        if (list) setSoilAmendmentsList(list);
        else return;
      })
      .catch(() => {})
      .finally(endApiProgress);
  };

  const deleteAcreageByUniqueKey = (uniqueKeyToBeDeleted) => {
    setActivityAcreageList((prevActivityAcreageList) =>
      prevActivityAcreageList.filter(
        (activity) => activity.uniqueKey !== uniqueKeyToBeDeleted,
      ),
    );
  };

  const isActivityHavingOpsData = (activityData) => {
    const { activityCategory, projectActivityType } = activityData;

    switch (activityCategory) {
      case 'Cover crop':
        return operationsData.cropList?.some(
          (crop) => crop.data?.productionPurpose === 'Cover crop',
        );
      case 'Residue + tillage management':
        return (
          operationsData.tillageTypeDtoList?.length > 0 &&
          projectActivityType === 'No tillage'
        );
      case 'Soil carbon amendment':
        return operationsData.cropList?.some((crop) => {
          return crop.data?.soilAmendmentList?.some(
            (soilAmendment) =>
              getSoilAmendmentTypeById(soilAmendment.soilAmendmentsType) ===
              projectActivityType,
          );
        });
      default:
        return false;
    }
  };

  const handleActivityDelete = (activityData) => {
    const {
      fieldProjectActivityId,
      uniqueKey,
      activityCategory,
      activityId,
      label,
      projectActivityType,
    } = activityData;

    if (
      activityCategory !== 'Cover crop' &&
      activityCategory !== 'Soil carbon amendment' &&
      activityCategory !== 'Residue + tillage management'
    ) {
      setDeletedActivities((prev) => [
        ...prev,
        {
          fieldProjectActivityId: fieldProjectActivityId,
          projectActivityId: activityId,
          activityType: '',
        },
      ]);
      deleteAcreageByUniqueKey(activityData.uniqueKey);
    }

    const hasOperationsData =
      isActivityHavingOpsData(activityData) && !isInBaselineYear;

    if (hasOperationsData) {
      // open confirmation dialog
      setActivityToBeDeleted({
        fieldProjectActivityId: fieldProjectActivityId,
        projectActivityId: activityId,
        activityType: getActivityTypeForDelete(activityData),
        uniqueKey: uniqueKey,
        activityCategory: activityCategory,
        projectActivityType: projectActivityType,
      });
      openActivityDeleteModal(label);
    } else {
      setDeletedActivities((prev) => [
        ...prev,
        {
          fieldProjectActivityId: fieldProjectActivityId,
          projectActivityId: activityId,
          activityType: getActivityTypeForDelete(activityData),
        },
      ]);
      deleteAcreageByUniqueKey(activityData.uniqueKey);
    }
  };

  // Note: This function is called when user tries to delete an implemented activity and is prompted by a popup to confirm deletion of ops data along with implemented activity, if user clicks Yes then implemented activity along with the corresponding ops data is deleted, this deletion happens only at frontend until the user clicks on save or continue
  const handleDeleteFromOpsData = () => {
    const {
      fieldProjectActivityId,
      uniqueKey,
      projectActivityId,
      activityType,
      activityCategory,
      projectActivityType,
    } = activityToBeDeleted;

    switch (activityCategory) {
      case ACTIVITY_CATEGORIES.coverCrop:
        setOperationsData((prev) => ({
          ...prev,
          cropList: prev.cropList.filter(
            (item) => item.data?.productionPurpose !== 'Cover crop',
          ),
        }));
        break;
      case ACTIVITY_CATEGORIES.residueTillage:
        setDeletePracticeModalsData((prev) => {
          return {
            ...prev,
            deletedTillageList: [
              ...prev.deletedTillageList,
              { id: getTillageOpsIdByType(projectActivityType) },
            ],
          };
        });
        setOperationsData((prev) => ({
          ...prev,
          tillageTypeDtoList: prev.tillageTypeDtoList.filter(
            (tillage) =>
              getTillageTypeById(tillage.data.tillagePractice) !==
              projectActivityType,
          ),
        }));
        break;
      case ACTIVITY_CATEGORIES.soilAmendment: {
        const soilAmendmentDeletedIdList = operationsData.cropList
          .filter((crop) => {
            return crop.data?.soilAmendmentList?.some(
              (soilAmendment) =>
                getSoilAmendmentTypeById(soilAmendment.soilAmendmentsType) ===
                projectActivityType,
            );
          })
          .flatMap((crop) => {
            return crop.data?.soilAmendmentList
              .filter(
                (soilAmendment) =>
                  getSoilAmendmentTypeById(soilAmendment.soilAmendmentsType) ===
                  projectActivityType,
              )
              .map((soilAmendment) => ({
                subId: soilAmendment.id,
                parentId: crop.id,
              }));
          });

        setDeletePracticeModalsData((prev) => {
          return {
            ...prev,
            deletedSoilAmendmentList: [
              ...prev.deletedSoilAmendmentList,
              ...soilAmendmentDeletedIdList,
            ],
          };
        });

        setOperationsData((prev) => ({
          ...prev,
          cropList: prev.cropList.map((crop) => {
            return {
              ...crop,
              data: {
                ...crop.data,
                soilAmendmentList: crop.data?.soilAmendmentList?.filter(
                  (soilAmendment) =>
                    getSoilAmendmentTypeById(
                      soilAmendment.soilAmendmentsType,
                    ) !== projectActivityType,
                ),
              },
            };
          }),
        }));
        break;
      }
    }

    setDeletedActivities((prev) => [
      ...prev,
      {
        fieldProjectActivityId: fieldProjectActivityId,
        projectActivityId: projectActivityId,
        activityType: activityType,
      },
    ]);
    deleteAcreageByUniqueKey(uniqueKey);
    closeActivityDeleteModal();
  };

  const formatUIToApiAcreageData = (acreageDataList) => {
    return acreageDataList.map((item) => {
      const activityCategory =
        projectActivitiesUiToApiMapping[item.activityCategory];
      let edgeOfFieldData = {};

      if (activityCategory === 'Edge of field practices') {
        edgeOfFieldData = {
          speciesCategoryId: item.speciesCategory,
          stripWidth: item.stripWidth,
          barrierWidth: item.barrierWidth,
          numberOfRows: item.rowsCount,
          speciesDensity: item.speciesDensity,
          edgeOfFieldArea: item.area,
          edgeOfFieldSampleId: item.edgeOfFieldSampleId ?? 0,
        };
      }

      return {
        fieldProjectActivityId: item.fieldProjectActivityId,
        activityId: item.activityId,
        area: item.area,
        activityCategory:
          projectActivitiesUiToApiMapping[item.activityCategory],
        edgeOfField: edgeOfFieldData,
        acresInputRequired: true, // Note: hard coded for now
      };
    });
  };

  const updateImplementedActivities = () => {
    startApiProgress();
    fetchActivityAcreage(
      currentFieldId,
      submitData?.sampleId,
      submitData?.currentYearProjectCycleId,
    )
      .then((acreageList) => {
        setActivityAcreageList(
          formatActivityAcreageData(
            acreageList,
            fieldArea,
            largestCoverCropArea,
            cropAreasWithSoilAmendments,
          ),
        );
        setDeletedActivities([]);
      })
      .catch(() => {})
      .finally(endApiProgress);
  };

  const isIrrigationDataValidated = (irrigationList) => {
    for (const irrigationData of irrigationList) {
      if (irrigationData.irrigationType !== irrigationNoneId) {
        if (
          irrigationData.irrigationType === '' ||
          irrigationData.uom === '' ||
          irrigationData.irrigationRate === ''
        ) {
          return false;
        }
      }
    }
    return true;
  };

  const allFieldsValidated = () => {
    const { cropList, nitrogenList, tillageTypeDtoList } = operationsData;

    //check nitrogen fertilizer data
    for (const nitrogenData of nitrogenList) {
      const { data } = nitrogenData;
      if (data.fertilizerType !== 0) {
        // if fertilizer type is not none
        if (data.applicationRate === '') {
          return false;
        }
        if (data.fertilizerCategory === 'Synthetic') {
          if (
            data.nPercentage === '' ||
            data.pPercentage === '' ||
            data.kPercentage === ''
          ) {
            return false;
          }
        }
      }
    }
    //check tillage data
    for (const tillageData of tillageTypeDtoList) {
      const { data } = tillageData;
      if (
        data.tillagePractice !==
        tillageTypeDropdown.find((tillage) => tillage.label === 'No tillage')
          .value
      ) {
        // if tillage type is not no till
        if (
          data.depthOfTillage === '0' ||
          Number(data.surfaceDisturbance) > 100
        ) {
          return false;
        }
      }
    }

    //check crop data
    for (const cropData of cropList) {
      const { data } = cropData;
      if (Number(data.cropArea) > Number(fieldArea) || isEmpty(data.cropArea)) {
        return false;
      }
      if (data.cropPlannedManagementTypeId === '') {
        return false;
      }
      if (data.agricultureResiduesManaged === '') {
        return false;
      }
      if (
        data.agricultureResiduesManaged !==
          residueTypeDropdown.find((residue) => residue.label === 'None')
            .value &&
        (data.cropResidueRemoved === '' ||
          data.cropResidueRemoved === undefined)
      ) {
        return false;
      }
      if (data.productionPurpose !== 'Fallow') {
        if (
          !managementTypeDropDown.find(
            (item) => item.value === data.cropPlannedManagementTypeId,
          )?.flag &&
          Number(data.annualYield) === 0
        ) {
          return false;
        }

        if (data.cropPlantDate === '' || data.cropTerminationDate === '') {
          return false;
        }
      }
      if (data.irrigationList.length > 0) {
        if (!isIrrigationDataValidated(data.irrigationList)) {
          return false;
        }
      }
      if (data.soilAmendmentList.length > 0) {
        if (
          data.soilAmendmentList.some(
            (item) =>
              item.soilAmendmentsType === '' ||
              item.amountApplied === '' ||
              item.unitOfMeasure === '',
          )
        ) {
          return false;
        }
      }
      /* Adding a check for optional field: Crop depth uom is mandatory if
       * crop depth (optional field) is present
       */
      if (
        !isEmpty(cropData.data.cropDepth) &&
        isEmpty(cropData.data.cropDepthUom)
      ) {
        return false;
      }
    }

    return true;
  };

  const implementedActivitiesHasErrors = () => {
    const errors = activityAcreageList.map((activityData) => {
      const largestCropAreaWithSoilAmendment =
        getLargestCropAreaWithSoilAmendment(
          cropAreasWithSoilAmendments,
          activityData.projectActivityType,
        );
      const isEdgeOfField =
        activityData.activityCategory === ACTIVITY_CATEGORY.EDGE_OF_FIELD;
      const checkStripWidth =
        isEdgeOfField &&
        canShowField(activityData, ACTIVITY_FIELDS.STRIP_WIDTH);
      const checkBarrierWidth =
        isEdgeOfField &&
        canShowField(activityData, ACTIVITY_FIELDS.BARRIER_WIDTH);
      const checkRowsCount =
        isEdgeOfField && canShowField(activityData, ACTIVITY_FIELDS.ROWS_COUNT);
      const checkSpeciesDensity =
        isEdgeOfField &&
        canShowField(activityData, ACTIVITY_FIELDS.SPECIES_DENSITY);
      const activityErrors = {
        acresRequiredError: isNullOrEmpty(activityData.area),
        acresError:
          getActivityError({
            activityCategory: activityData.activityCategory,
            activityAcres: activityData.area,
            fieldArea: fieldArea,
            largestCoverCropArea: largestCoverCropArea,
            largestCropAreaWithSoilAmendment: largestCropAreaWithSoilAmendment,
          }).length > 0,
        // Edge of field related errors
        speciesCategoryRequired:
          isEdgeOfField && isEmpty(activityData.speciesCategory),
        stripWidthRequired: checkStripWidth && isEmpty(activityData.stripWidth),
        barrierWidthRequired:
          checkBarrierWidth && isEmpty(activityData.barrierWidth),
        rowsCountRequired: checkRowsCount && isEmpty(activityData.rowsCount),
        speciesDensityRequired:
          checkSpeciesDensity && isEmpty(activityData.speciesDensity),
      };

      return Object.values(activityErrors).includes(true);
    });

    // Note: if tillage is deleted, it deletes ops data tillage if present which is also mandatory, so if tillage is deleted, it should be considered as a validation error
    const isTillageDeleted = deletedActivities?.some(
      (activity) => activity.activityType === 'Tillage' && !isInBaselineYear,
    );

    // Note: Not considering tillage since acres will be autopopulated with field area and added through mapping
    const implementedActivitiesMissed =
      missingImplementedActivities.filter(
        (activity) =>
          activity.activityCategory !== 'Residue + tillage management' &&
          !isInBaselineYear,
      ).length > 0;

    return (
      errors.includes(true) ||
      isCoverCropMissingInOpsData ||
      missingSoilAmendmentsInOpsData.length > 0 ||
      implementedActivitiesMissed ||
      isTillageDeleted
    );
  };

  const getYearStatus = () => {
    const { cropList, nitrogenList, tillageTypeDtoList } = operationsData;

    // conditions for not started
    if (
      cropList.length === 0 &&
      nitrogenList.length === 0 &&
      tillageTypeDtoList.length === 0 &&
      activityAcreageList.length === 0
    ) {
      return 'Not Started';
    }

    //codition for in progress
    if (
      !allFieldsValidated() ||
      implementedActivitiesHasErrors() ||
      cropList.length === 0 ||
      nitrogenList.length === 0 ||
      tillageTypeDtoList.length === 0
    ) {
      return 'In Progress';
    }

    return 'Completed';
  };

  const submitImplementedActivities = async (
    sampleId,
    projectCycleId,
    activityAcreageList,
    phase,
  ) => {
    const phaseValue = checkTernaryCondition(
      phase === 'baseline',
      'Baseline',
      'Activity',
    );

    const payload = {
      addFieldAreaList: formatUIToApiAcreageData(activityAcreageList),
      fieldRequestDto: {
        fieldId: currentFieldId,
        projectId: projectId,
        projectCycleId: projectCycleId,
        fieldImplementedActivitiesStatus: 'Not started', // TODO: Update status logic
        farmId: currentFarmId,
        participantId: participantId,
        sampleId: sampleId,
        enrolmentType: phaseValue,
        fieldActivityYearStatus: getYearStatus(),
      },
      deletedImplementedActivity: deletedActivities,
    };

    startApiProgress();
    return saveImplementedActivities(payload).finally(endApiProgress);
  };

  //********** ======> End of implemented activities related logic <====== **********//

  // Triggered from unsaved changes modal, trigger flag set to false inside callback function
  useEffect(() => {
    if (triggerFieldDataSubmit) {
      saveDataHandler().finally(dataSubmitCallback);
    }
  }, [triggerFieldDataSubmit]);

  // Delete handler for operations data
  const deleteHandler = (id, modalType, practiceTypeValue) => {
    setDeleteData({
      ...deleteData,
      id: id,
      practiceTypeValue: practiceTypeValue,
      modalType: modalType,
    });
    setIsDeleteModalOpen(true);
  };

  //fetch total field area
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentFieldId };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };
  //fetch field operations data
  const fetchBaseLineFieldData = (year) => {
    startApiProgress();
    reportingFetchAPI(
      `${GET_BASELINE_FIELD_DATA}?fieldId=${currentFieldId}&projectCycleId=${projectCycleId}&sampleId=${submitData.sampleId}`,
    )
      .then((response) => {
        const {
          editMode,
          deletedPracticesData,
          cashCropList,
          nitrogenList,
          tillageTypeDtoList,
        } = extractBaselineFieldData(
          response.data,
          animalTypeDropdown,
          fertilizerTypeDropDown,
          tillageTypeDropdown,
        );
        setDeletePracticeModalsData(deletedPracticesData);
        setSubmitData((prev) => ({
          ...prev,
          editMode: editMode,
        }));
        setOperationsData({
          nitrogenList: nitrogenList,
          tillageTypeDtoList: tillageTypeDtoList,
          fieldSampleCrops: cashCropList.map((item) => ({
            data: item,
            id: item.id,
            year: year,
          })),
          cropList: cashCropList.map((item) => ({
            data: item,
            id: item.id,
            year: year,
          })),
        });
      })
      .catch(() => {})
      .finally(() => {
        endApiProgress();
      });
  };

  //Function for submitting field data
  const axiosCall = (formData, phase) => {
    if (submitData.editMode) {
      const fieldId = currentFieldId;
      const { apiParams, reqBody } = getFieldEditApiData(
        formData,
        submitData,
        fieldId,
        deletePracticeModalsData,
      );
      return editBaselineField(apiParams, reqBody);
    } else {
      if (phase === 'project') {
        //Project is the phase used for Activity
        return submitActivityField(formData);
      } else {
        return submitBaselineField(formData);
      }
    }
  };

  const handleFieldSubmit = (phase) => {
    const cashCropList = operationsData.cropList;
    const nitrogenList = operationsData.nitrogenList;
    const tillageTypeDtoList = operationsData.tillageTypeDtoList;

    const yearStatus = getYearStatus();
    const formData = getcashCropSubmitBody({
      fieldId: currentFieldId,
      projectId,
      participantId,
      sampleId: submitData.sampleId,
      cashCropList,
      tillageTypeDtoList,
      nitrogenList,
      farmId: currentFarmId,
      yearStatus,
      projectCycleId: Number(projectCycleId),
      editMode: submitData.editMode,
      deletePracticeModalsData: deletePracticeModalsData,
    });

    return axiosCall(formData, phase);
  };

  //On click handler for continue and save button
  const saveDataHandler = async () => {
    const { sampleId, currentYearProjectCycleId, phase } = submitData;
    if (sampleId === null) return;

    return handleFieldSubmit(phase)
      .then(async () => {
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
        // Note: if activity is missing because it was deleted, we will not autopopulate it.
        const updatedMissingActivities = missingImplementedActivities.filter(
          (activity) =>
            !deletedActivities.some(
              (deletedActivity) =>
                deletedActivity.projectActivityId === activity.activityId,
            ),
        );

        const mappedImplementedActivities = checkTernaryCondition(
          isInBaselineYear,
          activityAcreageList,
          [...updatedMissingActivities, ...activityAcreageList],
        );

        return submitImplementedActivities(
          sampleId,
          currentYearProjectCycleId,
          mappedImplementedActivities,
          phase,
        )
          .catch(() => {})
          .finally(() => {
            setDeletedActivities([]);
          });
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      })
      .finally(() => {
        setDeletePracticeModalsData({
          ...deletePracticeModalsData,
          deletedIrrigationList: [],
          deletedSoilAmendmentList: [],
        });
      });
  };

  const handlePracticeTypeDelete = (
    isFetchedFromAPI,
    parentId,
    id,
    listType,
    type,
    noneVals,
  ) => {
    let newCropList = operationsData.cropList;
    let cropListData = operationsData.cropList.find(
      (item) => item.id === parentId,
    );
    let hasData = cropListData?.data;
    let typeList = checkTernaryCondition(
      hasData,
      cropListData?.data[listType]?.filter((item) => item.id !== id),
      cropListData[listType]?.filter((item) => item.id !== id),
    );
    if (id === 0) {
      typeList = [
        ...typeList,
        { id: id, irrigationType: '', uom: '', irrigationRate: '' },
      ];
    }
    if (noneVals?.isNone) {
      typeList = [...typeList, noneVals.formData].filter(
        (item) => item.irrigationType !== 0,
      );
    }
    const indexOfCropListData = operationsData.cropList.findIndex(
      (item) => item.id === parentId,
    );
    if (hasData) {
      newCropList[indexOfCropListData] = {
        ...cropListData,
        data: {
          ...cropListData?.data,
          [listType]: typeList,
        },
      };
    } else {
      newCropList[indexOfCropListData] = {
        ...cropListData,
        [listType]: typeList,
      };
    }
    setOperationsData({
      ...operationsData,
      cropList: newCropList,
    });
    if (isFetchedFromAPI) {
      setDeletePracticeModalsData({
        ...deletePracticeModalsData,
        [type]: [
          ...deletePracticeModalsData[type],
          { subId: id, parentId: parentId },
        ],
      });
    }
  };

  const saveCropListData = (data, id, year) => {
    const listToUpdate = [...operationsData.cropList];
    /* istanbul ignore else */
    if (listToUpdate) {
      const existingIndex = listToUpdate.findIndex((item) => item.id === id);

      if (existingIndex === -1) {
        setOperationsData((prevOperationsData) => ({
          ...prevOperationsData,
          cropList: [
            ...prevOperationsData.cropList,
            {
              id: id,
              data: { ...data, id: id },
              year: year,
            },
          ],
        }));
      } else {
        setOperationsData((prevOperationsData) => ({
          ...prevOperationsData,
          cropList: prevOperationsData.cropList.map((item) => {
            return checkTernaryCondition(
              item.id === id,
              {
                id: id,
                data: { ...data, id: id },
                year: year,
              },
              item,
            );
          }),
        }));
      }
    }
  };

  const tillageActivityAdded = activityAcreageList?.find(
    (item) =>
      item?.activityCategory ===
      ORIGINATION_DATA_CONTENT.residue_tillage_management,
  );

  const filteredTillageActivity = checkTernaryCondition(
    tillageActivityAdded,
    activityAcreageList?.filter(
      (item) =>
        item?.activityCategory === tillageActivityAdded?.activityCategory,
    ),
    [],
  );

  // Note: This will just update the currentPage data in field info form(fetches latest implemented activities and ops data)
  const refreshCurrentFieldData = () => {
    fetchBaseLineFieldData(submitData.currentYear);
    updateImplementedActivities();
  };

  // Note: This will be triggered from parent component(baselineMainPage/activityreportingMainPage)
  useEffect(() => {
    if (triggerCurrentFieldDataFetch) {
      refreshCurrentFieldData();
      setTriggerCurrentFieldDataFetch(false);
    }
  }, [triggerCurrentFieldDataFetch]);

  useEffect(() => {
    const tillageProjectActivityType = filteredTillageActivity?.map(
      (activity) => activity?.projectActivityType,
    );
    setTillageType(tillageProjectActivityType);
  }, [activityAcreageList]);
  const [globalAllExpanded, setGlobalAllExpanded] = useState(
    GLOBAL_EXPAND_COLLAPSE_INITIAL_STATE,
  );
  useEffect(() => {
    if (submitData?.sampleId !== null) {
      fetchBaseLineFieldData(submitData.currentYear);
    }
    if (currentFieldId) {
      fetchTotalFieldArea();
    }
  }, [submitData.sampleId, currentFieldId]);
  useEffect(() => {
    setIsInBaselineYear(submitData.phase === 'baseline');
  }, [submitData.phase]);
  useEffect(() => {
    setCropKeys(
      operationsData.cropList.map(
        (item) =>
          `${item.data.cropId}-${item.data.cropSeason}-${item.data.productionPurpose}`,
      ),
    );
  }, [operationsData.cropList]);

  useEffect(() => {
    fetchTillageTypeDropdown();
  }, []);

  const value = useMemo(
    () => ({
      apiLoading,
      operationsData,
      setOperationsData,
      deletePracticeModalsData,
      setDeletePracticeModalsData,
      deleteData,
      setDeleteData,
      isDeleteModalOpen,
      setIsDeleteModalOpen,
      isCropDeleteModalOpen,
      setIsCropDeleteModalOpen,
      submitData,
      setSubmitData,
      deleteHandler,
      fetchBaseLineFieldData,
      saveDataHandler,
      tillageType,
      setTillageType,
      activityAcreageList,
      setActivityAcreageList,
      activityList,
      setActivityList,
      edgeOfFieldSpeciesCategory,
      setEdgeOfFieldSpeciesCategory,
      largestCoverCropArea,
      cropAreasWithSoilAmendments,
      fetchEdgeOfFieldSpeciesTypeDropDown,
      fetchTillageTypeDropdown,
      startApiProgress,
      endApiProgress,
      submitImplementedActivities,
      saveCropListData,
      handlePracticeTypeDelete,
      fetchTotalFieldArea,
      totalFieldArea,
      setTotalFieldArea,
      fieldArea,
      setFieldArea,
      fetchSoilAmendmentsList,
      soilAmendmentsList,
      deletedActivities,
      setDeletedActivities,
      cropKeys,
      setCropKeys,
      isFieldDisabled,
      setIsFieldDisabled,
      isCoverCropMissingInOpsData,
      missingSoilAmendmentsInOpsData,
      globalAllExpanded,
      setGlobalAllExpanded,
      closeActivityDeleteModal,
      openActivityDeleteModal,
      activityDeleteModalProps,
      activityToBeDeleted,
      setActivityToBeDeleted,
      deleteAcreageByUniqueKey,
      handleActivityDelete,
      updateImplementedActivities,
      refreshCurrentFieldData,
      totalOperationCropArea,
      isInBaselineYear,
      setIsInBaselineYear,
      handleDeleteFromOpsData,
    }),
    [
      apiLoading,
      operationsData,
      deletePracticeModalsData,
      deleteData,
      isDeleteModalOpen,
      isCropDeleteModalOpen,
      submitData,
      tillageType,
      activityAcreageList,
      activityList,
      edgeOfFieldSpeciesCategory,
      fieldArea,
      largestCoverCropArea,
      totalFieldArea,
      cropAreasWithSoilAmendments,
      soilAmendmentsList,
      deletedActivities,
      cropKeys,
      isFieldDisabled,
      setIsFieldDisabled,
      isCoverCropMissingInOpsData,
      missingSoilAmendmentsInOpsData,
      globalAllExpanded,
      activityDeleteModalProps,
      activityToBeDeleted,
      isInBaselineYear,
    ],
  );

  return (
    <implementedActivitiesWithOpsDataContext.Provider value={value}>
      {children}
    </implementedActivitiesWithOpsDataContext.Provider>
  );
};

ImplementedActivitiesWithOpsDataProvider.propTypes = {
  currentFieldId: PropTypes.string.isRequired,
  currentFarmId: PropTypes.string.isRequired,
  triggerFieldDataSubmit: PropTypes.func.isRequired,
  dataSubmitCallback: PropTypes.func.isRequired,
  triggerCurrentFieldDataFetch: PropTypes.bool,
  setTriggerCurrentFieldDataFetch: PropTypes.func,
  children: PropTypes.node.isRequired,
};
