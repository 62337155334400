import PropTypes from 'prop-types';
import { ReactComponent as AddIcon } from '../../assets/icons/addicon.svg';
import { ReactComponent as AddDisabledIcon } from '../../assets/icons/addIconDisabled.svg';
import { AddButtonLabel, AddButtonWrapper } from './AddSection.style';
import { checkTernaryCondition } from 'utils/helper';
import { PAGE_CONTENT } from './AddSection.content';

const AddSectionButton = ({
  onClickHanlder,
  hiddenFlag,
  label,
  marginTop,
  marginBottom,
  hasBorder = false,
  width = '100%',
  disabled = false,
  backgroundColor,
}) => {
  return (
    <AddButtonWrapper
      data-testid="add-section"
      marginTop={marginTop}
      marginBottom={marginBottom}
      onClick={onClickHanlder}
      hidden={hiddenFlag}
      hasBorder={hasBorder}
      disabled={disabled}
      backgroundColor={backgroundColor}
      width={width}
      transForm={label}>
      {checkTernaryCondition(disabled, <AddDisabledIcon />, <AddIcon />)}
      {label !== PAGE_CONTENT.noNeed && (
        <AddButtonLabel disabled={disabled}>{label}</AddButtonLabel>
      )}
    </AddButtonWrapper>
  );
};

AddSectionButton.propTypes = {
  onClickHanlder: PropTypes.func,
  hiddenFlag: PropTypes.bool,
  label: PropTypes.string,
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasBorder: PropTypes.bool,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default AddSectionButton;
