import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import DialogBox from 'components/DialogBox/DialogBox.react';
import {
  MODAL_CONTENT,
  acceptedFilesInfo,
  maxFilesError,
  getError,
} from './AddParticipantsViaFileUpload.content';
import {
  DragAndDropText,
  DragAndDropTitle,
  FileName,
  SmallUploadWrapper,
  Subtext,
  UploadHelpText,
  AcceptedFileItem,
  DropBoxStyle,
  DialogBoxSx,
  AddMultipleParticipantWrapper,
  dialogTitleSx,
} from './AddParticipantsViaFileUpload.style';
import { COOL_GRAY, GRAYISH_BLUE } from 'theme/GlobalColors';
import { useForm } from 'hooks/useForm';
import {
  checkTernaryCondition,
  getDefaultValue,
  ternaryBooleanHandler,
} from 'utils/helper';
import { deleteIconSx } from 'components/FormComponents/FormStyles';
import axios from 'axios';
import {
  FETCH_PARTICIPANT_NOTIFICATIONS,
  GET_PROJECT_FOR_PARTICIPANTS,
  UPLOAD_PARTICIPANTS_FILE,
} from 'urls';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import uuid from 'react-uuid';
import BasicAutoComplete from 'components/FormComponents/BasicAutoComplete/BasicAutoComplete.react';
import PropTypes from 'prop-types';
import { OptionListStyle } from 'components/SearchAndFilterComponent/SearchAndFilterComponent.style';
import { OptionNoBoldStyle } from 'theme/GlobalStyles';
import { NotificationLogContext } from 'containers/NotificationLogContext.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { outlinedDropdownInputSx } from 'pages/ParticipantDataCollection/BaseLineFieldInfo/components/modals/Modals.style';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import Loader from 'components/Loader/Loader.react';

const AddParticipantsViaFileUpload = ({
  addParticipantsFileUploadOpen,
  setAddParticipantsFileUploadOpen,
  refreshHandler,
  projectName = null,
  isNotProjectParticipantsPage = true,
  isMockUsed = false,
  projectLifeCycleList = [],
}) => {
  const initialContractModalValues = {
    projectName: projectName,
    projectCycleId: '',
  };
  const { formValue, customFieldChangeHandler, setFormValue } = useForm(
    initialContractModalValues,
  );
  const [error, setError] = useState({
    projectNameError: false,
    projectCycleIdError: false,
  });
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [inputProjectName, setInputProjectName] = useState('');
  const [isProjectListFetched, setIsProjectListFetched] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [loading, setLoading] = useState(false);
  const { logs, setLogs, setNewNotification } = useContext(
    NotificationLogContext,
  );
  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFiles && { maxFiles: acceptedFilesInfo.maxFiles }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
    disabled: ternaryBooleanHandler(uploadedFiles.length === 1),
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);
  const isMaxFileLimitExceeded = useMemo(
    () =>
      uploadedFiles.length + rejectedFiles.length > acceptedFilesInfo.maxFiles,
    [uploadedFiles, rejectedFiles],
  );
  const clearUploadFileDetailsOnClose = () => {
    setUploadedFiles([]);
    setRejectedFiles([]);
    setUploadError({ flag: false, message: '' });
  };

  const deleteFile = (fileIndex, fileHandler) => {
    fileHandler((prevFiles) =>
      prevFiles.filter((_, index) => fileIndex !== index),
    );
    setUploadError({ flag: false, message: '' });
  };

  const addFiles = (files, addFilesHandler) => {
    if (files.length > 0) {
      addFilesHandler([files[files.length - 1]]);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length > 0)
      addFiles(
        acceptedFiles.map((file) => ({ file: file, id: uuid() })),
        setUploadedFiles,
      );
    setRejectedFiles([]);
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
      addFiles(
        fileRejections.map((fileItem) => ({ ...fileItem, id: uuid() })),
        setRejectedFiles,
      );
    }
  }, [fileRejections]);

  useEffect(() => {
    if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else if (isMaxFileLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else
      setUploadError({
        flag: true,
        message: getError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [rejectedFiles]);

  useEffect(() => {
    if (inputProjectName === '') {
      setIsProjectListFetched(false);
      setProjectList([]);
    }
  }, [inputProjectName]);

  useEffect(() => {
    if (isMaxFileLimitExceeded) {
      setUploadError({
        flag: true,
        message: maxFilesError(acceptedFilesInfo.maxFiles),
      });
    } else if (rejectedFiles.length === 0) {
      setUploadError({ flag: false, message: '' });
    } else
      setUploadError({
        flag: acceptedFilesInfo.maxFileSize,
        message: getError(
          fileRejections[0]?.errors[0].code,
          acceptedFilesInfo.maxFileSize,
          acceptedFilesInfo.fileNotSupportedErrorText,
        ),
      });
  }, [isMaxFileLimitExceeded]);

  useEffect(() => {
    if (addParticipantsFileUploadOpen) {
      clearUploadFileDetailsOnClose();
    }
  }, [addParticipantsFileUploadOpen]);

  const onClose = () => {
    setAddParticipantsFileUploadOpen(false);
    if (isNotProjectParticipantsPage) {
      setFormValue({ projectName: null });
    } else {
      setFormValue(initialContractModalValues);
    }
    setError({ projectNameError: false, projectCycleIdError: false });
    refreshHandler();
  };
  const fetchProjects = (value) => {
    setInputProjectName(value);
    if (typeof value === 'string' && value.trim()) {
      axios
        .get(GET_PROJECT_FOR_PARTICIPANTS, { params: { projectName: value } })
        .then((response) => {
          setProjectList(
            response.data.map((item) => ({
              value: item.internalProjectId,
              label: item.projectName,
            })),
          );
          setIsProjectListFetched(true);
        });
    }
  };

  const onSubmit = () => {
    let formData = new FormData();
    if (uploadedFiles.length > 0) {
      formData.append('file', uploadedFiles[0].file);
    } else {
      setUploadError({
        flag: true,
      });
    }
    const validateProjectName =
      getDefaultValue(inputProjectName) !== '' &&
      formValue?.projectName?.value === undefined &&
      (isProjectListFetched || isMockUsed);
    if (validateProjectName) {
      formData.delete('projectId');
      setError((prev) => ({ ...prev, projectNameError: true }));
    } else {
      formData.append('projectId', formValue?.projectName?.value || 0);
      setError((prev) => ({ ...prev, projectNameError: false }));
    }
    const validateProjectCycleId = formValue?.projectCycleId !== '';
    if (validateProjectCycleId) {
      setError((prev) => ({ ...prev, projectCycleIdError: false }));
      formData.append('projectCycleId', formValue?.projectCycleId);
    } else {
      setError((prev) => ({ ...prev, projectCycleIdError: true }));
    }
    const errorStatus = {
      projectName: validateProjectName,
      projectCycleId: !validateProjectCycleId,
    };
    if (
      !Object.values(errorStatus).includes(true) &&
      uploadError.flag === false &&
      (uploadedFiles.length > 0 || isMockUsed)
    ) {
      setLoading(true);
      axios
        .post(UPLOAD_PARTICIPANTS_FILE, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          axios.get(FETCH_PARTICIPANT_NOTIFICATIONS).then((response) => {
            if (response?.data?.length > logs?.length) {
              setNewNotification(true);
            }
            setLogs(response?.data);
          });
        })
        .finally(() => {
          setLoading(false);
          onClose();
        });
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(
      Object.values(error).includes(true) || uploadError.flag,
    );
  }, [error, uploadError]);

  const addMultipleParticipantTitle = (
    <AddMultipleParticipantWrapper
      title={checkTernaryCondition(
        formValue?.projectName?.label?.length > 31,
        formValue?.projectName?.label,
        '',
      )}>
      {checkTernaryCondition(
        isNotProjectParticipantsPage,
        MODAL_CONTENT.title,
        `${MODAL_CONTENT.title}`,
      )}
    </AddMultipleParticipantWrapper>
  );

  return (
    <DialogBox
      title={addMultipleParticipantTitle}
      dialogTitleSx={dialogTitleSx}
      isOpen={addParticipantsFileUploadOpen}
      acceptCtnLabel={MODAL_CONTENT.submit}
      btnDisabled={isSubmitDisabled}
      declineCtnLabel={MODAL_CONTENT.cancel}
      dialogActions
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={() => {
        onClose();
      }}
      customSx={DialogBoxSx}
      customContentSx={{ overflowX: 'hidden' }}
      darkGreenBtnColor>
      <Loader loading={loading} zIndex={99999} />
      <div>
        <DropBoxStyle>
          <DragAndDropTitle>
            {checkTernaryCondition(
              isNotProjectParticipantsPage,
              MODAL_CONTENT.upload_title,
              MODAL_CONTENT.project_page_upload_title,
            )}
            {uploadError.flag && <AlertIcon data-testid="upload-error-icon" />}
          </DragAndDropTitle>
          {isNotProjectParticipantsPage && (
            <DragAndDropTitle fontSize="0.75rem" color={COOL_GRAY}>
              {MODAL_CONTENT.uploadSubText}
            </DragAndDropTitle>
          )}
        </DropBoxStyle>
        <SmallUploadWrapper
          isDisabled={uploadedFiles.length === 1}
          width={'auto'}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input data-testid="fileDropzone" {...getInputProps()} />
            <DragAndDropText>
              {checkTernaryCondition(
                uploadedFiles.length === 1,
                MODAL_CONTENT.file_added_label,
                <>
                  {MODAL_CONTENT.dropzone_info_text}
                  <Subtext>{MODAL_CONTENT.browse}</Subtext>
                </>,
              )}
            </DragAndDropText>
          </div>
        </SmallUploadWrapper>
        {acceptedFilesInfo.infoTextList?.map((infoText) => (
          <UploadHelpText fontSize={'0.75rem'} key={infoText}>
            {infoText}
          </UploadHelpText>
        ))}

        {uploadError.flag && (
          <UploadHelpText marginTop={'0rem'} iserror={true}>
            {uploadError.message}
          </UploadHelpText>
        )}
        {uploadedFiles.map((fileItem, index) => (
          <AcceptedFileItem key={fileItem.id}>
            <FileName
              isError={isMaxFileLimitExceeded}
              tooltipText={checkTernaryCondition(
                fileItem.file.name.length < 50,
                '',
                fileItem.file.name,
              )}>
              {checkTernaryCondition(
                fileItem.file.name.length < 50,
                fileItem.file.name,
                fileItem.file.name.substring(0, 50) + '...',
              )}
            </FileName>
            <DeleteOutlinedIcon
              fontSize="large"
              data-testid="deleteIcon"
              sx={deleteIconSx}
              onClick={() => deleteFile(index, setUploadedFiles)}
            />
          </AcceptedFileItem>
        ))}
        {rejectedFiles.map(({ file, id }, index) => (
          <AcceptedFileItem key={id}>
            <FileName isError={true}>{file.name}</FileName>
            <DeleteOutlinedIcon
              data-testid="deleteIcon"
              sx={deleteIconSx}
              fontSize="medium"
              onClick={() => deleteFile(index, setRejectedFiles)}
            />
          </AcceptedFileItem>
        ))}
      </div>
      {isNotProjectParticipantsPage && (
        <BasicAutoComplete
          isOptional
          optionalLabelMarginLeft={'0rem'}
          labelMarginTop={'1rem'}
          labelMarginBottom={'0.25rem'}
          marginBottom={'0rem'}
          label={MODAL_CONTENT.project_label}
          options={projectList}
          placeholder={MODAL_CONTENT.project_placeholder}
          loadingText={MODAL_CONTENT.project_placeholder}
          value={formValue?.projectName}
          id="disable-close-on-select"
          onChangeHandler={(value) => {
            customFieldChangeHandler('projectName', value);
          }}
          inputHandler={(e) => {
            fetchProjects(e.target.value);
            setFilterText(e?.target.value);
            error.projectNameError &&
              setError({ ...error, projectNameError: false });
          }}
          isError={error.projectNameError}
          name="project"
          ErrorMessage={MODAL_CONTENT.project_name_error_message}
          renderCustomOptions={(props, option) => {
            const lowerText = filterText?.toLowerCase();
            const beginIndex = option?.label.toLowerCase().indexOf(lowerText);
            if (beginIndex === -1) return null;

            return (
              <li {...props} key={option.value}>
                <span style={OptionListStyle}>
                  {option?.label.substr(0, beginIndex)}
                </span>
                <span style={OptionNoBoldStyle}>
                  {option?.label.substr(beginIndex, filterText.length)}
                </span>
                <span style={OptionListStyle}>
                  {option?.label.substr(beginIndex + filterText.length)}
                </span>
              </li>
            );
          }}
        />
      )}
      <DropDown
        dropDownMinWidth={'13.75rem'}
        label={MODAL_CONTENT.project_life_cycle_label}
        ariaLabel={MODAL_CONTENT.project_life_cycle_label}
        dropDownPlaceholder={MODAL_CONTENT.project_life_cycle_placeholder}
        value={checkTernaryCondition(
          formValue.projectCycleId === '',
          MODAL_CONTENT.project_life_cycle_placeholder,
          formValue.projectCycleId,
        )}
        hasNoBottomMargin={true}
        dropdownlist={projectLifeCycleList}
        setStateValue={() => {}}
        setDropDownValInParent={(newVal) => {
          customFieldChangeHandler('projectCycleId', newVal);
          newVal !== '' && setError({ ...error, projectCycleIdError: false });
        }}
        setFormFieldValue={() => {}}
        height={MODAL_CONTENT.two_rem}
        error={error.projectCycleIdError}
        labelMarginTop={'1.5rem'}
        labelMarginBottom={'0.25rem'}
        customPlaceholderColor={COOL_GRAY}
        defaultDropdownColor={GRAYISH_BLUE}
        customSx={outlinedDropdownInputSx}
        showLabelAsValue={true}
      />
    </DialogBox>
  );
};

AddParticipantsViaFileUpload.propTypes = {
  addParticipantsFileUploadOpen: PropTypes.bool.isRequired,
  setAddParticipantsFileUploadOpen: PropTypes.func.isRequired,
  refreshHandler: PropTypes.func.isRequired,
  projectName: PropTypes.string,
  isNotProjectParticipantsPage: PropTypes.bool,
  isMockUsed: PropTypes.bool,
  projectLifeCycleList: PropTypes.array,
};

export default AddParticipantsViaFileUpload;
