import React, { useEffect, useState, useRef } from 'react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  ActivitiesTableHeading,
  ActivityTableData,
  CategoryWrapper,
  ActivityCategory,
  PracticeWrapper,
  PracticeListItem,
  InputFieldWrapper,
  ErrorWrapper,
  contractedErrorIconSx,
} from './AddContractModal.style';
import {
  calculateActivityErrors,
  MODAL_CONTENT,
  showAlertIcon,
} from './AddContractModal.content';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { FocusedInputSx } from 'components/FormComponents/FormStyles';
const ProjectActivityDetailsSections = ({
  totalEnrolledAcres,
  handleEnrolledAcresUpdate,
  enrolledAcresError,
  totalAcresInputProps,
  handlePracticeDataUpdate,
  inputStyleProps,
  year,
  projectCycleId,
  uniqueIncentiveErrorList = [],
  activityPracticeData = {},
  setAcreExceedError = () => {
    /* do nothing */
  },
}) => {
  // making error state
  const [errorMessageState, setErrorMessageState] = useState(false);
  const [residueAndTillageError, setResidueAndTillageError] = useState(false);
  const [activityData, setActivityData] = useState(activityPracticeData);
  const prevActivityPracticeDataRef = useRef();

  /* useEffect to handle changes in activityPracticeData or totalEnrolledAcres
This effect recalculates errors and updates state when dependencies change. */
  useEffect(() => {
    const prevActivityPracticeData = prevActivityPracticeDataRef.current;
    if (
      (prevActivityPracticeData &&
        prevActivityPracticeData !== activityPracticeData) ||
      totalEnrolledAcres
    ) {
      const { residueAndTillageError, hasError, updatedActivityPracticeData } =
        calculateActivityErrors(activityPracticeData, totalEnrolledAcres);

      setResidueAndTillageError(residueAndTillageError);
      setActivityData(updatedActivityPracticeData);
      setErrorMessageState(hasError);
      setAcreExceedError(hasError);
    }
    prevActivityPracticeDataRef.current = activityPracticeData;
  }, [activityPracticeData, totalEnrolledAcres]);

  /**
   * @description This function checks the ternary condition to determine the contracted units from the practice object.
   * It parses the value to a float and ensures that if the result is NaN, it defaults to 0.
   * @param {string} category - The name of the activity category.
   * @param {Object} practice - The practice object containing details about the activity.
   * @returns {JSX.Element} The rendered activity category component.
   */
  const renderActivityCategory = (category, practice) => {
    const errorConditions = {
      residueAndTillageError: residueAndTillageError,
      unitKey: MODAL_CONTENT.contractedUnits,
      errorKey: MODAL_CONTENT.errorKey,
    };
    return (
      <ActivityCategory width="31.75rem">
        {category}
        {showAlertIcon(category, practice, errorConditions) && (
          <AlertIcon data-testid="contracted-error-icon" />
        )}
      </ActivityCategory>
    );
  };
  /**
   * @description This function checks the ternary condition to determine the contracted units from the practice object.
   * It parses the value to a float and ensures that if the result is NaN, it defaults to 0.
   * @param {Object} practice - The practice object containing details about the activity.
   * @returns {JSX.Element} The rendered practice list item component.
   */
  const renderPracticeListItem = (practice) => (
    <PracticeListItem>
      {practice.projectActivityType}
      {practice?.contractedUnits > 0 &&
        (practice.activityType !== MODAL_CONTENT.soilCarbonAmendment ||
          practice?.incentiveRate > 0) &&
        practice.error === true && (
          <AlertIcon data-testid="contracted-error-icon" />
        )}
    </PracticeListItem>
  );
  return (
    <>
      <InputField
        labelMarginBottom={'0rem'}
        value={totalEnrolledAcres}
        customInputSx={FocusedInputSx}
        onUpdate={handleEnrolledAcresUpdate}
        primaryError={enrolledAcresError?.length > 0}
        primaryErrorMessage={''}
        labelWidth={'95%'}
        category="long-numbers-field"
        {...totalAcresInputProps}
      />
      <ActivitiesTableHeading width="31.75rem">
        {MODAL_CONTENT.activities_table_heading}
        <InfoTooltip
          bottomMargin="-4px"
          customSx={{ marginLeft: '0.5rem' }}
          title=""
          content={MODAL_CONTENT.activityTableHeaderTooltipContent}
        />
      </ActivitiesTableHeading>

      <table style={{ width: '31.75rem' }}>
        <tr>
          <ActivityTableData width="13.5rem">
            Activities + practices
          </ActivityTableData>
          <ActivityTableData alignment="right">
            Contracted units
          </ActivityTableData>
          <ActivityTableData alignment="right">
            Incentive rate
            {uniqueIncentiveErrorList?.some((error) => error.length > 0) && (
              <AlertIcon
                data-testid="incentive-error-icon"
                style={contractedErrorIconSx}
              />
            )}
          </ActivityTableData>
        </tr>
        <tr>
          <ActivityTableData></ActivityTableData>
          <ActivityTableData alignment="right">Acres</ActivityTableData>
          <ActivityTableData alignment="right">$ per acre</ActivityTableData>
        </tr>
      </table>

      {Object.keys(activityData)?.map((activityCategory) => {
        if (activityCategory === 'totalAcres') return null;
        const practicesCount = activityData[activityCategory]?.length;
        return (
          <CategoryWrapper width="31.75rem" key={activityCategory}>
            {practicesCount > 1 && renderActivityCategory(activityCategory)}

            {activityData[activityCategory]?.map((practice) => {
              return (
                <PracticeWrapper
                  width="31.25rem"
                  key={practice.projectActivityId}>
                  {practicesCount === 1 &&
                    renderActivityCategory(activityCategory, practice)}
                  {practicesCount > 1 && renderPracticeListItem(practice)}

                  <InputFieldWrapper>
                    <InputField
                      value={practice.contractedUnits}
                      name={MODAL_CONTENT.contractedUnits}
                      placeholder={MODAL_CONTENT.incentive_placeholder}
                      category="long-numbers-field"
                      onUpdate={(event) =>
                        handlePracticeDataUpdate(
                          MODAL_CONTENT.contractedUnits,
                          event.target.value,
                          practice.projectActivityId,
                          activityCategory,
                          year,
                          projectCycleId,
                        )
                      }
                      {...inputStyleProps}
                    />
                    <InputField
                      value={practice.incentiveRate}
                      name={MODAL_CONTENT.incentiveRate}
                      placeholder={MODAL_CONTENT.number_field_placeholder}
                      category="long-numbers-field"
                      onUpdate={(event) =>
                        handlePracticeDataUpdate(
                          MODAL_CONTENT.incentiveRate,
                          event.target.value,
                          practice.projectActivityId,
                          activityCategory,
                          year,
                          projectCycleId,
                        )
                      }
                      primaryError={practice.error?.length > 0}
                      {...inputStyleProps}
                      customInputSx={FocusedInputSx}
                    />
                  </InputFieldWrapper>
                </PracticeWrapper>
              );
            })}
          </CategoryWrapper>
        );
      })}
      {uniqueIncentiveErrorList
        ?.filter(
          (error) => error !== MODAL_CONTENT.incentive_rate_required_error,
        )
        .map((error) => {
          return (
            <ErrorWrapper className="contract-modal-error" key={error}>
              {error}
            </ErrorWrapper>
          );
        })}
      {errorMessageState && (
        <ErrorWrapper className="contract-modal-error">
          {MODAL_CONTENT.contracted_acre_sum_error}
        </ErrorWrapper>
      )}
    </>
  );
};
ProjectActivityDetailsSections.propTypes = {
  totalEnrolledAcres: PropTypes.object,
  handleEnrolledAcresUpdate: PropTypes.func,
  enrolledAcresError: PropTypes.string,
  totalAcresInputProps: PropTypes.object,
  activityPracticeData: PropTypes.object,
  handlePracticeDataUpdate: PropTypes.func,
  inputStyleProps: PropTypes.object,
  uniqueIncentiveErrorList: PropTypes.array,
  year: PropTypes.number,
  projectCycleId: PropTypes.number,
  setAcreExceedError: PropTypes.func,
};
export default ProjectActivityDetailsSections;
