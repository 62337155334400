import { useContext, useEffect } from 'react';
import DetailedOperationsDataComp from '../DetailedOperationsDataComp/DetailedOperationsDataComp.react';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import {
  MainWrapperDiv,
  NavigationBtnWrapper,
} from '../TabBodyComponent.style';
import UserNavigationButtonsV2 from 'pages/ParticipantDataCollection/components/UserNavigationButtonsV2/UserNavigationButtonsV2.react';
import Loader from 'components/Loader/Loader.react';
import Proptypes from 'prop-types';
import ImplementedActivitiesV2 from 'pages/ParticipantDataCollection/ActivityReporting/components/ImplementedActivitiesV2/ImplementedActivitiesV2.react';
import { useLocation } from 'react-router';

const TabBodyComponentV2 = ({
  currentYearData,
  currentFarmId,
  currentFieldId,
  allExpanded,
  setFieldDetails,
  setEnrollmentYears,
  disableAllFieldActions = false,
  navigateHandler = () => null,
  refreshStatusHandler = () => null,
  gotoNextInprogressPage = () => null,
  setUserActivityDetected = () => null,
}) => {
  const { pathname } = useLocation();
  const {
    apiLoading,
    saveDataHandler,
    setSubmitData,
    setGlobalAllExpanded,
    setIsFieldDisabled,
    isFieldDisabled,
    updateImplementedActivities,
    fetchBaseLineFieldData,
  } = useContext(implementedActivitiesWithOpsDataContext);
  useEffect(() => {
    const isLastItemTrue =
      allExpanded && allExpanded[allExpanded.length - 1] === true;
    setGlobalAllExpanded((currentState) =>
      currentState.map((item) => ({
        ...item,
        value: isLastItemTrue,
      })),
    );
  }, [allExpanded]);
  useEffect(() => {
    setSubmitData((prev) => ({
      ...prev,
      sampleId: currentYearData?.sampleId,
      currentYearProjectCycleId: currentYearData?.projectCycleId,
      currentYear: currentYearData?.yearValue,
      phase: currentYearData?.phase,
    }));
  }, [currentYearData]);

  useEffect(() => {
    setIsFieldDisabled(
      currentYearData?.phase === 'baseline' &&
        pathname?.includes('participant-field-activity'),
    );
  }, [currentYearData?.phase, pathname]);

  const makeFormDirty = () => {
    setUserActivityDetected(true);
  };

  return (
    <MainWrapperDiv>
      <Loader loading={apiLoading} zIndex={999999} />
      <ImplementedActivitiesV2
        currentFieldId={currentFieldId}
        currentFarmId={currentFarmId}
        disableAllFieldActions={disableAllFieldActions}
        currentYearData={currentYearData}
        refreshStatusHandler={refreshStatusHandler}
        makeFormDirty={makeFormDirty}
        setUserActivityDetected={setUserActivityDetected}
      />
      <DetailedOperationsDataComp
        year={currentYearData?.yearValue}
        sampleId={currentYearData?.sampleId}
        setFieldDetails={setFieldDetails}
        setEnrollmentYears={setEnrollmentYears}
        disableAllFieldActions={disableAllFieldActions}
        currentFarmId={currentFarmId}
        currentFieldId={currentFieldId}
        makeFormDirty={makeFormDirty}
      />
      <NavigationBtnWrapper marginTop={'0.5rem'}>
        {!isFieldDisabled && !disableAllFieldActions && (
          <UserNavigationButtonsV2
            handleCancel={() => {
              navigateHandler();
            }}
            handleSave={() => {
              saveDataHandler()
                .then(() => {
                  setUserActivityDetected(false);
                  updateImplementedActivities();
                  refreshStatusHandler();
                })
                .finally(() => {
                  fetchBaseLineFieldData(currentYearData?.yearValue);
                });
            }}
            handleContinue={() => {
              saveDataHandler()
                .then(() => {
                  setUserActivityDetected(false);
                  gotoNextInprogressPage();
                })
                .catch(() => {
                  fetchBaseLineFieldData(currentYearData?.yearValue);
                });
            }}
          />
        )}
      </NavigationBtnWrapper>
    </MainWrapperDiv>
  );
};

TabBodyComponentV2.propTypes = {
  currentYearData: Proptypes.object,
  currentFarmId: Proptypes.number,
  currentFieldId: Proptypes.number,
  allExpanded: Proptypes.array,
  refreshStatusHandler: Proptypes.func,
  gotoNextInprogressPage: Proptypes.func,
  setUserActivityDetected: Proptypes.func,
  setFieldDetails: Proptypes.func,
  setEnrollmentYears: Proptypes.func,
  navigateHandler: Proptypes.func,
  disableAllFieldActions: Proptypes.bool,
};

export default TabBodyComponentV2;
