import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  CustomXAxisStyle,
  LegendBox,
  LegendBoxWrapper,
  Legendscontainer,
  LegendText,
  LegendWrapper,
  TitleContainer,
  WrapperDefaultStyle,
} from './CustomBarChart.style';
import { uniqueId } from 'utils/uniqueIdGenerator';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const CustomBarChart = ({
  options,
  data,
  title,
  style,
  height,
  barChartStyle,
  customXAxisLabel,
  showLegends = true,
}) => {
  return (
    <div style={{ ...WrapperDefaultStyle, ...style }}>
      {title !== undefined && <TitleContainer>{title}</TitleContainer>}
      {showLegends && (
        <Legendscontainer>
          {data.datasets.map((dataset) => (
            <LegendWrapper key={uniqueId()}>
              <LegendBoxWrapper>
                <LegendBox backgroundColor={dataset.backgroundColor} />
              </LegendBoxWrapper>
              <LegendText>{dataset.label}</LegendText>
            </LegendWrapper>
          ))}
        </Legendscontainer>
      )}
      <div style={{ height: height, ...barChartStyle }}>
        <Bar data={data} options={options} />
      </div>
      {customXAxisLabel && (
        <div style={CustomXAxisStyle}>{customXAxisLabel}</div>
      )}
    </div>
  );
};

CustomBarChart.propTypes = {
  options: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  title: PropTypes.string,
  style: PropTypes.object,
  barChartStyle: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customXAxisLabel: PropTypes.string,
  showLegends: PropTypes.bool,
};

export default CustomBarChart;
