import { useDropzone } from 'react-dropzone';
import {
  PAGE_CONTENT,
  formatBytes,
  getFileUploadError,
  dropdownProps,
  GeoReferenceTooltip,
  getImgSrc,
} from './FileUploadCompPOI.content';
import {
  PAGE_CONTENT as PARENT_CONTENT,
  residueTillagePractices,
} from '../ProofOfImplementationMainComp/ProofOfImplementationMainComp.content';
import { useEffect, useState } from 'react';
import {
  ChipsWrapper,
  CloseIconStyled,
  DragAndDropText,
  DragAndDropTitle,
  DropdownChip,
  DropdownWrapper,
  ErrorTextWrapper,
  FileUploadCompWrapper,
  ImgAndIconWrapper,
  ImgContainer,
  ImgTag,
  ImgWrapper,
  SmallUploadWrapper,
  Subtext,
  UploadHelpText,
  UploadedContentWrapper,
  deleteIconStyle,
} from './FileUploadCompPOI.style';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { checkTernaryCondition } from 'utils/helper';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { DropDownPlaceholder } from 'components/FormComponents/FormStyles';
import PropTypes from 'prop-types';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import { COOL_GRAY } from 'theme/GlobalColors';
import { TOOLTIP_PLACEMENTS } from 'utils/config';

const FileUploadCompPOI = ({
  handleFileDelete,
  label,
  acceptedFilesInfo,
  dropzoneInfoText,
  currentDropzoneData = [],
  handleDropdownValueUpdate = () => {},
  addFilesToTabData,
  fieldDetails = [],
  practiceTypeList = [],
  handleDropdownValueRemove = () => {},
  hasPracticeTypeDropdown = false,
  isGeorefenceType = false,
  disableAllFarmActions = false,
}) => {
  const [deleteIcons, setDeleteIcons] = useState(
    Array.from({ length: currentDropzoneData?.length }, () => false),
  );
  const [hoveredThumbnail, setHoveredThumbnail] = useState(null);
  const [uploadError, setUploadError] = useState({ flag: false, message: '' });

  const handleMouseHover = (index, flag) => {
    setHoveredThumbnail(checkTernaryCondition(flag, index, null));
    const updatedDeleteIcons = [...deleteIcons];
    updatedDeleteIcons[index] = flag;
    setDeleteIcons(updatedDeleteIcons);
  };
  const renderErrorText = (fields, fileStatus) => {
    const [firstField] = fields;
    if (firstField === 0) {
      if (isGeorefenceType) {
        return PAGE_CONTENT.incorrect_geo_reference_error;
      }
      if (fileStatus === PAGE_CONTENT.submitted) {
        return PAGE_CONTENT.field_tag_required_error;
      }
    }
    if (firstField === -1) {
      return PAGE_CONTENT.missing_geo_reference_error;
    }
    if (isGeorefenceType && fields.length > 1) {
      return PAGE_CONTENT.multipleGeoReferenceError;
    }
    return null;
  };
  const dropZoneProps = {
    ...(acceptedFilesInfo.mimeType &&
      acceptedFilesInfo.extensions && {
        accept: { [acceptedFilesInfo.mimeType]: acceptedFilesInfo.extensions },
      }),
    ...(acceptedFilesInfo.maxFileSize && {
      maxSize: acceptedFilesInfo.maxFileSize,
    }),
    ...(acceptedFilesInfo.acceptProps && {
      accept: acceptedFilesInfo.acceptProps,
    }),
    disabled: disableAllFarmActions,
    multiple: true,
  };

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone(dropZoneProps);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      addFilesToTabData(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      const totalFiles = acceptedFiles.length + fileRejections.length;
      const numOfFileNotSupportedErrorText = `${fileRejections.length} of ${totalFiles} files were not uploaded due to invalid format. ${acceptedFilesInfo.fileNotSupportedErrorText}`;

      setUploadError({
        flag: true,
        message: getFileUploadError(
          fileRejections[0].errors[0].code,
          acceptedFilesInfo.maxFileSize,
          numOfFileNotSupportedErrorText,
        ),
      });
    } else {
      setUploadError({ flag: false, message: '' });
    }
  }, [fileRejections, acceptedFiles]);

  const getFieldChips = (
    selectedValues,
    dropdownList,
    fileId,
    dropdownType,
  ) => {
    if (selectedValues[0] === PAGE_CONTENT.fieldDropdownPlaceholder)
      return (
        <DropDownPlaceholder customPlaceholderColor={COOL_GRAY}>
          {PAGE_CONTENT.fieldDropdownPlaceholder}
        </DropDownPlaceholder>
      );

    if (selectedValues[0] === PAGE_CONTENT.practiceDropdownPlaceholder)
      return (
        <DropDownPlaceholder customPlaceholderColor={COOL_GRAY}>
          {PAGE_CONTENT.practiceDropdownPlaceholder}
        </DropDownPlaceholder>
      );

    return (
      <ChipsWrapper>
        {selectedValues?.map((value) => {
          const itemLabel = dropdownList.find(
            (item) => item.value === value,
          )?.label;

          return (
            <DropdownChip key={value}>
              {itemLabel}
              <CloseIconStyled
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
                onClick={() => {
                  !disableAllFarmActions &&
                    handleDropdownValueRemove(
                      fileId,
                      value,
                      dropdownType,
                      isGeorefenceType,
                    );
                }}
              />
            </DropdownChip>
          );
        })}
      </ChipsWrapper>
    );
  };

  const fieldDropdownProps = {
    dropdownlist: fieldDetails,
    dropDownPlaceholder: PAGE_CONTENT.fieldDropdownPlaceholder,
    ...dropdownProps,
  };

  const activityDropdownProps = {
    dropDownPlaceholder: PAGE_CONTENT.practiceDropdownPlaceholder,
    ...dropdownProps,
  };

  return (
    <FileUploadCompWrapper>
      {!disableAllFarmActions && (
        <DragAndDropTitle>
          {label}
          {isGeorefenceType && (
            <InfoTooltip
              placement={TOOLTIP_PLACEMENTS.BOTTOM_END}
              content={<GeoReferenceTooltip />}
            />
          )}
        </DragAndDropTitle>
      )}
      <UploadedContentWrapper>
        <ImgContainer>
          {currentDropzoneData.map((fileItem, index) => {
            const { file, fileId, fields, practice, fileName, fileStatus } =
              fileItem;

            const fieldDropdownValues = [
              ...fields.filter(
                (fieldId) =>
                  fieldDetails.find((field) => field.value === fieldId) !==
                  undefined,
              ),
            ];
            const practiceDropdownValues = [
              ...practice.filter(
                (fieldId) =>
                  practiceTypeList.find((field) => field.value === fieldId) !==
                  undefined,
              ),
            ];

            if (fieldDropdownValues?.length === 0)
              fieldDropdownValues.push(PAGE_CONTENT.fieldDropdownPlaceholder);
            if (practiceDropdownValues?.length === 0)
              practiceDropdownValues.push(
                PAGE_CONTENT.practiceDropdownPlaceholder,
              );

            // Note: This part is just for the sake of residue + tillage management activity, if any one of the practices belonging to residue + tillage is selected, the other practice will be filtered out.
            const selectedResiduePractice = practiceTypeList?.find(
              (practice) =>
                residueTillagePractices.includes(practice.label) &&
                practiceDropdownValues.includes(practice.value),
            );

            let updatedPracticeTypeDropdownList = [];
            if (selectedResiduePractice) {
              updatedPracticeTypeDropdownList = [selectedResiduePractice];
            } else updatedPracticeTypeDropdownList = practiceTypeList;

            //====== Till here ======//

            return (
              <ImgWrapper key={fileId}>
                <ImgAndIconWrapper
                  data-testid="thumbnailWrapper"
                  hasError={false}
                  onMouseEnter={() => handleMouseHover(index, true)}
                  onMouseLeave={() => handleMouseHover(index, false)}>
                  <ImgTag src={getImgSrc(file, fileName)} alt={fileName} />
                  {deleteIcons[index] &&
                    hoveredThumbnail === index &&
                    !disableAllFarmActions && (
                      <DeleteOutlinedIcon
                        sx={deleteIconStyle}
                        fontSize="large"
                        data-testid={`deleteIcon-${index}`}
                        onClick={() => handleFileDelete(fileId)}
                      />
                    )}
                </ImgAndIconWrapper>
                <DropdownWrapper>
                  <DropDown
                    value={fieldDropdownValues}
                    customRenderValues={(selectedValues, dropdownList) =>
                      getFieldChips(
                        selectedValues,
                        dropdownList,
                        fileId,
                        PARENT_CONTENT.fieldDropdown,
                      )
                    }
                    onUpdate={(event) =>
                      handleDropdownValueUpdate(
                        event.target.value.filter(
                          (item) =>
                            item !== PAGE_CONTENT.fieldDropdownPlaceholder,
                        ),
                        fileId,
                        PARENT_CONTENT.fieldDropdown,
                      )
                    }
                    {...fieldDropdownProps}
                    isDisabled={disableAllFarmActions}
                  />
                  <ErrorTextWrapper>
                    {renderErrorText(fields, fileStatus)}
                  </ErrorTextWrapper>
                  {hasPracticeTypeDropdown && (
                    <>
                      <DropDown
                        dropdownlist={updatedPracticeTypeDropdownList}
                        value={practiceDropdownValues}
                        customRenderValues={(selectedValues, dropdownList) =>
                          getFieldChips(
                            selectedValues,
                            dropdownList,
                            fileId,
                            PARENT_CONTENT.practiceTypeDropdown,
                          )
                        }
                        onUpdate={(event) =>
                          handleDropdownValueUpdate(
                            event.target.value.filter(
                              (item) =>
                                item !==
                                PAGE_CONTENT.practiceDropdownPlaceholder,
                            ),
                            fileId,
                            PARENT_CONTENT.practiceTypeDropdown,
                          )
                        }
                        {...activityDropdownProps}
                        isDisabled={disableAllFarmActions}
                      />
                      {practice.length === 0 &&
                        fileStatus === PAGE_CONTENT.submitted && (
                          <ErrorTextWrapper>
                            {PAGE_CONTENT.practice_tag_required_error}
                          </ErrorTextWrapper>
                        )}
                    </>
                  )}
                </DropdownWrapper>
              </ImgWrapper>
            );
          })}
        </ImgContainer>
      </UploadedContentWrapper>
      {!disableAllFarmActions && (
        <SmallUploadWrapper isDisabled={false} width={'auto'}>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input data-testid="fileDropzone" {...getInputProps()} />
            <DragAndDropText>
              {
                <>
                  {dropzoneInfoText}
                  <Subtext>{PAGE_CONTENT.browse}</Subtext>
                  {PAGE_CONTENT.FULL_STOP}
                </>
              }
            </DragAndDropText>
          </div>
        </SmallUploadWrapper>
      )}
      {acceptedFilesInfo?.maxFileSize && !disableAllFarmActions && (
        <UploadHelpText>{`${PAGE_CONTENT.max_file_info_text}${formatBytes(
          acceptedFilesInfo?.maxFileSize,
        )}`}</UploadHelpText>
      )}
      {uploadError.flag && (
        <UploadHelpText marginTop={'0rem'} iserror={true}>
          {uploadError.message}
        </UploadHelpText>
      )}
    </FileUploadCompWrapper>
  );
};

FileUploadCompPOI.propTypes = {
  handleFileDelete: PropTypes.func,
  label: PropTypes.string,
  acceptedFilesInfo: PropTypes.object,
  dropzoneInfoText: PropTypes.string,
  currentDropzoneData: PropTypes.array,
  handleDropdownValueUpdate: PropTypes.func,
  addFilesToTabData: PropTypes.func,
  fieldDetails: PropTypes.array,
  practiceTypeList: PropTypes.array,
  handleDropdownValueRemove: PropTypes.func,
  hasPracticeTypeDropdown: PropTypes.bool,
  isGeorefenceType: PropTypes.bool,
  disableAllFarmActions: PropTypes.bool,
};

export default FileUploadCompPOI;
