import React, { useContext, useEffect, useMemo } from 'react';
import {
  AcresError,
  ActivityFlexItems,
  ActivityItemData,
  ActivityTitle,
  EdgeOfFieldError,
  EdgeOfFieldItems,
  InputWithDeleteWrapper,
  LabelWithAlerts,
  NoteSection,
  NoteTag,
  PredefinedUnit,
  Subtitle,
  TextWrapper,
  Title,
  barrierWidthInputStyleProps,
  deleteIconStyles,
  inputStyleProps,
  rowsCountInputStyleProps,
  saveBtnSx,
  speciesCategoryStyleProps,
  speciesDensityInputStyleProps,
  stripWidthInputStyleProps,
} from './ImplementedActivitiesV2.style';
import {
  ACTIVITY_FIELDS_ERROR_KEY,
  INPUT_LABELS,
  JumpToOpsData,
  PAGE_CONTENT,
  residueTillageNote,
} from './ImplementedActivitiesV2.content';
import MenuOptionsButton from 'components/Buttons/MenuOptionsButton/MenuOptionsButton.react';
import AddIcon from '@mui/icons-material/Add';
import { useReportingApis } from 'hooks/useReportingApis';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  canShowField,
  formatActivityAcreageData,
  getActivityError,
  getLargestCropAreaWithSoilAmendment,
} from './ImplementedActivitiesV2.functions';
import { checkTernaryCondition, isEmpty } from 'utils/helper';
import { ReactComponent as DeleteIcon } from 'assets/icons/OutlinedDeleteWIthHoverEffect.svg';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { projectActivitiesMapping } from '../../ActivityReporting.content';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { uniqueId } from 'utils/uniqueIdGenerator';
import ActivityDeleteModal from 'pages/ParticipantDataCollection/components/ActivityDeleteModal/ActivityDeleteModal.react';
import { ACTIVITY_CATEGORY, ACTIVITY_FIELDS } from 'utils/config';

const ImplementedActivitiesV2 = ({
  currentFieldId,
  currentFarmId,
  currentYearData,
  disableAllFieldActions = false,
  refreshStatusHandler = () => null,
  makeFormDirty = () => null,
  setUserActivityDetected = () => null,
}) => {
  const { fetchActivityAcreage, fetchProjectActivities } = useReportingApis();
  const {
    fieldArea,
    largestCoverCropArea,
    cropAreasWithSoilAmendments,
    activityAcreageList,
    setActivityAcreageList,
    activityList,
    setActivityList,
    edgeOfFieldSpeciesCategory,
    fetchEdgeOfFieldSpeciesTypeDropDown,
    startApiProgress,
    endApiProgress,
    fetchSoilAmendmentsList,
    setDeletedActivities,
    isFieldDisabled,
    saveDataHandler,
    activityDeleteModalProps,
    activityToBeDeleted,
    handleActivityDelete,
    closeActivityDeleteModal,
    deleteAcreageByUniqueKey,
    updateImplementedActivities,
    fetchBaseLineFieldData,
    totalOperationCropArea,
    isInBaselineYear,
    handleDeleteFromOpsData,
  } = useContext(implementedActivitiesWithOpsDataContext);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('projectid');

  const handleEdgeOfFieldDataUpdate = (newVal, key, errorKey, activityId) => {
    if (
      key === ACTIVITY_FIELDS.SPECIES_CATEGORY ||
      newVal.match(/^\d*(\.\d{0,2})?$/) ||
      newVal === ''
    ) {
      const newValue = checkTernaryCondition(newVal === '.', '0.', newVal);

      setActivityAcreageList((prevActivityAcreageList) => {
        return prevActivityAcreageList.map((activity) => {
          if (activity.activityId === activityId) {
            return {
              ...activity,
              [key]: newValue,
              [errorKey]: newValue === '',
            };
          }
          return activity;
        });
      });
    }
  };

  const addNewActivity = (option) => {
    makeFormDirty();
    // Note: Populating field area directly for "Residue + tillage management" activity category(reduced till / No till)
    const newActivityArea = checkTernaryCondition(
      option.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE,
      fieldArea,
      '',
    );
    const newActivity = {
      // Unique key to identify each activity(just for UI purpose -> key for mapping)
      uniqueKey: uniqueId(),
      fieldProjectActivityId: 0,
      activityId: option.projectActivityId,
      area: newActivityArea,
      activityCategory: option.activityCategory,
      projectActivityType: option.projectActivityType,
      label: option.label,
      edgeOfFieldSampleId: 0,
      // Note: Edge of field related fields
      speciesCategory: '',
      stripWidth: '',
      barrierWidth: '',
      rowsCount: '',
      speciesDensity: '',
      // Errors
      acresError: '',
      acresRequiredError: false,
      // Edge of field related errors
      speciesCategoryRequired: false,
      stripWidthRequired: false,
      barrierWidthRequired: false,
      rowsCountRequired: false,
      speciesDensityRequired: false,
    };

    setActivityAcreageList((prevActivityAcreageList) => [
      ...prevActivityAcreageList,
      newActivity,
    ]);
  };

  const handleActivityAcresUpdate = (
    activityId,
    activityCategory,
    projectActivityType,
    value,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,5})?$/;

    if (decimalNumberRegex.test(value)) {
      const largestCropAreaWithSoilAmendment =
        getLargestCropAreaWithSoilAmendment(
          cropAreasWithSoilAmendments,
          projectActivityType,
        );

      const area = checkTernaryCondition(value === '.', '0.', value);
      const error = getActivityError({
        activityCategory: activityCategory,
        activityAcres: value,
        fieldArea: fieldArea,
        largestCoverCropArea: largestCoverCropArea,
        largestCropAreaWithSoilAmendment: largestCropAreaWithSoilAmendment,
        totalOperationCropArea,
      });

      setActivityAcreageList((prevActivityAcreageList) => {
        return prevActivityAcreageList.map((activity) => {
          if (activity.activityId === activityId) {
            return {
              ...activity,
              area: area,
              acresError: error,
              acresRequiredError: value === '',
            };
          }

          return activity;
        });
      });
    }
  };

  const fetchPageData = () => {
    startApiProgress();
    fetchActivityAcreage(
      currentFieldId,
      currentYearData?.sampleId,
      currentYearData?.projectCycleId,
    )
      .then((acreageList) => {
        setActivityAcreageList(
          formatActivityAcreageData(
            acreageList,
            fieldArea,
            largestCoverCropArea,
            cropAreasWithSoilAmendments,
          ),
        );
        setDeletedActivities([]);
      })
      .catch(() => {})
      .finally(endApiProgress);
  };

  const handleSave = (event) => {
    event.target.blur();
    saveDataHandler()
      .then(() => {
        updateImplementedActivities();
        fetchBaseLineFieldData(currentYearData?.yearValue);
        refreshStatusHandler();
      })
      .finally(() => {
        setUserActivityDetected(false);
      });
  };

  useEffect(() => {
    if (
      currentFarmId &&
      currentFieldId &&
      currentYearData.sampleId &&
      currentYearData.projectCycleId
    ) {
      fetchPageData();
    }
  }, [
    currentFarmId,
    currentFieldId,
    currentYearData.sampleId,
    currentYearData.projectCycleId,
  ]);

  useEffect(() => {
    startApiProgress();
    fetchProjectActivities(projectId)
      .then((activities) =>
        setActivityList(
          activities.map((activity) => ({
            ...activity,
            activityCategory:
              projectActivitiesMapping[activity.activityCategory],
          })),
        ),
      )
      .catch(() => {})
      .finally(endApiProgress);

    fetchEdgeOfFieldSpeciesTypeDropDown();
    fetchSoilAmendmentsList();
  }, []);

  const filteredActivityDropdownList = useMemo(() => {
    const populatedActivityIds = activityAcreageList?.map(
      (activity) => activity.activityId,
    );

    const residueTillageActivitySelected = activityAcreageList?.some(
      (activity) =>
        activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE,
    );

    const activities = activityList?.filter((activity) => {
      // If one of the residue + tillage management activity(reduced till / no till) is already selected, then don't show the other activity.
      if (
        residueTillageActivitySelected &&
        activity.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE
      ) {
        return false;
      }

      return !populatedActivityIds?.includes(activity.projectActivityId);
    });

    return activities.map((activity) => {
      let label = activity.activityCategory;
      // Note: Since 'Cover crop' and 'Nutrient management' have both category and activity as same name, we are not showing both category and activity name in the UI
      if (
        label !== ACTIVITY_CATEGORY.NUTRIENT_MANAGEMENT &&
        label !== ACTIVITY_CATEGORY.COVER_CROP
      ) {
        label += `: ${activity.projectActivityType}`;
      }
      return {
        id: activity.projectActivityId,
        projectActivityId: activity.projectActivityId,
        activityCategory: activity.activityCategory,
        projectActivityType: activity.projectActivityType,
        label: label,
      };
    });
  }, [activityAcreageList, activityList]);

  /**
   * @description function to render input field with delete icon
   * @param {Object} activityData
   * @param {String} marginTop
   * @param {Boolean} isDisabled
   * @param {Boolean} showErrorIcon
   * @param {Boolean} showEdgeOfFieldError
   * @param {Boolean} isFieldDisabled
   * @returns {Element} Input field with delete icon
   */
  const getInputWithDelete = (
    activityData,
    marginTop = null,
    isDisabled = false,
    showErrorIcon = false,
    showEdgeOfFieldError = false,
    isFieldDisabled = false,
    deleteButtonStyles = deleteIconStyles,
  ) => {
    return (
      <InputWithDeleteWrapper marginTop={marginTop}>
        <InputField
          category="long-numbers-field"
          testId={activityData.label}
          isDisabled={isDisabled || disableAllFieldActions} // Disable input field for Residue + tillage
          value={activityData.area}
          predefinedUnit={
            <PredefinedUnit>
              acres
              {showErrorIcon && <AlertIcon />}
            </PredefinedUnit>
          }
          onUpdate={(event) => {
            handleActivityAcresUpdate(
              activityData.activityId,
              activityData.activityCategory,
              activityData.projectActivityType,
              event.target.value,
            );
            makeFormDirty();
          }}
          {...inputStyleProps}
        />
        {/* Note: handling error layout differently for edge of field since it has different structure */}
        {showEdgeOfFieldError && (
          <EdgeOfFieldError>{activityData.acresError}</EdgeOfFieldError>
        )}
        {!isFieldDisabled && !disableAllFieldActions && (
          <DeleteIcon
            onClick={() => {
              handleActivityDelete(activityData);
              makeFormDirty();
            }}
            style={deleteButtonStyles}
            data-testid="delete-icon"
          />
        )}
      </InputWithDeleteWrapper>
    );
  };

  /**
   * @description function to render activity item data
   * @param {Object} activityData
   * @returns {Element} Activity item data
   */
  const getActivityItemData = (activityData) => {
    const isResidueTillage =
      activityData.activityCategory === ACTIVITY_CATEGORY.RESIDUE_PLUS_TILLAGE;
    const isCoverCrop =
      activityData.activityCategory === ACTIVITY_CATEGORY.COVER_CROP;
    const isSoilAmendment =
      activityData.activityCategory === ACTIVITY_CATEGORY.SOIL_CARBON_AMENDMENT;
    // Note: Edge of field related flags
    const isEdgeOfField =
      activityData.activityCategory === ACTIVITY_CATEGORY.EDGE_OF_FIELD;
    const showStripWidth =
      isEdgeOfField && canShowField(activityData, ACTIVITY_FIELDS.STRIP_WIDTH);
    const showBarrierWidth =
      isEdgeOfField &&
      canShowField(activityData, ACTIVITY_FIELDS.BARRIER_WIDTH);
    const showRowsCount =
      isEdgeOfField && canShowField(activityData, ACTIVITY_FIELDS.ROWS_COUNT);
    const showSpeciesDensity =
      isEdgeOfField &&
      canShowField(activityData, ACTIVITY_FIELDS.SPECIES_DENSITY);

    // Note: Error handling for acres field
    const acresFieldHasError = activityData.acresError?.length > 0;
    const showErrorIcon = acresFieldHasError || activityData.acresRequiredError;
    const disableDeleteInput = isResidueTillage || isFieldDisabled;

    return (
      <ActivityItemData key={activityData.uniqueKey}>
        <ActivityFlexItems>
          <ActivityTitle>{activityData.label}</ActivityTitle>
          {!isFieldDisabled && !disableAllFieldActions && (
            <JumpToOpsData category={activityData.activityCategory} />
          )}
        </ActivityFlexItems>
        {isResidueTillage && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {residueTillageNote(activityData.projectActivityType)}
          </NoteSection>
        )}
        {isCoverCrop && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {PAGE_CONTENT.coverCropNote}
          </NoteSection>
        )}
        {isSoilAmendment && (
          <NoteSection>
            <NoteTag>Note: </NoteTag>
            {PAGE_CONTENT.soilAmendmentNote}
          </NoteSection>
        )}
        {!isEdgeOfField &&
          getInputWithDelete(
            activityData,
            null,
            disableDeleteInput,
            showErrorIcon,
            acresFieldHasError && isEdgeOfField,
            isFieldDisabled,
          )}
        {/* Note: handling error layout differently for edge of field and other activities */}
        {acresFieldHasError && !isEdgeOfField && (
          <AcresError>{activityData.acresError}</AcresError>
        )}
        {isEdgeOfField && (
          <EdgeOfFieldItems>
            <DropDown
              isDisabled={isFieldDisabled || disableAllFieldActions}
              testId={`${activityData.label} - dropdown`}
              dropdownlist={edgeOfFieldSpeciesCategory}
              value={activityData.speciesCategory}
              setDropDownValInParent={(newVal) => {
                handleEdgeOfFieldDataUpdate(
                  newVal,
                  ACTIVITY_FIELDS.SPECIES_CATEGORY,
                  ACTIVITY_FIELDS_ERROR_KEY.SPECIES_CATEGORY_REQUIRED,
                  activityData.activityId,
                );
                makeFormDirty();
              }}
              label={
                <LabelWithAlerts>
                  Species category
                  {activityData.speciesCategoryRequired && <AlertIcon />}
                </LabelWithAlerts>
              }
              {...speciesCategoryStyleProps}
            />

            {showStripWidth && (
              <InputField
                isDisabled={isFieldDisabled || disableAllFieldActions}
                testId="stripWidth"
                value={activityData.stripWidth}
                category="long-numbers-field"
                onUpdate={(event) =>
                  handleEdgeOfFieldDataUpdate(
                    event.target.value,
                    ACTIVITY_FIELDS.STRIP_WIDTH,
                    ACTIVITY_FIELDS_ERROR_KEY.STRIP_WIDTH_REQUIRED,
                    activityData.activityId,
                  )
                }
                label={
                  <LabelWithAlerts>
                    {INPUT_LABELS.STRPI_WIDTH}
                    {activityData.stripWidthRequired && <AlertIcon />}
                  </LabelWithAlerts>
                }
                {...stripWidthInputStyleProps}
              />
            )}

            {showBarrierWidth && (
              <InputField
                isDisabled={isFieldDisabled || disableAllFieldActions}
                testId="barrierWidth"
                category="long-numbers-field"
                value={activityData.barrierWidth}
                onUpdate={(event) =>
                  handleEdgeOfFieldDataUpdate(
                    event.target.value,
                    ACTIVITY_FIELDS.BARRIER_WIDTH,
                    ACTIVITY_FIELDS_ERROR_KEY.BARRIER_WIDTH_REQUIRED,
                    activityData.activityId,
                  )
                }
                label={
                  <LabelWithAlerts>
                    {INPUT_LABELS.BARRIER_WIDTH}
                    {activityData.barrierWidthRequired && <AlertIcon />}
                  </LabelWithAlerts>
                }
                {...barrierWidthInputStyleProps}
              />
            )}

            {showRowsCount && (
              <InputField
                isDisabled={isFieldDisabled || disableAllFieldActions}
                category="long-numbers-field"
                testId="rowsCount"
                value={activityData.rowsCount}
                onUpdate={(event) =>
                  handleEdgeOfFieldDataUpdate(
                    event.target.value,
                    ACTIVITY_FIELDS.ROWS_COUNT,
                    ACTIVITY_FIELDS_ERROR_KEY.ROWS_COUNT_REQUIRED,
                    activityData.activityId,
                  )
                }
                label={
                  <LabelWithAlerts>
                    {INPUT_LABELS.ROW_COUNT}
                    {activityData.rowsCountRequired && <AlertIcon />}
                  </LabelWithAlerts>
                }
                {...rowsCountInputStyleProps}
              />
            )}

            {showSpeciesDensity && (
              <InputField
                isDisabled={isFieldDisabled || disableAllFieldActions}
                category="long-numbers-field"
                testId="speciesDensity"
                value={activityData.speciesDensity}
                onUpdate={(event) =>
                  handleEdgeOfFieldDataUpdate(
                    event.target.value,
                    ACTIVITY_FIELDS.SPECIES_DENSITY,
                    ACTIVITY_FIELDS_ERROR_KEY.SPECIES_DENSITY_REQUIRED,
                    activityData.activityId,
                  )
                }
                label={
                  <LabelWithAlerts>
                    {INPUT_LABELS.SPECIES_DENSITY}
                    {activityData.speciesDensityRequired && <AlertIcon />}
                  </LabelWithAlerts>
                }
                {...speciesDensityInputStyleProps}
              />
            )}
            {getInputWithDelete(
              activityData,
              '2.7rem',
              disableDeleteInput,
              showErrorIcon,
              acresFieldHasError && isEdgeOfField,
              isFieldDisabled,
              {
                ...deleteIconStyles,
                alignSelf: 'baseline',
                marginTop: '0.3rem',
              },
            )}
          </EdgeOfFieldItems>
        )}
      </ActivityItemData>
    );
  };

  useEffect(() => {
    return () => {
      // Clear acreage data on unmout of the component
      setActivityAcreageList([]);
    };
  }, []);

  return (
    <div>
      <Title>
        {checkTernaryCondition(
          isInBaselineYear,
          <TextWrapper gap={'0.25rem'}>
            <Title>{PAGE_CONTENT.titleForBaseline}</Title>
            <Title fontWeight={400}>{PAGE_CONTENT.optional}</Title>
          </TextWrapper>,
          <Title>{PAGE_CONTENT.title}</Title>,
        )}
      </Title>
      <Subtitle>
        {checkTernaryCondition(
          isInBaselineYear,
          PAGE_CONTENT.subtitleForBaseline,
          PAGE_CONTENT.subtitle,
        )}
      </Subtitle>
      {activityAcreageList?.map((activityData) =>
        getActivityItemData(activityData),
      )}

      {!isFieldDisabled && (
        <MenuOptionsButton
          btnLabel={PAGE_CONTENT.activityDropdownBtnLabel}
          startIcon={<AddIcon />}
          optionsList={filteredActivityDropdownList}
          handleOptionClick={addNewActivity}
          disabled={
            isEmpty(filteredActivityDropdownList) || disableAllFieldActions
          }
        />
      )}

      {!isFieldDisabled && (
        <Button
          sx={saveBtnSx}
          disabled={disableAllFieldActions} // Keep it always enabled
          disableElevation
          onClick={handleSave}>
          Save
        </Button>
      )}

      <ActivityDeleteModal
        open={activityDeleteModalProps?.open}
        closeModal={closeActivityDeleteModal}
        handleDeleteFromOpsData={handleDeleteFromOpsData}
        activityTitle={activityDeleteModalProps?.activityTitle}
      />
    </div>
  );
};

// proptypes
ImplementedActivitiesV2.propTypes = {
  currentFieldId: PropTypes.number,
  currentFarmId: PropTypes.number,
  currentYearData: PropTypes.object,
  refreshStatusHandler: PropTypes.func,
  makeFormDirty: PropTypes.func,
  setUserActivityDetected: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};

export default ImplementedActivitiesV2;
