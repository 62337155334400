import styled from 'styled-components';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  DARK_GRAY,
  DARK_GREEN,
  WHITE,
} from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';

export const Container = styled.div`
  border-right: ${({ borderRight }) => borderRight};
  border: ${({ border }) => border};
  padding: ${({ padding }) => padding};
  justify-content: ${({ justifyContent }) => justifyContent};
  display: flex;
  display: -webkit-flex;
  flex-direction: ${({ direction }) => direction};
  min-width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap ?? '1rem'};
  box-shadow: ${({ hasBoxShadow }) =>
    checkTernaryCondition(
      hasBoxShadow,
      '0px 2px 3px 0px rgba(0, 0, 0, 0.1)',
      'none',
    )};
  border-radius: ${({ borderRadius }) => borderRadius};
  min-height: ${({ minHeight }) => minHeight};
  box-sizing: ${({ boxSizing }) => boxSizing};
`;
export const DetailsContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-top: 3.5rem;
`;
export const ParticipantsContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  min-height: 3.5rem;
  padding: 1rem;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;
export const ParticipantsWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 550;
  font-size: 24px;
  line-height: 140%;
  color: ${COOL_GRAY};
  margin-top: 0.25rem;
`;
export const ParticipantBtnWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
`;

export const FieldsContainer = styled.div`
  display: flex;
  display: -webkit-flex
  gap: 1rem;
  flex-direction: column;
`;

export const FieldsRow = styled.div`
  display: flex;
  display: -webkit-flex
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
`;

export const Field = styled.div`
  min-width: ${(props) => props.minWidth ?? 'auto'};
  flex: 1;
`;

export const FieldTitle = styled.div`
  color: ${({ color }) => checkTernaryCondition(color, color, '#63666A')};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  padding: ${({ padding }) => padding};
  margin-bottom: 0.5rem;
`;
export const FieldValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${DARK_CHARCOAL};
  word-wrap: break-word;
  max-width: 20rem;
`;
export const FieldUl = styled.ul`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${DARK_CHARCOAL};
  word-wrap: break-word;
  max-width: 20rem;
  list-style-type: none;
  margin: 0;
  padding-left: 0;
`;
export const TitleWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
  margin-bottom: 0.5rem;
`;
export const SubheadingWrapper = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  color: ${COOL_GRAY};
`;
export const FreeTextWrapper = styled.div`
  border: 1px solid #97999b;
  border-radius: 2px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #333333;
  min-height: 6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  height: 8.25rem;
`;

export const SectionHeader = styled.div`
  font-family: Open Sans;
  color: ${DARK_CHARCOAL};
  font-weight: 600;
`;

export const FlexCenterContainer = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const ButtonSx = {
  textTransform: 'none',
  fontSize: '12px',
  padding: '5px 15px',
  minWidth: '3rem',
  height: '2rem',
  marginTop: '0.3rem',
  color: DARK_GREEN,
  border: `1px solid ${DARK_GREEN}`,
  '&: hover': {
    border: `1px solid ${DARK_GREEN}`,
  },
};

export const BarChartStyle = {
  padding: '1rem 1rem 0 1rem',
  width: '95%',
  height: '80%',
};

export const mapHeaderStyle = {
  fontWeight: 700,
  fontSize: '0.75rem',
  fontFamily: 'Open Sans',
  color: COOL_GRAY,
};
export const mapStyle = { height: '100%', width: '100%' };
export const BarChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: white;
`;

export const BarStyle = styled.div`
  display: ${({ display }) => display};
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-size: 12px;
  font-weight: 400;
  border-radius: ${({ borderRadius }) => borderRadius};
  height: ${({ height }) => height ?? '2rem'};
  width: ${({ width }) => width};
  border: ${({ border }) => border};
  border-width: ${({ borderWidth }) => borderWidth};
  padding: ${({ padding }) => padding ?? '4px'};
  font-style: ${({ fontStyle }) => fontStyle};
  align-items: ${({ alignItems }) => alignItems};
  background: ${({ background }) => background};
  line-height: ${({ lineHeight }) => lineHeight ?? '2rem'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-self: ${({ alignSelf }) => alignSelf};
`;
export const BarSection = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
export const TextWrapper = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  display: flex;
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  font-style: ${({ fontStyle }) => fontStyle};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign ?? 'center'};
`;
export const TextWrapperParentForReporting = styled.div`
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
  align-content: ${({ alignContent }) => alignContent};
  display: flex;
`;
export const TextWrapperForReportingParticipants = styled.div`
  gap: ${({ gap }) => gap};
  display: flex;
`;
export const TextWrapperForNoDataLabel = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  position: absolute;
  top: 22.5rem;
  align-self: center;
  justify-content: center;
`;
export const NoDataLabelAndChart = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EmptyDataWrapper = styled.div`
  font-size: 0.875rem;
  padding: ${({ padding }) => padding ?? '1rem'};
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor};
  height: ${({ height }) => height};
`;

export const EmptyDataLabel = styled.span`
  max-width: 20rem;
  text-align: center;
  font-weight: 600;
  color: ${COOL_GRAY};
`;

export const labelStyle = {
  fontSize: '0.75rem',
  color: COOL_GRAY,
};

export const dropDownStyle = {
  color: DARK_CHARCOAL,
  fontSize: '0.75rem',
  height: '1.75rem',
};

export const RecruitmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  line-height: 1.25rem;
`;

export const HeaderTitle = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: ${DARK_CHARCOAL};
`;

export const RecruitmentChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0rem;
`;

export const BarChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const GoalBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  gap: 0.125rem;
  margin: ${({ margin }) => margin};
  height: fit-content;
`;

export const GoalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: ${({ alignSelf }) => alignSelf};
  margin-right: ${({ marginRight }) => marginRight || '-0.5rem'};
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  width: ${({ width }) => width};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const HorizontalLine = styled.hr`
  color: ${DARK_GRAY};
  background-color: ${DARK_GRAY};
  width: 100%;
  margin: 0;
  border: none;
  padding: 0.5px;
  align-self: center;
`;

export const DottedLineDiv = styled.div`
  height: 2rem;
  border-left: 1px dashed ${WHITE};
  margin-bottom: -2.125rem;
  position: relative;
  margin-left: ${({ marginLeft }) => `calc(${marginLeft} - 0.125rem)`};
`;
