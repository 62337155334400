import {
  COMPONENT_TYPES,
  CONTRACT,
  CREDIT_BATCHES,
  CREDIT_DEMAND_SCHEDULE,
  CREDIT_REQUESTS,
  CREDIT_SUPPLY_SCHEDULE,
  filename,
  PORTFOLIO,
  PRICE_ASSUMPTIONS,
  PROJECTS,
  STAKEHOLDERS,
  SUPPLY_CONTRACT_PROJECT_MAPPING,
  UNIT_COST_SCHEDULE,
} from './config';
import moment from 'moment-timezone';

export const getFileName = (areaCode) => {
  const dt = new Date();
  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);

  let downloadFileName = '';

  const timestamp = `${padL(dt.getMonth() + 1)}${padL(
    dt.getDate(),
  )}${dt.getFullYear()}_${padL(dt.getHours())}${padL(dt.getMinutes())}${padL(
    dt.getSeconds(),
  )}`;

  downloadFileName = `${filename[areaCode]}_${timestamp}`;
  return downloadFileName;
};

export const yearDropdownGenerator = (start, end) => {
  let yearDropdownValues = [];
  for (let i = start; i <= end; i++) {
    yearDropdownValues.push(i);
  }
  return yearDropdownValues;
};

export const dbExtractionNeeded = (areaCode) => {
  const dbExtNeeded = [
    CREDIT_SUPPLY_SCHEDULE,
    PROJECTS,
    STAKEHOLDERS,
    UNIT_COST_SCHEDULE,
    CONTRACT,
    CREDIT_DEMAND_SCHEDULE,
    CREDIT_REQUESTS,
    PRICE_ASSUMPTIONS,
    CREDIT_BATCHES,
    SUPPLY_CONTRACT_PROJECT_MAPPING,
  ];
  return dbExtNeeded.includes(areaCode);
};

//to scroll to error section
export const scroll = (className) => {
  const section = document.querySelector(className);
  /* istanbul ignore else */
  if (section) {
    section?.scrollIntoView?.({ behavior: 'smooth', block: 'center' });
  }
};

export const covertDateToLocalTimeZone = (
  dateToConvert,
  format = 'MM/DD/YYYY HH:mm:ss',
) => {
  const dateUtc = moment.utc(dateToConvert);
  const localDate = moment(dateUtc).local().format(format);
  return localDate;
};

export const previousPathHandler = (previousPath) => {
  return previousPath ? previousPath : PORTFOLIO;
};

export const setErrorStateHandler = (condition, setMethod) => {
  condition ? setMethod(true) : setMethod(false);
};

export const classNameHandler = (flag, firstClassName, secondClassName) => {
  return flag ? firstClassName : secondClassName;
};

export const ternaryBooleanHandler = (condition) => {
  return condition ? true : false;
};

export const displayTextValue = (value, empty) => {
  return value === null || value === undefined || !value.trim() ? empty : value;
};
// By using this function redunce contigency complexity in sonar lint
export const checkTernaryCondition = (item, condition1, conditon2) => {
  return item ? condition1 : conditon2;
};

export const isNotNill = (text) => {
  return text !== null && text !== '';
};
export const isNotEmpty = (text) => {
  return text !== '';
};

/**
 * @description check if the data is empty
 * @param {String | null | undefined | Object | Array} data
 * @returns {Boolean}
 * @example
 * data = ''
 * @returns true
 * @example
 * data = undefined
 * @returns true
 * @example
 * data = {}
 * @returns true
 * @example
 * data = []
 * @returns true
 * @example
 * data = 'abc'
 * @returns false
 */
export const isEmpty = (data) => {
  if (typeof data === 'string') {
    data = data?.trim();
  }

  return (
    data === '' ||
    data === undefined ||
    data === null ||
    data === 'null' ||
    data === 'None' ||
    data === 'undefined' ||
    (typeof data === 'object' && Object.keys(data).length === 0) ||
    (Array.isArray(data) &&
      (data.length <= 0 || data.every((element) => element === undefined)))
  );
};

export const validateEmail = (email) => {
  const regex = /^\w+([.-]\w+)*@\w+([.-]\w+)*(\.\w{2,3})/;
  return email.match(regex);
};

export const validatePhone = (phone) => {
  const regex = /^\d{10}$/;
  return phone.match(regex);
};

export const replaceSpecialCharactersWithSingleCharacter = (
  regex,
  value,
  replaceCharacter,
) => {
  return value.replace(regex, replaceCharacter);
};

export const getCommaSeperatedStringValues = (array) => {
  return array.join(', ');
};

export const formatPhoneNumber = (value) => {
  const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
};

//Get date in June 16th, 2023 format.
export const getReportDateIssued = (timestamp) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const month = monthNames[timestamp.getMonth()];
  const date = timestamp.getDate();
  const year = timestamp.getFullYear();

  function getSuffixValue(date) {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const lastDigit = date % 10;
    const suffix =
      lastDigit >= 1 && lastDigit <= 3 && (date < 11 || date > 13)
        ? suffixes[lastDigit]
        : suffixes[0];
    return `${date}${suffix}`;
  }

  const formattedDate = `${month} ${getSuffixValue(date)}, ${year}`;

  return formattedDate;
};

export const isValidJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
};
export const addDashInPhoneNumber = (number) => {
  return number ? number.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';
};

//get current date in 20230703 format
export const getCurrentDate = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
};

/**
 *
 * @description function to conver date format from YYYY-MM-DD to DD/MM/YYYY
 */

export const convertDateFormat = (dateString) => {
  const formattedDate = moment(new Date(dateString)).format('MM/DD/YYYY');
  return formattedDate;
};
/**
 *
 * @description function to conver date format from Timestamp to DD/MM/YYYY by appending 0 for single digits in month and date
 */

export const convertTimeStampFormat = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const openPDFFromLink = (pdfUrl) => {
  const anchorElement = document.createElement('a');
  anchorElement.href = pdfUrl;
  anchorElement.target = '_blank';
  anchorElement.click();
};

//to load js scripts dynamically
export const loadJS = (file, dataDomainScript) => {
  // DOM: Create the script element
  const jsElm = document.createElement('script');
  // set the type attribute
  jsElm.type = 'application/javascript';
  //set attributes
  jsElm.setAttribute('data-domain-script', dataDomainScript);
  // make the script element load file
  jsElm.src = file;
  // finally insert the element to the body element in order to load the script
  document.getElementsByTagName('head')[0].appendChild(jsElm);
};

export const getTopMostCoordinate = (polygonCoordinates) => {
  let topMostCoordinate = polygonCoordinates[0];
  polygonCoordinates.forEach((coordinate) => {
    /* istanbul ignore else */
    if (topMostCoordinate.lat < coordinate.lat) topMostCoordinate = coordinate;
  });
  return topMostCoordinate;
};

export const formatBoundaries = (boundaries) => {
  return boundaries?.map((coordinate) => {
    return { latitude: coordinate.lat, longitude: coordinate.lng };
  });
};

export const parseBoundaries = (boundaries) => {
  return boundaries.map((coordinate) => {
    return { lat: coordinate.latitude, lng: coordinate.longitude };
  });
};

//to check the access of user based on role or permission
export const checkAccess = (
  userPermissionsOrRoles,
  requiredPermissionOrRole,
) => {
  if (userPermissionsOrRoles !== undefined) {
    return userPermissionsOrRoles.includes(requiredPermissionOrRole);
  } else {
    return false;
  }
};
export const hasAnyRequiredAccess = (
  userPermissionsOrRoles,
  requiredPermissionsOrRoles,
) => {
  return requiredPermissionsOrRoles.some((required) =>
    checkAccess(userPermissionsOrRoles, required),
  );
};
//to handle layout background
export const setParticipantLayoutBackgorund = (
  image,
  condition,
  commonBackground,
) => {
  if (image === '') return condition ? commonBackground : '';
  else
    return condition ? commonBackground : `${commonBackground}, url(${image})`;
};

export const formatGeoJson = (jsonData) => {
  const formattedData = [];
  jsonData.features.forEach((feature) => {
    feature.geometry.coordinates.forEach((coordinatesArray) => {
      const newPolygon = [];
      coordinatesArray[0].forEach((coordinate) => {
        newPolygon.push({ lat: coordinate[1], lng: coordinate[0] });
      });
      formattedData.push(newPolygon);
    });
  });

  return formattedData;
};

export const valueToBeDisplayedForEmptyString = (stringValue) => {
  return checkTernaryCondition(
    stringValue === '' || stringValue === undefined || stringValue === null,
    '--',
    stringValue,
  );
};

export const getYesOrNo = (condition) => {
  return condition ? 'Yes' : 'No';
};

export const isPointInsidePolygon = (polygonCoordinates, newPoint) => {
  let inside = false;
  const len = polygonCoordinates.length;
  for (let a = 0, b = len - 1; a < len; b = a, a++) {
    const lng_A = polygonCoordinates[a].lng;
    const lat_A = polygonCoordinates[a].lat;
    const lng_B = polygonCoordinates[b].lng;
    const lat_B = polygonCoordinates[b].lat;

    const intersect =
      lat_A < newPoint.lat !== lat_B < newPoint.lat &&
      newPoint.lng <
        lng_A + ((newPoint.lat - lat_A) * (lng_B - lng_A)) / (lat_B - lat_A);

    if (intersect) inside = !inside;
  }
  return inside;
};

export const doLinesIntersect = (lineOneCoordinates, lineTwoCoordinates) => {
  const { lat1: x1, lng1: y1, lat2: x2, lng2: y2 } = lineOneCoordinates;
  const { lat1: x3, lng1: y3, lat2: x4, lng2: y4 } = lineTwoCoordinates;
  // Calculate the slopes of the two lines
  const m1 = (y2 - y1) / (x2 - x1);
  const m2 = (y4 - y3) / (x4 - x3);

  // Calculate the y-intercepts
  const b1 = y1 - m1 * x1;
  const b2 = y3 - m2 * x3;

  // If the lines are parallel then there is no intersection
  if (m1 === m2) {
    return false;
  }

  // Calculate the x-coordinate of the intersection point
  const xIntersection = (b2 - b1) / (m1 - m2);

  // If the xIntersection is within the line segments then we have an intersection point
  return (
    xIntersection >= Math.min(x1, x2) &&
    xIntersection <= Math.max(x1, x2) &&
    xIntersection >= Math.min(x3, x4) &&
    xIntersection <= Math.max(x3, x4)
  );
};

/**
 * @description function to extract city, state, county and zip code from google map address components
 * @param {Array} addressComponents
 * @returns {Object}
 */
export const extractAddrFromGoogleMapComponents = (addressComponents) => {
  let address = { city: '', state: '', zipCode: '', county: '' };

  addressComponents.forEach((component) => {
    /* istanbul ignore else */
    if (component.types.includes(COMPONENT_TYPES.LOCALITY)) {
      address.city = component.long_name;
    }
    /* istanbul ignore else */
    if (component.types.includes(COMPONENT_TYPES.ADMINISTRATIVE_AREA_LEVEL_1)) {
      address.state = component.long_name;
    }
    /* istanbul ignore else */
    if (component.types.includes(COMPONENT_TYPES.ADMINISTRATIVE_AREA_LEVEL_2)) {
      address.county = component.long_name;
    }
    /* istanbul ignore else */
    if (component.types.includes(COMPONENT_TYPES.POSTAL_CODE)) {
      address.zipCode = component.short_name;
    }
  });

  return address;
};

export const isValueEmptyNullorUndefined = (value) => {
  return value?.trim() === '' || value === undefined || value === null;
};

export const getMailingAddress = (addressComponents) => {
  let name = '';
  addressComponents.forEach((component) => {
    /* istanbul ignore else */
    if (
      !component.types.includes(COMPONENT_TYPES.LOCALITY) &&
      !component.types.includes(COMPONENT_TYPES.ADMINISTRATIVE_AREA_LEVEL_1) &&
      !component.types.includes(COMPONENT_TYPES.ADMINISTRATIVE_AREA_LEVEL_2) &&
      !component.types.includes(COMPONENT_TYPES.POSTAL_CODE) &&
      !component.types.includes(COMPONENT_TYPES.COUNTRY) &&
      !component.types.includes(COMPONENT_TYPES.ROUTE)
    ) {
      name += ', ' + component.long_name;
    }
  });

  return name;
};

export const getDefaultValue = (value) => {
  return value === undefined || value === null ? '' : value;
};

export const getBooleanValueForNullOrUndefined = (value) => {
  return value === undefined || value === null || value === 0;
};

export const getBooleanValueForNullOrUndefinedOrEmpty = (value) => {
  return value === undefined || value === null || value === '';
};

export const getBooleanValueForNotNullOrUndefinedOrEmpty = (value) => {
  return value && value !== '' && value !== null;
};

export const getUniqueObjectsByKey = (list, key) => {
  return list?.reduce((uniqueArr, currentObj) => {
    const isUnique =
      uniqueArr.findIndex((obj) => obj[key] === currentObj[key]) === -1;
    /* istanbul ignore else */
    if (isUnique) {
      uniqueArr.push(currentObj);
    }
    return uniqueArr;
  }, []);
};
export const onlyCharsRegex = /^[a-zA-Z\s]*$/;

export const barChartOptions = {
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: true,
      },
      ticks: {
        display: true,
        stepSize: 50,
        drawTicks: true,
      },
      suggestedMax: 200,
      drawBorder: false,
    },
    y: {
      display: true,
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
  plugins: {
    datalabels: {
      /**
       * @description function (value, index, values)
       */
      formatter: function (value) {
        if (Math.abs(value) > 0) {
          return Math.round(value);
        } else {
          value = '';
          return value;
        }
      },
      anchor: 'end',
      align: 'start',
      font: {
        weight: 'bold',
      },
      offset: -32,
    },
    legend: {
      display: false,
    },
  },
};

// function to get the latest date from array
export const getLatestDate = (dateArray) => {
  let latestDate = dateArray[0];
  dateArray.forEach((date) => {
    /* istanbul ignore else */
    if (new Date(date) > new Date(latestDate)) {
      latestDate = date;
    }
  });
  return latestDate;
};

/**
 * @description function to debounce the function call to avoid multiple calls
 */
export const debounceFunc = (func, delay, timerRef) => {
  return function (...args) {
    const context = this;
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const getNextName = (existingNames = [], prefix = '') => {
  const regex = new RegExp(`^${prefix}(\\d+)$`);

  const numbers = existingNames.map((ele) => {
    const match = ele.match(regex);
    return match ? parseInt(match[1], 10) : 0;
  });
  /* istanbul ignore else */
  if (numbers.length === 0) {
    return prefix + '1';
  }

  return prefix + (Math.max(...numbers) + 1);
};

export const convertSquareMetersToAcres = (squareMeters) => {
  return (squareMeters * 0.000247105).toFixed(2);
};

export const parseStringArray = (stringArray) => {
  stringArray = stringArray.substring(1, stringArray.length - 1);
  const output = stringArray.split(',').map((item) => item.trim());

  return output;
};

export const getUniqueElements = (array) => {
  return [...new Set(array)];
};
export const checkAllValuesAreZeroTrue = (...values) => {
  return values.every((value) => value === 0);
};

export const verifyDateFormat = (dateString) => {
  const regex =
    /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
  return regex.test(dateString);
};

export const addZeroBeforeDot = (value) => {
  return checkTernaryCondition(value.startsWith('.'), `0${value}`, value);
};

export const isNullOrEmpty = (text) => text === null || text === '';

export function omit(obj, arr) {
  for (let key in obj) {
    /* istanbul ignore else */
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      /* istanbul ignore else */
      if (arr.includes(key)) {
        delete obj[key];
      } else if (Array.isArray(obj[key])) {
        for (const element of obj[key]) {
          omit(element, arr);
        }
      } else if (typeof obj[key] === 'object') {
        omit(obj[key], arr);
      }
    }
  }
  return obj;
}
export const isIntersect = (...arr) => {
  const n = arr.length;
  arr.sort((i1, i2) => {
    return +i1.startYear - +i2.startYear;
  });
  for (let i = 1; i < n; i++)
    /* istanbul ignore else */
    if (+arr[i - 1].endYear > +arr[i].startYear) return true;
  return false;
};

export const isLast20yearsDataNotPresent = (arr) => {
  const currentYear = new Date().getFullYear();
  const twentyYearsAgo = currentYear - 20;
  let coveredYears = new Set();

  arr.forEach((landUse) => {
    for (
      let year = Math.max(landUse.startYear, twentyYearsAgo);
      year <= landUse.endYear && year <= currentYear;
      year++
    ) {
      coveredYears.add(year);
    }
  });

  return coveredYears.size < 20;
};

/**
 * Checks if the difference between startYear and endYear exceeds 20 years for any land use type in the array.
 *
 * @param {Array<Object>} data - The array of land use type objects.
 * @param {string} dataArray[].startYear - The start year as a string.
 * @param {string} dataArray[].endYear - The end year as a string.
 * @returns {boolean} - Returns true if any difference between startYear and endYear exceeds 20 years, otherwise false.
 */

export const isHistoricLandUseType20years = (data = []) => {
  const totalNumberOfYears = data.reduce((acc, curr) => {
    const startYear = parseInt(curr.startYear) || 0;
    const endYear = parseInt(curr.endYear) || 0;
    return acc + endYear - startYear;
  }, 0);
  return totalNumberOfYears >= 20 - 1;
};

export const checkValidEndYear = (...arr) => {
  for (const element of arr) {
    /* istanbul ignore else */
    if (+element.endYear < +element.startYear) {
      return false; // Invalid endYear found
    }
  }
  return true; // All endYears are valid
};
export const checkIfValueZero = (value) => {
  return value === 0;
};

export const convertCommaseperatedStringToArray = (string) => {
  /* istanbul ignore else */
  if (string.length === 0) return [];
  return string.split(',').map((item) => Number(item.trim()));
};

export const sortWithAKeyInSpecificOrder = (list, key, orderList) => {
  return list.sort((a, b) => {
    const indexA = orderList.indexOf(a[key]);
    const indexB = orderList.indexOf(b[key]);
    /* istanbul ignore else */
    if (indexA === -1) return 1;
    /* istanbul ignore else */
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};

export const removeEmptyStringsFromArray = (array) =>
  removeStringsFromArray(array, '');

export const removeStringsFromArray = (array, stringsToRemoveArray) => {
  return array.filter((item) => !stringsToRemoveArray.includes(item));
};

export const calculatePercentage = (value, total) => {
  return (value / total) * 100;
};
export const b64toBlob = (b64Data, contentType) => {
  contentType = contentType || '';
  const sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, ''); // Removes the Base64 header part if present
  b64Data = b64Data.replace(/\s/g, '');
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};
export const setIntegerValueWhenEmpty = (value) => {
  if (value === '' || value === null) {
    return value;
  } else {
    return +value;
  }
};
export const returnTrueWhenValueIsNullOrEmpty = (value) => {
  return value === null || value === '';
};
export const whenValueIsUndefinedReturnEmptyString = (value) => {
  return value === undefined ? '' : value;
};
export const isEmptyOrUndefined = (value) => {
  return value === undefined || value === '';
};
export const isInValidDate = (date) => {
  /* istanbul ignore else */
  if (date === 'Invalid date') return true;
};
export const isNumberOrStringOfTheNumber = (value, refValue) => {
  return value === refValue || value === refValue.toString();
};

export function convertNumberToLocalString(number) {
  return number.toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}
/**
 * @description returns true if any of the arguments is true
 */
export function ifAnyIsTrue(...args) {
  return args.some((arg) => arg === true);
}
