import DialogBox from 'components/DialogBox/DialogBox.react';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import {
  PAGE_CONTENT,
  initialPipelineAcerageModalValues,
  inputStyleProps,
  totalAcresInputProps,
} from './PipelineAcerageModal.content';
import {
  DialogBoxButtonStyle,
  PracticeWrapper,
  PracticeListItem,
  ActivityCategory,
  ActivitiesTableHeading,
  ActivityTableData,
  CategoryWrapper,
  InfoTooltipSx,
} from '../AddContractModal/AddContractModal.style';
import { useForm } from 'hooks/useForm';
import {
  checkTernaryCondition,
  convertDateFormat,
  isEmpty,
} from 'utils/helper';
import axios from 'axios';
import { ADD_PIPELINE_ACREAGE, FETCH_PROJECT_CYCLES } from 'urls';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { toastStyle } from 'pages/AddOriginationProject/AddOriginationProject.style';
import InputField from 'components/FormComponents/InputField/InputField.react';
import InfoTooltip from 'components/FormComponents/InfoTooltip/InfoTooltip.react';
import Loader from 'components/Loader/Loader.react';
import {
  EdgeOfFieldTooltip,
  MODAL_CONTENT,
  activityCategoryMapping,
  calculateActivityErrors,
  showAlertIcon,
} from '../AddContractModal/AddContractModal.content';
import {
  ErrorWrapper,
  FocusedInputSx,
} from 'components/FormComponents/FormStyles';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { INPUT_CATEGORY, TOOLTIP_PLACEMENTS } from 'utils/config';
const PipelineAcerageModal = ({
  addPipelineAcresOpen,
  setAddPipelineAcresOpen,
  activityPracticeMapping,
  refreshHandler,
  projectId,
  participantId,
  projectCycleId,
  isEditMode = false,
}) => {
  const { setFormValue } = useForm(initialPipelineAcerageModalValues);
  const [activityPracticeData, setActivityPracticeData] = useState(
    activityPracticeMapping,
  );
  const [totalEnrolledAcres, setTotalEnrolledAcres] = useState(
    activityPracticeMapping.totalAcres,
  );
  const [loading, setLoading] = useState(false);
  const [projectCycles, setProjectCycles] = useState([]);
  const [errorMessageState, setErrorMessageState] = useState(false);
  const [residueAndTillageError, setResidueAndTillageError] = useState(false);
  const [activityData, setActivityData] = useState(activityPracticeMapping);

  useEffect(() => {
    let activityPracticeMapCopy = { ...activityPracticeMapping };
    delete activityPracticeMapCopy.totalAcres;

    setTotalEnrolledAcres(activityPracticeMapping.totalAcres ?? '');
    setActivityPracticeData(activityPracticeMapCopy);
    setActivityData(activityPracticeMapCopy);
  }, [activityPracticeMapping]);

  const onClose = () => {
    setAddPipelineAcresOpen({ isOpen: false, participantId: null });
    setFormValue({ pipelineUnits: [] });
    refreshHandler();
  };

  const onSubmit = () => {
    // Enrolled acres not provided
    const formattedActivityData = Object.keys(activityPracticeData).flatMap(
      (activityCategory) => {
        if (Array.isArray(activityPracticeMapping[activityCategory])) {
          return activityPracticeData[activityCategory].map((practice) => {
            return {
              projectActivityId: practice.projectActivityId,
              pipelineUnits: Number(practice.pipelineUnits),
            };
          });
        } else {
          return [];
        }
      },
    );
    setLoading(true);
    axios
      .post(ADD_PIPELINE_ACREAGE, formattedActivityData, {
        params: {
          participantId: participantId,
          projectId: projectId,
          projectCycleId: projectCycleId,
          totalEnrolledAcres:
            totalEnrolledAcres === undefined || totalEnrolledAcres === ''
              ? 0
              : Number(totalEnrolledAcres),
          pipelineUnits: JSON.stringify(formattedActivityData),
        },
      })
      .then(() =>
        toast(
          <CustomSnackbar
            type="success"
            message={
              isEditMode
                ? PAGE_CONTENT.edit_toast_success_message
                : PAGE_CONTENT.toast_success_message
            }
          />,
          toastStyle,
        ),
      )
      .catch(() => {
        toast(
          <CustomSnackbar
            type="error"
            message={
              isEditMode
                ? PAGE_CONTENT.edit_toast_error_message
                : PAGE_CONTENT.toast_error_message
            }
          />,
          toastStyle,
        );
      })
      .finally(() => {
        setLoading(false);
        onClose();
      });
  };

  const handlePracticeDataUpdate = (
    key,
    value,
    activityId,
    activityCategory,
  ) => {
    const decimalNumberRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalNumberRegex.test(value)) {
      setActivityPracticeData((prev) => {
        return {
          ...prev,
          [activityCategory]: prev[activityCategory].map((practice) => {
            if (practice.projectActivityId === activityId) {
              return {
                ...practice,
                [key]: checkTernaryCondition(value === '.', '0.', value),
              };
            }
            return practice;
          }),
        };
      });
    } else {
      setActivityPracticeData((prev) => {
        return {
          ...prev,
          [activityCategory]: prev[activityCategory].map((practice) => {
            if (practice.projectActivityId === activityId) {
              return {
                ...practice,
                [key]: '',
              };
            }
            return practice;
          }),
        };
      });
    }
  };

  const enrolledAcresUpdate = (event) => {
    const decimalRegex = /^\d{0,10}(\.\d{0,10})?$/;
    if (decimalRegex.test(event.target.value)) {
      setTotalEnrolledAcres(
        checkTernaryCondition(
          event.target.value === '.',
          '0.',
          event.target.value,
        ),
      );
    } else {
      setTotalEnrolledAcres('');
    }
  };

  const fetchProjectCycle = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;
        const cycleData = data.map((projectCycle) => {
          const endYear = new Date(projectCycle.cycleEndDate).getFullYear();
          return {
            label: `${endYear} (${convertDateFormat(
              projectCycle.cycleStartDate,
            )}-${convertDateFormat(projectCycle.cycleEndDate)})`,
            value: projectCycle.projectCycleId,
          };
        });

        setProjectCycles(cycleData);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const startYearLabel = useMemo(() => {
    const startCycleLabel = projectCycles.find(
      (cycle) => Number(cycle.value) === Number(projectCycleId),
    )?.label;
    return startCycleLabel ?? '';
  }, [projectCycles, projectCycleId]);

  useEffect(() => {
    fetchProjectCycle();
  }, []);
  /* useEffect to handle changes in activityPracticeData or totalEnrolledAcres
This effect recalculates errors and updates state when dependencies change. */
  useEffect(() => {
    const { residueAndTillageError, hasError, updatedActivityPracticeData } =
      calculateActivityErrors(activityPracticeData, totalEnrolledAcres, false);

    setResidueAndTillageError(residueAndTillageError);
    setActivityData(updatedActivityPracticeData);
    setErrorMessageState(hasError);
  }, [activityPracticeData, totalEnrolledAcres]);
  /**
   * @description This function takes an activity category and its corresponding practice object, and returns a JSX element.
   * If certain error conditions are met, an alert icon is displayed alongside the activity category.
   * @param {string} category - The name of the activity category.
   * @param {Object} practice - The practice object containing details about the activity.
   * @returns {JSX.Element} The rendered activity category component.
   */
  const renderActivityCategory = (category, practice) => {
    const errorConditions = {
      residueAndTillageError: residueAndTillageError,
      unitKey: PAGE_CONTENT.pipelineUnits,
      errorKey: MODAL_CONTENT.errorKey,
    };

    return (
      <ActivityCategory>
        {category}
        {category === MODAL_CONTENT.edgeOfField && (
          <InfoTooltip
            placement={TOOLTIP_PLACEMENTS.RIGHT}
            customSx={InfoTooltipSx}
            content={<EdgeOfFieldTooltip />}
          />
        )}
        {showAlertIcon(category, practice, errorConditions) && (
          <AlertIcon data-testid="pipeline-error-icon" />
        )}
      </ActivityCategory>
    );
  };
  /**
   * @description This function checks the ternary condition to determine the contracted units from the practice object.
   * It parses the value to a float and ensures that if the result is NaN, it defaults to 0.
   * @param {Object} practice - The practice object containing details about the activity.
   * @returns {JSX.Element} The rendered practice list item component.
   */
  const renderPracticeListItem = (practice) => (
    <PracticeListItem>
      {practice.projectActivityType}
      {practice.pipelineUnits > 0 && practice.error && (
        <AlertIcon data-testid="pipeline-error-icon" />
      )}
    </PracticeListItem>
  );
  return (
    <DialogBox
      title={checkTernaryCondition(
        isEditMode,
        PAGE_CONTENT.editTitle,
        PAGE_CONTENT.title,
      )}
      isOpen={addPipelineAcresOpen.isOpen}
      buttonGap="1rem"
      primaryButtonsMarginTop="0.5rem"
      onConfirm={() => {
        onSubmit();
      }}
      onCancel={onClose}
      btnDisabled={errorMessageState}
      acceptCtnLabel={PAGE_CONTENT.submit}
      declineCtnLabel={PAGE_CONTENT.cancel}
      focusButtonPadding="0.53rem 0.9rem"
      buttonSX={DialogBoxButtonStyle}
      dialogActions
      darkGreenBtnColor>
      <Loader loading={loading} zIndex={99999} />
      <InputField
        labelMarginTop="0.5rem"
        labelMarginBottom="0.25rem"
        rightSideLabelFormLabelMargin="0rem"
        category={INPUT_CATEGORY.LONG_NUMBER}
        value={totalEnrolledAcres}
        onUpdate={enrolledAcresUpdate}
        label={`${PAGE_CONTENT.total_enrolled_acres_label} ${startYearLabel}.`}
        {...totalAcresInputProps}
      />
      <ActivitiesTableHeading>
        {PAGE_CONTENT.activities_table_heading}
      </ActivitiesTableHeading>

      <table style={{ width: '28.25rem' }}>
        <tr>
          <ActivityTableData width="20.625rem">
            Activities + practices
          </ActivityTableData>
          <ActivityTableData width="6.125rem" alignment="right">
            Pipeline units
          </ActivityTableData>
        </tr>
        <tr>
          <ActivityTableData></ActivityTableData>
          <ActivityTableData alignment="right">Acres</ActivityTableData>
        </tr>
      </table>
      {Object.keys(activityData)?.map((activityCategory) => {
        if (activityCategory === 'totalAcres') return null;
        const category = activityCategoryMapping[activityCategory];
        const isCoverCropOrNutrient =
          category === MODAL_CONTENT.coverCrop ||
          category === MODAL_CONTENT.nutrientManagement;

        return (
          <CategoryWrapper key={activityCategory}>
            {!isCoverCropOrNutrient && renderActivityCategory(category)}

            {activityData[activityCategory]?.map((practice) => (
              <PracticeWrapper key={practice.projectActivityId}>
                {isCoverCropOrNutrient &&
                  renderActivityCategory(category, practice)}
                {!isCoverCropOrNutrient && renderPracticeListItem(practice)}

                <InputField
                  customInputSx={FocusedInputSx}
                  value={practice.pipelineUnits}
                  category={INPUT_CATEGORY.LONG_NUMBER}
                  placeholder={PAGE_CONTENT.numberFieldPlaceholder}
                  onUpdate={(event) =>
                    handlePracticeDataUpdate(
                      PAGE_CONTENT.pipelineUnits,
                      event.target.value,
                      practice.projectActivityId,
                      activityCategory,
                    )
                  }
                  primaryError={!isEmpty(practice.error)}
                  {...inputStyleProps}
                />
              </PracticeWrapper>
            ))}
          </CategoryWrapper>
        );
      })}
      {errorMessageState && (
        <ErrorWrapper>{PAGE_CONTENT.pipelineAcresSumLimit}</ErrorWrapper>
      )}
    </DialogBox>
  );
};

PipelineAcerageModal.propTypes = {
  addPipelineAcresOpen: PropTypes.bool,
  setAddPipelineAcresOpen: PropTypes.func,
  refreshHandler: PropTypes.func,
  activityPracticeMapping: PropTypes.object,
  projectId: PropTypes.number,
  participantId: PropTypes.number,
  projectCycleId: PropTypes.number,
  isEditMode: PropTypes.bool,
};

export default PipelineAcerageModal;
