import {
  checkTernaryCondition,
  isEmpty,
  returnTrueWhenValueIsNullOrEmpty,
} from 'utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';

export const MODAL_CONTENT = {
  crop_type_modal_heading: 'Crop',
  crop_season: 'Crop Season',
  crop_type: 'Crop type',
  date_planted: 'Date planted',
  date_harvested: 'Date terminated',
  selected_date: 'Select Date',
  submit: 'Submit',
  cancel: 'Cancel',
  uom: 'Unit of measure',
  select_unit_type: 'Select unit type',
  select_crop_type: 'Select crop type',
  annualYield: 'Annual yield',
  type_amount: 'Type amount',
  measured_per: 'Measured Per',
  crop_density: 'Planting density',
  type_density: 'Type density',
  crop_depth: 'Seeding depth',
  type_depth: 'Type depth',
  acre: 'acre',
  select_type: 'Select type',
  sixPointTwoFiveRem: '6.25rem',
  sevenPointFiveRem: '7.5rem',
  dropdownHeight: '2rem',
  thirteenPointSevenFiveRem: '13.75rem',
  eightPointSevenFiveRem: '8.75rem',
  eighteenPointTwoFiveRem: '18.25rem',
  tenRem: '10rem',
  crop_rotation_label: 'Implementation of crop rotation?',
  crop_area: 'Crop area',
  type_area: 'Type area',
  crop_type_error: 'Crop type selection is required.',
  crop_area_error: 'Crop area is required.',
  uom_error: 'Unit of measure selection is required.',
  annual_yield_error: 'Annual yield amount is required.',
  date_planted_error: 'Date planted is required.',
  date_harvested_error: 'Date terminated is required.',
  crop_rotation_error: 'Implementation of crop rotation  is required.',
  date_harvest_error: 'Date terminated must occur after date planted.',
  date_plant_error: 'Date planted must occur before date terminated.',
  date_planted_year_error:
    'Date planted can only occur within the baseline year and up to 1 year prior.',
  date_harvested_year_error:
    'Date terminated can only occur within the baseline year.',
  total_field_area_error: 'Crop area can’t exceed total area of the field',
  project_activity_label:
    'You indicated during field enrollment that the following project activities were implemented on this field. Which were applied to this cash crop?',
  project_activity_instruction: 'Select all that apply',
  project_activity_error:
    'A selection is required for any project activity implemented on the field.',
  crop_density_error:
    'Unit of measure required if planting density is provided.',
  crop_depth_error: 'Unit of measure required if seeding depth is provided.',
  cash_crop: 'Cash crop',
  cover_crop: 'Cover crop',
  cash_crop_value: 'CASH_CROP',
  cover_crop_value: 'COVER_CROP',
  fallow_crop_value: 'FALLOW',
  management_type: 'Planned management type',
  select_management_type: 'Select management type',
  management_type_error: 'Planned management type selection is required.',
  production_purpose: 'Production purpose',
  production_purpose_error: 'Production purpose selection is required.',
  silage: 'Mechanical harvest; silage',
  grain: 'Mechanical harvest; grain',
  otherHarvested: 'Other harvested crop',
  grazing: 'Grazing',
  typeAmount: 'Type amount',
  tillage_dropdown_heading:
    'How would you classify the tillage system for this crop?',
  select_tillage_system: 'Select tillage system',
  tillage_system_error: 'A tillage type selection is required.',
  tillage_practice: 'Please specify tillage practice',
  tillage_practice_error:
    'Please specify a tillage type if other selected for tillage practice.',
  disturbance_type: 'Disturbance type',
  disturbance_type_error: 'A disturbance type selection is required.',
  tillage_system: 'Tillage system',
  other: 'Other',
  tillage_practice_placeholder: 'Please specify tillage practice',
  crop_area_greater_than_zero_validation: 'Crop area must be greater than 0.',
  annual_yield_uom: 'null/null',
  invalid_annual_yield_uom: 'null',
  crop_value_input: 'Crop value ($)',
  crop_value_empty: '',
  crop_value_dollar: '$',
  crop_value_placeholder: 'Type crop value',
  fallow: 'Fallow',
  agriculture_residues_managed: 'How were agricultural residues managed?',
  agriculture_residues_managed_placeholder: 'Select option',
  agriculture_residues_managed_name: 'agricultureResiduesManaged',
  crop_residue_removed: 'Crop residues removed',
  date_planted_winter_error:
    'The allowable range is August 1st of the {{previous year}} - End of February of the {{current year}}',
  date_planted_summer_error:
    'The allowable range is January 1st of the {{current year}} - August 31st of the {{current year}}',
  date_harvested_summer_error:
    'The allowable range is June 1st of the {{current year}} - Dec 31st of the {{current year}}',
  date_harvested_winter_error:
    'The allowable range is October 1st of the {{previous year}} - August 31st of the {{current year}}',
  irrigation_button: ' + Irrigation',
  soil_amendment_button: ' + Soil Amendment',
  crop_type_help_text:
    'To change any of the disabled fields after saving, you must delete and re-enter the record.',
  summer: 'Summer',
  yieldError: 'The allowable range for annual yield is ',
  densityError: 'The allowable range for planting density is ',
  annualYieldName: 'annualYield',
  annualYieldRange: 'annualYieldRange',
  plantingDensityErrorMessage: 'plantingDensityErrorMessage',
};
export const initialCropTypeData = {
  sampleId: '',
  cropSampleId: '',
  cropId: '',
  annualYield: '',
  plantingDensity: '',
  cropValue: '',
  plantingDensityUom: '',
  cropDepth: '',
  cropDepthUom: '',
  cropPlantDate: '',
  cropTerminationDate: '',
  cropArea: '',
  cropPlannedManagementTypeId: '',
  productionPurpose: '',
  annualYieldUom: '',
  cropAreaUom: 'acre',
  cropSeason: '',
  agricultureResiduesManaged: '',
  cropResidueRemoved: '',
  irrigationList: [
    {
      irrigationRate: '',
      irrigationType: '',
      uom: '',
      id: uniqueId(),
    },
  ],
  soilAmendmentList: [],
};
export const modalOpenState = {
  flag: false,
  id: 0,
  data: null,
  currentRowId: 0,
};
export const cropDepthDropdownList = [
  { label: 'cm', value: 'cm' },
  { label: 'in', value: 'in' },
];

export const cropDensityDropdownList = [
  { label: 'kg/ha', value: 'kg/ha' },
  { label: 'seeds/m2', value: 'seeds/m2' },
  { label: 'lbs/acre', value: 'lbs/acre' },
];

export const cropYieldMeasuredDropdownList = [
  { label: 'acre', value: 'acre' },
  { label: 'hectare', value: 'hectare' },
  { label: 'square meters', value: 'square meters' },
];

export const cropYieldUomDropdownList = [
  { label: 'bushels', value: 'bushels' },
  { label: 'tons', value: 'tons' },
  { label: 'pounds', value: 'pounds' },
  { label: 'liner feet', value: 'linear feet' },
  { label: 'in', value: 'in' },
];
export const cropRotationList = [
  { itemLabel: 'Yes', value: 'Yes' },
  { itemLabel: 'No', value: 'No' },
];

export const annualYieldDropdown = [
  { label: 'lbs/acre', value: 'lbs/acre' },
  { label: 'tons/acre', value: 'tons/acre' },
  { label: 'bushels/acre', value: 'bushels/acre' },
  { label: '% harvest efficiency', value: '% harvest efficiency' },
];

export const cropList = ['Corn', 'Soybeans', 'Spring wheat', 'Winter wheat'];

export const errorSetter = (
  formValue,
  allCropTypeDropDown = [],
  managementTypeDropDown = [],
) => {
  // finding crop type value
  const cropTypeValue = allCropTypeDropDown.find(
    (item) => item.value === formValue.cropId,
  )?.label;

  // finding management type value
  const managementTypeValue = managementTypeDropDown.find(
    (item) => item.value === formValue.cropPlannedManagementTypeId,
  )?.label;

  const { densityRange, yieldRange } = getRange(
    cropTypeValue,
    formValue.productionPurpose,
    managementTypeValue,
  );
  const hasError = {
    cropId: formValue.cropId === '',
    cropPlantDate: formValue.cropPlantDate === '' && formValue.isFetchedFromAPI,
    cropHarvestDate:
      formValue.cropTerminationDate === '' && formValue.isFetchedFromAPI,
    cropArea:
      (returnTrueWhenValueIsNullOrEmpty(formValue.cropArea) ||
        formValue.cropArea === 0) &&
      formValue.isFetchedFromAPI,
    cropDensity:
      formValue.plantingDensity?.length > 0 &&
      formValue.plantingDensityUom === '',
    cropDepth: formValue.cropDepth?.length > 0 && formValue.cropDepthUom === '',
    productionPurpose: !formValue.productionPurpose,
    cropPlannedManagementTypeId:
      returnTrueWhenValueIsNullOrEmpty(formValue.cropPlannedManagementTypeId) &&
      formValue.isFetchedFromAPI,
    annualYield:
      returnTrueWhenValueIsNullOrEmpty(formValue.annualYield) &&
      formValue.isFetchedFromAPI,
    annualYieldUom: returnTrueWhenValueIsNullOrEmpty(formValue.annualYieldUom),
    agricultureResiduesManaged:
      (formValue.agricultureResiduesManaged === undefined ||
        formValue.agricultureResiduesManaged === '') &&
      formValue.isFetchedFromAPI,
    cropResidueRemoved:
      (formValue.cropResidueRemoved === undefined ||
        formValue.cropResidueRemoved === '') &&
      formValue.isFetchedFromAPI,
    plantingDensity: checkTernaryCondition(
      densityRange && !isEmpty(formValue.plantingDensity),
      formValue.plantingDensity < densityRange[0] ||
        formValue.plantingDensity > densityRange[1],
      false,
    ),
    annualYieldRange: checkTernaryCondition(
      yieldRange,
      formValue.annualYield < yieldRange[0] ||
        formValue.annualYield > yieldRange[1],
      false,
    ),
    plantingDensityErrorMessage: densityRange || [],
    annualYieldErrorMessage: yieldRange || [],
  };
  return hasError;
};
export const findDefaultDates = (season, type, cropYear) => {
  switch (type) {
    case 'Date planted':
      if (season === 'Winter') {
        return `08/01/${cropYear - 1}`;
      } else {
        return `01/01/${cropYear}`;
      }
    case 'Date terminated':
      if (season === 'Winter') {
        return `10/01/${cropYear - 1}`;
      } else {
        return `06/01/${cropYear}`;
      }
  }
};
export const findDateExceededError = (
  plantError,
  harvestError,
  errorArray,
  setDateExceedError,
) => {
  if (!Object.values(errorArray).includes(true)) {
    setDateExceedError({
      planted: plantError,
      harvested: harvestError,
    });
  }
};

export const cropTypeDropdownValues = {
  corn: 'Corn',
  soybeans: 'Soybeans',
  springWheat: 'Spring wheat',
  otherHarvested: 'Other harvested crop',
  winterWheat: 'Winter wheat',
  coverCrop: 'Cover crop',
};

export const managementTypeDropdownValues = {
  mechanicalHarvestGrain: 'Mechanical harvest; grain',
  mechanicalHarvestSilage: 'Mechanical harvest; silage',
  grazing: 'Grazing',
};
export function formatErrorMessage(errorState, fieldName, range) {
  const errorMessages = {
    [MODAL_CONTENT.plantingDensityErrorMessage]: MODAL_CONTENT.densityError,
    [MODAL_CONTENT.annualYieldRange]: MODAL_CONTENT.yieldError,
  };

  /* istanbul ignore else */
  if (errorState[fieldName] && errorMessages[fieldName]) {
    const errorMessage = errorMessages[fieldName];
    /* istanbul ignore else */
    if (range[1] === Infinity) {
      return `${errorMessage} >= ${range[0]}`;
    }
    return `${errorMessage} ${range[0]} - ${range[1]}`;
  }
  return '';
}
export const ranges = {
  density: {
    [cropTypeDropdownValues.corn]: [10000, 300000],
    [cropTypeDropdownValues.soybeans]: [10000, 300000],
    [cropTypeDropdownValues.springWheat]: [1000000, 2000000],
    [cropTypeDropdownValues.winterWheat]: [1000000, 2000000],
    [cropTypeDropdownValues.coverCrop]: [1, 100],
    default: [0, Infinity],
  },
  yield: {
    [cropTypeDropdownValues.corn]: {
      [managementTypeDropdownValues.mechanicalHarvestGrain]: [0, 600],
      default: [0, Infinity],
    },
    [cropTypeDropdownValues.soybeans]: {
      [managementTypeDropdownValues.mechanicalHarvestGrain]: [0, 200],
      default: [0, Infinity],
    },
    [cropTypeDropdownValues.springWheat]: {
      [managementTypeDropdownValues.mechanicalHarvestGrain]: [0, 200],
      default: [0, Infinity],
    },
    [cropTypeDropdownValues.winterWheat]: {
      [managementTypeDropdownValues.mechanicalHarvestGrain]: [0, 200],
      default: [0, Infinity],
    },
    [managementTypeDropdownValues.grazing]: [0, 100],
    [managementTypeDropdownValues.mechanicalHarvestSilage]: [0, Infinity],
    [cropTypeDropdownValues.coverCrop]: [0, Infinity],
    default: [0, Infinity],
  },
};

export function getRange(
  cropTypeValue,
  productionPurpose,
  managementTypeValue,
) {
  const cropType = cropTypeValue || 'default';
  const managementType = managementTypeValue || 'default';

  const densityRange =
    ranges.density[productionPurpose] ||
    ranges.density[cropType] ||
    ranges.density.default;
  const yieldRange =
    ranges.yield[productionPurpose] ||
    ranges.yield[cropType]?.[managementType] ||
    ranges.yield[managementType] ||
    ranges.yield.default;

  return { densityRange, yieldRange };
}

export const plantingDensityConstants = {
  LBS_PER_ACRE: 'lbs/acre',
  SEEDS_PER_M2: 'seeds/acre',
};

export const productionPuposeConstants = {
  CASH_CROP: 'Cash crop',
  COVER_CROP: 'Cover crop',
};
