import styled from 'styled-components';

export const addBtnSx = {
  padding: '0.375rem 0.5rem',
  height: '1.75rem',
};
export const RunCalcButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  margin: 1rem 0 0.5rem 0;
`;
