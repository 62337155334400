import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Line } from 'react-chartjs-2';
import {
  TitleCurrentValueWrapper,
  LegendBox,
  LegendBoxWrapper,
  Legendscontainer,
  LegendText,
  LegendWrapper,
  TitleContainer,
  TitleLegendWrapper,
  WrapperDefaultStyle,
  CurrentActualWrapper,
  TextWrapper,
  BottomLegendsContainer,
  noDataLabelSx,
} from './CustomLineChart.style';
import { uniqueId } from 'utils/uniqueIdGenerator';
import LightTooltip from 'components/LightTooltip';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/helper';
import { Typography } from '@mui/material';
import { NO_DATA_VALUE } from './CustomLineChart.content';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const CustomLineChart = ({
  options,
  data,
  title,
  style,
  height,
  width,
  showLegends = true,
  ToolTipIconSx,
  tooltipTitle,
  placement,
  currentValueLabel,
  currentValue,
  currentValueColor,
  noDataLabel = NO_DATA_VALUE,
  showLegendsAtBottom = false,
  isDataSetEmpty = false,
  showTooltip = false,
}) => {
  return (
    <div style={{ ...WrapperDefaultStyle, ...style }}>
      {isDataSetEmpty && (
        <Typography sx={noDataLabelSx}>{noDataLabel}</Typography>
      )}
      <TitleCurrentValueWrapper>
        <TitleLegendWrapper>
          <TitleContainer>{title}</TitleContainer>
          {showLegends && (
            <Legendscontainer>
              {data?.datasets?.map(({ borderColor, textColor, label }) => (
                <LegendWrapper key={uniqueId()}>
                  <LegendBoxWrapper>
                    <LegendBox backgroundColor={borderColor}></LegendBox>
                  </LegendBoxWrapper>
                  <LegendText color={textColor}>{label}</LegendText>
                </LegendWrapper>
              ))}
            </Legendscontainer>
          )}
        </TitleLegendWrapper>
        <CurrentActualWrapper color={currentValueColor}>
          <TextWrapper>{currentValue}</TextWrapper>
          <TextWrapper
            fontSize={'0.688rem'}
            fontWeight={400}
            lineHeight={'0.936rem'}>
            {currentValueLabel}
          </TextWrapper>
          {showTooltip && (
            <LightTooltip title={tooltipTitle} placement={placement} arrow>
              <InfoOutlinedIcon sx={ToolTipIconSx} />
            </LightTooltip>
          )}
        </CurrentActualWrapper>
      </TitleCurrentValueWrapper>
      <div style={{ width: '100%' }}>
        {!isEmpty(width) && width !== 0 && (
          <Line
            key={width}
            height={height}
            width={width}
            data={data}
            options={options}
          />
        )}
      </div>
      {showLegendsAtBottom && (
        <BottomLegendsContainer>
          {data.datasets.map((dataset) => (
            <LegendWrapper key={uniqueId()}>
              <LegendBoxWrapper>
                <LegendBox backgroundColor={dataset.borderColor}></LegendBox>
              </LegendBoxWrapper>
              <LegendText>{dataset.label}</LegendText>
            </LegendWrapper>
          ))}
        </BottomLegendsContainer>
      )}
    </div>
  );
};

CustomLineChart.propTypes = {
  options: PropTypes.object,
  data: PropTypes.shape({
    datasets: PropTypes.arrayOf(
      PropTypes.shape({
        borderColor: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
  }),
  title: PropTypes.string,
  style: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  showLegends: PropTypes.bool,
  ToolTipIconSx: PropTypes.object,
  tooltipTitle: PropTypes.string,
  placement: PropTypes.string,
  currentValueLabel: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noDataLabel: PropTypes.string,
  currentValueColor: PropTypes.string,
  showLegendsAtBottom: PropTypes.bool,
  isDataSetEmpty: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

export default CustomLineChart;
