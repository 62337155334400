import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const MainWrapperDiv = styled.div`
  margin-left: ${({ marginLeft }) => marginLeft};
  border-top: ${({ borderTop }) => borderTop};
  background: #ffff;
  height: 82vh;
  width: ${({ width }) => width ?? '100%'};
  position: relative;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
`;
export const ContentWrapper = styled.div`
  position: absolute;
  margin-top: ${({ marginTop }) => marginTop ?? '14.4375rem'};
  width: ${({ width }) => width || '25%'};
  left: ${({ left }) => left};
`;
export const TextWrapper = styled.div`
  font-style: normal;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-weight: ${({ fontWeight }) => fontWeight ?? 700};
  font-size: ${({ fontSize }) => fontSize ?? '1rem'};
  line-height: ${({ lineHeight }) => lineHeight ?? '1.3619rem'};
  text-align: center;
  letter-spacing: 0.2px;
  color: ${({ color }) => color ?? DARK_CHARCOAL};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? '0.5rem'};
  margin-top: 0rem;
`;
export const FilterTextWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 0.125rem;
`;

export const SearchedTextWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  font-size: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
  white-space-collapse: preserve;
  overflow-wrap: break-word;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  margin-top: ${({ marginTop }) => marginTop || '32px'};
`;

export const addButtonStyle = {
  textTransform: 'none',
  backgroundColor: '#26890D',
  color: 'white',
  '&:hover': {
    backgroundColor: '#045206',
  },
};
export const ImageContainer = styled.div`
  height: 5rem;
  margin-top: 7.9375rem;
  margin-bottom: 1.5rem;
`;
export const searchLogoStyle = {
  height: '5rem',
  width: '5rem',
};
export const LogoTextWrapper = styled.div`
  color: ${DARK_CHARCOAL};
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  margin-bottom: 1.5rem;
`;
