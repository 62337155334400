import { TextWrapper } from 'components/FormComponents/FormStyles';
import React, { useEffect, useState } from 'react';
import { PAGE_CONSTANTS } from './VideoTutorialsPage.content';
import { DARK_CHARCOAL } from 'theme/GlobalColors';
import { CircularProgress } from '@mui/material';
import {
  CircularProgressSx,
  GridCellContainer,
  GridContainer,
} from './VideoTutorialsPage.style';
import VideoTutorialCard from './VideoTutorialCard.react';
import axios from 'axios';
import { GET_VIDEO_TUTORIALS } from 'urls';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { checkTernaryCondition } from 'utils/helper';

const VideoTutorialsPage = () => {
  const [videoDetails, setVideoDetails] = useState([]);
  const [videoDataFetching, setVideoDataFetching] = useState(false);
  useEffect(() => {
    setVideoDataFetching(true);
    axios
      .get(GET_VIDEO_TUTORIALS)
      .then((response) => {
        setVideoDetails(response.data);
      })
      .finally(() => {
        setVideoDataFetching(false);
      });
  }, []);
  return (
    <div>
      <TextWrapper
        fontWeight="600"
        fontSize="1.25rem"
        color={DARK_CHARCOAL}
        marginBottom={'1.5rem'}>
        {PAGE_CONSTANTS.PAGE_HEADING}
      </TextWrapper>
      {checkTernaryCondition(
        videoDataFetching,
        <div style={CircularProgressSx}>
          <CircularProgress></CircularProgress>
        </div>,
        <GridContainer>
          {videoDetails?.map((video) => (
            <GridCellContainer key={uniqueId()}>
              <VideoTutorialCard
                videoTutorialUrl={video?.videoTutorialUrl}
                thumbnailUrl={video?.thumbnailUrl}
                videoTitle={video?.videoTitle}
                videoDescription={video?.videoDescription}
              />
            </GridCellContainer>
          ))}
        </GridContainer>,
      )}
    </div>
  );
};

export default VideoTutorialsPage;
