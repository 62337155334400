import styled from 'styled-components';
import { TYPOGRAPHY_GRAY, NEW_DARK_GREEN } from 'theme/GlobalColors';
import { checkTernaryCondition } from 'utils/helper';
import { PAGE_CONTENT } from './AddSection.content';

export const AddButtonWrapper = styled.div`
  cursor: pointer;
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
  pointer-events: ${({ disabled }) =>
    checkTernaryCondition(disabled, 'none', 'all')};
  border: ${({ disabled }) =>
    checkTernaryCondition(
      disabled,
      `1px solid ${TYPOGRAPHY_GRAY}`,
      `1px solid ${NEW_DARK_GREEN}`,
    )};
  border-radius: ${({ hasBorder }) => (hasBorder ? '0.25rem' : '0')};
  padding: ${({ hasBorder }) => (hasBorder ? ' 0.2rem 0.5rem' : '0')};
  width: ${({ width }) => (width ? 'fit-content' : 'auto')};
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  padding-left: 0.5rem;
  transform: ${({ transForm }) =>
    checkTernaryCondition(
      transForm === PAGE_CONTENT.noNeed,
      'none',
      'translateX(1.5rem)',
    )};
`;

export const AddButtonLabel = styled.span`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.188rem;
  color: ${({ disabled }) =>
    checkTernaryCondition(disabled, TYPOGRAPHY_GRAY, NEW_DARK_GREEN)};
  margin-left: 0.375rem;
`;
