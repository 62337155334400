import {
  PARTICAPNT_INFO,
  SHAPE_FILE_UPLOAD_CONTENT,
  convertStatus,
  fieldAddTypes,
  mapControlOptions,
  toastStyle,
  enrollmentInstanceStatus,
  errorPolygonOptions,
  getFieldAreaExceedingError,
  LEFT_MENU_SECTION_HEADERS,
  getAddFarmRequestBody,
  UNSAVED_CHANGES_MODAL_PROPS_INIT,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.content';
import ParticipantGeneralFarmInfo from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.react';
import LeftMenuSubsection from 'pages/ParticipantDataCollection/components/LeftMenuSubsection/LeftMenuSubsection.react';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ParticipantGenralInfoForm from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.react';
import { useLocation, useNavigate } from 'react-router';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import { uniqueId } from 'utils/uniqueIdGenerator';
import {
  checkTernaryCondition,
  getTopMostCoordinate,
  parseBoundaries,
  formatBoundaries,
  formatGeoJson,
  isPointInsidePolygon,
  doLinesIntersect,
  convertSquareMetersToAcres,
  getNextName,
  convertDateFormat,
} from 'utils/helper';
import ParticipantGeneralFieldInfoForm from 'pages/ParticipantDataCollection/components/ParticipantGeneralFieldInfoForm/ParticipantGeneralFieldInfoForm.react';
import {
  BOUNDARY_SHAPE_FILE_UPLOAD,
  FETCH_COUNTY_LIST,
  FETCH_FARM_INFO_LIST,
  FETCH_GENERAL_FARM_INFO,
  FETCH_STATE_LIST,
  FETCH_VIEW_ORIGINATION_PROJECT,
  PARTICIPANT_PROFILE,
  PARTICIPANT_REPORT_FIRST_PART,
  FETCH_PROJECT_CYCLES,
  ADD_FARM,
} from 'urls';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { PAGE_CONTENT } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { pathName } from 'Routes/routes.const';
import { toast } from 'react-toastify';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import {
  backdropSx,
  fieldMapViewStyle,
  mapStyle,
} from 'pages/ParticipantDataCollection/ParticipantDataCollection.style';
import FarmWithMapTabs from 'pages/ParticipantDataCollection/components/FarmWithMapTabs/FarmWithMapTabs.react';
import FieldAddTypeUpdaters from 'pages/ParticipantDataCollection/components/FieldAddTypeUpdaters/FieldAddTypeUpdaters.react';
import AddFieldsModal from 'pages/ParticipantDataCollection/components/LeftMenuFieldData/AddFieldsModal/AddFieldsModal.react';
import MultiFilesUploader from 'components/FileUploader/MultiFilesUploader.react';
import { getApiError } from 'components/FileUploader/FileUploader.content';
import InfoWindow from '../pages/ParticipantDataCollection/components/InfoWindows/InfoWindow.react';
import LoadingInfoWindow from 'pages/ParticipantDataCollection/components/InfoWindows/LoadingWindow.react';
import { usRegionsPolygons } from 'pages/Origination/OriginationSummaryTab/OriginationSummaryTab.content';
import ProfileInfoWithTabber from 'pages/ParticipantDataCollection/components/ProfileInfoWithTabber/ProfileInfoWithTabber.react';
import FieldInfoWithTabber from 'pages/ParticipantDataCollection/components/FieldInfoWithTabber/FieldInfoWithTabber.react';
import { GLOBAL_STRING_CONSTANTS, PARTICIPANT_CONTRIBUTOR } from 'utils/config';
import { userAuthRolesContext } from './userAuthRolesContext';
import { LANDING_PAGE_CONTENT } from 'pages/ParticipantDataCollection/pages/ParticipantPages.content';
import useThrottle from 'hooks/useThrottle';
import Footer from 'layout/footer/Footer.react';
import { FooterWrapper } from 'pages/Origination/Org_Project/Participants/PdfViewerPage/components/PdfComponent.style';

// To create participant info context
export const participantInfoContext = createContext({});
export const ParticipantInfoProvider = ({ children }) => {
  // Note: Value of this state is 1 for profile, 2 for farm/field, 3 for "Add farms' page
  const [rightSectionId, setRightSectionId] = useState(1);
  const [participantInfo, setParticipantInfo] = useState(PARTICAPNT_INFO);
  const [farmInfo, setFarmInfo] = useState([]);
  const [farmListStatus, setFarmListStatus] = useState('');
  const [farmListIds, setFarmListIds] = useState([]);
  const [statusList, setStatusList] = useState('');
  const [currentFarmId, setCurrentFarmId] = useState(-1);
  const [farmLoading, setFarmLoading] = useState(false);
  const [fetchedFarmIds, setFetchedFarmIds] = useState(false);
  const [currentFarmInfo, setCurrentFarmInfo] = useState({});
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const [openMapView, setOpenMapView] = useState(false);
  const [showFieldInfoForm, setShowFieldInfoForm] = useState(false);
  const [fetchedFarmValues, setFetchedFarmValues] = useState([]);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [savedFieldsForms, setSavedFiledForms] = useState([]);
  const { state } = useLocation();
  const [sectionList, setSectionList] = useState([]);
  const [selectedFieldId, setSelectedFieldId] = useState(null);
  const [saveAndCloseClicked, setSaveAndCloseClicked] = useState(false);
  const [searchParams] = useSearchParams();
  const [fieldPolygons, setFieldPolygons] = useState([]);
  const [fieldMarkers, setFieldMarkers] = useState([]);
  const [fieldInfoWindows, setFieldInfoWindows] = useState([]);
  const [fieldAddTypeModalOpen, setFieldAddTypeModalOpen] = useState(false);
  const [selectedFieldAddType, setSelectedFieldAddType] = useState(null);
  const [farmInfoCurrentTab, setFarmInfoCurrentTab] = useState(0);
  const [farmsIdsWithExistingFields, setFarmIdsWithExistingFields] = useState(
    [],
  );
  const [participantProfileStatus, setParticipantProfileStatus] =
    useState(null);
  const [modalData, setModalData] = useState({
    flag: false,
    id: 0,
    isDataSubmitted: false,
  });
  const [loading, setLoading] = useState(false);
  const [filesUploadError, setFilesUploadError] = useState({
    flag: false,
    message: [],
  });
  const [shapeFileUploadModalOpen, setShapeFileUploadModalOpen] =
    useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [fieldIdsAddedFromMap, setFieldIdsAddedFromMap] = useState([]);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const enrollmentInstanceId = searchParams.get('enrollmentInstanceId');
  const projectCycleId = searchParams.get('projectcycleid');
  const navigate = useNavigate();
  const [addFarmClicked, setAddFarmClicked] = useState({ flag: false, id: 0 });
  const [isTabSwitched, setIsTabSwitched] = useState(true);
  const [fieldSelectionInProgress, setFieldSelectionInProgress] =
    useState(false);
  const [tempBoundaries, setTempBoundaries] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [projectRegions, setProjectRegions] = useState([]);
  const [isFormUnmounted, setIsFormUnmounted] = useState({
    currentPage: null,
    flag: false,
    id: 0,
  });
  const [submitData, setSubmitData] = useState({
    currentPage: null,
    data: null,
    currentId: null,
  });
  const [isFirstDataFetched, setIsFirstDataFetched] = useState(true);
  // Used to check if user has made any changes to the web forms(profile, farm or field), based on this we prompt user when they try to leave the page with unsaved changes
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [profileInfoTab, setProfileInfoTab] = useState(0);
  const [fieldInfoTab, setFieldInfoTab] = useState(0);
  const [isFieldDeleted, setIsFieldDeleted] = useState(false);
  // Note: Below 3 states are being used to determine if we have to enable/disable the web forms based on the enrollment instances, previous instance data will just be visible and disabled, only current instance data will be editable.
  const [isProfileDataEditable, setIsProfileDataEditable] = useState(true);
  const [enrolledFarmIds, setEnrolledFarmIds] = useState([]);
  const [enrolledFieldIds, setEnrolledFieldIds] = useState([]);
  // Note: This flag indicates if there is atleast one farm or field in current enrollment instance. This will be used to check if we want to show enrollment complete modal or not. We will show the modal only if atleast one farm or field is present in current enrollment instance.
  const [hasNotApprovedFarmOrFields, setHasNotApprovedFarmOrFields] =
    useState(false);
  const [isFarmCsafUpdatedBySystem, setIsFarmCsafUpdatedBySystem] =
    useState(false);
  const [isFieldSubmitted, setIsFieldSubmitted] = useState(false);
  const currentFarmIndex = farmInfo.findIndex(
    (farm) => farm.id === currentFarmId,
  );
  // Note: This will have the sum of areas of all fields under current farm
  const [currentFarmFieldsArea, setCurrentFarmFieldsArea] = useState(0);
  const [currentCycleDataYear, setCurrentCycleDataYear] = useState('');
  const [currentCycleStartDate, setCurrentCycleStartDate] = useState('');
  const [currentCycleEndDate, setCurrentCycleEndDate] = useState('');
  const [triggerFieldInfoFetch, setTriggerFieldInfoFetch] = useState(false);
  const [unsavedChangesModalOpenProps, setUnsavedChangesModalOpenProps] =
    useState(UNSAVED_CHANGES_MODAL_PROPS_INIT);
  const [triggerFarmInfoSubmit, setTriggerFarmInfoSubmit] = useState(false);
  const [triggerFieldInfoSubmit, setTriggerFieldInfoSubmit] = useState(false);
  const [triggerProfileInfoSubmit, setTriggerProfileInfoSubmit] =
    useState(false);
  const [addedFromUiFarmIds, setAddedFromUiFarmIds] = useState([]);
  const { userRoles } = useContext(userAuthRolesContext);
  const isParticipantContributor = userRoles.includes(PARTICIPANT_CONTRIBUTOR);
  const resetFieldViewTab = () => setFieldInfoTab(0);
  const closeUnsavedChangesModal = () =>
    setUnsavedChangesModalOpenProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);
  const [addFarmBtnLoader, setAddFarmBtnLoader] = useState(false);

  const handleLeaveWithoutSave = () => {
    switch (unsavedChangesModalOpenProps.navigateNextTo) {
      case LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection:
        goToFarm(unsavedChangesModalOpenProps?.targetFarmId);
        break;
      case LEFT_MENU_SECTION_HEADERS.profileSection:
        goToProfile();
        break;
      case 'Field':
        openFieldInformation({
          id: unsavedChangesModalOpenProps?.targetFieldId,
          name: unsavedChangesModalOpenProps?.targetFieldName,
          farmId: unsavedChangesModalOpenProps?.targetFarmId,
        });
        break;
    }
    closeUnsavedChangesModal();
    fetchParticipantData(false);
  };

  const handleSaveAndLeave = () => {
    const currentPage = getCurrentPage();

    switch (currentPage) {
      case 'Farm':
        setTriggerFarmInfoSubmit(true);
        break;
      case 'Field':
        setTriggerFieldInfoSubmit(true);
        break;
      case 'Profile':
        setTriggerProfileInfoSubmit(true);
        break;
    }
  };

  const handleSubmitCallback = () => {
    switch (unsavedChangesModalOpenProps.navigateNextTo) {
      case 'Field':
        openFieldInformation({
          id: unsavedChangesModalOpenProps.targetFieldId,
          name: unsavedChangesModalOpenProps.targetFieldName,
          farmId: unsavedChangesModalOpenProps.targetFarmId,
        });
        break;
      case 'Farms':
        goToFarm(unsavedChangesModalOpenProps.targetFarmId);
        break;
      case 'Participant':
        goToProfile();
        break;
    }
    closeUnsavedChangesModal();
  };

  const fetchCurrentCycleData = () => {
    setLoading(true);
    const URL = `${FETCH_PROJECT_CYCLES}/${projectId}`;
    axios
      .get(URL)
      .then((response) => {
        const data = response.data;
        /* istanbul ignore else */
        if (data && data.length > 0) {
          const currentCycleData = data.find(
            (cycle) => cycle.projectCycleId == projectCycleId,
          );

          //format the date for the cycle start
          let currentCycleStartFormatted = convertDateFormat(
            currentCycleData?.cycleStartDate,
          );
          setCurrentCycleStartDate(currentCycleStartFormatted);
          //format the date for the cycle end
          let currentCycleEndFormatted = convertDateFormat(
            currentCycleData?.cycleEndDate,
          );

          setCurrentCycleEndDate(currentCycleEndFormatted);
          let currentCycleYear = new Date(currentCycleData?.cycleEndDate)
            ?.getFullYear()
            ?.toString();
          setCurrentCycleDataYear(currentCycleYear);
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchFarmList = async () => {
    setLoading(true);

    return axios
      .get(FETCH_FARM_INFO_LIST, {
        params: { participantId: participantId, projectId: projectId },
      })
      .then((response) => {
        const farms = response.data;
        return farms;
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const isNewPolygonEdgesIntersectingWithOthers = (newPolygonBoundaries) => {
    const length = newPolygonBoundaries.length;

    // For each edge of the new polygon
    for (let start = 0, end = length - 1; start <= length - 1; end = start++) {
      const currentLineCoordinates = {
        lat1: newPolygonBoundaries[start].lat,
        lng1: newPolygonBoundaries[start].lng,
        lat2: newPolygonBoundaries[end].lat,
        lng2: newPolygonBoundaries[end].lng,
      };

      // For each existing field
      for (const field of fieldPolygons) {
        // Note: We are skipping fields from other farms and also skipping checks with the same field
        // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
        if (field.farmId !== currentFarmId || typeof field.fieldId === 'string')
          continue;

        const boundaries = field.fieldBoundaries;
        const boundariesLength = boundaries.length;
        // Check for intersection of all edges of this field
        for (
          let front = 0, back = boundariesLength - 1;
          front <= boundariesLength - 1;
          back = front++
        ) {
          const existingLineCoordinates = {
            lat1: boundaries[front].lat,
            lng1: boundaries[front].lng,
            lat2: boundaries[back].lat,
            lng2: boundaries[back].lng,
          };

          if (doLinesIntersect(currentLineCoordinates, existingLineCoordinates))
            return true;
        }
      }
    }

    return false;
  };

  const isNewPolygonPointsInsideExistingPolygons = (newPolygonBoundaries) => {
    for (const polygon of fieldPolygons) {
      // Note: We are skipping fields from other farms and also skipping checks with the same field
      // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
      if (
        polygon.farmId !== currentFarmId ||
        typeof polygon.fieldId === 'string'
      )
        continue;

      for (const point of newPolygonBoundaries) {
        if (isPointInsidePolygon(polygon.fieldBoundaries, point)) return true;
      }
    }

    return false;
  };

  const isExistingPolygonsPointsInsideNewPolygon = (newPolygonBoundaries) => {
    for (const polygon of fieldPolygons) {
      // Note: We are skipping fields from other farms and also skipping checks with the same field
      // The check for fieldId not to be string is added since during edit polygon, we will already have a polygon in the fieldPolygons state, only way to skip checking the same field for overlapping is to check if the id is a string(uniqueId generated for newly added fields before api calls)
      if (
        polygon.farmId !== currentFarmId ||
        typeof polygon.fieldId === 'string'
      )
        continue;

      const polygonBoundaries = polygon.fieldBoundaries;
      for (const point of polygonBoundaries) {
        if (isPointInsidePolygon(newPolygonBoundaries, point)) return true;
      }
    }

    return false;
  };

  const isPolygonOverlapping = (newPolygonBoundaries) => {
    setLoading(true);
    const overlapping =
      isNewPolygonPointsInsideExistingPolygons(newPolygonBoundaries) ||
      isExistingPolygonsPointsInsideNewPolygon(newPolygonBoundaries) ||
      isNewPolygonEdgesIntersectingWithOthers(newPolygonBoundaries);
    setLoading(false);
    return overlapping;
  };

  // This function returns the total sum of all the fields acres in the selected farm
  function getCurrentFarmFieldsArea() {
    const currentFarm = farmInfo.find((farm) => farm.id === currentFarmId);
    if (!currentFarm) return 0;

    return (
      currentFarm.fields?.reduce(
        (total, field) => total + Number(field.totalArea),
        0,
      ) ?? 0
    );
  }

  const addFieldToFarm = (fieldId, fieldName, farmIndex, fieldBoundaries) => {
    if (farmIndex < 0 || farmIndex >= farmInfo.length) return;
    const url = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/general-field-info?farmId=${currentFarmId}&participantId=${participantId}&projectId=${projectId}`;
    const fieldArea = +convertSquareMetersToAcres(
      getPolygonArea(fieldBoundaries),
    );
    const farmArea = Number(currentFarmInfo.totalFarmAcreage);
    const emptyData = {
      enrollmentInstanceId: Number(enrollmentInstanceId),
      projectCycleId: Number(projectCycleId),
      fieldBoundaries: formatBoundaries(fieldBoundaries),
      fieldName: fieldName,
      formStatus: 'In Progress',
      fsaFieldId: '',
      fsaTractId: '',
      /*       csafImplemented: '', */
      activityList: [],
      totalFieldArea: fieldArea,
      /*   csafUpdatedBySystem: false,
      farmCsafUpdatedByField: false, 
      isConsistentLanduse20Yrs: '',
      landUseTypeId: '',*/
      fieldHistoricLanduseList: [],
    };
    setLoading(true);
    axios
      .post(url, emptyData)
      .then((resp) => {
        if (resp.data) {
          setFarmInfo((prevInfo) => {
            return prevInfo.map((farm, index) => {
              if (index === farmIndex) {
                return {
                  ...farm,
                  fields: [
                    ...farm.fields,
                    {
                      id: resp.data.fieldId,
                      value: resp.data.fieldName,
                      status: resp.data.formStatus,
                      info: resp.data.fieldName,
                      isApiPopulated: true,
                    },
                  ],
                };
              }
              return farm;
            });
          });

          setFieldIdsAddedFromMap((prev) => [...prev, resp.data.fieldId]);
          setFarmIdsWithExistingFields((prev) => [
            ...prev,
            farmInfo[farmIndex].id,
          ]);
          setFieldPolygons((prev) =>
            prev.map((polygon) => {
              return checkTernaryCondition(
                polygon.fieldId === fieldId,
                {
                  ...polygon,
                  fieldId: resp.data.fieldId,
                  fieldName: fieldName,
                  isEditable: false,
                  polygonOptions: null,
                },
                polygon,
              );
            }),
          );
          setFieldInfoWindows((prev) =>
            prev.filter((infoWindow) => infoWindow.fieldId !== fieldId),
          );

          toast(
            <CustomSnackbar
              type="success"
              message={`Field ${resp.data.fieldName} was added successfully`}
            />,
            toastStyle,
          );

          const existingFieldsArea = getCurrentFarmFieldsArea();
          const updatedArea = existingFieldsArea + fieldArea;

          // Note: We are considering a buffer size of 10% on farm area, hence checking with farmArea * 1.1 (This would be -> (farmArea + ((10/100) * farmArea)))
          if (updatedArea > farmArea * 1.1) {
            const errorMsg = getFieldAreaExceedingError(updatedArea, farmArea);
            toast(
              <CustomSnackbar type={'error'} message={errorMsg} />,
              toastStyle,
            );
          }
          setHasNotApprovedFarmOrFields(true);
          fetchParticipantData(false);
        }
      })
      .catch(() =>
        toast(
          <CustomSnackbar
            type={'error'}
            message={`Field ${fieldName} could not be added`}
          />,
          toastStyle,
        ),
      )
      .finally(() => {
        setLoading(false);
        setFieldSelectionInProgress(false);
      });
  };

  const addFieldsFromShapeFile = (fieldBoundaries) => {
    // check for overlapping, if any polygon overlaps stop adding new fields
    for (const polygonBoundary of fieldBoundaries) {
      if (isPolygonOverlapping(polygonBoundary)) {
        const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
        toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
        return;
      }
    }

    // Show a toast if area exceeds farmArea
    const farmArea = Number(currentFarmInfo.totalFarmAcreage);
    const fieldAreas = fieldBoundaries.map((boundary) =>
      Number(convertSquareMetersToAcres(getPolygonArea(boundary))),
    );
    const totalFieldArea = fieldAreas.reduce(
      (total, area) => total + Number(area),
      0,
    );
    const updatedArea = (currentFarmFieldsArea ?? 0) + totalFieldArea;
    const areaExceeding = updatedArea > farmArea;
    // Sum of areas of existing fields exceeds farmArea
    if (areaExceeding) {
      const errorMsg = getFieldAreaExceedingError(updatedArea, farmArea);
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
    }

    const farmIndex = farmInfo.findIndex((farm) => farm.id === currentFarmId);
    if (farmIndex === -1) return;
    const url = `${PARTICIPANT_REPORT_FIRST_PART}/enroll/general-field-info?farmId=${currentFarmId}&participantId=${participantId}&projectId=${projectId}`;

    let existingFieldNames = farmInfo?.flatMap((farm) =>
      farm.fields?.map((field) => field.value),
    );

    const addFieldRequests = fieldBoundaries.map((fieldBoundary) => {
      const newFieldName = getNextName(
        existingFieldNames,
        GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
      );
      existingFieldNames.push(newFieldName);
      const area = getPolygonArea(fieldBoundary);
      const emptyData = {
        enrollmentInstanceId: Number(enrollmentInstanceId),
        projectCycleId: Number(projectCycleId),
        fieldBoundaries: formatBoundaries(fieldBoundary),
        fieldName: newFieldName,
        formStatus: 'In Progress',
        fsaFieldId: '',
        fsaTractId: '',
        /*         csafImplemented: '', */
        activityList: [],
        totalFieldArea: +convertSquareMetersToAcres(area),
        /*      csafUpdatedBySystem: false,
        farmCsafUpdatedByField: false,
        isConsistentLanduse20Yrs: '',
        landUseTypeId: '',*/
        fieldHistoricLanduseList: [],
      };
      return axios.post(url, emptyData);
    });

    setLoading(true);
    Promise.all(addFieldRequests)
      .then((responses) => {
        responses.forEach((response, index) => {
          if (response.data) {
            setFarmInfo((prevInfo) => {
              return prevInfo.map((farm, index) => {
                if (index === farmIndex) {
                  return {
                    ...farm,
                    fields: [
                      ...farm.fields,
                      {
                        id: response.data.fieldId,
                        value: response.data.fieldName,
                        status: response.data.formStatus,
                        info: response.data.fieldName,
                        isApiPopulated: true,
                      },
                    ],
                  };
                }
                return farm;
              });
            });

            const newPolygon = {
              fieldId: response.data.fieldId,
              fieldName: response.data.fieldName,
              farmId: farmInfo[farmIndex].id,
              fieldBoundaries: fieldBoundaries[index],
            };
            setFieldPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
            setFieldIdsAddedFromMap((prev) => [...prev, response.data.fieldId]);
            setMapCenter(newPolygon.fieldBoundaries[0]);
          }
        });
        setFarmIdsWithExistingFields((prev) => [...prev, currentFarmId]);
        setHasNotApprovedFarmOrFields(true);
        fetchParticipantData(false);
      })
      .catch(() =>
        toast(
          <CustomSnackbar
            type={'error'}
            message={`Fields could not be added`}
          />,
          toastStyle,
        ),
      )
      .finally(() => setLoading(false));
  };

  const removeFieldPolygon = (fieldId) => {
    setFieldPolygons((prev) =>
      prev.filter((polygon) => polygon.fieldId !== fieldId),
    );
    setFieldInfoWindows((prev) =>
      prev.filter((infoWindow) => infoWindow.fieldId !== fieldId),
    );
    setFieldSelectionInProgress(false);
  };

  const setDummyLoading = (fieldId, position, _fieldName, farmId) => {
    const infoWindow = {
      fieldId: fieldId,
      position: position,
      farmId: farmId,
      content: <LoadingInfoWindow />,
      onClose: () => removeFieldPolygon(fieldId),
    };
    setFieldInfoWindows((prev) => [...prev, infoWindow]);
  };

  /**
   * @description This function calculates the area of a polygon using google maps api
   * @param polygonCoordinates is an array of objects each having a pair of lat and lng values(Eg: [{lat: number, lng: number}])
   * @returns area of polygon in square meters -> can be converted to required units(acres) during usage
   */
  const getPolygonArea = (polygonCoordinates) =>
    window.google.maps.geometry.spherical.computeArea(polygonCoordinates);

  const handlePolygonEdit = (fieldId, newBoundaries) => {
    if (isPolygonOverlapping(newBoundaries)) {
      removeFieldPolygon(fieldId);
      const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
      return;
    }
    const currentFarmArea = currentFarmInfo?.totalFarmAcreage ?? 0;
    const fieldArea = convertSquareMetersToAcres(getPolygonArea(newBoundaries));
    // Note: We are allowing a buffer of 10% on the farmArea, eg: if farmArea is 10acres, fieldArea can be <=11acres(10acres + 10% of 10acres)
    const isFieldBiggerThanFarm = fieldArea > Number(currentFarmArea) * 1.1;

    setFieldPolygons((prev) =>
      prev.map((polygon) => {
        if (polygon.fieldId === fieldId) {
          return {
            ...polygon,
            fieldBoundaries: newBoundaries,
            polygonOptions: isFieldBiggerThanFarm && errorPolygonOptions,
          };
        }
        return polygon;
      }),
    );

    setFieldInfoWindows((prev) =>
      prev.map((infoWindow) => {
        if (infoWindow.fieldId === fieldId) {
          return {
            ...infoWindow,
            position: getTopMostCoordinate(newBoundaries),
            content: (
              <InfoWindow
                fieldArea={fieldArea}
                totalFarmArea={+currentFarmArea}
                onAccept={(newName) => {
                  addFieldToFarm(
                    fieldId,
                    newName,
                    currentFarmIndex,
                    newBoundaries,
                  );
                  setIsFormUnmounted({
                    currentPage: null,
                    flag: false,
                    id: 0,
                  });
                  setSubmitData({
                    currentPage: null,
                    data: null,
                    currentId: null,
                  });
                }}
                onDelete={() => removeFieldPolygon(fieldId)}
                loadingState={false}
              />
            ),
          };
        }
        return infoWindow;
      }),
    );
  };

  const handleNewPolygon = (fieldBoundaries) => {
    const currentFarmIndexValue = farmInfo.findIndex(
      (farm) => farm.id === currentFarmId,
    );
    if (currentFarmIndexValue === -1) return;
    const currentFarmArea = currentFarmInfo?.totalFarmAcreage ?? 0;
    const fieldArea = convertSquareMetersToAcres(
      getPolygonArea(fieldBoundaries),
    );

    // Note: We are allowing a buffer of 10% on the farmArea, eg: if farmArea is 10acres, fieldArea can be <=11acres(10acres + 10% of 10acres)
    const isFieldBiggerThanFarm = fieldArea > Number(currentFarmArea) * 1.1;

    const newFieldId = uniqueId();
    let existingFieldNames = farmInfo?.flatMap((farm) =>
      farm.fields?.map((field) => field.value),
    );
    const newFieldName = getNextName(
      existingFieldNames,
      GLOBAL_STRING_CONSTANTS.default_field_name_prefix,
    );
    const newPolygon = {
      fieldId: newFieldId,
      fieldName: newFieldName,
      farmId: currentFarmId,
      fieldBoundaries: fieldBoundaries,
      isEditable: true,
      polygonOptions: isFieldBiggerThanFarm && errorPolygonOptions,
      isFieldBiggerThanFarm: isFieldBiggerThanFarm,
    };

    if (isPolygonOverlapping(newPolygon.fieldBoundaries)) {
      const errorMsg = PAGE_CONTENT.overlappingBoundaryError;
      toast(<CustomSnackbar type={'error'} message={errorMsg} />, toastStyle);
    } else {
      setFieldPolygons((prevPolygons) => [...prevPolygons, newPolygon]);
      setDummyLoading(
        newFieldId,
        getTopMostCoordinate(fieldBoundaries),
        newFieldName,
        currentFarmId,
      );

      setFieldSelectionInProgress(true);
      setTimeout(() => {
        setFieldInfoWindows((prev) =>
          prev.map((infoWindow) =>
            infoWindow.fieldId === newFieldId
              ? {
                  ...infoWindow,
                  position: getTopMostCoordinate(newPolygon.fieldBoundaries),
                  isFieldBiggerThanFarm: isFieldBiggerThanFarm,
                  content: (
                    <InfoWindow
                      fieldArea={fieldArea}
                      totalFarmArea={+currentFarmArea}
                      onAccept={(newName) => {
                        addFieldToFarm(
                          newFieldId,
                          newName,
                          currentFarmIndexValue,
                          newPolygon.fieldBoundaries,
                        );
                        setIsFormUnmounted({
                          currentPage: null,
                          flag: false,
                          id: 0,
                        });
                        setSubmitData({
                          currentPage: null,
                          data: null,
                          currentId: null,
                        });
                      }}
                      onDelete={() => removeFieldPolygon(newFieldId)}
                      loadingState={false}
                    />
                  ),
                  onClose: () => removeFieldPolygon(newFieldId),
                }
              : infoWindow,
          ),
        );
      }, 1000);
    }
  };

  const removePolygonsAndInfoWindowsWithError = () => {
    setFieldPolygons((prev) =>
      prev.filter((polygon) => !polygon.isFieldBiggerThanFarm),
    );
    setFieldInfoWindows((prev) =>
      prev.filter((infoWindow) => !infoWindow.isFieldBiggerThanFarm),
    );
    setFieldSelectionInProgress(false);
  };

  // Using tempBoundaries state here since direct use of fieldBoundaries wasn't updating the handler passed to map, causing some fields to be ignored for overlapping check.
  useEffect(() => {
    if (tempBoundaries && selectedFieldAddType !== null) {
      handleNewPolygon(tempBoundaries);
      setTempBoundaries(null);
    }
  }, [tempBoundaries]);

  const populatePolygons = (farmDetails) => {
    const polygonsList = [];
    farmDetails.forEach((farm) => {
      farm.fieldDetails.forEach((field) => {
        if (field.fieldBoundaries) {
          const newPolygon = {
            fieldId: field.fieldId,
            fieldName: field.fieldName,
            farmId: farm.farmId,
            fieldBoundaries: parseBoundaries(field.fieldBoundaries),
          };
          polygonsList.push(newPolygon);
        }
      });
    });
    setFieldPolygons(polygonsList);
  };

  const updateFarmFieldName = ({ farmId, fieldId, name }) => {
    const farmIndex = farmInfo.findIndex((farm) => farm.id === farmId);

    if (farmIndex >= 0) {
      const fieldsList = farmInfo[farmIndex].fields;
      const fieldIndex = fieldsList.findIndex((field) => field.id === fieldId);

      if (fieldIndex >= 0) {
        const fieldToBeUpdated = fieldsList[fieldIndex];
        const updatedField = { ...fieldToBeUpdated, value: name };

        setFarmInfo((prevFarmInfo) => {
          return prevFarmInfo.map((farm, index) => {
            return farmIndex === index
              ? {
                  ...farm,
                  fields: farm.fields.map((field, indexValue) => {
                    /* istanbul ignore else */
                    if (fieldIndex === indexValue) {
                      return updatedField;
                    }
                    return field;
                  }),
                }
              : farm;
          });
        });

        setFieldPolygons((prevPolygons) => {
          return prevPolygons.map((polygon) => {
            return checkTernaryCondition(
              polygon.fieldId === fieldId,
              { ...polygon, fieldName: name },
              polygon,
            );
          });
        });
      }
    }
  };

  const selectedFieldBoundaries = useMemo(() => {
    if (selectedFieldId === null) return '';
    else {
      const selectedFieldPolygon = fieldPolygons.find(
        (polygon) => polygon.fieldId === selectedFieldId.id,
      );
      return selectedFieldPolygon?.fieldBoundaries || [];
    }
  }, [selectedFieldId, fieldPolygons]);

  // Note: This memo creates a variable with the current polygon data of the selected field with map bounds required to fit the polygon in the map, used inside "View map" tab of field info form
  const currentFieldPolygonData = useMemo(() => {
    if (selectedFieldId === null) return { boundaries: [], bounds: [] };
    else {
      const currentPolygonData = fieldPolygons.find(
        (polygon) => polygon.fieldId === selectedFieldId.id,
      );
      const gmapBounds = new window.google.maps.LatLngBounds();
      currentPolygonData?.fieldBoundaries.forEach((bound) => {
        gmapBounds.extend(bound);
      });
      return {
        polygon: currentPolygonData ? [currentPolygonData] : [],
        bounds: gmapBounds,
      };
    }
  }, [selectedFieldId, fieldPolygons]);

  const selectedFieldName = useMemo(() => {
    if (selectedFieldId && farmInfo) {
      return (
        farmInfo
          .find((farm) => farm?.id === currentFarmId)
          ?.fields?.find((field) => field.id === selectedFieldId.id)?.value ||
        ''
      );
    }
    return '';
  }, [selectedFieldId, farmInfo]);

  const uploadShapeFiles = (files) => {
    const formData = new FormData();
    formData.append('file', files[0]);

    setLoading(true);
    axios
      .post(BOUNDARY_SHAPE_FILE_UPLOAD, formData)
      .then((resp) => {
        const polygons = formatGeoJson(resp.data);
        addFieldsFromShapeFile(polygons);
        setShapeFileUploadModalOpen(false);
        setFilesUploadError({ flag: false, message: '' });
      })
      .catch((error) => {
        const errorMsg = getApiError(
          error.response?.data?.errorCodeMsg ||
            error.response?.data?.message ||
            'Error',
          SHAPE_FILE_UPLOAD_CONTENT.infoText,
        );
        const messagesList = errorMsg.split('\n');
        setFilesUploadError({
          flag: true,
          messages: messagesList,
        });
        setLoading(false);
      });
  };

  const extractFarmIdsWithExistingFields = (farmList) => {
    let farmIdsHavingFields = [];
    if (farmList && farmList.length > 0) {
      farmList.forEach((farm) => {
        if (farm.fieldDetails?.length > 0)
          farmIdsHavingFields.push(farm.farmId);
      });
    }
    setFarmIdsWithExistingFields(farmIdsHavingFields);
  };

  const openFieldInformation = (field) => {
    setRightSectionId(2);
    setCurrentFarmId(field.farmId);
    setExpandedFarmId(field.farmId);
    setSelectedFieldId({ id: field.id, name: field.name });
    setShowFieldInfoForm(true);
  };

  const handleFieldBoundaryClick = (boundaryData) => {
    const fieldId = boundaryData.fieldId;
    const fieldName = boundaryData.fieldName;
    const farmId = boundaryData.farmId;

    openFieldInformation({ id: fieldId, name: fieldName, farmId: farmId });
  };

  const getMapDrawMode = () => {
    if (
      expandedFarmId === -1 ||
      currentFarmId == -1 ||
      fieldSelectionInProgress
    )
      return null;

    switch (selectedFieldAddType) {
      case fieldAddTypes[1]:
        return 'polygon';
      case fieldAddTypes[2]:
        return 'marker';
      case fieldAddTypes[0]:
        return null;
      default:
        return null;
    }
  };

  // Call field info fetch on tab change from 1 to 0
  const handleFieldInfoTabChange = (tabIndex) => {
    if (tabIndex === 0) {
      setTriggerFieldInfoFetch(true);
    } else if (tabIndex === 1) {
      fetchParticipantData(false);
    }
  };

  useEffect(() => {
    if (isFirstDataFetched) {
      setRightSectionId(
        checkTernaryCondition(
          state?.fieldData === null && state?.id === 1,
          1,
          2,
        ),
      );
      if (state?.fieldData !== null) {
        setOpenMapView(true);
        setShowFieldInfoForm(true);
        setExpandedFarmId(state?.fieldData?.farmId);
        setCurrentFarmId(state?.fieldData?.farmId);
        if (fieldPolygons.length > 0) {
          const fieldExists =
            fieldPolygons.find(
              (item) => item?.fieldId === state?.fieldData?.fieldId,
            ) === undefined;
          const fetchField = fieldPolygons.find(
            (item) => item?.farmId === state?.fieldData?.farmId,
          );
          const field = checkTernaryCondition(
            fieldExists,
            { id: fetchField?.fieldId, name: fetchField?.fieldName },
            {
              id: state?.fieldData?.fieldId,
              name: state?.fieldData?.fieldName,
            },
          );
          setSelectedFieldId({
            id: field?.id,
            name: field?.name,
          });
          setIsFirstDataFetched(false);
        }
      } else {
        setIsFirstDataFetched(false);
      }
    }
  }, [state, fieldPolygons]);

  useEffect(() => {
    if (
      farmInfoCurrentTab === 1 &&
      !farmsIdsWithExistingFields.includes(currentFarmId)
    ) {
      setFieldAddTypeModalOpen(true);
    }
    if (farmInfoCurrentTab === 0) setSelectedFieldAddType(null);
  }, [farmInfoCurrentTab]);

  const addFarmData = async (payload) => {
    const URL = ADD_FARM.replace('{participantId}', participantId);
    return axios.post(URL, payload);
  };

  const getUpdatedFarmDetails = async () => {
    return axios.get(
      `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
    );
  };

  const addFarmhandler = () => {
    setUserActivityDetected(false);
    const existingFarmNames = farmInfo.map((farm) => farm.value);
    const farmName = getNextName(
      existingFarmNames,
      GLOBAL_STRING_CONSTANTS.default_farm_name_prefix,
    );
    setAddFarmBtnLoader(true);
    addFarmData(
      getAddFarmRequestBody({
        internalProjectId: projectId,
        enrollmentInstanceId: enrollmentInstanceId,
        farmName: farmName,
        projectCycleId: projectCycleId,
      }),
    )
      .then(async (farmResponse) => {
        setAddedFromUiFarmIds((prev) => [...prev, farmResponse.data.farmId]);
        return getUpdatedFarmDetails().then((response) => {
          const currentFarmIdValue = farmResponse.data.farmId;
          const updatedFarmDetails = response.data.farmDetails;
          updateFarmInfo(updatedFarmDetails);
          setRightSectionId(2);
          setFarmInfoCurrentTab(0);
          setCurrentFarmId(currentFarmIdValue);
          setExpandedFarmId(currentFarmIdValue);
          setShowFieldInfoForm(false);
        });
      })
      .finally(() => setAddFarmBtnLoader(false));
  };

  const addFarmClickHandler = useThrottle(addFarmhandler, 500);

  useEffect(() => {
    setSectionList([
      {
        id: 1,
        leftSection: (
          <LeftMenuSubsection
            heading={LEFT_MENU_SECTION_HEADERS.profileSection}
            fieldData={participantInfo}
            setRightSectionId={setRightSectionId}
            updateFarmFieldName={updateFarmFieldName}
            currentFarmId={currentFarmId}
            setCurrentFarmId={setCurrentFarmId}
            setOpenMapView={setOpenMapView}
            setSelectedFieldId={setSelectedFieldId}
            setShowFieldInfoForm={setShowFieldInfoForm}
            setFieldAddTypeModalOpen={setFieldAddTypeModalOpen}
            setFarmInfoCurrentTab={setFarmInfoCurrentTab}
            setExpandedFarmId={setExpandedFarmId}
            farmsIdsWithExistingFields={farmsIdsWithExistingFields}
            farmInfo={farmInfo}
            setFarmInfo={setFarmInfo}
            selectedFieldId={selectedFieldId}
            setFieldPolygons={setFieldPolygons}
            rightSectionId={rightSectionId}
            expandedFarmId={expandedFarmId}
            setAddFarmClicked={setAddFarmClicked}
            setIsTabSwitched={setIsTabSwitched}
            setIsFormUnmounted={setIsFormUnmounted}
            resetFieldViewTab={resetFieldViewTab}
            hideSection={isParticipantContributor}
            handleAccordianSummaryClick={handleAccordianSummaryClick}
            isInBaselinePage={false}
            isInsideActivityReporting={false}
          />
        ),
        rightSection: (
          <ProfileInfoWithTabber
            currentTab={profileInfoTab}
            setCurrentTab={setProfileInfoTab}
            tabList={[
              {
                title: 'Profile info',
                component: <ParticipantGenralInfoForm />,
              },
            ]}
          />
        ),
      },
      {
        id: 2,
        leftSection: (
          <>
            <LeftMenuSubsection
              heading={LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection}
              fieldData={farmInfo}
              setRightSectionId={setRightSectionId}
              updateFarmFieldName={updateFarmFieldName}
              currentFarmId={currentFarmId}
              setCurrentFarmId={setCurrentFarmId}
              setOpenMapView={setOpenMapView}
              setSelectedFieldId={setSelectedFieldId}
              setShowFieldInfoForm={setShowFieldInfoForm}
              setFieldAddTypeModalOpen={setFieldAddTypeModalOpen}
              farmInfoCurrentTab={farmInfoCurrentTab}
              setFarmInfoCurrentTab={setFarmInfoCurrentTab}
              setExpandedFarmId={setExpandedFarmId}
              farmsIdsWithExistingFields={farmsIdsWithExistingFields}
              farmInfo={farmInfo}
              setFarmInfo={setFarmInfo}
              selectedFieldId={selectedFieldId}
              setFieldPolygons={setFieldPolygons}
              rightSectionId={rightSectionId}
              expandedFarmId={expandedFarmId}
              setAddFarmClicked={setAddFarmClicked}
              setIsTabSwitched={setIsTabSwitched}
              setCurrentFarmLocation={setCurrentFarmLocation}
              showFieldInfoForm={showFieldInfoForm}
              setIsFormUnmounted={setIsFormUnmounted}
              setIsFieldDeleted={setIsFieldDeleted}
              resetFieldViewTab={resetFieldViewTab}
              enrolledFarmIds={enrolledFarmIds}
              enrolledFieldIds={enrolledFieldIds}
              isParticipantContributor={isParticipantContributor}
              handleViewMapClick={handleViewMapClick}
              handleAccordianSummaryClick={handleAccordianSummaryClick}
              handleAccordianItemClick={handleAccordianItemClick}
              addSectionLabel={PAGE_CONTENT.noNeed}
              addSectionHandler={addFarmClickHandler}
              isInBaselinePage={false}
              isInsideActivityReporting={false}
              isAddFarmDisabled={addFarmBtnLoader}
            />
            <AddFieldsModal
              acceptedFilesInfo={{
                fileNotSupportedErrorText:
                  SHAPE_FILE_UPLOAD_CONTENT.fileNotSupportedError,
                infoText: SHAPE_FILE_UPLOAD_CONTENT.infoText,
                mimeType: SHAPE_FILE_UPLOAD_CONTENT.acceptedMimeType,
                extensions: SHAPE_FILE_UPLOAD_CONTENT.acceptedExtensions,
                maxFiles: SHAPE_FILE_UPLOAD_CONTENT.maxNoOfFiles,
                maxFileSize: SHAPE_FILE_UPLOAD_CONTENT.maxFileSize,
                overlappingFieldsInfo:
                  SHAPE_FILE_UPLOAD_CONTENT.fieldOverlappingInfoText,
              }}
            />
          </>
        ),
        rightSection: checkTernaryCondition(
          selectedFieldId !== null && showFieldInfoForm,
          <FieldInfoWithTabber
            currentTab={fieldInfoTab}
            setCurrentTab={(newTab) => setFieldInfoTab(newTab)}
            handleTabChange={handleFieldInfoTabChange}
            tabList={[
              {
                title: 'Field info',
                component: (
                  <ParticipantGeneralFieldInfoForm
                    id={selectedFieldId?.id}
                    participantId={state?.participantId}
                    name={selectedFieldName}
                    projectId={state?.projectId}
                    isEditModeOn={isEditModeOn}
                    selectedFieldId={selectedFieldId}
                    selectedFieldBoundaries={selectedFieldBoundaries}
                  />
                ),
              },
              {
                title: 'Map view',
                component: (
                  <>
                    <MapSearchContainer
                      additionalClassName="summary-map"
                      mapCenter={mapCenter}
                      fieldPolygons={currentFieldPolygonData.polygon}
                      mapControlOptions={mapControlOptions}
                      style={fieldMapViewStyle}
                      mapBounds={currentFieldPolygonData.bounds}
                      showPolygonArea={true}
                    />
                    <FooterWrapper>
                      <Footer
                        isParticipantLanding={false}
                        isParticipantMenu={false}
                        isInActivityPage={false}
                      />
                    </FooterWrapper>
                  </>
                ),
              },
            ]}
          />,
          <FarmWithMapTabs
            currentTab={farmInfoCurrentTab}
            setCurrentTab={(newTab) => {
              removePolygonsAndInfoWindowsWithError();
              setFarmInfoCurrentTab(newTab);
              if (newTab === 0) fetchParticipantData(false);
            }}
            tabList={[
              { title: 'Farm info', component: <ParticipantGeneralFarmInfo /> },
              {
                title: 'Map view',
                component: (
                  <>
                    <Backdrop sx={backdropSx} open={loading}>
                      <CircularProgress color="success" />
                    </Backdrop>
                    <MultiFilesUploader
                      isOpen={shapeFileUploadModalOpen}
                      onCancel={() => setShapeFileUploadModalOpen(false)}
                      title={SHAPE_FILE_UPLOAD_CONTENT.modalTitle}
                      dragAndDropText={
                        SHAPE_FILE_UPLOAD_CONTENT.dragAndDropInsideText
                      }
                      cancelText={SHAPE_FILE_UPLOAD_CONTENT.cancelText}
                      uploadText={SHAPE_FILE_UPLOAD_CONTENT.uploadText}
                      acceptedFilesInfo={{
                        fileNotSupportedErrorText:
                          SHAPE_FILE_UPLOAD_CONTENT.fileNotSupportedError,
                        infoTextList: SHAPE_FILE_UPLOAD_CONTENT.infoTextList,
                        mimeType: SHAPE_FILE_UPLOAD_CONTENT.acceptedMimeType,
                        extensions:
                          SHAPE_FILE_UPLOAD_CONTENT.acceptedExtensions,
                        maxFiles: SHAPE_FILE_UPLOAD_CONTENT.maxNoOfFiles,
                        maxFileSize: SHAPE_FILE_UPLOAD_CONTENT.maxFileSize,
                      }}
                      onUpload={uploadShapeFiles}
                      apiError={filesUploadError}
                      setApiError={setFilesUploadError}
                    />
                    <FieldAddTypeUpdaters
                      selectedFieldAddType={selectedFieldAddType}
                    />
                    <MapSearchContainer
                      additionalClassName="summary-map"
                      key={currentFarmId}
                      markersWithInfoWindows={
                        currentFarmId > -1 && expandedFarmId !== -1
                          ? fieldMarkers.filter(
                              (marker) => marker.farmId === currentFarmId,
                            )
                          : []
                      }
                      mapCenter={mapCenter}
                      fieldPolygons={checkTernaryCondition(
                        expandedFarmId !== -1 && currentFarmId !== -1,
                        fieldPolygons.filter(
                          (polygon) => polygon.farmId === currentFarmId,
                        ),
                        [],
                      )}
                      fieldInfoWindows={checkTernaryCondition(
                        expandedFarmId !== -1 && currentFarmId !== -1,
                        fieldInfoWindows.filter(
                          (infoWindow) => infoWindow.farmId === currentFarmId,
                        ),
                        [],
                      )}
                      mapControlOptions={mapControlOptions}
                      style={mapStyle}
                      handleNewPolygon={(fieldBoundaries) => {
                        setTempBoundaries(fieldBoundaries);
                      }}
                      drawMode={getMapDrawMode()}
                      mapBounds={mapBounds}
                      hasOnClickFunctionality
                      onPolygonClick={handleFieldBoundaryClick}
                      handlePolygonEdit={handlePolygonEdit}
                      showPolygonArea={true}
                    />
                    <FooterWrapper>
                      <Footer
                        isParticipantLanding={false}
                        isParticipantMenu={false}
                        isInActivityPage={false}
                      />
                    </FooterWrapper>
                  </>
                ),
              },
            ]}
          />,
        ),
      },
    ]);
  }, [
    participantInfo,
    farmInfo,
    expandedFarmId,
    currentFarmId,
    openMapView,
    selectedFieldId,
    fieldPolygons,
    loading,
    selectedFieldName,
    selectedFieldBoundaries,
    farmInfoCurrentTab,
    showFieldInfoForm,
    fieldMarkers,
    fieldInfoWindows,
    selectedFieldAddType,
    filesUploadError,
    shapeFileUploadModalOpen,
    mapCenter,
    mapBounds,
    rightSectionId,
    fieldInfoTab,
    isProfileDataEditable,
    enrolledFarmIds,
    enrolledFieldIds,
    submitData,
    userActivityDetected,
    addFarmBtnLoader,
  ]);

  const fetchStateList = async () => {
    return axios
      .get(FETCH_STATE_LIST)
      .then((response) => {
        return response.data.map(({ stateId, stateName }) => ({
          stateId: stateId,
          label: stateName,
          value: stateName,
        }));
      })
      .catch(() => {
        /* do error handling here */
      });
  };

  const fetchCountyList = async (stateId) => {
    if (!stateId) return;
    return axios
      .get(FETCH_COUNTY_LIST, { params: { stateId: stateId } })
      .then((response) => {
        const countyList = response.data.map((county) => ({
          countyId: county.countyId,
          label: county.countyName,
          value: county.countyName,
        }));
        return countyList;
      })
      .catch(() => {
        /* do error handling here */
      });
  };

  const filterApprovedEnrollmentData = (farmsList) => {
    const enrolledFarms = [],
      enrolledFields = [];

    for (const farm of farmsList) {
      if (farm.enrolmentStatus === enrollmentInstanceStatus.approved) {
        enrolledFarms.push(farm.farmId);
      } else {
        setHasNotApprovedFarmOrFields(true);
      }

      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        if (field.enrolmentStatus === enrollmentInstanceStatus.approved) {
          enrolledFields.push(field.fieldId);
        } else {
          setHasNotApprovedFarmOrFields(true);
        }
      }
    }

    setEnrolledFarmIds(enrolledFarms);
    setEnrolledFieldIds(enrolledFields);
  };

  /**
   * @description function to get the fields details of a farm
   * @param {Object} farm
   * @returns {Array}
   */
  const getFields = (farm) =>
    farm.fieldDetails?.map(
      ({
        fieldId,
        fieldName,
        formStatus,
        totalFieldArea,
        fieldBoundaries,
      }) => ({
        id: fieldId,
        value: fieldName,
        lat: '',
        lng: '',
        status: formStatus,
        info: '',
        isApiPopulated: true,
        totalArea: totalFieldArea,
        fieldBoundaries: fieldBoundaries,
      }),
    );

  const updateFarmInfo = (farms) => {
    setFarmInfo(
      farms.map((farm) => {
        return {
          id: farm.farmId,
          status: farm.farmStatus,
          formValidationFlag: farm.formValidationFlag,
          value: farm.farmName,
          isApiPopulated: true,
          fields: getFields(farm),
        };
      }),
    );
  };

  const fetchParticipantData = (isInitialCall) => {
    if (state) {
      axios
        .get(
          `${PARTICIPANT_PROFILE}?enrollmentType=PARTICIPANT&projectId=${projectId}&participantId=${participantId}`,
        )
        .then((response) => {
          setIsProfileDataEditable(
            !response.data?.participantProfileToBeDisabled,
          );
          if (
            response.data.farmDetails[0]?.fieldDetails?.length > 0 &&
            isInitialCall
          ) {
            setExpandedFarmId(response.data.farmDetails[0].farmId);
            setIsEditModeOn(true);
          }
          const data = response.data;
          const status = data.participantStatus;
          filterApprovedEnrollmentData(data?.farmDetails);
          setParticipantProfileStatus(status);
          setParticipantInfo([
            {
              id: 1,
              status: checkTernaryCondition(
                status === null || status === PAGE_CONTENT.IN_PROGRESS,
                PAGE_CONTENT.in_progress,
                PAGE_CONTENT.complete,
              ),
              value: `${response.data.firstName} ${response.data.lastName}`,
            },
          ]);
          const farmIds = data?.farmDetails;
          updateFarmInfo(data?.farmDetails);
          setFarmListIds(farmIds);
          populatePolygons(data?.farmDetails);
        })
        .catch((error) => {
          if (
            error?.response?.status === LANDING_PAGE_CONTENT.api_fail_status_401
          ) {
            navigate(pathName.participant.landingPage);
          }
        });
    } else {
      navigate(
        `${pathName.participant.landingPage}?projectid=${projectId}&participantid=${participantId}`,
      );
    }
  };

  const fetchProjectRegions = () => {
    axios
      .get(`${FETCH_VIEW_ORIGINATION_PROJECT}/${projectId}`)
      .then((response) => {
        const projectData = response.data;

        const projectRegionsList = projectData.projectRegion
          .slice(1, -1)
          .split(',')
          .map((item) => item.trim());

        setProjectRegions(projectRegionsList);
      })
      .catch(() => {
        /* do error handling here */
      });
  };

  const panToProjectRegion = () => {
    const boundsCoordinates = usRegionsPolygons
      .filter((polygon) => projectRegions.includes(polygon.region))
      .map((polygon) => polygon.polygonCoordinates)
      .reduce((accumulator, current) => [...accumulator, ...current], []);

    const gMapBounds = new window.google.maps.LatLngBounds();
    boundsCoordinates.forEach((bound) => {
      gMapBounds.extend(bound);
    });
    setMapBounds(gMapBounds);
  };

  useEffect(() => {
    fetchParticipantData(true);
    fetchFarmList();
    fetchProjectRegions();
    fetchCurrentCycleData();
  }, []);

  const performGeoCoding = (options) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(options, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const resultBounds = new window.google.maps.LatLngBounds(
          results[0].geometry.viewport.getSouthWest(),
          results[0].geometry.viewport.getNorthEast(),
        );
        setMapBounds(resultBounds);
      } else {
        panToProjectRegion();
      }
    });
  };

  const setMapLocation = (farmAddress) => {
    const { mailingAddress, state, county } = farmAddress;

    if (mailingAddress?.length > 0) {
      performGeoCoding({
        address: `${mailingAddress}, ${state}, ${county}`,
      });
    } else if (state?.length > 0 || county?.length > 0) {
      performGeoCoding({
        componentRestrictions: {
          administrativeArea: state + ' ' + county,
        },
      });
    } else {
      panToProjectRegion();
    }
  };

  const setCurrentFarmLocation = (farmId) => {
    setLoading(true);
    axios
      .get(`${FETCH_GENERAL_FARM_INFO}/${farmId}`, {
        params: {
          internalProjectId: projectId,
          participantOwnerId: participantId,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          const mailingAddress = data.farmMailingAddress;
          const stateName = data.farmState;
          const county = data.farmCounty;

          setMapLocation({
            mailingAddress: mailingAddress,
            state: stateName,
            county: county,
          });
        }
      })
      .catch(() => {
        /* do error handling here */
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (currentFarmId && fetchedFarmValues.length > 0) {
      const foundFarm = fetchedFarmValues.find(
        (farm) => farm.farmId === currentFarmId,
      );
      if (foundFarm) {
        setCurrentFarmInfo(foundFarm);
      }
    }
  }, [currentFarmId, fetchedFarmValues]);

  useEffect(() => {
    setFarmInfo((prevFarmInfo) => [
      ...farmListIds.reduce((acc, farm) => {
        if (!prevFarmInfo.some((info) => info.id === farm.farmId)) {
          acc.push({
            id: farm.farmId,
            status: farm.farmStatus,
            formValidationFlag: farm.formValidationFlag,
            value: farm.farmName,
            isApiPopulated: true,
            fields: getFields(farm),
          });
        }
        return acc;
      }, []),
      ...prevFarmInfo,
    ]);

    const currentFarmFields = farmListIds?.find(
      (farm) => farm.farmId === currentFarmId,
    )?.fieldDetails;
    if (!currentFarmFields) setCurrentFarmFieldsArea(0);
    else {
      const totalFieldArea = currentFarmFields?.reduce(
        (total, field) => total + Number(field.totalFieldArea),
        0,
      );
      setCurrentFarmFieldsArea(totalFieldArea ?? 0);
    }

    if (state?.id === 2 && farmListIds.length > 0 && rightSectionId !== 1) {
      setCurrentFarmId(
        currentFarmId === -1 ? farmListIds[0]?.farmId : currentFarmId,
      );
      setExpandedFarmId(
        currentFarmId === -1 ? farmListIds[0]?.farmId : currentFarmId,
      );
    }
    setFetchedFarmIds(true);
    extractFarmIdsWithExistingFields(farmListIds);
  }, [farmListIds]);

  useEffect(() => {
    if (fetchedFarmIds && fetchedFarmValues.length === 0) {
      farmListIds.forEach((fetchedFarm) => {
        axios
          .get(`${FETCH_GENERAL_FARM_INFO}/${fetchedFarm.farmId}`, {
            params: {
              internalProjectId: projectId,
              participantOwnerId: participantId,
            },
          })
          .then((response) => {
            const farmIdExists = fetchedFarmValues.some(
              (farm) => farm.farmId === fetchedFarm.farmId,
            );
            if (!farmIdExists) {
              setFetchedFarmValues((prevFetchedFarmValues) => [
                ...prevFetchedFarmValues,
                response.data,
              ]);
            }

            setFarmInfo((prevFarmInfo) => {
              return prevFarmInfo.map((farm) => {
                if (farm.id === fetchedFarm.farmId) {
                  return {
                    ...farm,
                    status:
                      convertStatus(response.data?.formStatus) || farm.status,
                  };
                }
                return farm;
              });
            });
          })
          .catch(() => {
            /* do error handling here */
          });
      });
    }
  }, [farmListIds, fetchedFarmIds, fetchedFarmValues]);

  const handleViewMapClick = (farmId) => {
    if (!farmsIdsWithExistingFields.includes(farmId)) {
      setFieldAddTypeModalOpen(true);
    }
  };

  const goToProfile = () => {
    setRightSectionId(1);
    setCurrentFarmId(-1);
    setExpandedFarmId(-1);
    setFarmInfoCurrentTab(0);
    setShowFieldInfoForm(false);
  };

  const goToFarm = (targetId) => {
    setRightSectionId(2);
    setCurrentFarmId(targetId);
    setExpandedFarmId(targetId);
    setFarmInfoCurrentTab(0);
    setShowFieldInfoForm(false);
    setOpenMapView(false);
  };

  const openGoogleMaps = (targetId) => {
    if (rightSectionId === 1) {
      setCurrentFarmId(targetId);
      setExpandedFarmId(targetId);
      setFarmInfoCurrentTab(1);
      setRightSectionId(2);
    } else {
      setCurrentFarmLocation(targetId);
      setCurrentFarmId(targetId);
      setExpandedFarmId(targetId);
      setIsTabSwitched(targetId === currentFarmId);
      setShowFieldInfoForm(false);
      setFarmInfoCurrentTab(1);
      if (!farmsIdsWithExistingFields.includes(targetId)) {
        setFieldAddTypeModalOpen(true);
      }
    }
  };

  const getCurrentPage = () => {
    if (rightSectionId === 1) return 'Profile';
    if (rightSectionId == 2 && selectedFieldId !== null && showFieldInfoForm)
      return 'Field';
    if (rightSectionId === 2) return 'Farm';
  };

  const handleAccordianSummaryClick = ({
    targetPlace,
    targetId: farmId,
    targetName: farmName,
  }) => {
    removePolygonsAndInfoWindowsWithError();
    if (targetPlace === LEFT_MENU_SECTION_HEADERS.profileSection) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: null,
          targetFarmName: '',
          navigateNextTo: LEFT_MENU_SECTION_HEADERS.profileSection,
          triggeredFrom: LEFT_MENU_SECTION_HEADERS.profileSection,
        });
      } else {
        goToProfile();
      }
    } else if (targetPlace === LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection) {
      if (userActivityDetected) {
        setUnsavedChangesModalOpenProps({
          isOpen: true,
          nextTab: 0,
          targetFieldId: null,
          targetFieldName: '',
          targetFarmId: farmId,
          targetFarmName: farmName,
          navigateNextTo: LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection,
          triggeredFrom: LEFT_MENU_SECTION_HEADERS.farmAndFieldsSection,
        });
      } else {
        goToFarm(farmId);
      }
    }
  };

  const handleAccordianItemClick = ({
    targetPlace = 'Field',
    targetId: fieldId,
    targetName: fieldName,
    targetParentId: farmId,
  }) => {
    if (targetPlace === 'Add fields') {
      setUserActivityDetected(false);
      openGoogleMaps(farmId);
      return;
    }

    if (userActivityDetected) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: 0,
        targetFieldId: fieldId,
        targetFieldName: fieldName,
        targetFarmId: farmId,
        targetFarmName: '',
        navigateNextTo: targetPlace,
        triggeredFrom: targetPlace,
      });
    } else {
      openFieldInformation({ id: fieldId, name: fieldName, farmId: farmId });
    }
  };

  const value = React.useMemo(
    () => ({
      sectionList,
      setSectionList,
      rightSectionId,
      setRightSectionId,
      participantInfo,
      setParticipantInfo,
      farmInfo,
      setFarmInfo,
      expandedFarmId,
      setExpandedFarmId,
      openMapView,
      setOpenMapView,
      updateFarmFieldName,
      selectedFieldId,
      setSelectedFieldId,
      farmListIds,
      setFarmListIds,
      currentFarmId,
      setCurrentFarmId,
      farmLoading,
      setFarmLoading,
      currentFarmInfo,
      setCurrentFarmInfo,
      fetchedFarmValues,
      setFetchedFarmValues,
      state,
      saveAndCloseClicked,
      setSaveAndCloseClicked,
      setIsEditModeOn,
      isEditModeOn,
      savedFieldsForms,
      setSavedFiledForms,
      showFieldInfoForm,
      setShowFieldInfoForm,
      fieldAddTypeModalOpen,
      setFieldAddTypeModalOpen,
      selectedFieldAddType,
      setSelectedFieldAddType,
      farmInfoCurrentTab,
      setFarmInfoCurrentTab,
      farmsIdsWithExistingFields,
      fieldPolygons,
      setFieldPolygons,
      addFieldsFromShapeFile,
      addFarmClicked,
      setAddFarmClicked,
      setShapeFileUploadModalOpen,
      fieldIdsAddedFromMap,
      setFieldIdsAddedFromMap,
      isTabSwitched,
      setIsTabSwitched,
      setMapBounds,
      setCurrentFarmLocation,
      fetchCountyList,
      fetchStateList,
      isFormUnmounted,
      setIsFormUnmounted,
      submitData,
      setSubmitData,
      userActivityDetected,
      setUserActivityDetected,
      isFieldDeleted,
      setIsFieldDeleted,
      modalData,
      setModalData,
      participantProfileStatus,
      setParticipantProfileStatus,
      isProfileDataEditable,
      setIsProfileDataEditable,
      enrolledFarmIds,
      enrolledFieldIds,
      hasNotApprovedFarmOrFields,
      isFarmCsafUpdatedBySystem,
      setIsFarmCsafUpdatedBySystem,
      isFieldSubmitted,
      setIsFieldSubmitted,
      setHasNotApprovedFarmOrFields,
      fetchParticipantData,
      loading,
      setLoading,
      isParticipantContributor,
      handleViewMapClick,
      statusList,
      setStatusList,
      farmListStatus,
      setFarmListStatus,
      currentCycleDataYear,
      setCurrentCycleDataYear,
      setCurrentCycleStartDate,
      setCurrentCycleEndDate,
      currentCycleStartDate,
      currentCycleEndDate,
      triggerFieldInfoFetch,
      setTriggerFieldInfoFetch,
      unsavedChangesModalOpenProps,
      setUnsavedChangesModalOpenProps,
      closeUnsavedChangesModal,
      triggerFarmInfoSubmit,
      setTriggerFarmInfoSubmit,
      triggerFieldInfoSubmit,
      setTriggerFieldInfoSubmit,
      setFieldInfoTab,
      addFarmData,
      getUpdatedFarmDetails,
      addFarmClickHandler,
      addedFromUiFarmIds,
      setAddedFromUiFarmIds,
      handleLeaveWithoutSave,
      handleSaveAndLeave,
      handleSubmitCallback,
      triggerProfileInfoSubmit,
      setTriggerProfileInfoSubmit,
      addFarmBtnLoader,
      setAddFarmBtnLoader,
      isPolygonOverlapping,
      setTempBoundaries,
      addFieldToFarm,
      handleFieldBoundaryClick,
    }),
    [
      rightSectionId,
      sectionList,
      participantInfo,
      farmInfo,
      expandedFarmId,
      selectedFieldId,
      farmListIds,
      currentFarmId,
      farmLoading,
      currentFarmInfo,
      fetchedFarmValues,
      state,
      isEditModeOn,
      savedFieldsForms,
      saveAndCloseClicked,
      showFieldInfoForm,
      fieldAddTypeModalOpen,
      selectedFieldAddType,
      farmInfoCurrentTab,
      farmsIdsWithExistingFields,
      fieldPolygons,
      addFarmClicked,
      fieldIdsAddedFromMap,
      isTabSwitched,
      isFormUnmounted,
      submitData,
      userActivityDetected,
      isFieldDeleted,
      modalData,
      participantProfileStatus,
      isProfileDataEditable,
      enrolledFarmIds,
      enrolledFieldIds,
      hasNotApprovedFarmOrFields,
      isFarmCsafUpdatedBySystem,
      isFieldSubmitted,
      loading,
      isParticipantContributor,
      statusList,
      setStatusList,
      farmListStatus,
      setFarmListStatus,
      currentCycleDataYear,
      currentCycleStartDate,
      currentCycleEndDate,
      triggerFieldInfoFetch,
      unsavedChangesModalOpenProps,
      triggerFarmInfoSubmit,
      triggerFieldInfoSubmit,
      fieldInfoTab,
      addedFromUiFarmIds,
      triggerProfileInfoSubmit,
      addFarmBtnLoader,
    ],
  );

  return (
    <participantInfoContext.Provider value={value}>
      {children}
    </participantInfoContext.Provider>
  );
};
