import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  LeftSectionWrapper,
  MainContainer,
  MainWrapper,
  ParentContainer,
  ProjectName,
  TabberBoxCustomStyle,
  TextWrapper,
  DividerStyles,
  BackButtonWrapper,
  backButtonStyle,
  footerStyle,
  HeaderContainer,
  HeaderButtonContainer,
  CollapseExpandButtonSx,
  projectNameStyle,
  TabComponentWrapper,
  ParticipantFlowFooterWrapper,
} from './BaseLineReportingMainPage.style';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Tabs,
  Tooltip,
} from '@mui/material';
import LeftMenuSubsection from '../components/LeftMenuSubsection/LeftMenuSubsection.react';
import { FETCH_PROJECT_LIFECYCLES, PARTICIPANT_PROFILE } from 'urls';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import {
  PAGE_CONTENT,
  baselineInstanceStatus,
  handleNextInProgrssSwitcher,
  tabsTitle,
} from './BaseLineReportingMainPage.content';
import { checkTernaryCondition, ternaryBooleanHandler } from 'utils/helper';
import Tab from '@mui/material/Tab';
import classNames from 'classnames';
import TabPanel from 'components/Tabber/TabPanel.react';
import TabBodyComponent from '../ReportingComponents/TabBodyComponent.react';
import EnrollmentCompleteModal from '../components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { pathName } from 'Routes/routes.const';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import {
  columnFlexStyle,
  ProjectNameWrapper,
} from '../ParticipantDataCollection.style';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import { useReportingApis } from 'hooks/useReportingApis';
import { getFormattedFarmData, getStatus } from '../ReportingUtilFunctions';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import moment from 'moment';
import axios from 'axios';
import { ReactComponent as BackArrow } from 'assets/icons/Round.svg';
import UnsavedChangesModal from '../components/UnsavedChangesModal/UnsavedChangesModal.react';
import Footer from 'layout/footer/Footer.react';
import { ImplementedActivitiesWithOpsDataProvider } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import TabBodyComponentV2 from '../ReportingComponents/TabBodyComponentV2/TabBodyComponentV2.react';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  FIELD_SUBMIT_TRIGGER_INIT,
  UNSAVED_CHANGES_MODAL_PROPS_INIT,
} from '../ParticipantDataCollection.content';

const BaseLineReportingMainPage = ({ isInTest = false }) => {
  const { userRoles } = useContext(userAuthRolesContext);
  const { loaderCounter } = useContext(baselineDropdownContext);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [fieldDetails, setFieldDetails] = useState({});
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [farmDetails, setFarmDetails] = useState();
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const [type, setType] = useState(PAGE_CONTENT.farm);
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [enrollmentYears, setEnrollmentYears] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [modalData, setModalData] = useState({ id: 0, flag: false });
  const [cycleYear, setCycleYear] = useState('');
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [currentFieldId, setCurrentFieldId] = useState({
    id: 0,
    name: '',
  });
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
  });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchLastSectionData, setFetchLastSectionData] = useState(false);
  const [baselineApprovedFarmIds, setBaselineApprovedFarmIds] = useState([]);
  const [baselineApprovedFieldIds, setBaselineApprovedFieldIds] = useState([]);
  const [
    hasNotApprovedBaselineFarmOrFields,
    setHasNotApprovedBaselineFarmOrFields,
  ] = useState(false);
  // Used to check if user has made any changes in the page, based on this we prompt user when they try to leave the page with unsaved changes
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [unsavedChangesModalOpenProps, setUnsavedChangesModalOpenProps] =
    useState(UNSAVED_CHANGES_MODAL_PROPS_INIT);
  const [allExpanded, setAllExpanded] = useState([true]);
  const [fieldSubmitTriggerProps, setFieldSubmitTriggerProps] = useState(
    FIELD_SUBMIT_TRIGGER_INIT,
  );
  const [triggerCurrentFieldDataFetch, setTriggerCurrentFieldDataFetch] =
    useState(false);

  /**
   * @description Using the flag 'userActivityDetected' we are tracking if there was a change in the web forms, if there is a change we add a 'beforeunload' event which gets triggered when user tries to close the browser window/tab, it provides a warning to the user to save changes before closing the browser.
   */
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );
  const {
    loading: apiLoading,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchFieldArea,
    fetchProjectData,
    submitBaselineFarm,
  } = useReportingApis();

  const navigate = useNavigate();
  const { TabberBox } = useStyles();
  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `baseline-page-tab-${index}`,
    };
  };

  const closeUnsavedChangesModal = () =>
    setUnsavedChangesModalOpenProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);

  // Logic to handle disabling of farm and fields on creation of Additional instance

  const disableAllFieldActions = baselineApprovedFieldIds?.includes(
    currentFieldId.id,
  );

  const switcher = ({
    switchTo,
    farmId,
    farmName,
    fieldId,
    fieldName,
    tabIndex,
    targetType,
  }) => {
    switch (switchTo) {
      case 'Tab':
        setCurrentTab(tabIndex);
        if (targetType === PAGE_CONTENT.farm) {
          fetchBaselineYears(PAGE_CONTENT.farm, farmId);
        } else {
          fetchBaselineYears(PAGE_CONTENT.crop, fieldId);
        }
        break;

      case 'Farm':
        setCurrentTab(0);
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
        fetchBaselineYears(PAGE_CONTENT.farm, farmId);
        setType(PAGE_CONTENT.farm);
        break;
      case 'Field':
        setCurrentTab(0);
        setCurrentFieldId({ id: fieldId, name: fieldName });
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        fetchBaselineYears(PAGE_CONTENT.crop, fieldId);
        setType(PAGE_CONTENT.crop);
        break;
      default:
        return;
    }
  };

  const handleLeaveWithoutSave = () => {
    switch (unsavedChangesModalOpenProps.navigateNextTo) {
      case 'Tab':
        switcher({
          switchTo: 'Tab',
          farmId: unsavedChangesModalOpenProps.targetFarmId,
          farmName: unsavedChangesModalOpenProps.targetFarmName,
          fieldId: unsavedChangesModalOpenProps.targetFieldId,
          fieldName: unsavedChangesModalOpenProps.targetFieldName,
          tabIndex: unsavedChangesModalOpenProps.nextTab,
          targetType: type, // Same as current type for tab change
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: unsavedChangesModalOpenProps.targetFarmId,
          farmName: unsavedChangesModalOpenProps.targetFarmName,
          fieldId: unsavedChangesModalOpenProps.targetFieldId,
          fieldName: unsavedChangesModalOpenProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: unsavedChangesModalOpenProps.targetFarmId,
          farmName: unsavedChangesModalOpenProps.targetFarmName,
          fieldId: unsavedChangesModalOpenProps.targetFieldId,
          fieldName: unsavedChangesModalOpenProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
    }
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
  };

  const handleSaveAndLeave = () => {
    if (type === PAGE_CONTENT.farm) {
      submitFarmData().then(() => {
        fetchFieldDetails(false, false);
        handleLeaveWithoutSave();
        updateReportingStatus();
      });
    } else if (type === PAGE_CONTENT.crop) {
      setFieldSubmitTriggerProps({
        trigger: true,
        navigateTo: unsavedChangesModalOpenProps.navigateNextTo,
        targetFarmId: unsavedChangesModalOpenProps.targetFarmId,
        targetFarmName: unsavedChangesModalOpenProps.targetFarmName,
        targetFieldId: unsavedChangesModalOpenProps.targetFieldId,
        targetFieldName: unsavedChangesModalOpenProps.targetFieldName,
        targetTab: unsavedChangesModalOpenProps.nextTab,
      });
      closeUnsavedChangesModal();
      setUserActivityDetected(false);
    }
  };

  const handleTabChange = (_, selectedTab) => {
    if (userActivityDetected) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: selectedTab,
        targetFarmId: currentFarmId,
        targetFarmName: farmDetails?.name,
        targetFieldId: currentFieldId.id,
        targetFieldName: currentFieldId.name,
        navigateNextTo: 'Tab',
      });
    } else {
      setCurrentTab(selectedTab);
    }
  };

  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');

  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.baselineMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  // Note: This function will be called once field data is save from context and we navigate to the target place from here based on the fieldSubmitTriggerProps.
  const handleSubmitCallback = () => {
    // Navigate to appropriate place based on trigger submit props
    switch (fieldSubmitTriggerProps.navigateTo) {
      case 'Tab':
        setCurrentTab(fieldSubmitTriggerProps.targetTab);
        fetchBaselineYears(
          PAGE_CONTENT.crop,
          fieldSubmitTriggerProps.targetFieldId,
        );
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
    }
    setUnsavedChangesModalOpenProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);
    setFieldSubmitTriggerProps(FIELD_SUBMIT_TRIGGER_INIT);
    fetchFieldDetails(false, false);
  };

  const updateStatus = (currentFieldId, newFieldStatus, fieldDetails) => {
    const updatedFieldDetails = { ...fieldDetails };
    let allFarmsCompleted = true;
    for (const farm of updatedFieldDetails.farmDetails) {
      for (const field of farm.fieldDetails) {
        if (field.fieldId === currentFieldId) {
          field.fieldStatus = newFieldStatus;
        }
        if (field.fieldStatus !== 'Completed') {
          allFarmsCompleted = false;
        }
      }
      farm.farmStatus = allFarmsCompleted ? 'complete' : 'in progress';
      allFarmsCompleted = true;
    }
    setFieldDetails(updatedFieldDetails);
  };

  const filterApprovedBaselineData = (farmsList) => {
    const baselineApprovedFarms = [],
      baselineApprovedFields = [];

    for (const farm of farmsList) {
      if (
        farm.baselineStatus === baselineInstanceStatus.approved &&
        farm.fieldDetails.every(
          (field) => field.baselineStatus === baselineInstanceStatus.approved,
        )
      ) {
        baselineApprovedFarms.push(farm.farmId);
      } else {
        setHasNotApprovedBaselineFarmOrFields(true);
      }

      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        if (field.baselineStatus === baselineInstanceStatus.approved) {
          baselineApprovedFields.push(field.fieldId);
        } else {
          setHasNotApprovedBaselineFarmOrFields(true);
        }
      }
    }
    setBaselineApprovedFarmIds(baselineApprovedFarms);
    setBaselineApprovedFieldIds(baselineApprovedFields);
  };
  const updateReportingStatus = () => {
    const URL = `${PARTICIPANT_PROFILE}?enrollmentType=BASELINE&projectId=${projectId}&participantId=${participantId}`;
    fetchBaselineFieldDetails(URL).then((data) => {
      setFieldDetails(data);
      fetchBaselineYears('field', currentFieldId.id);
      const farmList = data?.farmDetails;
      const baselineStatus = getStatus(farmList);
      updateStatus(currentFieldId.id, baselineStatus, data);
    });
  };

  const fetchFieldDetails = (isInitial, isSaveClicked) => {
    const URL = `${PARTICIPANT_PROFILE}?enrollmentType=BASELINE&projectId=${projectId}&participantId=${participantId}`;
    fetchBaselineFieldDetails(URL).then((data) => {
      const farmId = data?.farmDetails[0]?.farmId;
      const farmName = data?.farmDetails[0]?.farmName;
      const farmList = data?.farmDetails;
      filterApprovedBaselineData(farmList);
      setFieldDetails(data);
      if (isInitial) {
        setFieldDetails(data);
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);

        const baselineStatus = getStatus(farmList);
        updateStatus(currentFieldId.id, baselineStatus, data);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
      }
      if (isSaveClicked) {
        saveAndContinueClickHandler(farmList);
      }
    });
  };

  const submitFarmData = async () => {
    if (baselineApprovedFarmIds.includes(currentFarmId)) {
      return Promise.resolve();
    }

    const { sampleId, parentState } = submitData;
    if (sampleId === null) return Promise.resolve();

    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const payload = {
      activityReportingStatus: 'Interested',
      enrolmentType: 'Baseline',
      farmId: currentFarmId,
      farmSampleId: sampleId,
      projectId: projectId,
      participantId: participantId,
      farmOperationsDataStatus: 'Not Started',
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };

    return submitBaselineFarm(payload)
      .then(() => {
        setUserActivityDetected(false);
      })
      .catch(() => {});
  };

  const saveDataHandler = (
    isTabSwitched = false,
    clickdata = { type: 'none', id: null },
    gotoNextInProgress = false,
  ) => {
    const { sampleId, parentState, editMode } = submitData;
    if (sampleId === null) return;

    if (type === PAGE_CONTENT.farm) {
      handleFarmSubmit({
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
        gotoNextInProgress,
      });
    }
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      const cycleYear = moment(cycleObject[0]?.cycleEndDate)
        .format('MM/DD/YYYY')
        ?.slice(6);
      if (cycleObject?.length > 0) {
        setCycleYear(cycleYear);
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  };

  const handleFarmSubmit = ({
    isTabSwitched,
    clickdata,
    parentState,
    sampleId,
    gotoNextInProgress,
  }) => {
    if (baselineApprovedFarmIds.includes(currentFarmId)) {
      saveAndContinueClickHandler(fieldDetails.farmDetails);
      return;
    }
    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const submitData = {
      activityReportingStatus: 'Interested',
      enrolmentType: 'Baseline',
      farmId: currentFarmId,
      farmSampleId: sampleId,
      projectId: projectId,
      participantId: participantId,
      farmOperationsDataStatus: 'Not Started',
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };

    submitBaselineFarm(submitData)
      .then(() => {
        setUserActivityDetected(false);
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
        fetchFieldDetails(false, gotoNextInProgress);

        const shouldFetchBaselineYears =
          isTabSwitched ||
          (clickdata.type?.toLowerCase() === 'farm' &&
            clickdata.id === currentFarmId &&
            type === PAGE_CONTENT.farm);
        if (shouldFetchBaselineYears) {
          fetchBaselineYears(PAGE_CONTENT.farm, currentFarmId);
        }
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      });
  };

  const baselineTabs = useMemo(() => {
    if (type === PAGE_CONTENT.farm) {
      return enrollmentYears?.map((year) => {
        return {
          title: tabsTitle(year?.yearValue, year?.status),
          component: (
            <TabBodyComponent
              year={year?.yearValue}
              type={checkTernaryCondition(
                type === PAGE_CONTENT.farm,
                PAGE_CONTENT.empty_farm_label,
                PAGE_CONTENT.empty_crop_label,
              )}
              fieldType={type}
              sampleId={year?.sampleId}
              setLoading={setLoading}
              fieldId={currentFieldId.id}
              farmId={currentFarmId}
              totalFieldArea={totalFieldArea}
              enrollmentYearData={year}
              setFieldDetails={setFieldDetails}
              setEnrollmentYears={setEnrollmentYears}
              fetchFieldDetails={fetchFieldDetails}
              setSubmitData={setSubmitData}
              saveDataHandler={saveDataHandler}
              setIsInitialLoad={setIsInitialLoad}
              fetchLastSectionData={fetchLastSectionData}
              setFetchLastSectionData={setFetchLastSectionData}
              setUserActivityDetected={setUserActivityDetected}
              typeOfReporting={PAGE_CONTENT.baselineReportingType}
              approvedFarmIds={baselineApprovedFarmIds}
              baselineApprovedFieldIds={baselineApprovedFieldIds}
              handleCancel={navigateHandler}
            />
          ),
        };
      });
    } else if (type === PAGE_CONTENT.crop) {
      return enrollmentYears?.map((year) => {
        return {
          title: tabsTitle(year?.yearValue, year?.status),
          component: (
            <TabBodyComponentV2
              navigateHandler={navigateHandler}
              allExpanded={allExpanded}
              currentYearData={year}
              currentFarmId={currentFarmId}
              currentFieldId={currentFieldId.id}
              setFieldDetails={setFieldDetails}
              disableAllFieldActions={disableAllFieldActions}
              setEnrollmentYears={setEnrollmentYears}
              refreshStatusHandler={updateReportingStatus}
              gotoNextInprogressPage={() => {
                const URL = `${PARTICIPANT_PROFILE}?enrollmentType=BASELINE&projectId=${projectId}&participantId=${participantId}`;
                fetchBaselineFieldDetails(URL).then((data) => {
                  saveAndContinueClickHandler(data?.farmDetails);
                  const farmList = data?.farmDetails;
                  const baselineStatus = getStatus(farmList);
                  updateStatus(currentFieldId.id, baselineStatus, data);
                });
              }}
              setUserActivityDetected={setUserActivityDetected}
            />
          ),
        };
      });
    }
  }, [
    enrollmentYears,
    currentFieldId,
    currentFarmId,
    totalFieldArea,
    baselineApprovedFarmIds,
    baselineApprovedFieldIds,
    allExpanded,
    submitData,
    currentTab,
  ]);

  const fetchBaselineYears = (type, id) => {
    if (type === PAGE_CONTENT.farm) {
      const apiParams = { farmId: id, projectCycleId: Number(projectCycleId) };
      fetchBaselineFarmYears(apiParams).then((data) => {
        const enrollmentYear = data.filter((year) => year.phase === 'baseline');
        setEnrollmentYears(enrollmentYear);
      });
    } else {
      const apiParams = {
        fieldId: currentFieldId?.id,
        projectId: projectId,
        projectCycleId: Number(projectCycleId),
      };
      fetchBaselineFieldYears(apiParams).then((data) => {
        const enrollmentYear = data.filter((year) => year.phase === 'baseline');
        setEnrollmentYears(enrollmentYear);
      });
    }
  };
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentFieldId.id };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };

  const fetchProjectDetails = () => {
    const apiPath = `/${projectId}`;
    const errorHandler = () => setProjectDetails({ projectName: '' });
    fetchProjectData(apiPath, errorHandler).then((projectData) =>
      setProjectDetails(projectData),
    );
  };

  useEffect(() => {
    fetchFieldDetails(true, false);
    fetchProjectDetails();
    fetchLifeCycleDates();
  }, []);
  useEffect(() => {
    if (currentFieldId.id > 0) {
      setType(PAGE_CONTENT.crop);
      fetchBaselineYears(PAGE_CONTENT.crop, currentFieldId.id);
      fetchTotalFieldArea();
    }
  }, [currentFieldId]);
  useEffect(() => {
    if (farmDetails) {
      setType(PAGE_CONTENT.farm);
      fetchBaselineYears(PAGE_CONTENT.farm, farmDetails.id);
    }
  }, [farmDetails]);

  useEffect(() => {
    if (enrollmentYears && !isInitialLoad) {
      setEnrollmentYears((prev) =>
        prev.map((yearData) => {
          return checkTernaryCondition(
            yearData.sampleId === submitData.sampleId,
            {
              ...yearData,
              status: checkTernaryCondition(
                submitData.parentState?.length > 0,
                'In Progress',
                'Not Started',
              ),
            },
            yearData,
          );
        }),
      );
    }
    if (type === PAGE_CONTENT.farm && !isInitialLoad) {
      setFieldDetails((prev) => {
        return {
          ...prev,
          farmDetails: prev.farmDetails?.map((farm) => {
            return checkTernaryCondition(
              farm.farmId === currentFarmId,
              {
                ...farm,
                mainStatus: 'in progress',
              },
              farm,
            );
          }),
        };
      });
    }
    if (type === PAGE_CONTENT.crop && !isInitialLoad) {
      setFieldDetails((prev) => {
        return {
          ...prev,
          farmDetails: prev.farmDetails?.map((farm) => {
            return checkTernaryCondition(
              farm.farmId === currentFarmId,
              {
                ...farm,
                mainStatus: 'in progress',
                fieldDetails: farm.fieldDetails.map((field) => {
                  return checkTernaryCondition(
                    field.fieldId === currentFieldId.id,
                    { ...field, mainStatus: 'in progress' },
                    field,
                  );
                }),
              },
              farm,
            );
          }),
        };
      });
    }
  }, [submitData]);

  const saveAndContinueClickHandler = (farmDetails) => {
    let inProgressItem = null;
    inProgressItem = handleNextInProgrssSwitcher(
      inProgressItem,
      farmDetails,
      getCurrentFarm(),
      checkTernaryCondition(type === PAGE_CONTENT.farm, null, currentFieldId),
      ternaryBooleanHandler(type === PAGE_CONTENT.farm),
      currentTab,
    );
    formInProgressHandler(inProgressItem, farmDetails);
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.fieldDetails?.every(
              (field) => field.fieldStatus === 'Completed',
            ),
            { ...farm, farmStatus: 'complete' },
            farm,
          );
        }),
      };
    });
  };

  const getCurrentFarm = () => {
    return fieldDetails.farmDetails.find(
      (farm) => farm.farmId === currentFarmId,
    );
  };

  const formInProgressHandler = (inProgressItem, updatedFarmDetails) => {
    if (inProgressItem !== null) {
      if (inProgressItem.type === 'field') {
        setType(PAGE_CONTENT.crop);
        setCurrentFieldId({ id: inProgressItem.id, name: inProgressItem.name });
        setCurrentFarmId(inProgressItem.farmId);
        setExpandedFarmId(inProgressItem.farmId);
        setCurrentTab(inProgressItem.yearIndex);
      } else if (inProgressItem.type === 'farm') {
        setType(PAGE_CONTENT.farm);
        setFarmDetails({
          id: inProgressItem.id,
          name: inProgressItem.name,
        });
        setCurrentFarmId(inProgressItem.id);
        setExpandedFarmId(inProgressItem.id);
        setCurrentTab(inProgressItem.yearIndex);
      }

      return;
    }

    const areAllFarmsCompleted = updatedFarmDetails.every((farm) =>
      farm.fieldDetails.every(
        (field) => field?.mainStatus?.toLowerCase() === 'completed',
      ),
    );

    if (inProgressItem === null && areAllFarmsCompleted) {
      setModalData({
        flag: true,
        id: 0,
      });
      fetchBaselineYears(type, currentFarmId);
      setFetchLastSectionData(true);
      return;
    }

    if (!areAllFarmsCompleted) {
      // Note: Since all farms are not completed and we don't have any in progress item -> meaning we are at the last field, we need to refresh the data for current field.
      setTriggerCurrentFieldDataFetch(true);
      updateReportingStatus();
    }
  };

  const onClickBackButton = () => {
    if (
      [PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR, PAGE_CONTENT.PARTICIPANT].includes(
        userRoles[0],
      )
    ) {
      navigate(`${pathName.participant.landingPage}`);
      return;
    }
    navigate(
      `/stakeholders/baseline-menu-page?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`,
    );
  };

  const handleFarmClick = ({ targetId: farmId, targetName: farmName }) => {
    if (currentFarmId === farmId && type === PAGE_CONTENT.farm) return;

    if (userActivityDetected) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: null,
        targetFieldName: null,
        navigateNextTo: 'Farm',
      });
    } else {
      setFarmDetails({
        id: farmId,
        name: farmName,
      });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
    }
  };

  const handleFieldClick = ({
    targetId: fieldId,
    targetName: fieldName,
    targetFarmId: farmId,
    targetFarmName: farmName,
  }) => {
    if (currentFieldId.id === fieldId && type === PAGE_CONTENT.crop) return;

    if (userActivityDetected) {
      setUnsavedChangesModalOpenProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: fieldId,
        targetFieldName: fieldName,
        navigateNextTo: 'Field',
      });
    } else {
      setCurrentFieldId({ id: fieldId, name: fieldName });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
    }
  };

  return (
    <MainWrapper>
      <BackButtonWrapper onClick={onClickBackButton}>
        <BackArrow data-testid="back-icon" />
        <Button sx={backButtonStyle}>{PAGE_CONTENT.back}</Button>
      </BackButtonWrapper>
      <Tooltip title={projectDetails?.projectName}>
        <ProjectNameWrapper style={projectNameStyle}>
          <ProjectName>
            {projectDetails?.projectName + ' - ' + cycleYear || ''}
          </ProjectName>
          <ProjectName>{` (${projectCycleData?.cycleStartDate}`}</ProjectName>
          <ProjectName>{` - ${projectCycleData?.cycleEndDate})`}</ProjectName>
        </ProjectNameWrapper>
      </Tooltip>
      <Divider style={DividerStyles} />
      <ParentContainer style={{ marginTop: '2px' }}>
        <Backdrop
          sx={{
            color: WHITE,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: checkTernaryCondition(
              isInTest,
              'inherit',
              'white',
            ),
          }}
          open={
            loading ||
            loaderCounter > 0 ||
            apiLoading ||
            (!isInTest && enrollmentYears.length < 1)
          }>
          <CircularProgress color="success" />
        </Backdrop>
        <LeftSectionWrapper>
          <LeftMenuSubsection
            heading={PAGE_CONTENT.left_section_header}
            fieldData={fieldDetails?.farmDetails?.map((data) => ({
              id: data.farmId,
              status: data.mainStatus,
              value: data.farmName,
              fields: data.fieldDetails?.map((item) => ({
                value: item.fieldName,
                status: item.mainStatus,
                id: item.fieldId,
              })),
            }))}
            showFields={false}
            isBaselineFlow={true}
            currentFarmId={currentFarmId}
            expandedFarmId={expandedFarmId}
            selectedFieldId={currentFieldId}
            setFarmDetails={setFarmDetails}
            isInBaselinePage
            isFarmSelected={type === PAGE_CONTENT.farm}
            handleAccordianSummaryClick={handleFarmClick}
            handleAccordianItemClick={handleFieldClick}
          />
        </LeftSectionWrapper>
        <div style={columnFlexStyle}>
          <ImplementedActivitiesWithOpsDataProvider
            currentFieldId={currentFieldId?.id}
            currentFarmId={currentFarmId}
            triggerFieldDataSubmit={fieldSubmitTriggerProps?.trigger}
            dataSubmitCallback={handleSubmitCallback}
            triggerCurrentFieldDataFetch={triggerCurrentFieldDataFetch}
            setTriggerCurrentFieldDataFetch={setTriggerCurrentFieldDataFetch}>
            <MainContainer>
              <HeaderContainer style={{ padding: '1.5rem 0 1rem 1.5rem' }}>
                <TextWrapper
                  fontSize="16px"
                  fontWeight="600"
                  color={DARK_CHARCOAL}>
                  {`${
                    checkTernaryCondition(
                      type === PAGE_CONTENT.farm,
                      `${farmDetails?.name} ${PAGE_CONTENT.baseline_heading}`,
                      `${farmDetails?.name} - ${currentFieldId?.name}`,
                    ) || ''
                  }`}
                </TextWrapper>
                {type !== PAGE_CONTENT.farm && (
                  <HeaderButtonContainer>
                    <Button
                      onClick={() => {
                        setAllExpanded((prev) => {
                          return [...prev, false];
                        });
                      }}
                      variant="outlined"
                      size="medium"
                      sx={{
                        ...CollapseExpandButtonSx,
                      }}>
                      {PAGE_CONTENT.collapseButton}
                    </Button>
                    <Button
                      onClick={() => {
                        setAllExpanded((prev) => {
                          return [...prev, true];
                        });
                      }}
                      variant="outlined"
                      sx={{
                        ...CollapseExpandButtonSx,
                      }}>
                      {PAGE_CONTENT.expandAllButton}
                    </Button>
                  </HeaderButtonContainer>
                )}
              </HeaderContainer>

              <Box
                sx={{
                  ...flexColumn,
                  ...yAxisAutoScroll,
                }}>
                <TabberBox
                  style={{
                    ...TabberBoxCustomStyle,
                    padding: '0rem 0 0rem 1.5rem',
                  }}>
                  <Tabs
                    value={currentTab}
                    onChange={handleTabChange}
                    aria-label="page-tabs">
                    {baselineTabs?.map((tab, index) => (
                      <Tab
                        sx={{ textTransform: 'none', width: 'unset' }}
                        className={classNames({
                          'active-tab': currentTab === index,
                        })}
                        key={tab?.title}
                        label={tab?.title}
                        {...tabProps(index)}
                      />
                    ))}
                  </Tabs>
                </TabberBox>

                {baselineTabs?.map((tab, index) => (
                  <TabPanel
                    key={tab.title}
                    value={currentTab}
                    index={index}
                    extraClassName="styled-scrollbar participant-flow">
                    <TabComponentWrapper>{tab.component}</TabComponentWrapper>
                    <ParticipantFlowFooterWrapper
                      style={{
                        ...footerStyle,
                      }}>
                      <Footer
                        isBaselineFlow={true}
                        isParticipantMenu={
                          location.pathname === pathName.participant.menuPage
                        }
                        isInActivityPage={
                          location.pathname ===
                            pathName.participant.participantFieldActivity ||
                          location.pathname ===
                            pathName.participant.participantDataCollection ||
                          location.pathname ===
                            pathName.participant.participantFieldBaseline
                        }
                      />
                    </ParticipantFlowFooterWrapper>
                  </TabPanel>
                ))}
              </Box>
              <EnrollmentCompleteModal
                modalData={modalData}
                modalCloseHandler={() => {
                  setModalData({ flag: false, id: 0 });
                }}
                title={PAGE_CONTENT.submit_complete_modal_title}
                contentText={PAGE_CONTENT.submit_complete_modal_content}
                firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
                secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
                cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
                firstSubmitBtnHandler={() => {
                  setModalData({ flag: false, id: 0 });
                }}
                secondSubmitBtnHandler={navigateHandler}
                cancelHandler={() => {
                  setModalData({ flag: false, id: 0 });
                }}
              />
            </MainContainer>
          </ImplementedActivitiesWithOpsDataProvider>
        </div>
      </ParentContainer>
      <UnsavedChangesModal
        open={unsavedChangesModalOpenProps?.isOpen}
        handleClose={closeUnsavedChangesModal}
        handleLeaveWithoutSave={handleLeaveWithoutSave}
        handleSaveAndLeave={handleSaveAndLeave}
      />
    </MainWrapper>
  );
};

export default BaseLineReportingMainPage;
