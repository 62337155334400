import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import AddContractModal from 'pages/Origination/Org_Project/Participants/AddContractModal/AddContractModal.react';
import {
  FETCH_ACTIVITY_TAB_DATA,
  FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST,
  FETCH_PIPELINE_ACREAGE,
} from 'urls';
import { useActivityContext } from 'containers/ActivityListContainer';
import { ParticipantActivityContext } from 'contextAPI/participantActivityContext';
import { getActivityPracticeMapping } from './ActivityOptions.constants';
import { Backdrop, CircularProgress } from '@mui/material';
import { CircularProgressSx } from 'pages/Origination/Org_Project/Participants/AddContractModal/AddContractModal.style';
export const ContractActivityAction = () => {
  const { addContractOpen, setAddContractOpen, projectCycleId } =
    useActivityContext();
  const { participantId, projectId } = useContext(ParticipantActivityContext);
  const [activityPracticeMapping, setActivityPracticeMapping] = useState({});
  const [contractId, setContractId] = useState(null);
  const getContractId = (participantId, projectId) => {
    axios
      .get(FETCH_ACTIVITY_TAB_DATA, {
        params: { internalProjectId: projectId, participantId: participantId },
      })
      .then((response) => {
        const id =
          response?.data?.activityTabContractLevelList?.[0]?.contractId ?? null;
        /* istanbul ignore else */
        if (id !== null) {
          setContractId(id);
        }
      });
  };
  const fetchIncentiveListForParticipant = (participantId, projectId) => {
    Promise.all([
      axios.get(
        `${FETCH_PROJECT_ACTIVITY_INCENTIVE_LIST}?participantId=${participantId}&projectId=${projectId}`,
      ),
      axios.get(
        `${FETCH_PIPELINE_ACREAGE}?participantId=${participantId}&projectId=${projectId}&projectCycleId=${projectCycleId}`,
      ),
    ]).then(([incentiveResponse, pipelineResponse]) => {
      let activityPracticeMap = getActivityPracticeMapping(
        incentiveResponse.data,
      );
      const { pipelineAcreList } = pipelineResponse.data;
      pipelineAcreList.forEach((acre) => {
        const activity = Object.values(activityPracticeMap)
          .flat()
          .find(
            (activity) => activity.projectActivityId === acre.projectActivityId,
          );
        /* istanbul ignore else */
        if (activity) {
          activity.contractedUnits = acre.pipelinePlannedAcres;
        }
      });
      setActivityPracticeMapping((prevData) => {
        return {
          ...activityPracticeMap,
          totalAcres: pipelineResponse?.data?.totalAcres ?? '',
        };
      });
    });
  };

  useEffect(() => {
    if (addContractOpen.isOpen) {
      fetchIncentiveListForParticipant(participantId, projectId);
      getContractId(participantId, projectId);
    }
  }, [addContractOpen]);

  if (!addContractOpen.isOpen) {
    return null;
  }
  if (
    activityPracticeMapping.totalAcres != undefined ||
    activityPracticeMapping.totalAcres != null
  ) {
    return (
      <AddContractModal
        data-testid="add-contract-modal"
        addContractOpen={addContractOpen}
        setAddContractOpen={setAddContractOpen}
        activityPracticeMapping={activityPracticeMapping}
        projectId={projectId}
        participantId={participantId}
        contractId={contractId}
        refreshHandler={() => {
          setAddContractOpen({ isOpen: false });
        }}
        interestedCycleId={projectCycleId}
      />
    );
  } else {
    return (
      <Backdrop
        open={!activityPracticeMapping.totalAcres}
        sx={CircularProgressSx}>
        <CircularProgress color="success" />
      </Backdrop>
    );
  }
};
