import styled from 'styled-components';
import {
  DARK_CHARCOAL,
  COOL_GRAY,
  DOWNLOAD_LINK,
  NEW_DARK_GREEN,
  NEW_HOVER_GREEN_BACKGROUND,
  NEW_FOCUS_DARK_GREEN,
  BLACK,
  QUILL_GRAY,
  NEW_HOVER_DARK_GREEN,
  WHITE,
} from 'theme/GlobalColors';

export const HeadingStyle = styled.header`
  display: flex;
  gap: 0.5rem;
  font-size: 1.25rem;
`;

export const SubHeadingStyle = styled.header`
  display: flex;
  gap: 1rem;
  margin-top: 0.25rem;
`;

export const BackButtonStyle = styled.div`
  color: ${DOWNLOAD_LINK};
  display: flex;
  align-items: center;
  gap: 0.375rem;
  cursor: pointer;
`;

export const TitleStyle = styled.span`
  color: ${COOL_GRAY};
  font-size: 0.6875rem;
`;

export const ResponseStyle = styled.span`
  color: ${DARK_CHARCOAL};
  font-size: 0.875rem;
`;
export const TabberWrapperStyle = { marginBottom: '1rem', marginTop: '1rem' };

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  height: 1.75rem;
`;

export const primaryButtonStyle = () => {
  return {
    fontWeight: '400',
    textTransform: 'none',
    backgroundColor: NEW_DARK_GREEN,
    color: WHITE,
    '&:hover': {
      backgroundColor: NEW_HOVER_DARK_GREEN,
      color: WHITE,
    },
    '&.Mui-disabled': {
      color: COOL_GRAY,
      backgroundColor: QUILL_GRAY,
      border: 'none',
    },
  };
};
export const secondaryButtonStyle = {
  fontWeight: '400',
  textTransform: 'none',
  color: NEW_DARK_GREEN,
  border: `1px solid ${NEW_DARK_GREEN}`,
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: NEW_FOCUS_DARK_GREEN,
  },
  '&.Mui-disabled': {
    color: BLACK,
    backgroundColor: QUILL_GRAY,
    border: 'none',
  },
};
