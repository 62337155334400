import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  COOL_GRAY,
  DARK_CHARCOAL,
  NATURAL_GRAY,
  NEW_DARK_GREEN,
  WHITE,
  EMPTY_STATE_COLOR,
  QUILL_GRAY,
  CURIOUS_BLUE,
  DARK_MODERATE_BLUE,
  FADED_BLUISH_CYAN,
  EMPTY_STATE_TEXT_COLOR,
  BLACK,
  BLUISH_CYAN,
} from 'theme/GlobalColors';
import {
  Container,
  DetailsContainer,
  Field,
  FieldTitle,
  FieldValue,
  TitleWrapper,
  FieldsContainer,
  FieldUl,
  mapHeaderStyle,
  mapStyle,
  BarStyle,
  BarSection,
  TextWrapper,
  labelStyle,
  dropDownStyle,
  RecruitmentContainer,
  HeaderWrapper,
  RecruitmentChartWrapper,
  BarChartWrapper,
  GoalBarWrapper,
  GoalWrapper,
  HorizontalLine,
  TextWrapperParentForReporting,
  TextWrapperForReportingParticipants,
  TextWrapperForNoDataLabel,
  NoDataLabelAndChart,
  FieldsRow,
  DottedLineDiv,
} from './OriginationSummaryTab.style';
import { dateFormatter } from 'utils/dateFormatter';
import AddParticipants from '../Org_Project/Participants/AddParticipants/AddParticipants.react';
import {
  calculateBarPercentage,
  checkIfValuesAreNull,
  FIELDS,
  findAcreageItem,
  ORIGINATION_SUMMARY_TAB_CONTENT,
  projectSummaryPageMapControlOptions,
  setValueWhenNull,
} from './OriginationSummaryTab.content';
import {
  calculatePercentage,
  checkAllValuesAreZeroTrue,
  checkTernaryCondition,
  getBooleanValueForNullOrUndefined,
  isEmpty,
} from 'utils/helper';
import { ReactComponent as VerraIcon } from 'assets/icons/Verra.svg';
import MapSearchContainer from 'components/MapComponent/MapSearchContainer.react';
import {
  FETCH_PROJECT_GHG_INSIGHTS,
  FETCH_PROJECT_PARTICIPANT_INCENTIVES,
  FETCH_PROJECT_SUMMARY_PROGRESS_BAR_DATA,
  FETCH_PROJECT_SUMMARY_REPORTING,
  FETCH_RECRUITMENT_GOALS,
  GET_PROJECT_LIFE_CYCLE_LIST,
} from 'urls';
import axios from 'axios';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { ReactComponent as BlueCyanIcon } from 'assets/icons/BlueCyan.svg';
import { ReactComponent as InterestedIcon } from 'assets/icons/InterestedIcon.svg';
import { ReactComponent as CompletedIcon } from 'assets/icons/CompletedIconPatch.svg';
import { ReactComponent as NotStartedIcon } from 'assets/icons/NotStarted.svg';
import { ReactComponent as emptyStateIcon } from 'assets/icons/emptyStateIcon.svg';
import geojson from 'assets/regions_us.json';
import { CardHeaderStyle, backdropSx } from 'theme/GlobalStyles';
import CustomLineChart from 'components/Charts/CustomLineChart/CustomLineChart.react';
import { rangeNumArray } from 'pages/PortfolioOriginationTab/PortfolioOriginationTab.content';
import { Backdrop, CircularProgress } from '@mui/material';
import ParticipantIncentivesCard from '../ParticipantIncentivesCard/ParticipantIncentivesCard.react';
import { ReactComponent as ArrowDropDownIcon } from 'assets/icons/ArrowDropDownIcon.svg';
import moment from 'moment';
import CustomBarChart from 'components/Charts/CustomBarChart/CustomBarChart.react';

const OriginationSummaryTab = ({
  originationProjectDetails,
  refreshHandler,
  projectId,
}) => {
  const [participantDialogOpen, setParticipantDialogOpen] = useState({
    flag: false,
    id: 0,
  });
  const [fieldTitleArray, setFieldTitleArray] = useState([]);
  const [regionPolygons, setRegionPolygons] = useState([]);
  const [mapBounds, setMapBounds] = useState(null);
  const [ghgEmissions, setGhgEmissions] = useState(null);
  const [progressTrackerData, setProgressTrackerData] = useState(null);
  const [participantTrackerData, setParticipantTrackerData] = useState(null);
  const [fetchedProjectLifeCycleList, setFetchedProjectLifeCycleList] =
    useState([]);
  const [projectCycleId, setProjectCycleId] = useState('');
  const [containerWidth, setContainerWidth] = useState(0);
  const [isLoading, setIsLoading] = useState({
    GHGLoading: false,
    recruitmentLoading: false,
  });
  const [incentivesData, setIncentivesData] = useState({});
  const [projectParticipantsSummary, setProjectParticipantsSummary] = useState(
    {},
  );
  const [projectParticipantMaxValue, setProjectParticipantMaxValue] =
    useState(null);
  const [isTotalParticipantsMore, setIsTotalParticipantsMore] = useState(false);

  useEffect(() => {
    if (projectCycleId !== '') {
      axios
        .get(
          `${FETCH_PROJECT_PARTICIPANT_INCENTIVES}/${projectId}/project-incentives?projectCycleId=${projectCycleId}`,
        )
        .then((res) => {
          setIncentivesData(res.data);
        })
        .catch(() => {
          setIncentivesData({
            internalProjectId: 0.0,
            averageProjectedIncentive: 0.0,
            averageActualIncentive: 0.0,
            totalProjectedIncentive: 0.0,
            totalActualIncentive: 0.0,
          });
        });
    }
  }, [projectCycleId]);
  useEffect(() => {
    fetchSummaryDetails();
  }, [originationProjectDetails]);

  const fetchGHGData = () => {
    setIsLoading((prev) => ({ ...prev, GHGLoading: true }));
    axios
      .get(`${FETCH_PROJECT_GHG_INSIGHTS}/${projectId}`)
      .then((res) => {
        setGhgEmissions(res.data);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, GHGLoading: false }));
      });
  };
  const fetchProgressTrackerData = () => {
    axios
      .get(`${FETCH_PROJECT_SUMMARY_PROGRESS_BAR_DATA}${projectId}`)
      .then((res) => {
        const { participantRecruitment, summaryGraphs } = res.data;
        setProgressTrackerData({
          recruitmentInterested: +setValueWhenNull(
            participantRecruitment.interested,
          ),
          recruitmentContracted: +setValueWhenNull(
            participantRecruitment.contracted,
          ),
          recruitmentTotal:
            +setValueWhenNull(participantRecruitment.interested) +
            +setValueWhenNull(participantRecruitment.contracted),
          enrolmentTotal:
            +setValueWhenNull(
              findAcreageItem(
                summaryGraphs,
                ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
                ORIGINATION_SUMMARY_TAB_CONTENT.complete,
              )?.area,
            ) +
            +setValueWhenNull(
              findAcreageItem(
                summaryGraphs,
                ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
                ORIGINATION_SUMMARY_TAB_CONTENT.inProgress,
              )?.area,
            ),
          enrolmentInProgress: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
              ORIGINATION_SUMMARY_TAB_CONTENT.inProgress,
            )?.area,
          ),
          enrolmentComplete: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
              ORIGINATION_SUMMARY_TAB_CONTENT.complete,
            )?.area,
          ),
          baselineTotal: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
              ORIGINATION_SUMMARY_TAB_CONTENT.complete,
            )?.area,
          ),
          baselineInProgress: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.baselineReporting,
              ORIGINATION_SUMMARY_TAB_CONTENT.inProgress,
            )?.area,
          ),
          baselineComplete: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.baselineReporting,
              ORIGINATION_SUMMARY_TAB_CONTENT.complete,
            )?.area,
          ),
          baselineNotStarted:
            +setValueWhenNull(
              findAcreageItem(
                summaryGraphs,
                ORIGINATION_SUMMARY_TAB_CONTENT.enrollment,
                ORIGINATION_SUMMARY_TAB_CONTENT.complete,
              )?.area,
            ) -
            +setValueWhenNull(
              findAcreageItem(
                summaryGraphs,
                ORIGINATION_SUMMARY_TAB_CONTENT.baselineReporting,
                ORIGINATION_SUMMARY_TAB_CONTENT.inProgress,
              )?.area,
            ) -
            +setValueWhenNull(
              findAcreageItem(
                summaryGraphs,
                ORIGINATION_SUMMARY_TAB_CONTENT.baselineReporting,
                ORIGINATION_SUMMARY_TAB_CONTENT.complete,
              )?.area,
            ),
          activityNotStarted: +setValueWhenNull(
            findAcreageItem(
              summaryGraphs,
              ORIGINATION_SUMMARY_TAB_CONTENT.baselineReporting,
              ORIGINATION_SUMMARY_TAB_CONTENT.complete,
            )?.area,
          ),
        });
      });
  };
  const fetchProjectCycleList = () => {
    axios
      .get(`${GET_PROJECT_LIFE_CYCLE_LIST}/${projectId}`)
      .then((response) => {
        setFetchedProjectLifeCycleList(
          response.data.map((item) => ({
            label: `${moment(item.cycleEndDate).format('YYYY')} (${moment(
              item.cycleStartDate,
            ).format('MM/DD/YYYY')} - ${moment(item.cycleEndDate).format(
              'MM/DD/YYYY',
            )})`,
            value: item.projectCycleId,
          })),
        );
      });
  };

  useEffect(() => {
    if (fetchedProjectLifeCycleList.length > 0) {
      setProjectCycleId(
        checkTernaryCondition(
          projectCycleId === '',
          fetchedProjectLifeCycleList[0]?.value,
          projectCycleId,
        ),
      );
    }
  }, [fetchedProjectLifeCycleList]);

  const fetchRecruitmenGoals = () => {
    if (projectCycleId !== '') {
      setIsLoading((prev) => ({ ...prev, recruitmentLoading: true }));
      axios
        .get(
          `${FETCH_RECRUITMENT_GOALS}?internalProjectId=${projectId}&projectCycleId=${projectCycleId}`,
        )
        .then((res) => {
          if (!isEmpty(res.data)) {
            setParticipantTrackerData(res.data);
          } else setParticipantTrackerData(null);
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading((prev) => ({ ...prev, recruitmentLoading: false }));
        });
    }
  };

  const drawProjectRegionsOnMap = (originationProjectDetails) => {
    /* istanbul ignore else */
    if (originationProjectDetails) {
      const projectRegions = originationProjectDetails?.projectRegion
        .slice(1, -1)
        .split(',')
        .map((item) => item.trim().toLowerCase());

      const polygons = [];
      geojson.features.forEach((feature) => {
        const regionName = feature.properties.NAME;
        /* istanbul ignore else */
        if (projectRegions.includes(regionName.toLowerCase())) {
          feature.geometry.coordinates.forEach((coordinate) => {
            coordinate.forEach((polygon) => {
              const polygonCoordinates = polygon.map((points) => {
                return { lat: points[1], lng: points[0] };
              });
              polygons.push({
                fieldName: regionName,
                fieldBoundaries: polygonCoordinates,
                hideAcres: true,
              });
            });
          });
        }
      });

      setRegionPolygons(polygons);
      /* istanbul ignore else */
      if (polygons.length > 0) {
        const updatedPolygonData = polygons.map((polygon) => {
          if (polygon.fieldName.toLowerCase() === 'west') {
            // Note: restricting west region bounds to eastern part
            return [
              { lat: 49.04119397248323, lng: -127.31975544509145 },
              { lat: 31.613868395125337, lng: -100.28671928263208 },
            ];
          }

          return polygon.fieldBoundaries;
        });
        const bounds = updatedPolygonData.reduce(
          (accumulator, current) => [...accumulator, ...current],
          [],
        );
        setMapBounds(bounds);
      }
    }
  };

  const fetchSummaryDetails = () => {
    let tempQuantApproach = [];
    let tempActivities = [];
    let tempFieldTitleArray = [];

    drawProjectRegionsOnMap(originationProjectDetails);

    const commercializationApproach =
      originationProjectDetails?.commercializationApproach
        ?.commercializationApproach ?? '';
    const originationType =
      originationProjectDetails?.projectSector?.originationProjectType;

    originationProjectDetails?.projectBlueprintList.map((data) => {
      tempActivities.push(data?.projectActivity);
    });
    tempQuantApproach.push(
      ORIGINATION_SUMMARY_TAB_CONTENT.quantification_approach.slice(-7, -1),
    );
    tempFieldTitleArray = [
      {
        key: 1,
        title: FIELDS.type,
        value: checkTernaryCondition(
          originationType?.indexOf('(') !== -1,
          originationType?.split('(')[1]?.replace(')', ''),
          originationType,
        ),
        hasMultipleValue: false,
        minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
      },
      {
        key: 2,
        title: FIELDS.commercializationApproach,
        value: commercializationApproach,
        hasMultipleValue: false,
        minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
      },
      {
        key: 3,
        title: FIELDS.quantificationApproach,
        value: tempQuantApproach,
        hasMultipleValue: true,
        minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
      },
    ];

    if (
      originationProjectDetails?.projectBlueprintList[0]
        ?.commercializationApproach === 'Offsetting'
    ) {
      tempFieldTitleArray = [
        ...tempFieldTitleArray,
        {
          key: 4,
          title: FIELDS.registry,
          value: <VerraIcon />,
          hasMultipleValue: false,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
        },
        {
          key: 5,
          title: FIELDS.validationAndVerificationBody,
          value: originationProjectDetails?.vvAuditor,
          hasMultipleValue: false,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
        },
        {
          key: 6,
          title: '',
          value: '',
          hasMultipleValue: false,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
        },
        {
          key: 7,
          title: FIELDS.projectActivities,
          value: tempActivities,
          hasMultipleValue: true,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twentyFivePointTwentyFive,
        },
        {
          key: 8,
          title: FIELDS.createdon,
          value: checkTernaryCondition(
            !isEmpty(originationProjectDetails?.createdDate),
            dateFormatter(originationProjectDetails?.createdDate),
            '--',
          ),
          hasMultipleValue: false,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
        },
      ];
    } else {
      tempFieldTitleArray = [
        ...tempFieldTitleArray,
        {
          key: 4,
          title: FIELDS.projectActivities,
          value: tempActivities,
          hasMultipleValue: true,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twentyFivePointTwentyFive,
        },
        {
          key: 5,
          title: FIELDS.createdon,
          value: checkTernaryCondition(
            !isEmpty(originationProjectDetails?.createdDate),
            dateFormatter(originationProjectDetails?.createdDate),
            '--',
          ),
          hasMultipleValue: false,
          minWidth: ORIGINATION_SUMMARY_TAB_CONTENT.twelvePointOneTwoFive,
        },
      ];
    }
    setFieldTitleArray(tempFieldTitleArray);
  };

  // Function to calculate display goals
  const getDisplayGoals = (remainingGoals) => {
    return Math.floor(
      checkTernaryCondition(
        remainingGoals > 100,
        remainingGoals - 100,
        remainingGoals,
      ),
    );
  };

  // Function to determine the suffix
  const getGoalSuffix = (remainingGoals) => {
    return checkTernaryCondition(
      remainingGoals > 100,
      ORIGINATION_SUMMARY_TAB_CONTENT.percentOverGoal,
      ORIGINATION_SUMMARY_TAB_CONTENT.percent_to_goal,
    );
  };

  useEffect(() => {
    const calculateContainerWidth = () => {
      const container = document.getElementById('ghg-container');
      /* istanbul ignore else */
      if (container) {
        const width = container.offsetWidth - 32;
        setContainerWidth(width);
      }
    };
    calculateContainerWidth();
    window.addEventListener('resize', calculateContainerWidth);
    return () => {
      window.removeEventListener('resize', calculateContainerWidth);
    };
  }, []);

  useEffect(() => {
    fetchGHGData();
    fetchProgressTrackerData();
    fetchProjectCycleList();
  }, [projectId]);

  useEffect(() => {
    fetchRecruitmenGoals();
  }, [projectId, projectCycleId]);

  const enrolmentReportingValList = [
    {
      name: 'Enrollment',
      total: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.enrolmentTotal),
      ),
      notStarted: 0,
      inProgress: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.enrolmentInProgress),
      ),
      complete: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.enrolmentComplete),
      ),
    },
    {
      name: 'Baseline Reporting',
      total: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.baselineTotal),
      ),
      notStarted: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.baselineNotStarted),
      ),
      inProgress: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.baselineInProgress),
      ),
      complete: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.baselineComplete),
      ),
    },
    {
      name: 'Activity Reporting',
      total: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.activityNotStarted),
      ),
      notStarted: checkIfValuesAreNull(
        Math.ceil(progressTrackerData?.activityNotStarted),
      ),
      inProgress: 0,
      complete: 0,
    },
  ];

  const LabelIconList = [
    {
      icon: BlueCyanIcon,
      label: (
        <>
          <TextWrapper color={DARK_CHARCOAL} fontSize="12px" fontWeight={600}>
            {participantTrackerData?.contractedParticipants}
          </TextWrapper>
          &nbsp;
          {`Contracted (${getDisplayGoals(
            participantTrackerData?.remainingContractedGoals,
          )}${getGoalSuffix(
            participantTrackerData?.remainingContractedGoals,
          )})`}
        </>
      ),
    },
    {
      icon: InterestedIcon,
      label: (
        <>
          <TextWrapper color={DARK_CHARCOAL} fontSize="12px" fontWeight={600}>
            {participantTrackerData?.interestedParticipants}
          </TextWrapper>
          &nbsp;
          {'Interested'}
        </>
      ),
    },
    { icon: emptyStateIcon, label: 'Contracted' },
    { icon: emptyStateIcon, label: 'Interested' },
    {
      icon: checkTernaryCondition(
        checkAllValuesAreZeroTrue(
          enrolmentReportingValList[0].total,
          enrolmentReportingValList[1].total,
          enrolmentReportingValList[2].total,
        ),
        emptyStateIcon,
        NotStartedIcon,
      ),
      label: 'Not started',
    },
    {
      icon: checkTernaryCondition(
        checkAllValuesAreZeroTrue(
          enrolmentReportingValList[0].total,
          enrolmentReportingValList[1].total,
          enrolmentReportingValList[2].total,
        ),
        emptyStateIcon,
        CompletedIcon,
      ),
      label: 'In progress',
    },
    {
      icon: checkTernaryCondition(
        checkAllValuesAreZeroTrue(
          enrolmentReportingValList[0].total,
          enrolmentReportingValList[1].total,
          enrolmentReportingValList[2].total,
        ),
        emptyStateIcon,
        BlueCyanIcon,
      ),
      label: 'Complete',
    },
  ];

  const yearsRange = rangeNumArray(20, 30);
  const mapLabels = ghgEmissions?.ghgInsights?.map((insight) => {
    const year = new Date(insight.abatementDate)
      .getFullYear()
      .toString()
      .slice(-2);
    return parseInt(year, 10);
  });
  const projectedData = [];
  const actualData = [];

  yearsRange.forEach((year) => {
    if (mapLabels?.length > 0) {
      const index = mapLabels?.indexOf(year);
      if (index !== -1) {
        actualData.push(ghgEmissions?.ghgInsights[index].actualAbatement);
        projectedData.push(ghgEmissions?.ghgInsights[index].projectedAbatement);
      } else {
        actualData.push(null);
        projectedData.push(null);
      }
    }
  });

  const isDataSetEmpty = isEmpty(projectedData) && isEmpty(actualData);
  const isGhgEmissionsEmpty =
    isEmpty(ghgEmissions?.ghgInsights) || isLoading.GHGLoading;
  const projectedColour = checkTernaryCondition(
    isGhgEmissionsEmpty,
    EMPTY_STATE_COLOR,
    COOL_GRAY,
  );
  const actualColour = checkTernaryCondition(
    isGhgEmissionsEmpty,
    EMPTY_STATE_COLOR,
    NEW_DARK_GREEN,
  );
  const textColor = checkTernaryCondition(
    isGhgEmissionsEmpty,
    EMPTY_STATE_TEXT_COLOR,
    BLACK,
  );
  const chartData = {
    labels: isDataSetEmpty ? [] : yearsRange,
    datasets: [
      {
        label: ORIGINATION_SUMMARY_TAB_CONTENT.projected,
        data: projectedData,
        pointRadius: 1,
        borderColor: projectedColour,
        backgroundColor: projectedColour,
        borderDash: [10, 10],
        textColor: textColor,
      },
      {
        label: ORIGINATION_SUMMARY_TAB_CONTENT.actual,
        data: actualData,
        pointRadius: 1,
        borderColor: actualColour,
        backgroundColor: actualColour,
        textColor: textColor,
      },
    ],
  };

  const options = {
    responsive: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            // Format the label to ensure it's in English
            return `${tooltipItem.dataset.label}: ${Intl.NumberFormat(
              'en-US',
            ).format(tooltipItem.raw)}`;
          },
        },
      },
    },
    scales: {
      x: {
        border: {
          display: isDataSetEmpty,
        },
        max: checkTernaryCondition(!isDataSetEmpty, 30, null),
        min: checkTernaryCondition(!isDataSetEmpty, 20, null),
        ticks: {
          stepSize: 1,
          padding: 5,
          color: DARK_CHARCOAL,
          callback: function (value) {
            return "'" + (value + 20);
          },
          locale: 'en-US',
        },
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        border: {
          display: false,
        },
        max: 600,
        min: 0,
        ticks: {
          stepSize: 150,
          color: DARK_CHARCOAL,
          callback: function (value) {
            return value.toString();
          },
          locale: 'en-US',
        },
        title: {
          display: true,
          color: COOL_GRAY,
          text: ORIGINATION_SUMMARY_TAB_CONTENT.CO2e,
        },
        grid: {
          display: !isDataSetEmpty,
        },
      },
    },
  };
  const fetchProjectParticipantsSummary = async () => {
    const url = FETCH_PROJECT_SUMMARY_REPORTING(
      projectId,
      projectCycleId || -1,
    );

    return axios
      .get(url)
      .then((response) => setProjectParticipantsSummary(response?.data))
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    /* istanbul ignore else */
    if (projectId || projectCycleId) {
      fetchProjectParticipantsSummary();
    }
  }, [projectId, projectCycleId]);

  const projectParticipantsEmpty =
    projectParticipantsSummary?.totalNotStarted === 0 &&
    projectParticipantsSummary?.totalInProgress === 0 &&
    projectParticipantsSummary?.totalCompleted === 0;

  const projectParticipantsTotal =
    projectParticipantsSummary?.totalNotStarted +
    projectParticipantsSummary?.totalInProgress +
    projectParticipantsSummary?.totalCompleted;

  useEffect(() => {
    /* istanbul ignore else */
    if (projectParticipantsSummary) {
      const participantMaxValue = Math.max(
        projectParticipantsSummary?.totalNotStarted,
        projectParticipantsSummary?.totalInProgress,
        projectParticipantsSummary?.totalCompleted,
      );
      setProjectParticipantMaxValue(participantMaxValue);
    }
  }, [projectParticipantsSummary]);

  useEffect(() => {
    setIsTotalParticipantsMore(
      participantTrackerData?.totalParticipants >
        participantTrackerData?.totalGoal,
    );
  }, [participantTrackerData]);

  const data = {
    labels: [
      ORIGINATION_SUMMARY_TAB_CONTENT.not_started,
      ORIGINATION_SUMMARY_TAB_CONTENT.inProgress,
      ORIGINATION_SUMMARY_TAB_CONTENT.complete,
    ],
    datasets: [
      {
        label: '',
        data: checkTernaryCondition(
          projectParticipantsEmpty || isNaN(projectParticipantsTotal),
          [0.01, 0.01, 0.01],
          [
            projectParticipantsSummary?.totalNotStarted,
            projectParticipantsSummary?.totalInProgress,
            projectParticipantsSummary?.totalCompleted,
          ],
        ),
        backgroundColor: checkTernaryCondition(
          projectParticipantsEmpty || isNaN(projectParticipantsTotal),
          [EMPTY_STATE_COLOR, EMPTY_STATE_COLOR, EMPTY_STATE_COLOR],
          [CURIOUS_BLUE, DARK_MODERATE_BLUE, FADED_BLUISH_CYAN],
        ),
        barThickness: 40,
        borderwidth: 0,
      },
    ],
  };

  const reportingOptions = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: Math.max(...data.datasets[0].data) + 0.1,
        grid: {
          display: false,
        },
        display: false,
        ticks: {
          stepSize: checkTernaryCondition(
            projectParticipantMaxValue !== 0,
            projectParticipantMaxValue / 2,
            null,
          ),
          max: projectParticipantMaxValue,
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          title: () => '',
          label: (context) => {
            let label =
              data.labels[context.dataIndex] ===
              ORIGINATION_SUMMARY_TAB_CONTENT.complete
                ? `${projectParticipantsSummary?.totalCompleted}  ${ORIGINATION_SUMMARY_TAB_CONTENT.complete}`
                : [
                    `${
                      data.labels[context.dataIndex] ===
                      ORIGINATION_SUMMARY_TAB_CONTENT.inProgress
                        ? projectParticipantsSummary?.inProgressEnrollment
                        : projectParticipantsSummary?.notStartedEnrollment
                    }  ${ORIGINATION_SUMMARY_TAB_CONTENT.enrollment}`,
                    `${
                      data.labels[context.dataIndex] ===
                      ORIGINATION_SUMMARY_TAB_CONTENT.inProgress
                        ? projectParticipantsSummary?.inProgressBaseline
                        : projectParticipantsSummary?.notStartedBaseline
                    }  ${ORIGINATION_SUMMARY_TAB_CONTENT.baseline}`,
                    `${
                      data.labels[context.dataIndex] ===
                      ORIGINATION_SUMMARY_TAB_CONTENT.inProgress
                        ? projectParticipantsSummary?.inProgressActivityReporting
                        : projectParticipantsSummary?.notStartedActivityReporting
                    }  ${ORIGINATION_SUMMARY_TAB_CONTENT.activity}`,
                  ];
            return label;
          },
        },
        backgroundColor: 'black',
      },
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: 'black',
        formatter: (value) => {
          return checkTernaryCondition(
            projectParticipantsEmpty,
            ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous,
            value,
          );
        },
        align: 'end',
        anchor: 'end',
        clamp: true,
        font: {
          weight: 'bold',
          size: 10,
        },
        padding: { top: 0, bottom: 0 },
      },
    },
    datasets: {},
  };

  const getRecruitmentChart = () => {
    /* istanbul ignore else */
    if (isEmpty(participantTrackerData)) {
      return (
        <Container
          backgroundColor="white"
          gap="0.5rem"
          direction={ORIGINATION_SUMMARY_TAB_CONTENT.column}>
          <TextWrapper color={DARK_CHARCOAL} fontSize="1rem" fontWeight={600}>
            {ORIGINATION_SUMMARY_TAB_CONTENT.progress_tracker}
          </TextWrapper>
          <RecruitmentContainer>
            <HeaderWrapper>
              <TextWrapper
                color={DARK_CHARCOAL}
                fontSize="0.875rem"
                fontWeight={600}>
                {ORIGINATION_SUMMARY_TAB_CONTENT.recruitment}
              </TextWrapper>
              <TextWrapper
                color={COOL_GRAY}
                fontSize="0.75rem"
                fontWeight={400}>
                <TextWrapper
                  color={COOL_GRAY}
                  fontSize="0.75rem"
                  fontWeight={600}>
                  {ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous}
                </TextWrapper>
                &nbsp;
                {`${ORIGINATION_SUMMARY_TAB_CONTENT.participant_tracker} (${ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous}${ORIGINATION_SUMMARY_TAB_CONTENT.percent_to_goal})`}
              </TextWrapper>
            </HeaderWrapper>
            <RecruitmentChartWrapper>
              <GoalBarWrapper width={'100%'}>
                <GoalWrapper marginRight={'0rem'} alignSelf="center">
                  <TextWrapper
                    fontWeight={400}
                    color={COOL_GRAY}
                    fontSize="0.875rem">
                    {ORIGINATION_SUMMARY_TAB_CONTENT.noDataLabel}
                  </TextWrapper>
                </GoalWrapper>
                <BarChartWrapper>
                  <BarStyle
                    bgColor={QUILL_GRAY}
                    width={'2px'}
                    display="flex"
                    padding="0px"
                  />
                  <BarStyle
                    bgColor={QUILL_GRAY}
                    color={WHITE}
                    borderRadius={'2px 0px 0px 2px'}
                    width={'100%'}
                    padding="0px"
                    height="0.125rem"
                    alignSelf="center"
                    display="flex"
                    justifyContent="flex-end"
                  />
                  <BarStyle
                    bgColor={QUILL_GRAY}
                    width={'2px'}
                    display="flex"
                    padding="0px"
                  />
                </BarChartWrapper>
              </GoalBarWrapper>
            </RecruitmentChartWrapper>
            <BarSection height={'1rem'} direction="row" gap="1rem">
              {LabelIconList.slice(2, 4).map((item, index) => {
                const keyVal = index;
                return (
                  <BarSection
                    direction="row"
                    gap="4px"
                    alignItems="baseline"
                    key={keyVal}>
                    <item.icon />
                    <TextWrapper
                      color={DARK_CHARCOAL}
                      fontSize="12px"
                      fontWeight={400}>
                      {`${ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous} ${item.label}`}
                      {keyVal == 0 &&
                        ` (${ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous}${ORIGINATION_SUMMARY_TAB_CONTENT.percent_to_goal})`}
                    </TextWrapper>
                  </BarSection>
                );
              })}
            </BarSection>
          </RecruitmentContainer>
        </Container>
      );
    }
    return (
      <Container
        backgroundColor="white"
        gap="0.5rem"
        direction={ORIGINATION_SUMMARY_TAB_CONTENT.column}>
        <TextWrapper color={DARK_CHARCOAL} fontSize="1rem" fontWeight={600}>
          {ORIGINATION_SUMMARY_TAB_CONTENT.progress_tracker}
        </TextWrapper>
        <RecruitmentContainer>
          <HeaderWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.875rem"
              fontWeight={600}>
              {ORIGINATION_SUMMARY_TAB_CONTENT.recruitment}
            </TextWrapper>
            <TextWrapper
              color={DARK_CHARCOAL}
              fontSize="0.75rem"
              fontWeight={400}>
              <TextWrapper
                color={DARK_CHARCOAL}
                fontSize="0.75rem"
                fontWeight={600}>
                {participantTrackerData?.totalParticipants}
              </TextWrapper>
              &nbsp;
              {`${
                ORIGINATION_SUMMARY_TAB_CONTENT.participant_tracker
              } (${getDisplayGoals(
                Math.floor(
                  calculatePercentage(
                    participantTrackerData?.totalParticipants,
                    participantTrackerData?.totalGoal,
                  ),
                ),
              )}${getGoalSuffix(
                Math.floor(
                  calculatePercentage(
                    participantTrackerData?.totalParticipants,
                    participantTrackerData?.totalGoal,
                  ),
                ),
              )})`}
            </TextWrapper>
          </HeaderWrapper>
          <div>
            {isTotalParticipantsMore && (
              <>
                <GoalWrapper
                  alignSelf="flex-end"
                  width={calculateBarPercentage(
                    participantTrackerData?.totalGoal,
                    participantTrackerData?.totalParticipants,
                  )}
                  justifyContent={'end'}
                  marginLeft={'0.4rem'}
                  marginBottom={'0.25rem'}>
                  <TextWrapper
                    fontWeight={400}
                    color={DARK_CHARCOAL}
                    width={'max-content'}
                    fontSize="0.75rem">
                    {`${participantTrackerData?.totalGoal} ${ORIGINATION_SUMMARY_TAB_CONTENT.goal}`}
                  </TextWrapper>
                  <ArrowDropDownIcon />
                </GoalWrapper>
                <DottedLineDiv
                  marginLeft={calculateBarPercentage(
                    participantTrackerData?.totalGoal,
                    participantTrackerData?.totalParticipants,
                  )}
                />
              </>
            )}
            <RecruitmentChartWrapper>
              <GoalBarWrapper
                margin={checkTernaryCondition(
                  !isTotalParticipantsMore,
                  '1rem 0 0 0',
                  '0',
                )}
                width={calculateBarPercentage(
                  participantTrackerData?.contractedParticipants,
                  checkTernaryCondition(
                    isTotalParticipantsMore,
                    participantTrackerData?.totalParticipants,
                    participantTrackerData?.totalGoal,
                  ),
                )}>
                <BarChartWrapper>
                  <BarStyle
                    bgColor={BLUISH_CYAN}
                    color={WHITE}
                    borderRadius={'2px 0px 0px 2px'}
                    padding="0px"
                    width={'100%'}
                    display="flex"
                    justifyContent="flex-end"
                  />
                  {participantTrackerData?.contractedParticipants > 0 && (
                    <BarStyle
                      bgColor={QUILL_GRAY}
                      width={'2px'}
                      padding="0px"
                    />
                  )}
                </BarChartWrapper>
              </GoalBarWrapper>
              <GoalBarWrapper
                margin={checkTernaryCondition(
                  !isTotalParticipantsMore,
                  '1rem 0 0 0',
                  '0',
                )}
                width={calculateBarPercentage(
                  participantTrackerData?.interestedParticipants,
                  checkTernaryCondition(
                    isTotalParticipantsMore,
                    participantTrackerData?.totalParticipants,
                    participantTrackerData?.totalGoal,
                  ),
                )}>
                <BarChartWrapper>
                  <BarStyle
                    bgColor={NATURAL_GRAY}
                    color={WHITE}
                    width={'100%'}
                    padding="0px"
                    borderRadius={'2px 0px 0px 2px'}
                    display="flex"
                    justifyContent="flex-end"
                  />
                  {participantTrackerData?.interestedParticipants > 0 && (
                    <BarStyle
                      bgColor={QUILL_GRAY}
                      width={'2px'}
                      padding="0px"
                    />
                  )}
                </BarChartWrapper>
              </GoalBarWrapper>
              {!isTotalParticipantsMore && (
                <GoalBarWrapper
                  width={calculateBarPercentage(
                    participantTrackerData?.totalGoal -
                      (participantTrackerData?.contractedParticipants +
                        participantTrackerData?.interestedParticipants),
                    participantTrackerData?.totalGoal,
                  )}>
                  <GoalWrapper alignSelf="flex-end">
                    <TextWrapper
                      fontWeight={400}
                      color={DARK_CHARCOAL}
                      width={'max-content'}
                      fontSize="0.75rem">
                      {`${participantTrackerData?.totalGoal} ${ORIGINATION_SUMMARY_TAB_CONTENT.goal}`}
                    </TextWrapper>
                    <ArrowDropDownIcon />
                  </GoalWrapper>
                  <BarChartWrapper>
                    <BarStyle
                      bgColor={QUILL_GRAY}
                      width={'100%'}
                      padding="0px"
                      color={WHITE}
                      borderRadius={'2px 0px 0px 2px'}
                      height="0.125rem"
                      alignSelf="center"
                      display="flex"
                      justifyContent="flex-end"
                    />
                    <BarStyle
                      bgColor={QUILL_GRAY}
                      width={'2px'}
                      display="flex"
                      padding="0px"
                    />
                  </BarChartWrapper>
                </GoalBarWrapper>
              )}
            </RecruitmentChartWrapper>
          </div>
          <BarSection height={'1rem'} direction="row" gap="1rem">
            {LabelIconList.slice(0, 2).map((item, index) => {
              const keyVal = index;
              return (
                <BarSection
                  direction="row"
                  gap="4px"
                  alignItems="baseline"
                  key={keyVal}>
                  <item.icon />
                  <TextWrapper
                    color={DARK_CHARCOAL}
                    fontSize="12px"
                    fontWeight={400}>
                    {item.label}
                  </TextWrapper>
                </BarSection>
              );
            })}
          </BarSection>
        </RecruitmentContainer>
      </Container>
    );
  };
  //Splitting the content into two rows for the details section
  //First row will have 3 fields and second row will have the rest
  //This is done to maintain the UI consistency
  const detailsFirstRowFields = fieldTitleArray.slice(0, 3) ?? [];
  const detailsSecondRowFields = fieldTitleArray.slice(3) ?? [];

  //Creating a common component for the fields
  const FieldMapper = ({ fields }) => {
    return (
      <>
        {fields?.map((item) => (
          <Field key={item.key} minWidth={item.minWidth}>
            <FieldTitle>{item.title}</FieldTitle>
            {checkTernaryCondition(
              item.hasMultipleValue,
              <FieldUl>
                {Array.isArray(item.value) &&
                  item?.value.map((data) => <li key={data}>{data}</li>)}
              </FieldUl>,
              <FieldValue>{item.value ?? ''}</FieldValue>,
            )}
          </Field>
        ))}
      </>
    );
  };

  return (
    <>
      <Backdrop sx={backdropSx} open={Object.values(isLoading).includes(true)}>
        <CircularProgress color="success" />
      </Backdrop>
      <Container>
        <Container
          width="100%"
          direction={ORIGINATION_SUMMARY_TAB_CONTENT.column}
          gap="0.5rem">
          {/* Section1 is for widgets and charts */}
          <DropDown
            dropDownMinWidth={'13.75rem'}
            label={ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_label}
            ariaLabel={ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_label}
            labelStyle={labelStyle}
            dropDownStyle={dropDownStyle}
            value={checkTernaryCondition(
              getBooleanValueForNullOrUndefined(
                fetchedProjectLifeCycleList.length,
              ),
              ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_placeholder,
              projectCycleId,
            )}
            dropdownlist={fetchedProjectLifeCycleList}
            dropDownPlaceholder={
              ORIGINATION_SUMMARY_TAB_CONTENT.project_life_cycle_placeholder
            }
            setStateValue={() => {}}
            setDropDownValInParent={(newVal) => {
              setProjectCycleId(newVal);
            }}
            customSx={{ backgroundColor: WHITE }}
            setFormFieldValue={() => {}}
            height="1.75rem"
            display="flex"
            direction="row"
            gap="4px"
            alignItems="baseline"
            hasNoBottomMargin
            marginTop="-0.5rem"
            customPlaceholderColor={DARK_CHARCOAL}
            showLabelAsValue
          />
          <Container
            height="25.125rem"
            direction={ORIGINATION_SUMMARY_TAB_CONTENT.row}
            width="100%">
            <Container
              width="calc(50% - 1.5rem)"
              height="calc(100% - 2.6rem)"
              backgroundColor="white"
              gap="1rem"
              padding={'1rem'}
              className="curved-border"
              direction={ORIGINATION_SUMMARY_TAB_CONTENT.column}>
              {getRecruitmentChart()}
              <HorizontalLine />
              <TextWrapperParentForReporting
                alignItems="center"
                gap="0.5rem"
                alignContent="center">
                <TextWrapper
                  color={DARK_CHARCOAL}
                  fontSize="0.875rem"
                  fontWeight={600}>
                  {ORIGINATION_SUMMARY_TAB_CONTENT.summary_project_reporting}
                </TextWrapper>
                <TextWrapperForReportingParticipants gap="0.25rem">
                  <TextWrapper fontSize="0.75rem" fontWeight={600}>
                    {checkTernaryCondition(
                      projectParticipantsEmpty ||
                        isNaN(projectParticipantsTotal),
                      ORIGINATION_SUMMARY_TAB_CONTENT.noDataValueContinuous,
                      projectParticipantsTotal,
                    )}
                  </TextWrapper>
                  <TextWrapper fontSize="0.75rem" fontWeight={400}>
                    {ORIGINATION_SUMMARY_TAB_CONTENT.recruitment_participants}
                  </TextWrapper>
                </TextWrapperForReportingParticipants>
              </TextWrapperParentForReporting>
              <NoDataLabelAndChart>
                {projectParticipantsEmpty && (
                  <TextWrapperForNoDataLabel
                    fontWeight={400}
                    color={COOL_GRAY}
                    fontSize="0.75rem">
                    {ORIGINATION_SUMMARY_TAB_CONTENT.noDataLabel}
                  </TextWrapperForNoDataLabel>
                )}
                <CustomBarChart
                  barChartStyle={{
                    alignSelf: 'center',
                    justifyContent: 'center',
                  }}
                  data={data}
                  showLegends={false}
                  options={reportingOptions}
                />
              </NoDataLabelAndChart>
            </Container>

            {/*GHG Emissions */}
            <Container
              width="calc(50% - 1.5rem)"
              gap="1rem"
              className="curved-border"
              direction={ORIGINATION_SUMMARY_TAB_CONTENT.column}>
              <ParticipantIncentivesCard data={incentivesData} />
              <Container
                id="ghg-container"
                height="59.85%"
                backgroundColor="white">
                <CustomLineChart
                  options={options}
                  data={chartData}
                  title={ORIGINATION_SUMMARY_TAB_CONTENT.ghgReductionsRemovals}
                  height={'140px'}
                  width={containerWidth}
                  showLegends={false}
                  showLegendsAtBottom
                  currentValue={checkTernaryCondition(
                    ghgEmissions?.currentYearAbatement,
                    `${ghgEmissions?.currentYearAbatement}K`,
                    '- -',
                  )}
                  currentValueLabel={ORIGINATION_SUMMARY_TAB_CONTENT.actual}
                  currentValueColor={checkTernaryCondition(
                    isGhgEmissionsEmpty,
                    COOL_GRAY,
                    NEW_DARK_GREEN,
                  )}
                  isDataSetEmpty={isDataSetEmpty}
                  ToolTipIconSx={{ fontSize: '1.2rem' }}
                />
              </Container>
            </Container>
          </Container>

          {/* {GHG Emissions} */}
          <Container
            width="100%"
            gap="0rem"
            direction="row"
            minHeight="30rem" //This is to maintain the minimum height of the details section
            alignItems="flex-start"
            backgroundColor={WHITE}>
            <Container
              className="curved-border"
              direction="column"
              width={ORIGINATION_SUMMARY_TAB_CONTENT.thirtyPercent}
              backgroundColor={WHITE}
              padding={ORIGINATION_SUMMARY_TAB_CONTENT.oneRem}
              height={'100%'}
              boxSizing="border-box"
              gap={ORIGINATION_SUMMARY_TAB_CONTENT.pointFiveRem}
              hasBoxShadow={false}>
              <TitleWrapper>
                <h5 style={CardHeaderStyle}>
                  {ORIGINATION_SUMMARY_TAB_CONTENT.details}
                </h5>
              </TitleWrapper>
              <div style={mapHeaderStyle}>
                {ORIGINATION_SUMMARY_TAB_CONTENT.mapHeader}
              </div>
              <MapSearchContainer
                additionalClassName="summary-map"
                mapControlOptions={projectSummaryPageMapControlOptions}
                showSearchBox={false}
                style={mapStyle}
                fieldPolygons={regionPolygons}
                boundsCoordinates={mapBounds}
              />
            </Container>
            <DetailsContainer backgroundColor={WHITE} className="curved-border">
              <FieldsContainer>
                {/* Splitting the content into two rows for the details section */}
                <FieldsRow>
                  {/* First row will have 3 fields and second row will have the rest */}
                  {<FieldMapper fields={detailsFirstRowFields} />}
                </FieldsRow>
                <FieldsRow>
                  {/* Second row will have the rest */}
                  {<FieldMapper fields={detailsSecondRowFields} />}
                </FieldsRow>
              </FieldsContainer>
            </DetailsContainer>
          </Container>
        </Container>
        <AddParticipants
          refreshHandler={() => {
            refreshHandler();
          }}
          isModalOpen={participantDialogOpen.flag}
          setIsModalOpen={setParticipantDialogOpen}
          projectId={projectId}
          projectName={originationProjectDetails?.projectName}
          particpantId={participantDialogOpen.id}
        />
      </Container>
    </>
  );
};

OriginationSummaryTab.propTypes = {
  originationProjectDetails: PropTypes.object,
  refreshHandler: PropTypes.func,
  projectId: PropTypes.number,
};
export default OriginationSummaryTab;
