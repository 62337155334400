import PropTypes from 'prop-types';
import CalculationSummaryComp from 'components/CalculationSummaryComp/CalculationSummaryComp.react';
import ToggleViewCalculations from 'components/ToggleViewCalculations/ToggleViewCalculations.react';
import {
  PROJECT_CALCULATION_COLUMN_TOOLTIP,
  PROJECT_CALCULATION_REPORT_CONTENT,
} from './ProjectCalculationReport.content';
import NewCalculationReport from '../CalculationReport/NewCalculationReport.react';
import { useContext, useEffect } from 'react';
import { checkTernaryCondition } from 'utils/helper';
import CalculationEmptyStateComp from 'components/CalculationEmptyStateComp/CalculationEmptyStateComp.react';
import { projectCalculationContext } from 'contextAPI/projectCalculationContext';
import { Backdrop, CircularProgress } from '@mui/material';
import AddButton from 'components/AddButton/AddButton.react';
import {
  addBtnSx,
  RunCalcButtonWrapper,
} from './ProjectCalculationReport.style';
import { NEW_DARK_GREEN, WHITE } from 'theme/GlobalColors';

const ProjectCalculationReport = ({ projectId }) => {
  const { calculationReportRows, setProjectId, emptyState, calcLoading } =
    useContext(projectCalculationContext);
  useEffect(() => {
    setProjectId(projectId);
  }, [projectId]);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={calcLoading}>
        <CircularProgress color="success" />
      </Backdrop>

      {!calcLoading &&
        checkTernaryCondition(
          emptyState,
          <>
            {!emptyState && (
              <RunCalcButtonWrapper>
                <AddButton
                  buttonName={
                    PROJECT_CALCULATION_REPORT_CONTENT.run_calculations
                  }
                  disableButton={true}
                  backgroundColor={NEW_DARK_GREEN}
                  textColor={WHITE}
                  customBtnSx={addBtnSx}
                  addBtnPadding={'0.406rem'}
                  noDropdown={true}
                />
              </RunCalcButtonWrapper>
            )}
            <CalculationEmptyStateComp
              noCalcDataTitle={
                PROJECT_CALCULATION_REPORT_CONTENT.noCalcDataTitle
              }
              noCalcDataSubTitle={
                PROJECT_CALCULATION_REPORT_CONTENT.noCalcDataSubTitle
              }
              buttonLabel={PROJECT_CALCULATION_REPORT_CONTENT.run_calculations}
            />
          </>,
          <>
            <CalculationSummaryComp />
            <ToggleViewCalculations
              toogleViewCalculationsTooltip={
                PROJECT_CALCULATION_REPORT_CONTENT.toogleViewCalculationsTooltip
              }
            />
            <NewCalculationReport
              calculationColumnTooltip={PROJECT_CALCULATION_COLUMN_TOOLTIP}
              data={calculationReportRows}
            />
          </>,
        )}
    </>
  );
};

ProjectCalculationReport.propTypes = {
  projectId: PropTypes.number,
};
export default ProjectCalculationReport;
