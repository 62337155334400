import React from 'react';
import PropTypes from 'prop-types';
import { NavModuleWrapper, NavLinksWrapper } from './NavModule.style';
import { uniqueId } from 'utils/uniqueIdGenerator';
import CollapsableBox from './CollapsableBox/CollapsableBox.react';

const NavModule = ({ navLinks = [], hasNoBorder = false }) => {
  return (
    <NavModuleWrapper hasNoBorder={hasNoBorder}>
      <NavLinksWrapper>
        {navLinks.map((link) => {
          const linkProps = link.disabled
            ? { onClick: (e) => e.preventDefault() }
            : {
                to: link.path ?? '/',
                additionalPath: link.additionalPath ?? '/',
              };
          const isActive = [linkProps.to, linkProps.additionalPath].some(
            (item) => item === '/' + window.location.pathname.split('/')[1],
          );
          return (
            link.isModuleEnabled && (
              <CollapsableBox
                nameOfClass={`${link.disabled ? 'disabled' : ''} ${
                  isActive ? 'active' : ''
                }`}
                link={link}
                linkProps={linkProps}
                isActive={isActive}
                projectList={link.projectList}
                key={uniqueId()}
                header={link.title}
              />
            )
          );
        })}
      </NavLinksWrapper>
    </NavModuleWrapper>
  );
};

NavModule.propTypes = {
  moduleName: PropTypes.string,
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  hasNoBorder: PropTypes.bool,
};

export default NavModule;
