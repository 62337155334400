import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  DividerStyles,
  LeftSectionWrapper,
  MainContainer,
  MainWrapper,
  ProjectName,
  ProjectNameWrapper,
  TabberBoxCustomStyle,
  ParentContainer,
  TabStylesSx,
  BackButtonWrapper,
  backButtonStyle,
  projectNameStyle,
} from './ActivityReporting.style';
import {
  TabComponentWrapper,
  ParticipantFlowFooterWrapper,
  footerStyle,
  CollapseExpandButtonSx,
  HeaderButtonContainer,
  HeaderContainer,
} from '../BaseLineReporting/BaseLineReportingMainPage.style';
import {
  Backdrop,
  CircularProgress,
  Divider,
  Box,
  Tabs,
  Button,
} from '@mui/material';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import classNames from 'classnames';
import {
  PAGE_CONTENT,
  autoSaveTargetKeys,
  tabsTitle,
  activityInstanceStatus,
} from './ActivityReporting.content';
import { TextWrapper } from 'theme/GlobalStyledComps';
import LeftMenuSubsection from '../components/LeftMenuSubsection/LeftMenuSubsection.react';
import { DARK_CHARCOAL, WHITE } from 'theme/GlobalColors';
import {
  checkTernaryCondition,
  sortWithAKeyInSpecificOrder,
} from 'utils/helper';
import { useStyles, flexColumn, yAxisAutoScroll } from 'theme/GlobalStyles';
import Tab from '@mui/material/Tab';
import TabPanel from 'components/Tabber/TabPanel.react';
import { useReportingApis } from 'hooks/useReportingApis';
import {
  FETCH_PARTICIPANT_PROFILE_ACTIVITY,
  FETCH_PROJECT_LIFECYCLES,
  PARTICIPANT_PROFILE,
} from 'urls';
import { pathName } from 'Routes/routes.const';
import TabBodyComponent from '../ReportingComponents/TabBodyComponent.react';
import useBeforeUnloadEvent from 'hooks/useBeforeUnloadEvent';
import { getFormattedFarmData, getStatus } from '../ReportingUtilFunctions';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import ProofOfImplementationMainComp from './components/ProofOfImplementationMainComp/ProofOfImplementationMainComp.react';
import { REPORTING_STATUS } from 'utils/config';
import {
  getFarmById,
  getOperationsDataBody,
  submitPoiTabData,
} from './ActivityReporting.functions';
import axios from 'axios';
import EnrollmentCompleteModal from '../components/EnrollmentCompleteModal/EnrollmentCompleteModal.react';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import moment from 'moment';
import UnsavedChangesModal from '../components/UnsavedChangesModal/UnsavedChangesModal.react';
import { ReactComponent as BackArrow } from 'assets/icons/Round.svg';
import Footer from 'layout/footer/Footer.react';
import { columnFlexStyle } from '../ParticipantDataCollection.style';
import { ImplementedActivitiesWithOpsDataProvider } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import TabBodyComponentV2 from '../ReportingComponents/TabBodyComponentV2/TabBodyComponentV2.react';
import { displayToast } from 'pages/OriginationProjectList/OriginationProjectList.content';
import {
  FIELD_SUBMIT_TRIGGER_INIT,
  UNSAVED_CHANGES_MODAL_PROPS_INIT,
} from '../ParticipantDataCollection.content';

const ActivityReporting = ({ isInTesting = false }) => {
  const { setActivityStatusList } = useContext(baselineDropdownContext);
  const { userRoles } = useContext(userAuthRolesContext);
  const [searchParams] = useSearchParams();
  const [farmDetails, setFarmDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { loaderCounter } = useContext(baselineDropdownContext);
  const [projectCycleData, setProjectCycleData] = useState({
    cycleStartDate: '',
    cycleEndDate: '',
  });
  const [currentFieldId, setCurrentFieldId] = useState({
    id: 0,
    name: '',
  });
  const [type, setType] = useState(PAGE_CONTENT.farm);
  const [fieldDetails, setFieldDetails] = useState({});
  const [currentTab, setCurrentTab] = useState(0);
  const [activityTabData, setActivityTabData] = useState(null);
  const [currentFarmId, setCurrentFarmId] = useState(null);
  const [projectDetails, setProjectDetails] = useState(null);
  const [expandedFarmId, setExpandedFarmId] = useState(-1);
  const projectId = searchParams.get('projectid');
  const participantId = searchParams.get('participantid');
  const projectCycleId = searchParams.get('projectcycleid');
  const [totalFieldArea, setTotalFieldArea] = useState();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [fetchLastSectionData, setFetchLastSectionData] = useState(false);
  const [userActivityDetected, setUserActivityDetected] = useState(false);
  const [poiStatusList, setPoiStatusList] = useState([]);
  const [
    activityReportingMappingModalErrorMessage,
    setActivityReportingMappingModalErrorMessage,
  ] = useState('');
  const [submitData, setSubmitData] = useState({
    sampleId: null,
    parentState: null,
    editMode: false,
    practiceModalsData: null,
    deletePracticeModalsData: null,
    practicesData: null,
  });
  const [poiCurrentTab, setPoiCurrentTab] = useState(0);
  const [poiActivities, setPoiActivities] = useState([]);
  const [poiData, setPoiData] = useState([]);
  const [poiCurrentTabStatus, setPoiCurrentTabStatus] = useState('Not Started');
  const [activityFieldTabs, setActivityFieldTabs] = useState([]);
  const [reportingCompleteModal, setReportingCompleteModal] = useState({
    flag: false,
  });
  const [unsavedChangesModalProps, setUnsavedChangesModalProps] = useState(
    UNSAVED_CHANGES_MODAL_PROPS_INIT,
  );
  const [fieldSubmitTriggerProps, setFieldSubmitTriggerProps] = useState(
    FIELD_SUBMIT_TRIGGER_INIT,
  );
  const [triggerCurrentFieldDataFetch, setTriggerCurrentFieldDataFetch] =
    useState(false);

  const [allExpanded, setAllExpanded] = useState([true]);
  const navigate = useNavigate();

  const { TabberBox } = useStyles();
  useBeforeUnloadEvent(
    userActivityDetected,
    PAGE_CONTENT.unsavedChangesPromptMsg,
  );
  const [activityApprovedFarmIds, setActivityApprovedFarmIds] = useState([]);
  const [activityApprovedFieldIds, setActivityApprovedFieldIds] = useState([]);

  const [annualImplementationCost, setAnnualImplementationCost] = useState('');
  const {
    loading: apiLoading,
    fetchBaselineFieldDetails,
    fetchBaselineFarmYears,
    fetchBaselineFieldYears,
    fetchProjectData,
    fetchFieldArea,
    submitBaselineFarm,
  } = useReportingApis();

  const closeUnsavedChangesModal = () =>
    setUnsavedChangesModalProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);

  const switcher = ({
    switchTo,
    farmId,
    farmName,
    fieldId,
    fieldName,
    tabIndex,
    targetType,
  }) => {
    switch (switchTo) {
      case 'Tab':
        setCurrentTab(tabIndex);
        if (targetType === PAGE_CONTENT.farm) {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmId);
        } else {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, fieldId);
        }
        break;

      case 'Farm':
        setCurrentTab(0);
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmId);
        break;
      case 'Field':
        setCurrentTab(0);
        setCurrentFieldId({ id: fieldId, name: fieldName });
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, fieldId);
        break;
      default:
        return;
    }
  };

  const handleLeaveWithoutSave = () => {
    switch (unsavedChangesModalProps.navigateNextTo) {
      case 'Tab':
        switcher({
          switchTo: 'Tab',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: unsavedChangesModalProps.nextTab,
          targetType: type, // Same as current type for tab change
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: unsavedChangesModalProps.targetFarmId,
          farmName: unsavedChangesModalProps.targetFarmName,
          fieldId: unsavedChangesModalProps.targetFieldId,
          fieldName: unsavedChangesModalProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
    }
    closeUnsavedChangesModal();
    setUserActivityDetected(false);
  };

  const handleSaveAndLeave = () => {
    if (type === PAGE_CONTENT.farm) {
      submitCurrentPage().then(() => {
        closeUnsavedChangesModal();
        setUserActivityDetected(false);
        handleLeaveWithoutSave();
        fetchFieldDetails(false).then((fieldDetails) => {
          fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, currentFarmId);
        });
      });
    } else if (type === PAGE_CONTENT.crop) {
      closeUnsavedChangesModal();
      setUserActivityDetected(false);

      setFieldSubmitTriggerProps({
        trigger: true,
        navigateTo: unsavedChangesModalProps.navigateNextTo,
        targetFarmId: unsavedChangesModalProps.targetFarmId,
        targetFarmName: unsavedChangesModalProps.targetFarmName,
        targetFieldId: unsavedChangesModalProps.targetFieldId,
        targetFieldName: unsavedChangesModalProps.targetFieldName,
        targetTab: unsavedChangesModalProps.nextTab,
      });
    }
  };

  /*================> Start of in progress handler logic <==================*/
  const constructPageList = (fieldDetails) => {
    const farmList = fieldDetails.farmDetails;
    const pageList = [];

    if (!farmList) return pageList;

    for (const farm of farmList) {
      pageList.push({
        type: PAGE_CONTENT.farm,
        page: autoSaveTargetKeys.farmOperationsData,
        id: farm.farmId,
        name: farm.farmName,
        status: farm.farmOperationsDataStatus ?? REPORTING_STATUS.not_started,
        farmId: null,
      });

      pageList.push({
        type: PAGE_CONTENT.farm,
        page: autoSaveTargetKeys.proofOfImplementation,
        id: farm.farmId,
        name: farm.farmName,
        status:
          farm.farmProofOfImplementationStatus ?? REPORTING_STATUS.not_started,
        farmId: null,
      });

      // Here since fieldActivityReportingStatusDto and fieldDetails may be out of sync wrt their ordering, we are sorting based on the order as in fieldDetails(which will be sorted based on their creation time).
      for (const field of farm.fieldDetails) {
        const fieldName = field?.fieldName;
        let fieldYears = field?.fieldYearStatus?.sort(
          (a, b) => b.dateId - a.dateId,
        );

        pageList.push({
          type: PAGE_CONTENT.crop,
          page: autoSaveTargetKeys.fieldYear,
          id: field.fieldId,
          name: fieldName,
          status: fieldYears[0]?.formStatus ?? REPORTING_STATUS.not_started,
          farmId: farm.farmId,
        });
      }
    }

    return pageList;
  };

  const getCurrentScreenIndex = (pageList) => {
    if (type === PAGE_CONTENT.farm) {
      const currentPage = checkTernaryCondition(
        currentTab == 0,
        autoSaveTargetKeys.farmOperationsData,
        autoSaveTargetKeys.proofOfImplementation,
      );

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.farm &&
          item.page === currentPage &&
          item.id == currentFarmId,
      );
    } else if (type === PAGE_CONTENT.crop) {
      const currentPage = autoSaveTargetKeys.fieldYear;
      const fieldId = currentFieldId.id;

      return pageList.findIndex(
        (item) =>
          item.type === PAGE_CONTENT.crop &&
          item.page === currentPage &&
          item.farmId == currentFarmId &&
          item.id == fieldId,
      );
    }
  };

  const switchScreen = (pageData) => {
    if (pageData === null) return;

    if (pageData.type === PAGE_CONTENT.farm) {
      setCurrentFarmId(pageData.id);
      setExpandedFarmId(pageData.id);
      setFarmDetails({
        id: pageData.id,
        name: pageData.name,
      });

      const newTab = checkTernaryCondition(
        pageData.page === autoSaveTargetKeys.farmOperationsData,
        0,
        1,
      );
      setCurrentTab(newTab);
      updateReportingStatus(PAGE_CONTENT.farm, pageData.id);
    } else if (pageData.type === PAGE_CONTENT.crop) {
      setCurrentFarmId(pageData.farmId);
      setExpandedFarmId(pageData.farmId);
      setCurrentFieldId({
        id: pageData.id,
        name: pageData.name,
      });
      setCurrentTab(0);
    }
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @returns item from pageList with current farm POI data
   */
  const getCurrentFarmPoiData = (pageList, currentScreenIndex) => {
    for (let index = currentScreenIndex; index >= 0; index--) {
      if (pageList[index].page === autoSaveTargetKeys.proofOfImplementation)
        return pageList[index];
    }

    return null;
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @returns true if all the fields under current farm are completed and POI in current farm is not completed.
   */
  const canGoToPoi = (pageList, currentScreenIndex) => {
    let pointerIndex = currentScreenIndex;
    for (; pointerIndex >= 0; pointerIndex--) {
      if (
        pageList[pointerIndex].page === autoSaveTargetKeys.proofOfImplementation
      )
        break;
    }

    // If poi is completed, don't navigate here
    if (pageList[pointerIndex].status === REPORTING_STATUS.completed)
      return false;

    pointerIndex++;

    for (
      ;
      pointerIndex < pageList.length &&
      pageList[pointerIndex].type !== PAGE_CONTENT.farm;
      pointerIndex++
    ) {
      // if any field is in progress we can't navigate to POI -> return canGoToPOI as false
      if (
        pageList[pointerIndex].status === REPORTING_STATUS.in_progress ||
        pageList[pointerIndex].status === REPORTING_STATUS.not_started
      )
        return false;
    }

    // If none of the fields are "In progress"/"Not started" we return canGoToPOI as true.
    return true;
  };

  /**
   *
   * @param {Array} pageList has array of objects with status, id, type, page -> Gives data about current page and data to navigate to next page
   * @param {number} currentScreenIndex has the index of current screen in pageList
   * @param fieldData is the response from getParticipantPropfile api -> has all farm/field data
   * It searches for the next in progress page from the current page and switches the screen to it, recursively calls if needed with updated currentScreenIndex
   */
  const recursiveNavigationHandler = (
    pageList,
    currentScreenIndex,
    fieldData,
  ) => {
    for (let index = currentScreenIndex + 1; index < pageList.length; index++) {
      // Searching for "In progress"/"Not started" items in pageList
      if (
        pageList[index].status === REPORTING_STATUS.not_started ||
        pageList[index].status === REPORTING_STATUS.in_progress
      ) {
        // If navigating to POI tab check if all fields under current farm are completed
        if (pageList[index].page === autoSaveTargetKeys.proofOfImplementation) {
          for (
            let fieldIndex = index + 1;
            fieldIndex < pageList.length &&
            pageList[fieldIndex].type !== PAGE_CONTENT.farm;
            fieldIndex++
          ) {
            if (
              pageList[fieldIndex].status === REPORTING_STATUS.not_started ||
              pageList[fieldIndex].status === REPORTING_STATUS.in_progress
            ) {
              //Note: index here will be POI tab index in the pageList, so next recursive call will start from POI tab and go to next in progress field
              recursiveNavigationHandler(pageList, index, fieldData);
              return;
            }
          }
        }

        switchScreen(pageList[index]);
        return;
      }
    }

    // If none of the items after current screen is "In progress"/"Not started" just switch to next screen
    if (currentScreenIndex + 1 < pageList.length) {
      switchScreen(pageList[currentScreenIndex + 1]);
    }

    // If we are at the end of the pageList, then we trigger the fetch for current field data
    if (currentScreenIndex === pageList.length - 1) {
      setTriggerCurrentFieldDataFetch(true);
      updateReportingStatus('field', currentFieldId.id);
    }
  };

  /**
   *
   * @param fieldData is the response from getParticipantPropfile api -> has all farm/field data
   * This is the starting point of "next in progress" handler logic, actual navigation happens inside recursiveNavigationHandler
   */
  const gotoNextInprogressPage = (fieldData = fieldDetails) => {
    const pageList = constructPageList(fieldData);
    const currentScreenIndex = getCurrentScreenIndex(pageList);

    // Note: If all pages are completed, then open review modal
    if (getActivityReportingStatus(fieldData) === REPORTING_STATUS.completed) {
      setReportingCompleteModal({ flag: true });
      return;
    }

    // If user clicks on continue from a field tab(impl activities/ops data) we check if all fields under current farm are in completed state, if so we navigate to POI directly.
    if (
      pageList[currentScreenIndex]?.type === PAGE_CONTENT.crop &&
      canGoToPoi(pageList, currentScreenIndex)
    ) {
      const currentFarmPoiData = getCurrentFarmPoiData(
        pageList,
        currentScreenIndex,
      );
      switchScreen(currentFarmPoiData);
      return;
    }

    // Call for navigating to in progress handler
    recursiveNavigationHandler(pageList, currentScreenIndex, fieldData);
  };

  //Separate Approved Farm and fields and add into an array
  function filterApprovedActivityData(farmsList) {
    const activityApprovedFarms = [],
      activityApprovedFields = [];
    for (const farm of farmsList) {
      /* istanbul ignore else */
      if (
        farm.activityReportingStatus === activityInstanceStatus.approved &&
        farm.fieldDetails.every(
          (field) =>
            field.activityReportingStatus === activityInstanceStatus.approved,
        )
      ) {
        activityApprovedFarms.push(farm.farmId);
      }
      const fieldsList = farm.fieldDetails;
      for (const field of fieldsList) {
        /* istanbul ignore else */
        if (field.activityReportingStatus === activityInstanceStatus.approved) {
          activityApprovedFields.push(field.fieldId);
        }
      }
    }
    setActivityApprovedFarmIds(activityApprovedFarms);
    setActivityApprovedFieldIds(activityApprovedFields);
  }

  /*=============> End of in progress handler logic <==================*/

  // Logic to handle disabling of farm and fields on creation of Additional instance
  const disableAllFarmActions =
    activityApprovedFarmIds?.includes(currentFarmId);
  const disableAllFieldActions = activityApprovedFieldIds?.includes(
    currentFieldId.id,
  );

  const tabProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `activity-page-tab-${index}`,
    };
  };
  const fetchTotalFieldArea = () => {
    const apiParams = { fieldId: currentFieldId.id };
    fetchFieldArea(apiParams).then((area) => setTotalFieldArea(area));
  };

  const getOperationsDataTab = (tabData) => {
    const title = checkTernaryCondition(
      type === PAGE_CONTENT.farm,
      tabData?.displayTitle,
      tabData?.yearValue,
    );
    return {
      id: 1,
      title: tabsTitle(title, tabData.status, tabData.phase),
      component: (
        <TabBodyComponent
          isInActivity
          year={tabData?.yearValue}
          type={checkTernaryCondition(
            type === PAGE_CONTENT.farm,
            PAGE_CONTENT.empty_farm_label,
            PAGE_CONTENT.empty_crop_label,
          )}
          fieldType={type}
          sampleId={tabData?.sampleId}
          phase={tabData?.phase}
          setLoading={setLoading}
          fieldId={currentFieldId.id}
          farmId={currentFarmId}
          totalFieldArea={totalFieldArea}
          enrollmentYearData={tabData}
          setFieldDetails={setFieldDetails}
          fetchFieldDetails={fetchFieldDetails}
          setSubmitData={setSubmitData}
          saveDataHandler={saveDataHandler}
          setIsInitialLoad={setIsInitialLoad}
          fetchLastSectionData={fetchLastSectionData}
          setFetchLastSectionData={setFetchLastSectionData}
          setUserActivityDetected={setUserActivityDetected}
          typeOfReporting={'Activity'}
          setActivityReportingMappingModalErrorMessage={
            setActivityReportingMappingModalErrorMessage
          }
          activityReportingMappingModalErrorMessage={
            activityReportingMappingModalErrorMessage
          }
          handleCancel={navigateHandler}
          approvedFarmIds={activityApprovedFarmIds}
        />
      ),
    };
  };

  const fetchBaselineYears = (fieldDetails, type, id) => {
    if (type === PAGE_CONTENT.farm) {
      const farm = getFarmById(fieldDetails?.farmDetails, id);
      const apiParams = { farmId: id, projectCycleId: Number(projectCycleId) };
      fetchBaselineFarmYears(apiParams, 'activity').then((data) => {
        setActivityTabData({
          ...data[0],
          farmOperationsDataStatus: farm?.farmOperationsDataStatus,
          farmProofOfImplementationStatus:
            farm?.farmProofOfImplementationStatus,
        });
        setPoiStatusList(farm.poiTabStatus);
      });
    } else {
      const farm = getFarmById(fieldDetails?.farmDetails, currentFarmId);
      if (id === 0) return;
      const apiParams = {
        fieldId: id,
        projectId: projectId,
        projectCycleId: Number(projectCycleId),
      };
      const currentField = farm?.fieldActivityReportingStatusDto.find(
        (field) => field.fieldId === id,
      );

      currentField &&
        fetchBaselineFieldYears(apiParams, 'baseline').then((data) =>
          setActivityFieldTabs(data),
        );
    }
  };

  const fetchFieldDetails = async (isInitial) => {
    const URL = `${FETCH_PARTICIPANT_PROFILE_ACTIVITY}&projectId=${projectId}&participantId=${participantId}`;
    return fetchBaselineFieldDetails(URL).then((data) => {
      const fieldData = data;
      const farmId = fieldData?.farmDetails[0]?.farmId;
      const farmName = fieldData?.farmDetails[0]?.farmName;
      const farmList = fieldData?.farmDetails;
      filterApprovedActivityData(farmList);
      setFieldDetails(fieldData);
      if (isInitial) {
        setCurrentFarmId(farmId);
        setExpandedFarmId(farmId);
        setFarmDetails({
          id: farmId,
          name: farmName,
        });
      }

      return fieldData;
    });
  };

  const updateReportingStatus = (targetType, targetId) => {
    fetchFieldDetails(false).then((fieldDetails) => {
      fetchBaselineYears(fieldDetails, targetType, targetId);
    });
  };

  const fetchLifeCycleDates = () => {
    axios.get(`${FETCH_PROJECT_LIFECYCLES}/${projectId}`).then((res) => {
      const cycleObject = res.data.filter(
        (item) => +item.projectCycleId === +projectCycleId,
      );
      if (cycleObject?.length > 0) {
        setProjectCycleData({
          cycleStartDate: moment(cycleObject[0]?.cycleStartDate).format(
            'MM/DD/YYYY',
          ),
          cycleEndDate: moment(cycleObject[0]?.cycleEndDate).format(
            'MM/DD/YYYY',
          ),
        });
      }
    });
  };

  useEffect(() => {
    fetchLifeCycleDates();
  }, []);

  const handleFarmSubmit = ({
    isTabSwitched,
    clickdata,
    parentState,
    sampleId,
    gotoNextInprogressScreen,
  }) => {
    const { yearStatus, fossiFuelFormatted } =
      getFormattedFarmData(parentState);
    const submitData = {
      activityReportingStatus: 'Enrollment In Progress',
      enrolmentType: 'Activity',
      farmSampleId: sampleId,
      farmId: currentFarmId,
      farmOperationsDataStatus: yearStatus,
      projectId: projectId,
      participantId: participantId,
      participantBaselineStatus: 'Enrollment In Progress',
      yearStatus: yearStatus,
      fossilFuels: fossiFuelFormatted,
      projectCycleId: Number(projectCycleId),
    };
    submitBaselineFarm(submitData, type)
      .then(() => {
        setUserActivityDetected(false);
        displayToast(PAGE_CONTENT.success, PAGE_CONTENT.success_msg);
        fetchFieldDetails(false).then((fieldData) => {
          const shouldFetchBaselineYears =
            isTabSwitched ||
            (clickdata.type === 'farm' &&
              clickdata.id === currentFarmId &&
              type === PAGE_CONTENT.farm);
          if (shouldFetchBaselineYears) {
            fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, currentFarmId);
          } else {
            setActivityTabData((prev) => ({
              ...prev,
              type: 'farm',
              farmOperationsDataStatus: checkTernaryCondition(
                fossiFuelFormatted.length > 0,
                'Completed',
                'Not Started',
              ),
            }));
          }

          if (gotoNextInprogressScreen) {
            gotoNextInprogressPage(fieldData);
          }
        });
      })
      .catch(() => {
        displayToast(PAGE_CONTENT.error, PAGE_CONTENT.error_msg);
      });
  };

  const saveDataHandler = (
    isTabSwitched = false,
    clickdata = { type: 'none', id: null },
    gotoNextInprogressScreen = false,
  ) => {
    const { sampleId, parentState, editMode } = submitData;
    if (sampleId === null) return;
    if (type === PAGE_CONTENT.farm) {
      handleFarmSubmit({
        isTabSwitched,
        clickdata,
        parentState,
        editMode,
        sampleId,
        gotoNextInprogressScreen,
      });
    }
  };
  const navigateHandler = () => {
    navigate(
      {
        pathname: pathName.participant.activityMenuPage,
        search: createSearchParams({
          projectid: projectId,
          participantid: participantId,
          projectcycleid: projectCycleId,
        }).toString(),
      },
      { state: { fromNotLanding: true } },
    );
  };

  const updateStatus = (currentFieldId, newFieldStatus, fieldDetails) => {
    const updatedFieldDetails = { ...fieldDetails };
    let areAllFarmsCompleted = true;
    for (const farm of updatedFieldDetails.farmDetails) {
      for (const field of farm.fieldDetails) {
        if (field.fieldId === currentFieldId) {
          field.fieldStatus = newFieldStatus;
        }
        if (field.fieldStatus !== 'Completed') {
          areAllFarmsCompleted = false;
        }
      }
      farm.farmStatus = areAllFarmsCompleted ? 'complete' : 'in progress';
      areAllFarmsCompleted = true;
    }
    setFieldDetails(updatedFieldDetails);
  };

  const updateFieldLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.in_progress,
              fieldActivityReportingStatusDto:
                farm.fieldActivityReportingStatusDto.map((field) => {
                  return checkTernaryCondition(
                    field.fieldId === currentFieldId.id,
                    {
                      ...field,
                      fieldActivityReportingStatus:
                        REPORTING_STATUS.in_progress,
                    },
                    field,
                  );
                }),
            },
            farm,
          );
        }),
      };
    });
  };

  const updateFarmLevelStatus = () => {
    setFieldDetails((prev) => {
      return {
        ...prev,
        farmDetails: prev.farmDetails?.map((farm) => {
          return checkTernaryCondition(
            farm.farmId === currentFarmId,
            {
              ...farm,
              farmActivityReportingStatus: REPORTING_STATUS.in_progress,
            },
            farm,
          );
        }),
      };
    });
  };

  const proofOfImplementationTabs = [
    {
      id: 2,
      title: tabsTitle(
        PAGE_CONTENT.proof_of_implementation,
        activityTabData?.farmProofOfImplementationStatus,
        PAGE_CONTENT.project,
      ),
      isDisabled: false,
      component: (
        <ProofOfImplementationMainComp
          farmId={currentFarmId}
          setActivityTabData={setActivityTabData}
          fetchFieldDetails={fetchFieldDetails}
          poiStatusList={poiStatusList}
          updateFarmLevelStatus={updateFarmLevelStatus}
          currentTab={poiCurrentTab}
          setCurrentTab={setPoiCurrentTab}
          projectActivities={poiActivities}
          setProjectActivities={setPoiActivities}
          currentTabData={poiData}
          setCurrentTabData={setPoiData}
          currentTabStatus={poiCurrentTabStatus}
          setCurrentTabStatus={setPoiCurrentTabStatus}
          updateReportingStatus={updateReportingStatus}
          gotoNextInprogressPage={gotoNextInprogressPage}
          farmProofOfImplementationStatus={
            activityTabData?.farmProofOfImplementationStatus
          }
          setUserActivityDetected={setUserActivityDetected}
          userActivityDetected={userActivityDetected}
          setAnnualImplementationCost={setAnnualImplementationCost}
          annualImplementationCost={annualImplementationCost}
          setReportingCompleteModal={setReportingCompleteModal}
          disableAllFarmActions={disableAllFarmActions}
        />
      ),
    },
  ];

  const farmActivityTabs = [
    getOperationsDataTab({
      ...activityTabData,
      status: activityTabData?.farmOperationsDataStatus,
    }),
    ...proofOfImplementationTabs,
  ];

  const fieldActivityTabs = useMemo(() => {
    return activityFieldTabs.map((tab) => ({
      title: tabsTitle(tab?.yearValue, tab?.status, tab?.phase),
      component: (
        <TabBodyComponentV2
          navigateHandler={navigateHandler}
          allExpanded={allExpanded}
          currentYearData={tab}
          currentFarmId={currentFarmId}
          currentFieldId={currentFieldId.id}
          setEnrollmentYears={setActivityFieldTabs}
          setFieldDetails={setFieldDetails}
          refreshStatusHandler={() =>
            updateReportingStatus('field', currentFieldId.id)
          }
          gotoNextInprogressPage={() => {
            const URL = `${PARTICIPANT_PROFILE}?enrollmentType=ACTIVITY&projectId=${projectId}&participantId=${participantId}`;
            fetchBaselineFieldDetails(URL).then((data) => {
              gotoNextInprogressPage(data);
              const farmList = data?.farmDetails;
              const baselineStatus = getStatus(farmList);
              updateStatus(currentFieldId.id, baselineStatus, data);
            });
          }}
          setUserActivityDetected={setUserActivityDetected}
          disableAllFieldActions={disableAllFieldActions}
        />
      ),
    }));
  }, [activityFieldTabs, currentFieldId, currentFarmId, allExpanded]);

  const getActivityTabs = () => {
    if (type === PAGE_CONTENT.farm) {
      return farmActivityTabs;
    }
    return fieldActivityTabs;
  };

  const fetchProjectDetails = () => {
    const apiPath = `/${projectId}`;
    const errorHandler = () => setProjectDetails({ projectName: '' });
    fetchProjectData(apiPath, errorHandler).then((projectData) =>
      setProjectDetails(projectData),
    );
  };

  const updateCurrentTab = (newTab) => setCurrentTab(newTab);

  useEffect(() => {
    fetchFieldDetails(true);
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    if (currentFieldId?.id > 0) {
      setType(PAGE_CONTENT.crop);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, currentFieldId.id);
      fetchTotalFieldArea();
    }
  }, [currentFieldId]);
  useEffect(() => {
    if (farmDetails) {
      setType(PAGE_CONTENT.farm);
      fetchBaselineYears(fieldDetails, PAGE_CONTENT.farm, farmDetails.id);
    }
  }, [farmDetails]);

  useEffect(() => {
    if (activityTabData && !isInitialLoad) {
      let updatedStatusData = {};
      if (type === PAGE_CONTENT.farm) {
        updatedStatusData = {
          farmOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      } else {
        updatedStatusData = {
          fieldOperationsDataStatus: checkTernaryCondition(
            submitData.parentState.length > 0 ||
              submitData.practiceModalsData.edgeOfFieldList.length > 0,
            'In Progress',
            'Not Started',
          ),
        };
      }
      setActivityTabData((prev) => ({
        ...prev,
        ...updatedStatusData,
      }));
    }
    if (type === PAGE_CONTENT.farm && !isInitialLoad) {
      updateFarmLevelStatus();
    }
    if (type === PAGE_CONTENT.crop && !isInitialLoad) {
      updateFieldLevelStatus();
    }
  }, [submitData]);

  useEffect(() => {
    let finalData = [];
    fieldDetails?.farmDetails?.map((farm) =>
      finalData.push(farm?.farmActivityReportingStatus),
    );
    if (finalData.every((element) => element === PAGE_CONTENT.completed)) {
      setActivityStatusList(PAGE_CONTENT.completed);
    } else {
      setActivityStatusList(PAGE_CONTENT.in_progress);
    }
  }, [fieldDetails]);

  /**============> Start of autosave logic <============== **/

  /**
   * This function is used to identify the current page in which the user is in, using this data w.r.t current page is saved
   * @returns {string} representing the name of the current page based on the type prop and current tab value(page names are stored in autoSaveTargetKeys constant)
   */
  const getCurrentPage = () => {
    if (type === PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.farmOperationsData;
    if (type === PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.proofOfImplementation;
    if (type !== PAGE_CONTENT.farm && currentTab === 0)
      return autoSaveTargetKeys.fieldImplementedActivities;
    if (type !== PAGE_CONTENT.farm && currentTab === 1)
      return autoSaveTargetKeys.fieldOperationsData;
  };

  /**
   *
   * This function submits the current page and is used for the purpose of autosave feature, whenever user clicks on any other field/farm/tab, current page data is saved using this function, post which navigation to the target place is carried out.
   * @returns {Promise} -> this async promise is used by autosave function, on resolve of this Promise navigation to target place happens.
   */
  const submitCurrentPage = () => {
    const currentPage = getCurrentPage();

    switch (currentPage) {
      case autoSaveTargetKeys.farmOperationsData: {
        const apiBody = getOperationsDataBody(
          submitData.parentState,
          activityTabData?.sampleId,
          currentFarmId,
          projectId,
          participantId,
          projectCycleId,
        );

        return submitBaselineFarm(apiBody);
      }

      case autoSaveTargetKeys.proofOfImplementation: {
        return submitPoiTabData(
          poiActivities,
          poiCurrentTab,
          poiData,
          projectId,
          currentFarmId,
          poiCurrentTabStatus,
          participantId,
          annualImplementationCost,
        );
      }

      case autoSaveTargetKeys.fieldImplementedActivities: {
        // Note: Commenting autosave for field data, will be updated in future
        return Promise.resolve();
        // return submitImplementedActivities(true);
      }

      case autoSaveTargetKeys.fieldOperationsData: {
        // Note: Commenting autosave for field data, will be updated in future
        return Promise.resolve();
        // return handleFieldSubmit({
        //   isSaveAndCloseClicked: false,
        //   isUnmounting: false,
        //   isTabSwitched: false,
        //   clickdata: null,
        //   parentState: submitData.parentState,
        //   editMode: submitData.editMode,
        //   fromAutosave: true,
        // });
      }

      default:
        break;
    }
  };

  /**
   *
   * @param {string} targetPlace - This is the destination place to navigate after saving current page data.
   * @param {number} targetId - This is the id of the field/farm to navigate to.
   * @param {string} targetName - This is the name of the field/farm to navigate to.
   */

  // Note: This is called when user switches tab inside farm/field
  const handleTabChange = (_, selectedTab) => {
    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: selectedTab,
        targetFarmId: currentFarmId,
        targetFieldId: currentFieldId.id,
        navigateNextTo: 'Tab',
      });
    } else {
      updateCurrentTab(selectedTab);
    }
  };

  // Note: This is called on clicking field name from left menu
  const handleAccordianItemClick = ({
    targetId: fieldId,
    targetName: fieldName,
    targetFarmId: farmId,
    targetFarmName: farmName,
  }) => {
    if (currentFieldId.id === fieldId && type === PAGE_CONTENT.crop) return;

    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: fieldId,
        targetFieldName: fieldName,
        navigateNextTo: 'Field',
      });
    } else {
      setCurrentTab(0);
      setCurrentFieldId({ id: fieldId, name: fieldName });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
    }
  };

  // Note: This is called on clicking farm name from left menu
  const handleAccordianSummaryClick = ({
    targetId: farmId,
    targetName: farmName,
  }) => {
    if (currentFarmId === farmId && type === PAGE_CONTENT.farm) return;

    if (userActivityDetected) {
      setUnsavedChangesModalProps({
        isOpen: true,
        nextTab: 0,
        targetFarmId: farmId,
        targetFarmName: farmName,
        targetFieldId: null,
        targetFieldName: null,
        navigateNextTo: 'Farm',
      });
    } else {
      setFarmDetails({
        id: farmId,
        name: farmName,
      });
      setCurrentFarmId(farmId);
      setExpandedFarmId(farmId);
      setCurrentTab(0);
    }
  };

  /**===============> End of autosave logic <=============== **/

  // Note: This function will be called once field data is save from context and we navigate to the target place from here based on the fieldSubmitTriggerProps.
  const handleSubmitCallback = () => {
    // Navigate to appropriate place based on trigger submit props
    switch (fieldSubmitTriggerProps.navigateTo) {
      case 'Tab':
        setCurrentTab(fieldSubmitTriggerProps.targetTab);
        fetchBaselineYears(fieldDetails, PAGE_CONTENT.crop, currentFieldId.id);
        break;
      case 'Field':
        switcher({
          switchTo: 'Field',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Field',
        });
        break;
      case 'Farm':
        switcher({
          switchTo: 'Farm',
          farmId: fieldSubmitTriggerProps.targetFarmId,
          farmName: fieldSubmitTriggerProps.targetFarmName,
          fieldId: fieldSubmitTriggerProps.targetFieldId,
          fieldName: fieldSubmitTriggerProps.targetFieldName,
          tabIndex: 0,
          targetType: 'Farm',
        });
        break;
    }
    setUnsavedChangesModalProps(UNSAVED_CHANGES_MODAL_PROPS_INIT);
    setFieldSubmitTriggerProps(FIELD_SUBMIT_TRIGGER_INIT);
    fetchFieldDetails(false);
  };

  const getActivityReportingStatus = (fieldData) => {
    const farmList = fieldData?.farmDetails;
    if (!farmList) return 'Not Started';

    for (let farm of farmList) {
      if (farm.farmActivityReportingStatus !== 'Completed') {
        return 'In Progress';
      }
    }

    return 'Completed';
  };

  const getFieldsList = () => {
    return fieldDetails?.farmDetails?.map((data) => {
      let fieldList = [];
      if (data.fieldActivityReportingStatusDto) {
        fieldList = sortWithAKeyInSpecificOrder(
          data.fieldActivityReportingStatusDto,
          'fieldId',
          data.fieldDetails.map((field) => field.fieldId),
        ).map((item) => ({
          value: data.fieldDetails.find(
            (field) => field.fieldId === item.fieldId,
          )?.fieldName,
          status: item.fieldActivityReportingStatus,
          id: item.fieldId,
        }));
      }

      return {
        id: data.farmId,
        status: data.farmActivityReportingStatus,
        value: data.farmName,
        fields: fieldList,
      };
    });
  };

  const onClickBackButton = () => {
    if (
      [PAGE_CONTENT.PARTICIPANT_CONTRIBUTOR, PAGE_CONTENT.PARTICIPANT].includes(
        userRoles[0],
      )
    ) {
      navigate(`${pathName.participant.landingPage}`);
      return;
    }
    navigate(
      `${pathName.participant.activityMenuPage}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`,
    );
  };

  return (
    <MainWrapper>
      <BackButtonWrapper onClick={onClickBackButton}>
        <BackArrow data-testid="back-icon" />
        <Button sx={backButtonStyle}>{PAGE_CONTENT.back}</Button>
      </BackButtonWrapper>
      <ProjectNameWrapper style={projectNameStyle}>
        <ProjectName>{projectDetails?.projectName}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate.slice(
          6,
        )}`}</ProjectName>
        <ProjectName>{` (${projectCycleData?.cycleStartDate}`}</ProjectName>
        <ProjectName>{` - ${projectCycleData?.cycleEndDate})`}</ProjectName>
      </ProjectNameWrapper>
      <Divider style={DividerStyles} />
      <ParentContainer>
        {!isInTesting && (
          <Backdrop
            sx={{
              backgroundColor: WHITE,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={
              loading ||
              activityTabData === null ||
              apiLoading ||
              (!isInTesting && loaderCounter > 0)
            }>
            <CircularProgress color="success" />
          </Backdrop>
        )}
        <LeftSectionWrapper>
          <LeftMenuSubsection
            heading={PAGE_CONTENT.leftSectionHeading}
            fieldData={getFieldsList()}
            showFields={true}
            setCurrentFieldId={setCurrentFieldId}
            isBaselineFlow={true}
            currentFarmId={currentFarmId}
            expandedFarmId={expandedFarmId}
            selectedFieldId={currentFieldId}
            isInBaselinePage
            isInsideActivityReporting
            isFarmSelected={type === PAGE_CONTENT.farm}
            handleAccordianItemClick={handleAccordianItemClick}
            handleAccordianSummaryClick={handleAccordianSummaryClick}
          />
        </LeftSectionWrapper>
        <div style={columnFlexStyle}>
          <MainContainer>
            <HeaderContainer style={{ padding: '1.5rem 0 1rem 1.5rem' }}>
              <TextWrapper
                fontSize="1rem"
                fontWeight="600"
                color={DARK_CHARCOAL}>
                {checkTernaryCondition(
                  type === PAGE_CONTENT.farm,
                  <>{farmDetails?.name}</>,
                  <>{`${farmDetails?.name} - ${currentFieldId?.name}`}</>,
                )}
              </TextWrapper>
              {type !== PAGE_CONTENT.farm && (
                <HeaderButtonContainer>
                  <Button
                    onClick={() => {
                      setAllExpanded((prev) => {
                        return [...prev, false];
                      });
                    }}
                    variant="outlined"
                    size="medium"
                    sx={{
                      ...CollapseExpandButtonSx,
                    }}>
                    {PAGE_CONTENT.collapseButton}
                  </Button>
                  <Button
                    onClick={() => {
                      setAllExpanded((prev) => {
                        return [...prev, true];
                      });
                    }}
                    variant="outlined"
                    sx={{
                      ...CollapseExpandButtonSx,
                    }}>
                    {PAGE_CONTENT.expandAllButton}
                  </Button>
                </HeaderButtonContainer>
              )}
            </HeaderContainer>

            <Box
              sx={{
                ...flexColumn,
                ...yAxisAutoScroll,
              }}>
              <TabberBox
                style={{
                  ...TabberBoxCustomStyle,
                  padding: '0rem 0 0rem 1.5rem',
                }}>
                <Tabs
                  value={currentTab}
                  onChange={handleTabChange}
                  aria-label="page-tabs">
                  {getActivityTabs()?.map((tab, index) => {
                    const key = `Tab title ${index}`;
                    return (
                      <Tab
                        disabled={tab?.isDisabled}
                        sx={TabStylesSx}
                        className={classNames({
                          'active-tab': currentTab === index,
                        })}
                        key={key}
                        label={tab?.title}
                        {...tabProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </TabberBox>
              <ImplementedActivitiesWithOpsDataProvider
                currentFieldId={currentFieldId.id}
                currentFarmId={currentFarmId}
                triggerFieldDataSubmit={fieldSubmitTriggerProps?.trigger}
                dataSubmitCallback={handleSubmitCallback}
                triggerCurrentFieldDataFetch={triggerCurrentFieldDataFetch}
                setTriggerCurrentFieldDataFetch={
                  setTriggerCurrentFieldDataFetch
                }>
                {getActivityTabs()?.map((tab, index) => {
                  const key = `Tab body ${index}`;
                  return (
                    <TabPanel
                      key={key}
                      value={currentTab}
                      index={index}
                      style={{ maxHeight: '40rem', overflow: 'auto' }}
                      extraClassName="styled-scrollbar participant-flow">
                      <TabComponentWrapper>{tab.component}</TabComponentWrapper>
                      <ParticipantFlowFooterWrapper
                        style={{
                          ...footerStyle,
                        }}>
                        <Footer
                          isBaselineFlow={true}
                          isParticipantMenu={
                            location.pathname === pathName.participant.menuPage
                          }
                          isInActivityPage={
                            location.pathname ===
                              pathName.participant.participantFieldActivity ||
                            location.pathname ===
                              pathName.participant.participantDataCollection ||
                            location.pathname ===
                              pathName.participant.participantFieldBaseline
                          }
                        />
                      </ParticipantFlowFooterWrapper>
                    </TabPanel>
                  );
                })}
              </ImplementedActivitiesWithOpsDataProvider>
            </Box>
          </MainContainer>
        </div>
      </ParentContainer>
      <EnrollmentCompleteModal
        modalData={reportingCompleteModal}
        modalCloseHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        title={PAGE_CONTENT.submit_complete_modal_title}
        contentText={PAGE_CONTENT.submit_complete_modal_content}
        firstSubmitBtnLabel={PAGE_CONTENT.continue_btn_label}
        secondSubmitBtnLabel={PAGE_CONTENT.review_btn_label}
        cancelBtnLabel={PAGE_CONTENT.cancel_btn_label}
        firstSubmitBtnHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
        secondSubmitBtnHandler={navigateHandler}
        cancelHandler={() => {
          setReportingCompleteModal({ flag: false });
        }}
      />

      <UnsavedChangesModal
        open={unsavedChangesModalProps?.isOpen}
        handleClose={closeUnsavedChangesModal}
        handleSaveAndLeave={handleSaveAndLeave}
        handleLeaveWithoutSave={handleLeaveWithoutSave}
      />
    </MainWrapper>
  );
};
export default ActivityReporting;
