import {
  DOWNLOAD_ENROLLMENT_BASELINE_REPORT,
  SEND_ACTIVITY_REOPEN_EMAIL,
} from 'urls';

import axios from 'axios';
import { checkTernaryCondition } from 'utils/helper';
import { pathName } from 'Routes/routes.const';

export const activityReview = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Review activity reporting',
  contentText:
    'Please access the activity reporting responses to review and make your approval decision once complete.',
  firstSubmitBtnLabel: 'Approve activity reporting',
  secondSubmitBtnLabel: 'Reopen activity reporting',
  cancelBtnLabel: 'Cancel',
  firstSubmitBtnCb: () => {},
  secondSubmitBtnCb: () => {},
  cancelCb: () => {},
  modalCloseCb: () => {},
  modalData: { flag: false },
  reviewComponent:
    'Please access the activity reporting responses to review and make your approval decision once complete.',
  reviewButtonText: 'Review operations data',
  reviewButtonText2: 'Review proof of implementation',
  fetchInitialDataCb: () =>
    axios
      .get(
        DOWNLOAD_ENROLLMENT_BASELINE_REPORT.replace(
          ':participnatId',
          participantId,
        ).replace(':projectId', projectId),
      )
      .then((response) => response.data.reportLink),
  reOpenProp: activityReOpen(participantId, projectId),
});

export const activityReOpen = (participantId, projectId) => ({
  participantId,
  projectId,
  title: 'Reopen activity reporting ',
  contentText: (participantName) =>
    `How would you like to reopen ${checkTernaryCondition(
      participantName,
      `${participantName}'s`,
      ' ',
    )} activity reporting?`,
  emailPlaceholder: (companyName, projectName) =>
    `Your activity reporting for ${companyName}'s ${projectName} project has been reopened. Below is a description of what’s needed for approval. You may access your activity reporting with the link below to continue with the reporting process.`,
  enrollmentCta: 'Activity reporting request link',
  customEmailMessage: '',
  isOnBehalf: false,
  onReopenSubmit: async (
    customEmailMessage,
    isOnBehalf,
    setIsLoading,
    closeModal,
    instanceId,
    actionId,
    contractId,
    updateParticipantStatus,
    // TODO: Use this while integrating project cycle for activity reporting
    projectCycleId,
    updateParticipantBaselineStatus,
    baselineActionId,
    formId,
    activityId,
    ishandleApprove = false,
    handleApprove = () => {},
  ) => {
    setIsLoading(true);
    try {
      await axios
        .post(`${SEND_ACTIVITY_REOPEN_EMAIL}?radioSelection=${isOnBehalf}`, {
          internalProjectId: projectId,
          participantId: participantId,
          approvalDescription: customEmailMessage,
          emailFrom: '',
          retryId: 0,
          projectCycleId: projectCycleId,
        })
        .then(() => {
          updateParticipantBaselineStatus({
            actionId: activityId,
            formId: formId,
            projectCycleId,
          }).then(() => {
            if (ishandleApprove) {
              handleApprove();
            }
          });

          if (!isOnBehalf) {
            window.open(
              `${pathName.participant.participantActivity}?projectid=${projectId}&participantid=${participantId}&projectcycleid=${projectCycleId}`,
              '_blank',
            );
          }
        });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  },
});
