import styled from 'styled-components';
import { DARK_CHARCOAL } from 'theme/GlobalColors';

export const HeaderContainer = styled.div`
  padding: ${({ padding }) => padding || '0rem 0px 1rem 0px;'};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderTitle = styled.div(({ titleColor, marginBottom }) => ({
  fontFamily: 'Open Sans, sans-serif',
  fontStyle: 'normal',
  fontSize: '1.25rem',
  height: '28px',
  marginRight: '0.5rem',
  overflowWrap: 'break-word',
  flex: 1,
  maxWidth: '100%',
  color: titleColor || DARK_CHARCOAL,
  marginBottom: marginBottom,
  fontWeight: 400,
  '& .MuiBreadcrumbs-ol': {
    width: 'max-content',
    maxWidth: '60rem',
  },
}));

export const addBtnSx = {
  height: '1.75rem',
  fontSize: '0.875rem',
  padding: '0.375rem 0.5rem',
  fontWeight: '400',
  fontFamily: 'Open sans, sans-serif',
};

export const addBtnPadding = '4px 6.5px';
