import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  MainWrapper,
  FormSection,
  ButtonWrapper,
  scrollStyle,
  DeleteWrapper,
  dropdownProps,
  instructionTextStyles,
  FormWrapper,
  breadcrumbStyles,
  linkStyles,
  projectNameStyle,
  dividerSx,
  checkboxPadding,
  autoCompleteSx,
  formLabelCustomSx,
  formLabelTooltipCustomSx,
  ProjectHeadingWrapper,
} from './AddOriginationProject.style';
import {
  PAGE_CONTENT,
  regionList,
  quantificationApproachList,
  monitoringOptions,
  formModal,
  mvrTechnologyList,
  afoluActivityList,
  quantificationMethodList,
  quantificationModelList,
  validationAndVerificationAuditorsList,
  validationAndVerificationYesOrNoList,
  yesOrNoList,
  usRegionsTooltipContent,
  usRegionsTooltipTitle,
} from './AddOriginationProject.content';
import { useForm } from 'hooks/useForm';
import { useLocation, useNavigate } from 'react-router';
import InputField from 'components/FormComponents/InputField/InputField.react';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import TagComponent from 'components/FormComponents/TagComponent/TagComponent.react';
import { useOriginationProject } from 'hooks/useOriginationProjectDetails';
import AutoCompleteForm from 'components/FormComponents/AutoComplete/AutoComplete.react';
import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { FORM_CONTENT } from 'components/FormComponents/FormComponents.content';
import FieldValueComponent from 'components/FormComponents/FieldValueComponent/FieldValueComponent.react';
import {
  checkTernaryCondition,
  covertDateToLocalTimeZone,
  ternaryBooleanHandler,
} from 'utils/helper';
import TagFieldValueComponent from 'components/FormComponents/TagFieldValueComponent/TagFieldValueComponent.react';
import SummaryViewHeader from 'components/PageHeaders/SummaryViewHeader/SummaryViewHeader.react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from 'react-router-dom';
import { pathName } from 'Routes/routes.const';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { InputSx } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.style';
import {
  outlinedDropdownInputSx,
  radioBtnPadding,
} from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';

const AddOriginationProject = ({ id, isEdit }) => {
  const { formValue, customFieldChangeHandler, setFormValue } =
    useForm(formModal);
  const {
    projectName,
    setProjectName,
    projectRegion,
    setProjectRegion,
    projectActivities,
    setProjectActivities,
    tags,
    handleTextFieldValueChange,
    handleCheckboxValueChange,
    handleAutocompleteChange,
    addTagsHandler,
    handleFormSubmit,
    fetchTags,
    duplicateNameError,
    findDuplicateOriginationProject,
    formValidated,
    setFormValidated,
    postProjectData,
    fetchOriginationProjectDetails,
    projectRegionList,
    setProjectRegionList,
    fetchedProjectDetails,
    editProjectName,
    isActive,
    lastUpdatedOn,
    fetchedProjectRegion,
    modelMeasuringOption,
    handleMVROptionChange,
    projectType,
    afoluActivities,
    setAfoluActivities,
    quantificationMethod,
    setQuantificationMethod,
    quantificationModel,
    setQuantificationModel,
    standardsBodyOrRegistry,
    setStandardsBodyOrRegistry,
    validationAndVerificationYesOrNo,
    setValidationAndVerificationYesOrNo,
    validationAndVerificationAuditor,
    setValidationAndVerificationAuditor,
    handleRadioBtnGroupValueChange,
    commercializationApproach,
    setCommercializationApproach,
    commercializationRadioBtn,
    handleCheckboxRadioBtnChange,
    countryList,
    fetchCountryList,
    projectCountryId,
    setProjectCountryId,
    climateProgramEnrollmentYesOrNo,
    setClimateProgramEnrollmentYesOrNo,
    climatePrograms,
    setClimatePrograms,
    climateProgramsList,
    fetchClimateProgramList,
    activitiesList,
    fetchActivitiesList,
    submitLoading,
    fetchDataLoading: loadingData,
    commercializationApproachList,
    fetchCommercializationApproaches,
    standardsBodyOrRegistryList,
    fetchStandardBodies,
    projectTypeList,
    fetchProjectTypes,
    fetchActivityCategories,
    projectActivityCategories,
    loaderCount,
    fetchMethodologyList,
  } = useOriginationProject();

  const [isFormEdited, setIsFormEdited] = useState(false);

  const { state } = useLocation();
  const navigate = useNavigate();
  const isDeleteAndNotEdit = checkTernaryCondition(
    isActive !== -1 && !isEdit,
    true,
    false,
  );
  const checkBoxClick = (index) => {
    handleCheckboxValueChange(
      index,
      isEdit ? projectRegionList : regionList,
      setProjectRegion,
      customFieldChangeHandler,
      PAGE_CONTENT.project_region,
    );
    setIsFormEdited(true);
  };
  const scroll = (className) => {
    const section = document.querySelector(className);
    if (section) {
      section.scrollIntoView(scrollStyle);
    }
  };

  const formatStringToArray = (stringVal) => {
    return stringVal
      .slice(1, -1)
      .split(PAGE_CONTENT.comma)
      .map((item) => item.trim());
  };

  const selectedCountryValue = useMemo(() => {
    if (countryList.length === 0) return '';
    const selectedCountryList = countryList.filter(
      (country) => country.countryId === projectCountryId.value,
    );
    if (selectedCountryList.length === 0) return '';

    return selectedCountryList[0].countryName;
  }, [projectCountryId, countryList]);

  const breadcrumbs = useMemo(
    () => [
      <Link
        key="breadcrumb-1"
        to={checkTernaryCondition(
          state?.previousPath,
          state?.previousPath,
          pathName.originationProject.view,
        )}
        style={{ ...breadcrumbStyles, ...linkStyles }}>
        {PAGE_CONTENT.projects}
      </Link>,
      <div
        key="breadcrumb-2"
        style={projectNameStyle}
        title={projectName.value}>
        <Typography noWrap sx={breadcrumbStyles} color="text.primary">
          {checkTernaryCondition(
            projectName.value,
            projectName.value,
            PAGE_CONTENT.page_title_add,
          )}
        </Typography>
      </div>,
    ],
    [projectName.value, state?.previousPath],
  );

  useEffect(() => {
    fetchTags();
    fetchCountryList();
    fetchClimateProgramList();
    fetchActivitiesList();
    fetchCommercializationApproaches();
    fetchStandardBodies();
    fetchProjectTypes();
    // Note: This function fetchActivityCategories should be called on change of origination project type(AFOLU/non-AFOLU), currently since we have the project type hard coded to AFOLU, we are calling it in the first render itself. Not waiting for value changes, this needs to be updated when we have the project type as a dynamic value. Here argument 1 denotes projectTypeId for AFOLU.
    fetchActivityCategories(1);
    fetchMethodologyList();
    if (!isEdit) {
      const newRegionList = regionList;
      newRegionList.forEach((item) => (item.checked = false));
      setProjectRegionList(newRegionList);
    }
    isEdit && fetchOriginationProjectDetails(id, customFieldChangeHandler);
  }, []);
  useEffect(() => {
    if (isEdit && fetchedProjectDetails !== '') {
      const newModalValue = {
        ...formModal,
        projectName: fetchedProjectDetails?.projectName,
        standardsBodyOrRegistry:
          fetchedProjectDetails?.standardsBody?.standardsBody ?? '',
        countryId: fetchedProjectDetails?.country.countryId,
        projectRegion: formatStringToArray(
          fetchedProjectDetails?.projectRegion,
        ),
        projectType: projectTypeList[0].itemLabel,
        afoluActivities: fetchedProjectDetails?.originationTypeActivities?.map(
          (item) => item.originationProjectActivityCategory,
        ),
        projectActivities: fetchedProjectDetails?.projectBlueprintList.map(
          (item) => item.projectActivity,
        ),
        commercializationApproach: [
          fetchedProjectDetails?.commercializationApproach
            .commercializationApproach,
        ],
        validationAndVerificationAuditor:
          fetchedProjectDetails?.vvAuditor || '',
        quantificationApproach: quantificationApproachList[0].itemLabel,
        quantificationMethod:
          fetchedProjectDetails?.methodologyQuantification ??
          quantificationMethodList?.[1].itemLabel,
        quantificationModel: fetchedProjectDetails?.modelLeveraged,
        modelMeasuringOption: modelMeasuringOption.value,
        tags: checkTernaryCondition(
          fetchedProjectDetails.projectTags,
          [...fetchedProjectDetails.projectTags.map((tag) => tag.tagName)],
          [],
        ),
        climateProgramEnrollmentYesOrNO: checkTernaryCondition(
          fetchedProjectDetails?.climatePrograms === true,
          'Yes',
          'No',
        ),
        climatePrograms: fetchedProjectDetails?.climateProgramDims.map(
          (climateProgram) => climateProgram.climateProgramId,
        ),
      };
      setFormValue(newModalValue);
    }
  }, [fetchedProjectDetails]);
  useEffect(() => {
    const debounceEffect = setTimeout(() => {
      if (!(isEdit && formValue.projectName === editProjectName)) {
        findDuplicateOriginationProject(formValue.projectName);
      }
    }, 500);
    return () => clearTimeout(debounceEffect);
  }, [projectName]);
  useEffect(() => {
    scroll(PAGE_CONTENT.error_class_name);
  }, [
    projectName,
    commercializationApproach,
    projectRegion,
    quantificationMethod,
    quantificationModel,
    afoluActivities,
    standardsBodyOrRegistry,
    validationAndVerificationYesOrNo,
    validationAndVerificationAuditor,
    duplicateNameError,
    modelMeasuringOption,
    projectCountryId,
    climateProgramEnrollmentYesOrNo,
    climatePrograms,
  ]);
  useEffect(() => {
    if (formValidated) {
      const fieldArray = [
        projectName.error,
        commercializationApproach.error,
        projectRegion.error,
        projectActivities.error,
        quantificationMethod.error,
        quantificationModel.error,
        checkTernaryCondition(
          afoluActivities.value.length === 0,
          false,
          afoluActivities.error,
        ),
        standardsBodyOrRegistry.error,
        validationAndVerificationYesOrNo.error,
        validationAndVerificationAuditor.error,
        projectCountryId.error,
        climateProgramEnrollmentYesOrNo.error,
        climatePrograms.error &&
          climateProgramEnrollmentYesOrNo.value === 'Yes',
      ];
      const valueError = fieldArray.includes(true) || duplicateNameError;
      if (!valueError) {
        isEdit
          ? postProjectData(formValue, true, id)
          : postProjectData(formValue, false);
      }
    }
    setFormValidated(false);
  }, [formValidated]);

  useEffect(() => {
    if (
      isEdit &&
      climateProgramsList?.length > 0 &&
      climatePrograms?.climateProgramIds?.length > 0 &&
      climatePrograms?.value?.length === 0
    ) {
      const climateProgramListNames = climateProgramsList
        .filter((program) =>
          climatePrograms.climateProgramIds.includes(program.climateProgramId),
        )
        .map((program) => program.climateProgramName);
      setClimatePrograms((prev) => ({
        ...prev,
        value: climateProgramListNames,
        climateProgramIds: [],
      }));
      setFormValue((prev) => ({
        ...prev,
        climatePrograms: climateProgramListNames,
      }));
    }
  }, [isEdit, climateProgramsList, climatePrograms]);

  const marginStylingForProjectLabels = checkTernaryCondition(
    isEdit,
    STYLE_CONSTANTS.one_point_five_rem,
    STYLE_CONSTANTS.zero_rem,
  );
  return (
    <>
      <Backdrop
        data-testid="backdrop"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitLoading || loadingData || loaderCount > 0}>
        <CircularProgress color="success" />
      </Backdrop>

      <ProjectHeadingWrapper>
        {PAGE_CONTENT.ORIGINATION_PROJECT}
      </ProjectHeadingWrapper>
      <Divider sx={dividerSx} />
      <MainWrapper>
        <FormWrapper>
          <FormSection>
            {isActive === -1 && (
              <DeleteWrapper>{`${PAGE_CONTENT.deletedLabel} 
                
                  ${checkTernaryCondition(
                    lastUpdatedOn !== null,
                    covertDateToLocalTimeZone(lastUpdatedOn).slice(0, 10),
                    null,
                  )}`}</DeleteWrapper>
            )}
            {(isActive === -1 && (
              <FieldValueComponent
                label={PAGE_CONTENT.project_delete_label}
                hasNoBottomMargin
                value={projectName.value}
                labelMarginTop={STYLE_CONSTANTS.zero_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></FieldValueComponent>
            )) || (
              <InputField
                label={PAGE_CONTENT.project_label}
                value={projectName.value}
                placeholder={PAGE_CONTENT.project_placeholder}
                name={PAGE_CONTENT.project_element_name}
                width="100%"
                rightSideLabelFormLabelMargin={'0rem'}
                primaryError={projectName.error}
                secondaryError={duplicateNameError}
                category="name-field"
                maxLength={1000}
                secondaryErrorMessage={PAGE_CONTENT.secondary_error_message}
                onUpdate={(event) => {
                  handleTextFieldValueChange(
                    setProjectName,
                    customFieldChangeHandler,
                    event,
                    PAGE_CONTENT.project_element_name,
                    null,
                    () => {},
                  );
                  setIsFormEdited(true);
                }}
                customInputSx={InputSx}
                labelMarginTop={STYLE_CONSTANTS.zero_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              />
            )}

            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={checkTernaryCondition(
                  isActive === -1,
                  PAGE_CONTENT.commercialization_approach_delete_label,
                  PAGE_CONTENT.commercialization_approach_label,
                )}
                value={commercializationApproach.value[0]}
                hasNoBottomMargin
                labelMarginTop={marginStylingForProjectLabels}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={isActive === -1}
              />,
              false,
            ) || (
              <CheckboxFormGroup
                list={commercializationApproachList}
                label={PAGE_CONTENT.commercialization_approach_label}
                instructionText={PAGE_CONTENT.instruction_text}
                primaryError={commercializationApproach?.error}
                actAsRadioButton
                radioBtnSelectedValue={commercializationRadioBtn}
                formLabelTooltipCustomSx={formLabelTooltipCustomSx}
                handleRadioBtnChange={(event) =>
                  handleCheckboxRadioBtnChange(
                    event,
                    customFieldChangeHandler,
                    setCommercializationApproach,
                    PAGE_CONTENT.commercialization_approach,
                  )
                }
                onClickHandler={(index) =>
                  handleCheckboxValueChange(
                    index,
                    isEdit
                      ? commercializationApproach
                      : commercializationApproachList,
                    setCommercializationApproach,
                    customFieldChangeHandler,
                    PAGE_CONTENT.commercialization_approach,
                  )
                }
                hasTooltipOnItem
                hasTooltipOnLabel
                tooltipContent={
                  PAGE_CONTENT.commercialization_approach_label_tooltip
                }
                gap={STYLE_CONSTANTS.one_rem}
                toolTipNextToLabel
                toolTipBottomMargin={PAGE_CONTENT.toolTipBottomMargin}
                titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                checkboxPadding={checkboxPadding}
                labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                disableAllOptions
              />
            )}

            {commercializationApproach.value[0] === PAGE_CONTENT.offSetting &&
              (checkTernaryCondition(
                isEdit,
                <FieldValueComponent
                  label={PAGE_CONTENT.standards_body_or_registry_label}
                  hasNoBottomMargin
                  value={standardsBodyOrRegistry?.value}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  isDeleted={checkTernaryCondition(
                    isActive === -1,
                    true,
                    false,
                  )}
                  labelMarginTop={
                    marginStylingForProjectLabels
                  }></FieldValueComponent>,
                false,
              ) || (
                <RadioButtonsGroup
                  list={standardsBodyOrRegistryList}
                  label={PAGE_CONTENT.standards_body_or_registry_label}
                  selectedValue={standardsBodyOrRegistry?.value}
                  primaryError={standardsBodyOrRegistry?.error}
                  primaryErrorMessage={
                    PAGE_CONTENT.standards_body_or_registry_error
                  }
                  handleChange={(event) =>
                    handleRadioBtnGroupValueChange(
                      setStandardsBodyOrRegistry,
                      customFieldChangeHandler,
                      PAGE_CONTENT.standars_body_or_registry_field,
                      event.target.value,
                    )
                  }
                  radioBtnGap={STYLE_CONSTANTS.one_rem}
                  formLabelCustomSx={formLabelCustomSx}
                  marginRight={STYLE_CONSTANTS.zero_rem}
                />
              ))}

            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={PAGE_CONTENT.country_label}
                value={selectedCountryValue}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></FieldValueComponent>,
              false,
            ) || (
              <DropDown
                label={PAGE_CONTENT.country_label}
                dropdownlist={countryList}
                isDisabled
                value={selectedCountryValue}
                setDropDownValInParent={(newVal) =>
                  // handleRadioBtnGroupValueChange can be used for any single value updates
                  handleRadioBtnGroupValueChange(
                    setProjectCountryId,
                    customFieldChangeHandler,
                    PAGE_CONTENT.countryIdField,
                    newVal,
                  )
                }
                setStateValue={() => null}
                width={PAGE_CONTENT.countryDropdownWidth}
                height={PAGE_CONTENT.countryDropdownHeight}
                error={projectCountryId?.error}
                errorMessage={PAGE_CONTENT.country_error}
                menuProps={dropdownProps}
                hasNoBottomMargin
                customSx={outlinedDropdownInputSx}
                labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
              />
            )}

            {(isActive === -1 && (
              <FieldValueComponent
                label={PAGE_CONTENT.region_delete_label}
                value={fetchedProjectRegion.slice(1, -1)}
                labelMarginTop={STYLE_CONSTANTS.zero_rem}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></FieldValueComponent>
            )) || (
              <CheckboxFormGroup
                list={projectRegionList}
                label={PAGE_CONTENT.region_label}
                placeholder={PAGE_CONTENT.project_activities_label}
                instructionText={PAGE_CONTENT.instruction_text}
                primaryError={projectRegion.error}
                onClickHandler={(index) => checkBoxClick(index)}
                titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                checkboxPadding={checkboxPadding}
                gap={STYLE_CONSTANTS.one_rem}
                labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                toolTipNextToLabel
                formLabelCustomSx={formLabelCustomSx}
                hasNoBottomMargin
                formLabelTooltipCustomSx={formLabelTooltipCustomSx}
                hasTooltipOnLabel
                tooltipTitle={usRegionsTooltipTitle}
                tooltipContent={usRegionsTooltipContent}
              />
            )}
            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={checkTernaryCondition(
                  isActive === -1,
                  PAGE_CONTENT.project_type_delete_label,
                  PAGE_CONTENT.project_type_label,
                )}
                value={projectTypeList[0]?.itemLabel ?? ''}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></FieldValueComponent>,
              false,
            ) || (
              <RadioButtonsGroup
                list={projectTypeList}
                label={PAGE_CONTENT.project_type_label}
                selectedValue={projectTypeList[0]?.itemLabel}
                direction={PAGE_CONTENT.column}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                radioBtnGap={STYLE_CONSTANTS.one_rem}
                formLabelCustomSx={formLabelCustomSx}
                marginRight={STYLE_CONSTANTS.zero_rem}
                isDisabled
              />
            )}

            {checkTernaryCondition(
              projectType.value === projectTypeList[0].itemLabel,
              (isEdit && (
                <FieldValueComponent
                  label={checkTernaryCondition(
                    isActive === -1,
                    PAGE_CONTENT.afolu_activity_delete_label,
                    PAGE_CONTENT.afolu_activity_delete_label,
                  )}
                  value={afoluActivities?.value}
                  labelMarginTop={marginStylingForProjectLabels}
                  hasNoBottomMargin
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  isListNeeded
                  isDeleted={checkTernaryCondition(
                    isActive === -1,
                    true,
                    false,
                  )}></FieldValueComponent>
              )) || (
                <CheckboxFormGroup
                  stackedView
                  list={projectActivityCategories}
                  label={PAGE_CONTENT.afolu_activity_label}
                  instructionText={PAGE_CONTENT.instruction_text}
                  primaryError={afoluActivities?.error}
                  errorMessage={PAGE_CONTENT.afolu_activity_error}
                  onClickHandler={(index) =>
                    handleCheckboxValueChange(
                      index,
                      isEdit ? afoluActivities : afoluActivityList,
                      setAfoluActivities,
                      customFieldChangeHandler,
                      PAGE_CONTENT.afolu_activities,
                    )
                  }
                  titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                  checkboxPadding={checkboxPadding}
                  gap={STYLE_CONSTANTS.one_rem}
                  labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                />
              ),
              null,
            )}

            {(isActive === -1 && (
              <FieldValueComponent
                label={PAGE_CONTENT.project_activities_delete_label}
                value={projectActivities?.value}
                labelMarginTop={STYLE_CONSTANTS.zero_rem}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(isActive === -1, true, false)}
                isListNeeded></FieldValueComponent>
            )) || (
              <AutoCompleteForm
                label={PAGE_CONTENT.project_activities_label}
                instructionText={PAGE_CONTENT.instruction_text}
                value={projectActivities?.value}
                valueSetter={(value) => {
                  if (value?.length === 0) {
                    scroll(PAGE_CONTENT.error_class_name);
                  }
                  handleAutocompleteChange(
                    setProjectActivities,
                    customFieldChangeHandler,
                    PAGE_CONTENT.projectActivities,
                    value,
                  );
                  setIsFormEdited(true);
                }}
                list={activitiesList.map((activity) => activity.label)}
                primaryError={projectActivities.error}
                placeholder={PAGE_CONTENT.project_activities_placeholder}
                formValue={formValue.projectActivities}
                onClickHandler={() => {}}
                customSx={autoCompleteSx(
                  formValue.projectActivities?.length > 0,
                )}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                labelGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                hasNewStyles
                onBlurHandler={() => {
                  scroll(PAGE_CONTENT.error_class_name);
                }}
              />
            )}

            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={checkTernaryCondition(
                  isActive === -1,
                  PAGE_CONTENT.quantification_approach_delete_label,
                  PAGE_CONTENT.quantification_approach_label,
                )}
                value={quantificationApproachList[0].itemLabel}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={isActive === -1}
              />,
              false,
            ) || (
              <CheckboxFormGroup
                list={quantificationApproachList}
                label={PAGE_CONTENT.quantification_approach_label}
                instructionText={PAGE_CONTENT.quantification_instruction}
                instructionTextStyles={instructionTextStyles}
                onClickHandler={() => {
                  /* do nothing here */
                }}
                hasTooltipOnItem
                toolTipNextToLabel
                toolTipBottomMargin={PAGE_CONTENT.toolTipBottomMargin}
                titleAndInfoGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                checkboxPadding={checkboxPadding}
                gap={STYLE_CONSTANTS.one_rem}
                labelMarginTop={STYLE_CONSTANTS.one_point_five_rem}
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                disableAllOptions
              />
            )}
            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={PAGE_CONTENT.quantification_method_delete_label}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                value={quantificationMethod?.value}
                isDeleted={isActive === -1}
              />,
              false,
            ) || (
              <RadioButtonsGroup
                list={quantificationMethodList}
                label={PAGE_CONTENT.quantification_method_label}
                selectedValue={quantificationMethod?.value}
                direction={PAGE_CONTENT.column}
                formLabelTooltipCustomSx={formLabelTooltipCustomSx}
                tooltipContent={
                  PAGE_CONTENT.quantification_method_label_tooltip
                }
                hasTooltipOnItem
                hasTooltipOnLabel
                primaryError={quantificationMethod?.error}
                handleChange={(event) =>
                  handleRadioBtnGroupValueChange(
                    setQuantificationMethod,
                    customFieldChangeHandler,
                    PAGE_CONTENT.quantification_method,
                    event.target.value,
                  )
                }
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                radioBtnGap={STYLE_CONSTANTS.one_rem}
                formLabelCustomSx={formLabelCustomSx}
                marginRight={STYLE_CONSTANTS.zero_rem}
              />
            )}

            {checkTernaryCondition(
              isEdit,
              <FieldValueComponent
                label={PAGE_CONTENT.quantification_model_delete_label}
                value={quantificationModel?.value}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></FieldValueComponent>,
              false,
            ) || (
              <RadioButtonsGroup
                list={quantificationModelList}
                formLabelTooltipCustomSx={formLabelTooltipCustomSx}
                label={PAGE_CONTENT.quantification_model_label}
                isDisabled
                selectedValue={quantificationModel?.value}
                tooltipContent={PAGE_CONTENT.quantification_model_label_tooltip}
                hasTooltipOnLabel
                primaryError={quantificationModel?.error}
                primaryErrorMessage={PAGE_CONTENT.quantification_model_error}
                handleChange={(event) =>
                  handleRadioBtnGroupValueChange(
                    setQuantificationModel,
                    customFieldChangeHandler,
                    PAGE_CONTENT.quantification_model,
                    event.target.value,
                  )
                }
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
              />
            )}

            {isDeleteAndNotEdit && (
              <RadioButtonsGroup
                list={monitoringOptions}
                label={PAGE_CONTENT.mrv_label}
                selectedValue={modelMeasuringOption.value}
                direction={PAGE_CONTENT.row}
                handleChange={(event) =>
                  handleMVROptionChange(event, customFieldChangeHandler)
                }
                primaryError={modelMeasuringOption.error}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                radioBtnGap={STYLE_CONSTANTS.one_rem}
                formLabelCustomSx={formLabelCustomSx}
                marginRight={STYLE_CONSTANTS.zero_rem}
              />
            )}
            {isEdit && modelMeasuringOption.value === PAGE_CONTENT.yes && (
              <FieldValueComponent
                label={PAGE_CONTENT.edit_mrv_technology_label}
                value={PAGE_CONTENT.habiterre}
                labelMarginTop={marginStylingForProjectLabels}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(isActive === -1, true, false)}
              />
            )}
            {!isEdit && (
              <RadioButtonsGroup
                list={mvrTechnologyList}
                label={PAGE_CONTENT.mrv_technology_label}
                selectedValue={checkTernaryCondition(
                  modelMeasuringOption.value === PAGE_CONTENT.yes,
                  PAGE_CONTENT.habiterre,
                  null,
                )}
                direction={PAGE_CONTENT.row}
                isDisabled={ternaryBooleanHandler(
                  modelMeasuringOption.value !== PAGE_CONTENT.yes,
                )}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
              />
            )}

            {/* Validation and verification part */}

            {isActive === -1 &&
              validationAndVerificationAuditor?.value !== '' && (
                <FieldValueComponent
                  label={PAGE_CONTENT.vvAuditor_edit_label}
                  value={validationAndVerificationAuditor?.value}
                  labelMarginTop={STYLE_CONSTANTS.zero_rem}
                  hasNoBottomMargin
                  labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  isDeleted={checkTernaryCondition(
                    isActive === -1,
                    true,
                    false,
                  )}></FieldValueComponent>
              )}
            {isActive !== -1 && (
              <RadioButtonsGroup
                list={validationAndVerificationYesOrNoList}
                label={PAGE_CONTENT.validation_and_verification_yes_or_no_label}
                selectedValue={validationAndVerificationYesOrNo?.value}
                tooltipContent={
                  PAGE_CONTENT.validation_and_verification_auditor_label_tooltip
                }
                hasTooltipOnLabel
                primaryError={validationAndVerificationYesOrNo?.error}
                formLabelTooltipCustomSx={formLabelTooltipCustomSx}
                handleChange={(event) => {
                  handleRadioBtnGroupValueChange(
                    setValidationAndVerificationYesOrNo,
                    customFieldChangeHandler,
                    PAGE_CONTENT.validation_and_verification_yes_or_no_field,
                    event.target.value,
                  );
                  setIsFormEdited(true);
                }}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                radioBtnGap={STYLE_CONSTANTS.one_rem}
                formLabelCustomSx={formLabelCustomSx}
                marginRight={STYLE_CONSTANTS.zero_rem}
              />
            )}
            {isActive !== -1 &&
              validationAndVerificationYesOrNo?.value === PAGE_CONTENT.yes && (
                <RadioButtonsGroup
                  list={validationAndVerificationAuditorsList}
                  label={PAGE_CONTENT.validation_and_verification_auditor_label}
                  selectedValue={validationAndVerificationAuditor?.value}
                  hasTooltipOnLabel={false}
                  primaryError={validationAndVerificationAuditor?.error}
                  handleChange={(event) =>
                    handleRadioBtnGroupValueChange(
                      setValidationAndVerificationAuditor,
                      customFieldChangeHandler,
                      PAGE_CONTENT.validation_and_verification_auditor_field,
                      event.target.value,
                    )
                  }
                  marginTop={checkTernaryCondition(
                    isEdit,
                    '0.8rem',
                    STYLE_CONSTANTS.one_point_five_rem,
                  )}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  labelFontSize={STYLE_CONSTANTS.input_font_size}
                  radioBtnPadding={radioBtnPadding}
                  radioBtnGap={STYLE_CONSTANTS.one_rem}
                  formLabelCustomSx={formLabelCustomSx}
                  marginRight={STYLE_CONSTANTS.zero_rem}
                />
              )}

            {isActive !== -1 && (
              <RadioButtonsGroup
                list={yesOrNoList}
                label={PAGE_CONTENT.climate_program_enrollment_yes_or_no_label}
                selectedValue={climateProgramEnrollmentYesOrNo?.value}
                primaryError={climateProgramEnrollmentYesOrNo?.error}
                handleChange={(event) => {
                  handleRadioBtnGroupValueChange(
                    setClimateProgramEnrollmentYesOrNo,
                    customFieldChangeHandler,
                    PAGE_CONTENT.climate_program_enrollment_yes_or_no_field,
                    event.target.value,
                  );
                  setIsFormEdited(true);
                }}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                labelFontSize={STYLE_CONSTANTS.input_font_size}
                radioBtnPadding={radioBtnPadding}
                radioBtnGap={STYLE_CONSTANTS.one_rem}
                formLabelCustomSx={formLabelCustomSx}
                marginRight={STYLE_CONSTANTS.zero_rem}
              />
            )}

            {isActive !== -1 &&
              climateProgramEnrollmentYesOrNo?.value === PAGE_CONTENT.yes && (
                <AutoCompleteForm
                  label={PAGE_CONTENT.climate_programs_label}
                  instructionText={PAGE_CONTENT.instruction_text}
                  value={climatePrograms?.value}
                  valueSetter={(value) => {
                    handleAutocompleteChange(
                      setClimatePrograms,
                      customFieldChangeHandler,
                      PAGE_CONTENT.climate_programs_field,
                      value,
                    );
                    setIsFormEdited(true);
                  }}
                  list={climateProgramsList.map(
                    (climateProgram) => climateProgram.climateProgramName,
                  )}
                  primaryError={climatePrograms.error}
                  placeholder={
                    climatePrograms?.value.length === 0 &&
                    PAGE_CONTENT.climate_programs_placeholder
                  }
                  helperText={PAGE_CONTENT.climate_programs_error}
                  formValue={formValue.climatePrograms}
                  onClickHandler={() => {}}
                  customSx={autoCompleteSx(
                    formValue.climatePrograms?.length > 0,
                  )}
                  marginTop={STYLE_CONSTANTS.one_point_five_rem}
                  labelGap={STYLE_CONSTANTS.zero_point_two_five_rem}
                  marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                  hasNewStyles
                />
              )}

            {isActive === -1 && (
              <FieldValueComponent
                label={PAGE_CONTENT.climate_programs_delete_view_label}
                value={climatePrograms?.value}
                labelMarginTop={STYLE_CONSTANTS.zero_rem}
                hasNoBottomMargin
                labelMarginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                isDeleted={checkTernaryCondition(isActive === -1, true, false)}
                isListNeeded></FieldValueComponent>
            )}
            {(isActive === -1 && (
              <TagFieldValueComponent
                label={PAGE_CONTENT.tags_delete_label}
                value={formValue?.tags}
                isDeleted={checkTernaryCondition(
                  isActive === -1,
                  true,
                  false,
                )}></TagFieldValueComponent>
            )) || (
              <TagComponent
                label={PAGE_CONTENT.tags}
                tags={tags}
                handleNewTags={(event) => {
                  addTagsHandler(event, formValue, customFieldChangeHandler);
                  setIsFormEdited(true);
                }}
                formValue={formValue}
                customFieldChangeHandler={customFieldChangeHandler}
                value={formValue?.tags}
                viewMode={checkTernaryCondition(isActive === -1, true, false)}
                setIsFormEdited={setIsFormEdited}
                flexDirection={'row'}
                marginTop={STYLE_CONSTANTS.one_point_five_rem}
                marginBottom={STYLE_CONSTANTS.zero_point_two_five_rem}
                gap={STYLE_CONSTANTS.zero_point_two_five_rem}
                customSx={autoCompleteSx(formValue?.tags?.length > 0)}
              />
            )}
            {isActive !== -1 && (
              <ButtonWrapper>
                <Button
                  size={FORM_CONTENT.medium}
                  sx={tertiaryButtonStyle}
                  onClick={() => {
                    navigate(-1);
                  }}>
                  {PAGE_CONTENT.cancel_button_label}
                </Button>
                <Button
                  variant={FORM_CONTENT.contained}
                  size={FORM_CONTENT.medium}
                  sx={primaryButtonStyle()}
                  onClick={() => handleFormSubmit(isEdit, formValue)}
                  disabled={isEdit && !isFormEdited}
                  disableElevation>
                  {checkTernaryCondition(
                    isEdit,
                    PAGE_CONTENT.save_button_label,
                    PAGE_CONTENT.submit_button_label,
                  )}
                </Button>
              </ButtonWrapper>
            )}
          </FormSection>
        </FormWrapper>
      </MainWrapper>
    </>
  );
};

AddOriginationProject.propTypes = {
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};
export default AddOriginationProject;
