import { DARK_CHARCOAL, RED } from 'theme/GlobalColors';
import { CLIENT_TYSON_EMAIL } from 'utils/config';
import { checkTernaryCondition } from 'utils/helper';

export const PAGE_CONTENT = {
  project_activities: 'Project activities:',
  field_acres_text:
    'Implemented on {{noOfFields}} fields for a total of {{acresCount}} acres.',
  list_proof_of_implementation_label_type1:
    'Complete proof of implementation for at least one of the following:',
  list_proof_of_implementation_label_type2:
    'Complete proof of implementation for the following:',
  dropzone_info_text1:
    'Drag and drop .jpeg, .png, .tiff, or .gif file(s) here or ',
  dropzone_info_text2:
    'Drag and drop .jpeg .png, .pdf, .xls, or .doc file(s) here or ',
  cover_crop_dropzone1_label:
    'Submit an image for each field where cover crops were implemented.',
  success: 'success',
  success_msg: 'Form details saved successfully.',
  error: 'error',
  error_msg:
    'Form details failed to save due to technical error. Please try again.',
  cover_crop_dropzone2_label:
    'Submit an image or PDF of purchase receipts/invoices for cover crop seeds. ',
  cover_crop_dropzone3_label:
    'Submit an image, PDF, or doc of a 578 with cover crop plan. ',
  residue_dropzone1_label:
    'Submit an image for each field at the time of planting.',
  nutrient_dropzone1_label:
    'Submit an image or PDF of the nutrient management log for all fields.',
  nutrient_dropzone2_label:
    'Submit an image or PDF for the nutrient management plan and/or fertilizer recommendations for all fields written and signed by a crop consultant.',
  fifty_mb: 1024 * 1024 * 50,
  hundred_mb: 1024 * 1024 * 100,
  fieldDropdown: 'field',
  practiceTypeDropdown: 'practice type',
  soil_carbon_dropzone1_label:
    'Submit an image or PDF of purchase receipts/invoices for basalt, biochar or humic acid for all fields.',
  soil_carbon_dropzone2_label:
    'Submit an image or PDF of the soil carbon amendment management plan and / or soil carbon amendment recommendations for all fields written and signed by a crop consultant.',
  reciepts_invoice_mgmt_plan_dropzone_info_text:
    'Drag and drop .jpeg .png, .pdf, .xls, or .doc file(s) here or',
  edge_of_field_dropzone1_label:
    'Submit an image for each field where edge of field practices were implemented.',
  edge_of_field_dropzone2_label:
    'Submit an image or PDF of the building or engineering plan for all fields.',
  field_and_practice_mapping_error_msg:
    'There are field(s) added to implemented activities which require proof of implementation.',
  poiImageOptionTooltipText: `If you are pursing photo uploads for POI, email <Tyson Support Email> to learn how many photos need to be uploaded for each activity`,
  info_test_implemented_activities:
    'Implemented activities must be completed for all fields associated with the farm before you may start adding the proof of implementation. ',
  in_progress: 'In Progress',
  completed: 'Completed',
  not_started: 'Not Started',
  annual_cost_per_acre: 'Estimated implementation cost ($)',
  per_acre: 'per acre',
  rejected: 'Rejected',
  farm: 'Farm',
};
export const tabProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `proof-of-implmentation-tab-${index}`,
  };
};

export const geoReferenceImageAcceptedFilesInfo = {
  fileNotSupportedErrorText:
    'Please upload a file in .jpeg, .png, .tiff, or .gif format.',
  acceptProps: {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/tiff': ['.tiff'],
    'image/gif': ['.gif'],
  },
  maxFileSize: 1024 * 1024 * 10,
};

export const recieptsInvoiceAndMgmtPlanAcceptedFilesInfo = {
  fileNotSupportedErrorText:
    'Please upload a file in .jpeg .png, .pdf, .xls, or .doc format.',
  acceptProps: {
    'application/pdf': ['.pdf'],
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'application/vnd.ms-excel': ['.xls'],
    'application/msword': ['.doc'],
  },
  maxFileSize: 1024 * 1024 * 10,
};

export const possibleFileTypes = [
  { type: 1, name: 'Geo-referenced image' },
  { type: 2, name: 'Receipt / invoice' },
  { type: 3, name: 'Management plan' },
  { type: 4, name: 'Management log' },
  { type: 5, name: 'Geo-referenced image (Manual)' },
];
export const aggregateObjects = (arr) => {
  const groupedData = arr.reduce((acc, curr) => {
    if (!acc[curr.projectActivityCategory]) {
      acc[curr.projectActivityCategory] = {
        projectActivityId: curr.projectActivityId,
        projectActivityCategory: curr.projectActivityCategory,
        implementedOn: curr.implementedOn,
        implementedAcres: curr.implementedAcres,
        fieldsOfActivity: [],
        practiceTypeList: [],
      };
    } else {
      acc[curr.projectActivityCategory].implementedOn += curr.implementedOn;
      acc[curr.projectActivityCategory].implementedAcres +=
        curr.implementedAcres;
    }

    const practiceType = {
      label: curr.projectActivityType,
      value: curr.projectActivityId,
    };

    acc[curr.projectActivityCategory].practiceTypeList.push(practiceType);
    acc[curr.projectActivityCategory].fieldsOfActivity.push(
      ...curr.fieldsOfActivity.filter((field) => field !== null),
    );

    return acc;
  }, {});

  const convertedData = Object.values(groupedData);

  return convertedData;
};
export const projectActivitiesLabels = [
  {
    activityId: 1,
    title: 'Cover crop',
  },
  {
    activityId: 2,
    title: 'Residue + tillage management',
  },
  {
    activityId: 3,
    title: 'Nutrient management',
  },
  {
    activityId: 4,
    title: 'Edge of field',
  },
  {
    activityId: 5,
    title: 'Soil carbon amendment',
  },
];

export const formatPoiData = (data) => {
  return data?.map((activity) => ({
    ...activity,
    fieldsOfActivity: activity?.fieldsOfActivity?.map((field) => ({
      label: field.value,
      value: field.id,
    })),
  }));
};

export const formatSubmitData = (
  data,
  projectId,
  farmId,
  activityIds,
  activityCategory,
  activityStatus,
  participantId,
  annualImplementationCost,
) => {
  if (!data || data.length === 0) {
    return {
      proofs: [
        {
          poiFileId: 0,
          fileType: null,
          fileName: null,
          fieldIds: null,
          activityIds,
          activityCategory,
          activityStatus: annualImplementationCost
            ? PAGE_CONTENT.in_progress
            : PAGE_CONTENT.not_started,
        },
      ],
      estimatedActivityImplementationCost: annualImplementationCost
        ?.substring(1)
        ?.trim(),
    };
  }
  const proofs = data?.map((file) => ({
    poiFileId: Number(file.fileId),
    fileType: file.fileType,
    fileName: file.fileName,
    fieldIds: file.fields,
    activityIds: checkTernaryCondition(
      activityCategory === projectActivitiesLabels[0].title ||
        activityCategory === projectActivitiesLabels[2].title,
      activityIds,
      file.practice,
    ),
    activityCategory: activityCategory,
    activityStatus: activityStatus,
  }));

  return {
    proofs,
    estimatedActivityImplementationCost: annualImplementationCost
      ?.substring(1)
      ?.trim(),
  };
};

export const createFieldActivityMapping = (data) => {
  const mapping = data.reduce((acc, activity) => {
    activity.fieldsOfActivity.forEach((field) => {
      if (acc[field.value]) {
        acc[field.value].push(activity.projectActivityId);
      } else {
        acc[field.value] = [activity.projectActivityId];
      }
    });
    return acc;
  }, {});

  return mapping;
};

export const findIfAllFieldsAndActivitiesMapped = (
  fieldWithActivityIdsMapping,
  currentTabData,
) => {
  const fieldMapping = { ...fieldWithActivityIdsMapping };

  for (const file of currentTabData) {
    for (const fieldId of file.fields) {
      if (fieldId > 0) {
        fieldMapping[fieldId] = fieldMapping[fieldId]?.filter(
          (practiceId) => !file.practice.includes(practiceId),
        );
      }
    }
  }

  return Object.values(fieldMapping).every(
    (practices) => practices?.length === 0,
  );
};

const activityHeadingToCategoryMapping = {
  'Residue + tillage management': 'Residue + tillage management',
  'Cover crop': 'Cover crop',
  'Edge of field': 'Edge of field practices',
  'Nutrient management': 'Nutrient management',
  'Soil carbon amendment': 'Soil carbon amendment',
};

export const getActivityCategory = (activityHeading) =>
  activityHeadingToCategoryMapping[activityHeading] ?? activityHeading;

const getAcresAndFieldsStyle = (isError) => ({
  color: checkTernaryCondition(isError, RED, DARK_CHARCOAL),
  fontWeight: 600,
});

export const getFieldAcresText = (isError, tabData) => {
  return (
    <div style={{ color: DARK_CHARCOAL }}>
      Implemented on{' '}
      <span style={getAcresAndFieldsStyle(isError)}>
        {tabData?.implementedOn}
      </span>{' '}
      fields for a total of{' '}
      <span style={getAcresAndFieldsStyle(isError)}>
        {tabData?.implementedAcres}
      </span>{' '}
      acres.
    </div>
  );
};

const sectionHeadingMapping = {
  'Reduce tillage': 'Residue + tillage management',
  'Improve crop planting and harvesting': 'Cover crop',
  'Edge of field practices': 'Edge of field',
  'Improve residue management': 'Nutrient management',
  'Improve soil amendments application': 'Soil carbon amendment',
};

export const getSectionHeading = (sectionName) =>
  sectionHeadingMapping[sectionName] ?? sectionName;

export const residueTillagePractices = ['Reduced tillage', 'No tillage'];

export const POI_UNSAVED_CHANGES_MODAL_INITIAL_STATE = {
  open: false,
  nextTab: null,
};

export const clientEmailKey = {
  TYSON_EMAIL_VALUE: '<Tyson Support Email>',
};

/**
 * Replaces the placeholder '<Tyson Support Email>' in the tooltip text with the actual email address from client configurations.
 *
 * @param {Array} clientConfigs - Array of client configuration objects, each containing a 'key' and 'value'.
 * @returns {string} - The tooltip text with the email address replaced.
 */

export function replaceToolTipEmail(clientConfigs) {
  const emailAddress = clientConfigs.find(
    (config) => config.key === CLIENT_TYSON_EMAIL,
  )?.value;

  return checkTernaryCondition(
    emailAddress,
    PAGE_CONTENT.poiImageOptionTooltipText.replaceAll(
      clientEmailKey.TYSON_EMAIL_VALUE,
      emailAddress,
    ),
    PAGE_CONTENT.poiImageOptionTooltipText,
  );
}
