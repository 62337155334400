import {
  FlexWrapper,
  InfoSx,
  InfoTooltipWrapper,
  NpkFieldWrapper,
  PercentageWrapper,
  practiceTypeDeleteSx,
  radioBtbLabelSx,
  StyledInput,
  TooltipSx,
} from '../NitrogentFertilizerModal.style';
import {
  FieldSectionWrapper,
  AddOptionWrapper,
  TextWrapper,
  TooltipAndErrorWrapper,
} from '../../Modals.style';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import {
  formFieldNames,
  hasInhibitorList,
  MODAL_CONTENT,
} from '../NitrogenFertilizerModal.content';
import DateItemComp from '../DateItem.react';
import { addZeroBeforeDot, checkTernaryCondition, isEmpty } from 'utils/helper';
import {
  BLACK,
  COOL_GRAY,
  FARM_FIELD_LABEL_COLOR,
  GLOBAL_BLUE,
  RED,
  TEXT_HOVER_COLOR,
} from 'theme/GlobalColors';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonsGroup from 'components/FormComponents/RadioButtonGroup/RadioButtonGroup.react';
import InputField from 'components/FormComponents/InputField/InputField.react';
import {
  checkApplicationRateValues,
  zeroString,
} from '../NitrogenFertilizerWrapper/NitrogenFertilizerWrapper.content';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from 'components/LightTooltip';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { radioBtnPadding } from 'pages/ParticipantDataCollection/ParticipantGeneralFarmInfo/ParticipantGeneralFarmInfo.style';
import {
  FocusedInputSx,
  FormLabels,
} from 'components/FormComponents/FormStyles';
import { INPUT_CATEGORY, TOOLTIP_PLACEMENTS } from 'utils/config';
import { implementedActivitiesWithOpsDataContext } from 'contextAPI/implementedActivitiesWithOpsDataContext';
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as AlertIcon } from 'assets/icons/AlertIcon.svg';
import { MODAL_CONTENT_PERCENT } from './NitrogenFertilizerContent.content';

const NitrogenFertilizerContent = ({
  fertilizertypeDropdownProps,
  noDataOptionSelected,
  fertilizerTypeDropDown,
  formValue,
  animalTypeDropdownProps,
  manureTypeDropdownProps,
  datesAppliedArray,
  dateAppliedPickerProps,
  setDateAppliedArray,
  customFieldChangeHandler,
  dateRequiedList,
  setDateRequiedList,
  isInTesting,
  setDuplicateDate,
  duplicatedDate,
  setOnHoverDateApplied,
  onHoverDateApplied,
  errorState,
  setErrorState,
  dateError,
  applicationMethodDropdownProps,
  unitOfMeasurementDropdownProps,
  mineralPercentageArray,
  inputsDisabled = false,
  deleteHandler,
  disableAllFieldActions = false,
  isCombinationUnique = true,
}) => {
  const { isFieldDisabled } = useContext(
    implementedActivitiesWithOpsDataContext,
  );
  return (
    <>
      <FieldSectionWrapper
        style={{ overflow: 'hidden' }}
        columnGap="1rem"
        marginBottom="0"
        alignItems="flex-end">
        <DropDown {...fertilizertypeDropdownProps} />
        {inputsDisabled && !disableAllFieldActions && (
          <DeleteIcon
            sx={practiceTypeDeleteSx}
            onClick={() => deleteHandler()}
          />
        )}
      </FieldSectionWrapper>
      {!noDataOptionSelected && (
        <>
          {fertilizerTypeDropDown?.filter(
            (item) => item?.value === formValue?.fertilizerType,
          )[0]?.label === MODAL_CONTENT.manure_label && (
            <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
              <DropDown {...animalTypeDropdownProps} />
              <DropDown {...manureTypeDropdownProps} />
            </FieldSectionWrapper>
          )}
          <FieldSectionWrapper direction="column" marginBottom="0">
            {datesAppliedArray?.map((item, index) => {
              const keyVal = index;
              return (
                <DateItemComp
                  key={keyVal}
                  keyVal={keyVal}
                  dateAppliedPickerProps={(id) =>
                    dateAppliedPickerProps(id, item)
                  }
                  datesAppliedArray={datesAppliedArray}
                  setDateAppliedArray={setDateAppliedArray}
                  item={item}
                  index={index}
                  customFieldChangeHandler={customFieldChangeHandler}
                  dateRequiedList={dateRequiedList}
                  setDateRequiedList={setDateRequiedList}
                  isInTesting={isInTesting}
                  marginBottomForDatePicker={'0.25rem'}
                  setDuplicateDate={setDuplicateDate}
                  duplicatedDate={duplicatedDate}
                  showDeleteIcon={!inputsDisabled}
                />
              );
            })}
            {!inputsDisabled && (
              <AddOptionWrapper
                onMouseEnter={() => {
                  setOnHoverDateApplied(true);
                }}
                onMouseLeave={() => {
                  setOnHoverDateApplied(false);
                }}
                onClick={() => {
                  setDateAppliedArray([...datesAppliedArray, null]);
                  customFieldChangeHandler([...formValue.datesApplied, null]);
                }}>
                <TextWrapper
                  color={checkTernaryCondition(
                    onHoverDateApplied,
                    TEXT_HOVER_COLOR,
                    GLOBAL_BLUE,
                  )}
                  fontSize="0.875rem"
                  gap="0.25rem">
                  <AddIcon
                    fontSize="small"
                    gap="0.25rem"
                    sx={{
                      '&:hover': {
                        fill: onHoverDateApplied && TEXT_HOVER_COLOR,
                      },
                    }}
                  />
                  {MODAL_CONTENT.date_applied}
                </TextWrapper>
              </AddOptionWrapper>
            )}
            {dateError.flag && !inputsDisabled && (
              <TextWrapper color={RED} fontSize="0.75rem">
                {MODAL_CONTENT.date_error_label}
              </TextWrapper>
            )}
            {duplicatedDate.flag && (
              <TextWrapper color={RED} position="relative" fontSize="0.75rem">
                {MODAL_CONTENT.duplicate_date_error}
              </TextWrapper>
            )}
          </FieldSectionWrapper>
          <RadioButtonsGroup
            headerColor={FARM_FIELD_LABEL_COLOR}
            list={hasInhibitorList}
            label={MODAL_CONTENT.contains_inhibitor_label}
            direction="row"
            labelFontSize="0.875rem"
            handleChange={(event) => {
              customFieldChangeHandler(
                formFieldNames.hasInhibitor,
                event.target.value,
              );
              setErrorState({
                ...errorState,
                hasInhibitor: isEmpty(event.target.value),
              });
            }}
            marginBottom={'0.25rem'}
            marginTop={'1.5rem'}
            checkedIsDarkGreen
            selectedValue={formValue.hasInhibitor}
            primaryError={errorState.hasInhibitor}
            primaryErrorMessage={''}
            isDisabled={inputsDisabled}
            marginRight={'0'}
            radioBtnPadding={radioBtnPadding}
            validationError={!isCombinationUnique}
            formLabelCustomSx={radioBtbLabelSx}
          />
          <FieldSectionWrapper columnGap="1.5rem" marginBottom="0">
            <DropDown {...applicationMethodDropdownProps} />
            <InputField
              required
              headerColor={FARM_FIELD_LABEL_COLOR}
              inputTextFieldColor={COOL_GRAY}
              isDisabled={isFieldDisabled || disableAllFieldActions}
              label={MODAL_CONTENT.application_rate_label}
              name={formFieldNames.applicationRate}
              value={formValue.applicationRate}
              category="long-numbers-field"
              customInputSx={FocusedInputSx}
              onUpdate={(e) => {
                if (
                  e.target.value.match(/^\d*(\.\d{0,2})?$/) ||
                  e.target.value === '' ||
                  zeroString.indexOf(e.target.value) !== -1
                ) {
                  customFieldChangeHandler(
                    formFieldNames.applicationRate,
                    addZeroBeforeDot(e.target.value),
                  );
                  setErrorState({
                    ...errorState,
                    applicationRate: checkApplicationRateValues(e.target.value),
                  });
                }
              }}
              placeholder={MODAL_CONTENT.application_rate_placeholder}
              primaryError={errorState.applicationRate}
              primaryErrorMessage={''}
              maxLength={100}
              minWidth="max-content"
              width="3.75rem"
              rightSideLabelFormLabelMargin={'0'}
              labelMarginTop={'1.5rem'}
              labelMarginBottom={'0.25rem'}
            />
            <DropDown {...unitOfMeasurementDropdownProps} />
          </FieldSectionWrapper>
          {errorState.applicationTiming && (
            <TextWrapper color={RED} fontSize="0.75rem">
              {MODAL_CONTENT.application_timing_error}
            </TextWrapper>
          )}
          {[5, 10].includes(formValue.fertilizerType) && (
            <>
              {formValue.fertilizerType !== '' &&
                formValue.fertilizerType !== 21 && (
                  <FormLabels marginTop="1.5rem" marginBottom="0.25rem">
                    <TextWrapper
                      color={FARM_FIELD_LABEL_COLOR}
                      fontSize="0.875rem"
                      gap="1rem"
                      alignItems="center">
                      {MODAL_CONTENT.npk_label}
                      <TooltipAndErrorWrapper>
                        {(formValue.fertilizerType === 5 ||
                          formValue.fertilizerType === 10) && (
                          <LightTooltip
                            placement={TOOLTIP_PLACEMENTS.BOTTOM_END}
                            arrow
                            sx={TooltipSx}
                            title={
                              <InfoTooltipWrapper>
                                {MODAL_CONTENT.tooltip_content}
                              </InfoTooltipWrapper>
                            }>
                            <InfoOutlinedIcon sx={InfoSx} />
                          </LightTooltip>
                        )}
                        {mineralPercentageArray.some((item) => item.error) && (
                          <AlertIcon />
                        )}
                      </TooltipAndErrorWrapper>
                    </TextWrapper>
                  </FormLabels>
                )}
              <FlexWrapper gap={'0.5rem'}>
                <FlexWrapper style={{ height: '30px' }}>
                  {formValue.fertilizerCategory === MODAL_CONTENT.synthetic &&
                    mineralPercentageArray.slice(0, 3).map((item, index) => {
                      const keyVal = index;
                      return (
                        <FieldSectionWrapper
                          marginBottom={'0'}
                          columnGap="1.5rem"
                          style={{ height: '30px' }}
                          key={keyVal}>
                          <NpkFieldWrapper>
                            <StyledInput>
                              <InputField
                                inputTextFieldColor={COOL_GRAY}
                                width="70%"
                                required
                                label={item.label}
                                labelOnLeftSide={true}
                                name={item.name}
                                value={item.value}
                                isDisabled={item.disabled || isFieldDisabled}
                                onUpdate={(e) => {
                                  if (
                                    (e.target.value.match(
                                      /^\d*(\.\d{0,2})?$/,
                                    ) ||
                                      e.target.value === '') &&
                                    e.target.value !== '.'
                                  ) {
                                    const newErrorState = { ...errorState };
                                    newErrorState[item.name] =
                                      e.target.value === '';
                                    const value = checkTernaryCondition(
                                      e.target.value[0] === '.',
                                      `0${e.target.value}`,
                                      e.target.value,
                                    );
                                    customFieldChangeHandler(item.name, value);
                                    setErrorState(newErrorState);
                                  }
                                }}
                                placeholder={MODAL_CONTENT_PERCENT}
                                primaryErrorMessage={''}
                                maxLength={100}
                                minWidth="0"
                                textDisabledColor={checkTernaryCondition(
                                  item.disabled,
                                  BLACK,
                                  '',
                                )}
                                rightSideLabelFormLabelMargin={'0.35rem'}
                                category={INPUT_CATEGORY.PERCENT}
                              />
                            </StyledInput>
                            <PercentageWrapper>
                              {MODAL_CONTENT_PERCENT}
                            </PercentageWrapper>
                          </NpkFieldWrapper>
                        </FieldSectionWrapper>
                      );
                    })}
                </FlexWrapper>
              </FlexWrapper>
              {formValue.fertilizerCategory === MODAL_CONTENT.synthetic &&
                mineralPercentageArray.slice(0, 3).map((item, index) => {
                  const keyVal = index;
                  if (item.error) {
                    return (
                      <TextWrapper key={keyVal} color={RED} fontSize="0.75rem">
                        {item.error_message}
                      </TextWrapper>
                    );
                  }
                })}
            </>
          )}
        </>
      )}
    </>
  );
};

export default NitrogenFertilizerContent;

NitrogenFertilizerContent.propTypes = {
  fertilizertypeDropdownProps: PropTypes.object,
  noDataOptionSelected: PropTypes.bool,
  fertilizerTypeDropDown: PropTypes.array,
  formValue: PropTypes.shape({
    fertilizerType: PropTypes.number,
    datesApplied: PropTypes.array,
    hasInhibitor: PropTypes.string,
    applicationRate: PropTypes.string,
    fertilizerCategory: PropTypes.string,
    syntheticMineralPercentage: PropTypes.array,
    organicMineralPercentage: PropTypes.array,
    manureType: PropTypes.string,
    animalType: PropTypes.string,
    applicationMethod: PropTypes.string,
    unitOfMeasurement: PropTypes.string,
  }),
  animalTypeDropdownProps: PropTypes.shape({
    options: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
  }),
  manureTypeDropdownProps: PropTypes.shape({
    options: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
  }),
  datesAppliedArray: PropTypes.shape({
    date: PropTypes.string,
    id: PropTypes.string,
  }),
  dateAppliedPickerProps: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.string,
    dateError: PropTypes.bool,
    isInTesting: PropTypes.bool,
    setDuplicateDate: PropTypes.func,
    duplicatedDate: PropTypes.object,
    setOnHoverDateApplied: PropTypes.func,
    onHoverDateApplied: PropTypes.bool,
    customFieldChangeHandler: PropTypes.func,
    dateRequiedList: PropTypes.array,
    setDateRequiedList: PropTypes.func,
    setErrorState: PropTypes.func,
    errorState: PropTypes.object,
  }),
  setDateAppliedArray: PropTypes.func,
  customFieldChangeHandler: PropTypes.func,
  dateRequiedList: PropTypes.array,
  setDateRequiedList: PropTypes.func,
  isInTesting: PropTypes.bool,
  setDuplicateDate: PropTypes.func,
  duplicatedDate: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  setOnHoverDateApplied: PropTypes.func,
  onHoverDateApplied: PropTypes.bool,
  errorState: PropTypes.shape({
    applicationRate: PropTypes.bool,
    hasInhibitor: PropTypes.bool,
    applicationTiming: PropTypes.bool,
  }),
  setErrorState: PropTypes.func,
  dateError: PropTypes.shape({
    flag: PropTypes.bool,
  }),
  applicationMethodDropdownProps: PropTypes.shape({
    options: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
  }),
  unitOfMeasurementDropdownProps: PropTypes.shape({
    options: PropTypes.array,
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
  }),
  mineralPercentageArray: PropTypes.array,
  inputsDisabled: PropTypes.bool,
  deleteHandler: PropTypes.func,
  isCombinationUnique: PropTypes.bool,
  disableAllFieldActions: PropTypes.bool,
};
