import styled from 'styled-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DropDown from 'components/FormComponents/Dropdown/Dropdown.react';
import { GRAYISH_BLUE, PAGE_BACKGROUND_COLOR, WHITE } from 'theme/GlobalColors';
import CheckboxFormGroup from 'components/FormComponents/CheckboxFormGroup/CheckboxFormGroup.react';
import { FilterPanelContent } from './FilterPanel.content';
import { poiCheckBoxStyle } from 'pages/Origination/Org_Project/Participants/ActivityReportingPOIReviewComp/ActivityReportingPOIReviewComp.style';

const RowDiv = styled.div`
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: baseline;
`;

const FilterPanel = ({
  dropDownList,
  farm,
  setFarm,
  allStatus,
  setAllStatus,
  projectActivity,
  setProjectActivity,
  flag,
  setFlag,
}) => {
  const [mappedDropDownList, setMappedDropdownList] = useState({
    projectActivityDropDownsList: [],
    reviewStatusDropDownsList: [],
    projectFarmDropDownsList: [],
  });

  useEffect(() => {
    const projectActivityDropDownsList =
      dropDownList?.projectActivityDropDowns?.map((item) => {
        return {
          value: item.id,
          label: item.value,
        };
      });
    const uniqueFarmId = [
      ...new Set(dropDownList?.projectFarmDropDowns.map((item) => item.id)),
    ];
    const projectFarmDropDownsList = uniqueFarmId.map((farmId) => {
      const farm = dropDownList?.projectFarmDropDowns.find(
        (item) => item.id === farmId,
      );
      return {
        value: farm.id,
        label: farm.value,
      };
    });
    const reviewStatusDropDownsList = dropDownList?.reviewStatusDropDowns?.map(
      (item) => {
        return {
          value: item,
          label: item,
        };
      },
    );
    // adding default values
    projectActivityDropDownsList?.push({
      value: FilterPanelContent.isNone,
      label: FilterPanelContent.activities_placeholder,
    });
    projectFarmDropDownsList?.push({
      value: FilterPanelContent.isNone,
      label: FilterPanelContent.farms_placeholder,
    });
    reviewStatusDropDownsList?.push({
      value: FilterPanelContent.isNone,
      label: FilterPanelContent.status_placeholder,
    });
    setMappedDropdownList({
      projectActivityDropDownsList: projectActivityDropDownsList,
      reviewStatusDropDownsList: reviewStatusDropDownsList,
      projectFarmDropDownsList: projectFarmDropDownsList,
    });
  }, [dropDownList]);

  return (
    <RowDiv>
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="5.5rem"
        minWidth="5.5rem"
        hasNoBottomMargin={true}
        value={farm}
        dropDownPlaceholder={FilterPanelContent.farms_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.projectFarmDropDownsList}
        onUpdate={(e) => {
          setFarm(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="12.625rem"
        minWidth="12.625rem"
        hasNoBottomMargin={true}
        value={projectActivity}
        dropDownPlaceholder={FilterPanelContent.activities_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.projectActivityDropDownsList}
        onUpdate={(e) => {
          setProjectActivity(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <DropDown
        customSx={{ borderColor: GRAYISH_BLUE }}
        height="1.75rem"
        isAutoWidth
        dropDownMinWidth="7.5rem"
        minWidth="7.5rem"
        hasNoBottomMargin={true}
        value={allStatus}
        dropDownPlaceholder={FilterPanelContent.status_placeholder}
        setFormFieldValue={() => {}}
        dropdownlist={mappedDropDownList.reviewStatusDropDownsList}
        onUpdate={(e) => {
          setAllStatus(e.target.value);
        }}
        showLabelAsValue
        dropdownBackgroundColor={WHITE}
      />
      <CheckboxFormGroup
        list={[
          {
            itemLabel: FilterPanelContent.show_flag_label,
            checked: flag,
          },
        ]}
        label={''}
        instructionText={''}
        onClickHandler={() => {
          setFlag(!flag);
        }}
        checkboxPadding={''}
        gap={'0.5rem'}
        labelMarginTop={'-0.7rem'}
        labelMarginBottom={''}
        backgroundColor={PAGE_BACKGROUND_COLOR}
        customStyles={poiCheckBoxStyle}
      />
    </RowDiv>
  );
};

export default FilterPanel;

FilterPanel.propTypes = {
  dropDownList: PropTypes.shape({
    projectActivityDropDowns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
    projectFarmDropDowns: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    reviewStatusDropDowns: PropTypes.arrayOf(PropTypes.string),
  }),
  farm: PropTypes.string,
  setFarm: PropTypes.func,
  allStatus: PropTypes.string,
  setAllStatus: PropTypes.func,
  projectActivity: PropTypes.string,
  setProjectActivity: PropTypes.func,
  flag: PropTypes.bool,
  setFlag: PropTypes.func,
};
