import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddressWrapper,
  DetailsContainer,
  HeaderWrapper,
  MainContainer,
  MainWrapper,
  ParentWrapper,
  TextWrapper,
  FarmWrapper,
  DataGridWrapper,
  datagridSx,
  FilterHeader,
  customArrowStyle,
  OptionsWrapper,
  LinkTextWrapper,
  deleteToastMessageStyle,
  FarmCardHeaderStyle,
  contributorDataGridSx,
  ContributorFarmCardHeaderStyle,
  ContributorMainWrapper,
  ContributorHeaderWrapper,
  ContractDivider,
  ParticipantButtonWrapper,
  disabledEditIcon,
  disabledAddParticipantContributor,
  optionsSx,
  simpleOptionsButtonSx,
  customBtnSx,
} from './ParticipantProfile.style';
import {
  checkTernaryCondition,
  covertDateToLocalTimeZone,
  hasAnyRequiredAccess,
} from '../../../../../utils/helper';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { DataGrid } from '@mui/x-data-grid';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  CellValueWrapper,
  RowCellWrapper,
} from '../../../../../components/NewApplicationAuditLogComp/NewApplicationAuditLogComp.style';
import FieldValueComponent from 'components/FormComponents/FieldValueComponent/FieldValueComponent.react';
import { PAGE_CONTENT } from '../../../../../components/ViewDetailedAuditLog/ViewDetailedAuditLog.content';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router';
import { GET_PARTICIPANT_INFO, REMOVE_PARTICIPANT_CONTRIBUTOR } from 'urls';
import {
  PARTICIPANT_CONTENT,
  PARTICIPANT_INFO_INITIAL_STATE,
} from './ParticipantProfile.content';
import { Backdrop, CircularProgress, Divider } from '@mui/material';
import { SortWrapper } from 'pages/OriginationProjectList/OriginationProjectList.style';
import { formatPhoneNumber, displayTextValue } from 'utils/helper';
import {
  DARK_CHARCOAL,
  DOWNLOAD_LINK,
  COOL_GRAY,
  DARK_GREEN,
  BORDER_GRAY_COLOR,
  DIM_GRAY,
} from 'theme/GlobalColors';
import EditParticipantContributorModal from 'pages/ParticipantDataCollection/components/ParticipantContributorsForm/EditParticipantContributorModal.react';
import SimpleOptionsButton from 'components/SimpleOptionsButton/SimpleOptionsButton.react';
import {
  genderOptions,
  raceOrEthnicityOptions,
} from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';
import { ORIGINATION_VIEWER, stateOptions } from 'utils/config';
import { ReactComponent as EditIconWithHoverEffect } from 'assets/icons/EditIconWithHoverEffect.svg';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import { ReactComponent as AddIconWithBorderEffect } from 'assets/icons/AddBlueIconWithoutBorder.svg';
import AddIcon from '@mui/icons-material/Add';
import EditParticipantProfile from '../EditParticipantProfile/EditParticipantProfile.react';
import {
  CardHeaderStyle,
  TablePaginationMenuListStyle,
} from 'theme/GlobalStyles';
import { pathName } from 'Routes/routes.const';
import NodataComponent from 'components/NoDataComp/NodataContent';
import ParticipantContributorModal from 'pages/ParticipantDataCollection/components/ParticipantContributorsForm/ParticipantContributorModal.react';
import DialogBox from 'components/DialogBox/DialogBox.react';
import CustomSnackbar from 'components/CustomSnackbar/CustomSnackbar.react';
import { toast } from 'react-toastify';
import { fetchAddContributorFarmAndProject } from 'pages/ParticipantDataCollection/components/ParticipantContributorsForm/ParticipantContributorsForm.funtions';
import { userAuthRolesContext } from 'contextAPI/userAuthRolesContext';
import ParticipantsImage from 'assets/Participants.png';

const ParticipantProfile = ({ projectData, disableVirtualization }) => {
  const [participantDetails, setParticipantDetails] = useState();
  const [participantType, setParticipantType] = useState(
    PARTICIPANT_CONTENT.participant_owner,
  );
  const navigate = useNavigate();
  const [farmData, setFarmData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isParticipantEditModalOpen, setIsParticipantEditModalOpen] =
    useState(false);
  const [participantIdValue, setParticipantIdValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [sortFilter, setSortFilter] = useState({
    column: 'updatedDate',
    order: 'DESC',
  });
  const [pageSize, setPageSize] = useState(
    PARTICIPANT_CONTENT.default_page_size,
  );
  const [pageNumber, setPageNumber] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [participantInfo, setParticipantInfo] = useState(
    PARTICIPANT_INFO_INITIAL_STATE,
  );
  const { id } = useParams();
  const [totalElements, setTotalElements] = useState();
  const [farmDetails, setFarmDetails] = useState([]);
  const { userRoles } = useContext(userAuthRolesContext);

  const isViewerRole =
    !!farmDetails?.length &&
    !hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]);

  const deleteClickHandler = (
    participantId,
    projectId,
    participantName,
    farmId,
    farmName,
    projectName,
  ) => {
    setIsDeleteDialogOpen(true);
    setParticipantInfo({
      participantId: participantId,
      projectId: projectId,
      participantName: participantName,
      farmId: farmId,
      farmName: farmName,
      projectName: projectName,
    });
  };

  const customCell = (value1, value2, color1, color2, cellClickHandler) => {
    if (typeof value1 === 'object') {
      return (
        <RowCellWrapper
          gap={'0.7rem'}
          alignSelf={'flex-start'}
          direction={PAGE_CONTENT.column}
          onClick={cellClickHandler}>
          {value1.map((val) => (
            <LinkTextWrapper
              color={DARK_CHARCOAL}
              marginTop={'0.25rem'}
              key={val}
              fontSize="0.875rem">
              {covertDateToLocalTimeZone(val).slice(0, 10)}
            </LinkTextWrapper>
          ))}
        </RowCellWrapper>
      );
    } else {
      return (
        <RowCellWrapper
          direction={PAGE_CONTENT.column}
          alignSelf="baseline"
          onClick={cellClickHandler}>
          <CellValueWrapper color={color1} fontSize="0.9rem" fontWeight={400}>
            {value1} - {value2}
          </CellValueWrapper>
        </RowCellWrapper>
      );
    }
  };

  const customContributorCell = (value1, value2, value3) => {
    return (
      <RowCellWrapper
        direction={PAGE_CONTENT.column}
        alignSelf={'flex-start'}
        gap={'0.7rem'}>
        {value1.map((element, index) => (
          <LinkTextWrapper
            key={element}
            marginTop={
              participantType === PARTICIPANT_CONTENT.participant_owner
                ? '0.25rem'
                : ''
            }
            fontSize="0.875rem"
            onClick={() =>
              onContributorCellClick(
                value2[index],
                PARTICIPANT_CONTENT.profile_tab,
                value3,
              )
            }
            color={DOWNLOAD_LINK}>
            {element}
          </LinkTextWrapper>
        ))}
      </RowCellWrapper>
    );
  };
  const customOptionsCell = (
    value1,
    value2,
    projectId,
    farmId,
    farmName,
    projectName,
  ) => {
    return (
      <RowCellWrapper
        marginLeft={
          participantType === PARTICIPANT_CONTENT.participant_owner
            ? '-0.3rem'
            : ''
        }
        alignSelf={'center'}
        direction={PAGE_CONTENT.column}
        gap={'0.7rem'}>
        {value1.map((element, index) => (
          <OptionsWrapper
            paddingRight={checkTernaryCondition(
              participantType === PARTICIPANT_CONTENT.participant_owner,
              '3rem',
              '',
            )}
            key={element}
            data-testid={`options-${value2[index]}`}>
            <SimpleOptionsButton
              key={element}
              editId={element}
              onEdit={() => {
                setParticipantIdValue(value2[index]);
                setIsParticipantEditModalOpen(true);
              }}
              onDelete={() =>
                deleteClickHandler(
                  value2[index],
                  projectId,
                  element,
                  farmId,
                  farmName,
                  projectName,
                )
              }
              disableIconButton={hasAnyRequiredAccess(userRoles, [
                ORIGINATION_VIEWER,
              ])}
              removeOrDelete
              simpleOptionsSx={simpleOptionsButtonSx}
              horizontalIcon
              hasEditOption={
                participantType === PARTICIPANT_CONTENT.participant_owner
              }
            />
          </OptionsWrapper>
        ))}
      </RowCellWrapper>
    );
  };
  const getGenderOrRaceValues = (value, optionsList) => {
    if (value === null || value === undefined) {
      return '-';
    } else {
      return optionsList.filter((option) => option.value === value)[0].label;
    }
  };

  const fetchParticipantDetails = () => {
    setLoading(true);
    axios
      .post(`${GET_PARTICIPANT_INFO}`, {
        enrolmentType: 'Baseline',
        pageNumber: 0,
        pageSize: 10,
        participantId: id,
        sortFilter: {
          column: sortFilter?.updatedDate,
          order: sortFilter?.order,
        },
      })
      .then((response) => {
        let responseData = [];
        if (response.data) {
          responseData = [
            {
              label: PARTICIPANT_CONTENT.name,
              value: `${response?.data?.content[0]?.firstName} ${response?.data?.content[0]?.lastName}`,
            },
            {
              label: PARTICIPANT_CONTENT.email,
              value: response?.data?.content[0]?.email,
            },
            {
              label: PARTICIPANT_CONTENT.phone,
              value: displayTextValue(
                formatPhoneNumber(
                  response?.data?.content[0]?.phone
                    ? response?.data?.content[0]?.phone
                    : '',
                ),
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.mailing_address_1,
              value: displayTextValue(
                response?.data?.content[0]?.mailingAddress1,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.preferred_communication_Method,
              value: displayTextValue(
                response?.data?.content[0]?.preferredCommunicationMethod,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.race,
              value: getGenderOrRaceValues(
                response?.data?.content[0]?.race,
                raceOrEthnicityOptions,
              ),
            },
            {
              label: PARTICIPANT_CONTENT.gender,
              value: getGenderOrRaceValues(
                response?.data?.content[0]?.gender,
                genderOptions,
              ),
            },
            {
              label: PARTICIPANT_CONTENT.historically_underserved_status,
              value: displayTextValue(
                response?.data?.content[0]?.historicallyUnderservedStatus,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.lgbtqia,
              value: displayTextValue(response?.data?.content[0]?.lgbtqia, '-'),
            },
            {
              label: PARTICIPANT_CONTENT.women_owned,
              value: displayTextValue(
                response?.data?.content[0]?.womenOwned,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.veteran_owned,
              value: displayTextValue(
                response?.data?.content[0]?.veteranOwned,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.disabled_veteran_owned,
              value: displayTextValue(
                response?.data?.content[0]?.disabledVeteranOwned,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.disability_owned,
              value: displayTextValue(
                response?.data?.content[0]?.disabilityOwned,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.small_business,
              value: displayTextValue(
                response?.data?.content[0]?.smallBusiness,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.city,
              value: displayTextValue(response?.data?.content[0]?.city, '-'),
            },
            {
              label: PARTICIPANT_CONTENT.state,
              value: displayTextValue(
                stateOptions.find(
                  (item) => item.value === response?.data?.content[0]?.state,
                )?.label,
                '-',
              ),
            },
            {
              label: PARTICIPANT_CONTENT.zip_code,
              value: displayTextValue(response?.data?.content[0]?.zip, '-'),
            },
          ];
        }
        setPageSize(response?.data?.size);
        setPageNumber(response?.data?.number);
        setTotalElements(response?.data?.totalElements);
        setParticipantType(response.data?.content[0]?.participantType);
        const farmDetails = response?.data?.content[0]?.farmDetails;

        setFarmData(
          checkTernaryCondition(
            !farmDetails,
            [],
            farmDetails?.map((item) => {
              return {
                id: uniqueId(),
                projectId: item?.projectId,
                projectName: item?.projectName,
                farmName: item?.farmName,
                fsaId: item?.fsaid,
                farmId: item?.farmId,
                contributorList: item?.contributorDetails?.map(
                  (contributor) => {
                    return `${contributor?.participantFirstName} ${contributor?.participantLastName}`;
                  },
                ),
                ownerList: item?.ownerDetails?.map((owner) => {
                  return `${owner?.participantFirstName} ${owner?.participantLastName}`;
                }),
                updatedDate: item?.contributorDetails?.map((contributor) => {
                  return contributor?.updatedDate;
                }),
                contributorIds: item?.contributorDetails?.map((contributor) => {
                  return contributor?.participantId;
                }),
                ownerIds: item?.ownerDetails?.map((owner) => {
                  return owner?.participantId;
                }),
              };
            }),
          ),
        );
        setParticipantDetails(responseData);
      })
      .finally(() => setLoading(false));
  };

  const handleRemoveParticipant = () => {
    setIsDeleteDialogOpen(false);
    const successMessage = `${participantInfo?.participantName} was successfully removed.`;
    const errorMessage = `${participantInfo?.participantName} failed to be removed. Please try again.`;
    const ownerId =
      participantType === PARTICIPANT_CONTENT.participant_owner
        ? id
        : participantInfo?.participantId;
    const contributorId =
      participantType === PARTICIPANT_CONTENT.participant_owner
        ? participantInfo?.participantId
        : id;
    axios
      .delete(
        `${REMOVE_PARTICIPANT_CONTRIBUTOR}?farmId=${participantInfo?.farmId}&participantOwnerId=${ownerId}&participantContributorId=${contributorId}&internalProjectId=${participantInfo?.projectId}`,
      )
      .then(() => {
        toast(
          <CustomSnackbar type="success" message={successMessage} />,
          deleteToastMessageStyle,
        );
        fetchParticipantDetails();
      })
      .catch(() => {
        toast(
          <CustomSnackbar type="error" message={errorMessage} />,
          deleteToastMessageStyle,
        );
      });
  };

  const handleSortClick = (params) => {
    setSortFilter((prevState) => ({
      column: params.field,
      order: prevState.order === 'ASC' ? 'DESC' : 'ASC',
    }));
  };

  const onProjectNameCellClick = (projectId, tab) => {
    navigate(`${pathName.originationProject.view}/${projectId}`, {
      state: { activeTab: tab },
    });
  };
  const onContributorCellClick = (contributorId, tab, projectId) => {
    navigate(
      `${pathName.originationParticipants.view}/${contributorId}?projectid=${projectId}&tab=${tab}`,
      {
        state: { activeTab: tab },
      },
    );
  };
  useEffect(() => {
    fetchAddContributorFarmAndProject(id).then((data) => setFarmDetails(data));
  }, []);

  useEffect(() => {
    fetchParticipantDetails();
  }, [
    sortFilter,
    pageNumber,
    pageSize,
    isModalOpen,
    isParticipantEditModalOpen,
  ]);

  const columns = [
    {
      field: PARTICIPANT_CONTENT.projectName,
      headerName: PARTICIPANT_CONTENT.project,
      disableColumnMenu: true,
      flex: 0.25,
      sortable: false,
      renderCell: (params) => (
        <LinkTextWrapper
          fontSize="0.875rem"
          alignSelf="baseline"
          paddingTop="8px"
          onClick={() => onProjectNameCellClick(params?.row?.projectId)}
          color={DOWNLOAD_LINK}>
          {params?.row?.projectName}
        </LinkTextWrapper>
      ),
    },
    {
      field: PARTICIPANT_CONTENT.farmName,
      headerName: PARTICIPANT_CONTENT.farm_and_fsa_id,
      disableColumnMenu: true,
      flex: 0.25,
      sortable: false,
      renderCell: (params) =>
        customCell(
          params?.row?.farmName,
          params?.row?.fsaId,
          DARK_CHARCOAL,
          COOL_GRAY,
        ),
    },
    {
      field: PARTICIPANT_CONTENT.contributorList,
      disableColumnMenu: true,
      flex: 0.2,
      sortable: false,
      renderHeader: () => (
        <TextWrapper color={DARK_CHARCOAL}>
          {PARTICIPANT_CONTENT.contributor}
        </TextWrapper>
      ),
      renderCell: (params) =>
        customContributorCell(
          params?.row?.contributorList,
          params?.row?.contributorIds,
          params?.row?.projectId,
        ),
    },
    {
      field: PARTICIPANT_CONTENT.updatedDate,
      disableColumnMenu: true,
      flex: 0.2,
      sortable: false,
      renderHeader: (params) => (
        <FilterHeader onClick={(e) => e.stopPropagation()}>
          <TextWrapper color={DARK_CHARCOAL}>
            {PARTICIPANT_CONTENT.last_updated}
          </TextWrapper>
          <SortWrapper
            data-testid="sort-participant-added-on"
            onClick={() => {
              handleSortClick(params);
            }}>
            {sortFilter.order === 'ASC' ? (
              <ArrowUpward
                data-testid="Time-up-arrow"
                fontSize="small"
                sx={customArrowStyle}
              />
            ) : (
              <ArrowDownward
                data-testid="Time-down-arrow"
                fontSize="small"
                sx={customArrowStyle}
              />
            )}
          </SortWrapper>
        </FilterHeader>
      ),
      renderCell: (params) => customCell(params?.row?.updatedDate),
    },
    {
      field: 'options',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell: (params) =>
        customOptionsCell(
          params?.row?.contributorList,
          params?.row?.contributorIds,
          params?.row?.projectId,
          params?.row?.farmId,
          params?.row?.farmName,
          params?.row?.projectName,
        ),
      renderHeader: () => (
        <TextWrapper marginLeft={'1em'}>
          {PARTICIPANT_CONTENT.action}
        </TextWrapper>
      ),
    },
  ];

  const contributorColumns = [
    {
      field: PARTICIPANT_CONTENT.projectName,
      disableColumnMenu: true,
      flex: 0.4,
      sortable: false,
      renderHeader: () => (
        <TextWrapper fontSize="0.75rem" color={DARK_CHARCOAL}>
          {PARTICIPANT_CONTENT.project}
        </TextWrapper>
      ),
      renderCell: (params) => (
        <TextWrapper
          fontSize="0.875rem"
          alignSelf="baseline"
          paddingTop="8px"
          onClick={() => onProjectNameCellClick(params?.row?.projectId)}
          color={DOWNLOAD_LINK}>
          {params?.row?.projectName}
        </TextWrapper>
      ),
    },
    {
      field: PARTICIPANT_CONTENT.ownerList,
      disableColumnMenu: true,
      flex: 0.35,
      sortable: false,
      renderHeader: () => (
        <TextWrapper fontSize="0.75rem" color={DARK_CHARCOAL}>
          {PARTICIPANT_CONTENT.owner}
        </TextWrapper>
      ),
      renderCell: (params) =>
        customContributorCell(
          params?.row?.ownerList,
          params?.row?.ownerIds,
          params?.row?.projectId,
        ),
    },
    {
      field: PARTICIPANT_CONTENT.farmName,
      disableColumnMenu: true,
      flex: 0.35,
      sortable: false,
      renderHeader: () => (
        <TextWrapper fontSize="0.75rem" color={DARK_CHARCOAL}>
          {PARTICIPANT_CONTENT.farm_and_fsa_id}
        </TextWrapper>
      ),
      renderCell: (params) =>
        customCell(
          params?.row?.farmName,
          params?.row?.fsaId,
          DARK_CHARCOAL,
          COOL_GRAY,
        ),
    },
    {
      field: PARTICIPANT_CONTENT.actionField,
      headerName: PARTICIPANT_CONTENT.actionLabel,
      disableColumnMenu: true,
      flex: 0.1,
      sortable: false,
      align: 'start',
      renderCell: (params) =>
        customOptionsCell(
          params?.row?.ownerList,
          params?.row?.ownerIds,
          params?.row?.projectId,
          params?.row?.farmId,
          params?.row?.farmName,
          params?.row?.projectName,
        ),
    },
  ];

  return (
    <div>
      <ParentWrapper className="curved-border">
        {(loading && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}>
            <CircularProgress color="success" />
          </Backdrop>
        )) || (
          <MainWrapper>
            <MainContainer>
              <HeaderWrapper>
                <h5 style={CardHeaderStyle}>{PARTICIPANT_CONTENT.contact}</h5>
                {checkTernaryCondition(
                  !hasAnyRequiredAccess(userRoles, [ORIGINATION_VIEWER]),
                  <EditIconWithHoverEffect
                    className="edit-icon-style"
                    data-testid="edit-icon"
                    onClick={() => setIsEditModalOpen(true)}
                  />,
                  <EditIcon style={disabledEditIcon} />,
                )}
              </HeaderWrapper>
              <ContractDivider>
                <Divider />
              </ContractDivider>
              <DetailsContainer>
                {participantDetails?.slice(0, 5).map((item, index) => {
                  const key = index;
                  if (
                    participantType !== PARTICIPANT_CONTENT.participant_owner &&
                    (item.label === PARTICIPANT_CONTENT.mailing_address_1 ||
                      item.label === PARTICIPANT_CONTENT.state ||
                      item.label === PARTICIPANT_CONTENT.city ||
                      item.label === PARTICIPANT_CONTENT.zip_code)
                  ) {
                    return null;
                  }
                  return (
                    <div key={key}>
                      <FieldValueComponent
                        key={key}
                        label={item.label}
                        value={item.value}
                        minWidth={PARTICIPANT_CONTENT.minWidth}
                        fontWeight={700}
                        color={COOL_GRAY}
                        labelFontSize={PARTICIPANT_CONTENT.labelFontSize}
                        labelMarginTop={PARTICIPANT_CONTENT.labelMarginTop}
                        labelMarginBottom={
                          PARTICIPANT_CONTENT.labelMarginBottom
                        }
                        fieldMarginBottom={
                          PARTICIPANT_CONTENT.fieldMarginBottom
                        }
                      />
                      {item.label === PARTICIPANT_CONTENT.mailing_address_1 && (
                        <AddressWrapper>
                          <TextWrapper width={PARTICIPANT_CONTENT.cityWidth}>
                            {participantDetails[14]?.value}
                          </TextWrapper>
                          <TextWrapper width={PARTICIPANT_CONTENT.stateWidth}>
                            {participantDetails[15]?.value}
                          </TextWrapper>
                          <TextWrapper width={PARTICIPANT_CONTENT.pincodeWidth}>
                            {participantDetails[16]?.value}
                          </TextWrapper>
                        </AddressWrapper>
                      )}
                    </div>
                  );
                })}
              </DetailsContainer>
            </MainContainer>
            {isEditModalOpen && (
              <EditParticipantProfile
                isModalOpen={isEditModalOpen}
                type={checkTernaryCondition(
                  participantType === PARTICIPANT_CONTENT.participant_owner,
                  PARTICIPANT_CONTENT.owner_label,
                  PARTICIPANT_CONTENT.contributor_label,
                )}
                modalCloseHandler={() => setIsEditModalOpen(false)}
                participantId={id}
                refreshHandler={fetchParticipantDetails}
              />
            )}
          </MainWrapper>
        )}
      </ParentWrapper>
      {isModalOpen && (
        <ParticipantContributorModal
          internalProjectId={projectData?.internalProjectId}
          participantOwnerId={id}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          refreshHandler={fetchParticipantDetails}
        />
      )}
      {participantType === PARTICIPANT_CONTENT.participant_owner ? (
        <FarmWrapper className="curved-border">
          <MainWrapper>
            <HeaderWrapper>
              <h5 style={FarmCardHeaderStyle}>
                {PARTICIPANT_CONTENT.farm_assigments_title}
              </h5>
              {checkTernaryCondition(
                farmData?.length > 0,
                <ParticipantButtonWrapper
                  onClick={() => {
                    setIsModalOpen(true);
                  }}>
                  <AddIconWithBorderEffect
                    data-testid="add-icon"
                    className="icon-with-border"
                  />
                  {PARTICIPANT_CONTENT.participantContributor}
                </ParticipantButtonWrapper>,
                null,
              )}
            </HeaderWrapper>
            {isParticipantEditModalOpen && (
              <EditParticipantContributorModal
                participantOwnerId={id}
                participantId={participantIdValue}
                internalProjectId={projectData?.internalProjectId}
                isParticipantEditModalOpen={isParticipantEditModalOpen}
                setIsParticipantEditModalOpen={setIsParticipantEditModalOpen}
                refreshHandler={fetchParticipantDetails}
              />
            )}
            {checkTernaryCondition(
              !!farmDetails?.length &&
                farmData.every(
                  (farm) =>
                    farm?.contributorList === undefined ||
                    farm?.contributorList?.length === 0,
                ),
              <NodataComponent
                nodata={PARTICIPANT_CONTENT.emptyDataContent}
                addNewLabel={PARTICIPANT_CONTENT.addNewParticipantLabel}
                imageSource={ParticipantsImage}
                addNewLabelFontSize="0.875rem"
                width={'25.5625rem'}
                componentMarginBottom={'0.3125rem'}
                addNewLabelLineHeight={'1.1919rem'}
                addNewLabelFontWeight={'400'}
                addNewLabelMarginBottom={'0rem'}
                buttonMarginTop={'1.5rem'}
                customBtnSx={customBtnSx}
                addButtonSx={customBtnSx}
                addNewLabelColor={DIM_GRAY}
                addbuttonLabel={{
                  name: PARTICIPANT_CONTENT.addParticipantContributorBtn,
                  link: '',
                }}
                modalHandler={() => {
                  setIsModalOpen(true);
                }}
                buttonBackgroundColor={DARK_GREEN}
                disableButton={hasAnyRequiredAccess(userRoles, [
                  ORIGINATION_VIEWER,
                ])}
              />,
              checkTernaryCondition(
                farmDetails?.length === 0,
                <NodataComponent
                  borderTop={`1px solid ${BORDER_GRAY_COLOR}`}
                  componentWidth={'23.438rem'}
                  componentHeight={'1.5rem'}
                  left={'30%'}
                  buttonMarginTop={'3.125rem'}
                  buttonPosition={'relative'}
                  buttonLeft={'10%'}
                  nodata={PARTICIPANT_CONTENT.no_farm}
                  marginLeft={'-1rem'}
                />,
                null,
              ),
            )}
            {farmData.some((farm) => farm?.contributorList?.length !== 0) && (
              <DataGridWrapper>
                <DataGrid
                  headerHeight={40}
                  disableSelectionOnClick
                  sx={datagridSx}
                  rowCount={totalElements}
                  columns={columns}
                  rows={farmData}
                  page={pageNumber}
                  paginationMode={PAGE_CONTENT.pagination_mode}
                  onPageChange={(newPage) => setPageNumber(newPage)}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pagination
                  rowsPerPageOptions={
                    PARTICIPANT_CONTENT.default_pagination_options
                  }
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
                      SelectProps: TablePaginationMenuListStyle,
                    },
                  }}
                  disableVirtualization={disableVirtualization}
                />
              </DataGridWrapper>
            )}
          </MainWrapper>
        </FarmWrapper>
      ) : (
        <FarmWrapper className="curved-border">
          <ContributorMainWrapper>
            <DataGridWrapper>
              <ContributorHeaderWrapper>
                <h5 style={ContributorFarmCardHeaderStyle}>
                  {PARTICIPANT_CONTENT.farm_assigments_title}
                </h5>
              </ContributorHeaderWrapper>
              {(farmData.every((farm) => farm?.ownerList === undefined) ||
                farmData.every((farm) => farm?.ownerList?.length === 0)) && (
                <NodataComponent
                  borderTop={`1px solid ${BORDER_GRAY_COLOR}`}
                  componentWidth={'375px'}
                  componentHeight={'24px'}
                  left={'30%'}
                  buttonMarginTop={'50px'}
                  buttonPosition={'relative'}
                  buttonLeft={'10%'}
                  nodata={PARTICIPANT_CONTENT.contributorEmptyDataContent}
                  marginLeft={'-1rem'}
                  modalHandler={() => {}}
                  buttonBackgroundColor={DARK_GREEN}
                />
              )}
              {farmData.some((farm) => farm?.ownerList?.length !== 0) && (
                <DataGrid
                  headerHeight={40}
                  disableSelectionOnClick
                  sx={contributorDataGridSx}
                  rowCount={totalElements}
                  columns={contributorColumns}
                  rows={farmData}
                  page={pageNumber}
                  paginationMode={PAGE_CONTENT.pagination_mode}
                  onPageChange={(newPage) => setPageNumber(newPage)}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  pagination
                  rowsPerPageOptions={
                    PARTICIPANT_CONTENT.default_pagination_options
                  }
                  componentsProps={{
                    pagination: {
                      labelRowsPerPage: PAGE_CONTENT.label_rows_per_page,
                      SelectProps: TablePaginationMenuListStyle,
                    },
                  }}
                  disableVirtualization={disableVirtualization}
                />
              )}
            </DataGridWrapper>
          </ContributorMainWrapper>
        </FarmWrapper>
      )}

      <DialogBox
        dialogActions
        deleteOperation
        isOpen={isDeleteDialogOpen}
        onConfirm={() => handleRemoveParticipant()}
        subtitle={checkTernaryCondition(
          participantType === PARTICIPANT_CONTENT.participant_owner,
          `Are you sure you want to remove ${participantInfo?.participantName} from ${participantInfo?.farmName} for ${participantInfo?.projectName} ?`,
          `Are you sure you want to remove ${participantInfo?.participantName} ? They’ll be removed from all projects and farms they are assigned to.`,
        )}
        onCancel={() => setIsDeleteDialogOpen(false)}
        declineCtnLabel="Cancel"
        acceptCtnLabel="Remove"
        title={`Remove ${participantInfo?.participantName}`}></DialogBox>
    </div>
  );
};

export default ParticipantProfile;

ParticipantProfile.propTypes = {
  projectData: PropTypes.shape({
    internalProjectId: PropTypes.number,
  }),
};
