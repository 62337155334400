import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  CancelBtnStyle,
  DialogActionStyle,
  DialogContentStyle,
  DialogTitleStyle,
  SubmitBtnStyle,
  closeIconSx,
} from '../Modals.style';
import {
  MODAL_CONTENT,
  formFieldNames,
  NitrogenFertilizerFormInitialData,
  fertilizerTypeOptions,
  ModalInitialErrorState,
} from './NitrogenFertilizerModal.content';
import { useEffect, useContext } from 'react';
import { flexSpaceBetween } from 'theme/GlobalStyles';
import {
  ErrorWrapper,
  primaryButtonStyle,
  tertiaryButtonStyle,
} from 'components/FormComponents/FormStyles';
import { baselineDropdownContext } from 'contextAPI/baselineDropdownContext';
import { uniqueId } from 'utils/uniqueIdGenerator';
import { BASELINE_MODAL_CONTENT } from 'pages/ParticipantDataCollection/ReportingComponents/TabBodyComponent.content';
import moment from 'moment';
import NitrogenFertilizerContent from './NitrogenFertilizerContent/NitrogenFertilizerContent.react';
import { checkApplicationRateValues } from './NitrogenFertilizerWrapper/NitrogenFertilizerWrapper.content';
import { checkTernaryCondition } from 'utils/helper';
import {
  NitrogenModalDialogActionStyle,
  NitrogenModalDialogStyle,
} from './NitrogentFertilizerModal.style';
import { STYLE_CONSTANTS } from 'pages/ParticipantDataCollection/components/ParticipantGeneralInfoForm/ParticipantGeneralInfoForm.content';

const NitrogentFertilizerModal = ({
  modalOpen,
  setModalOpen,
  submitHandler,
  formValue,
  setFormValue,
  setErrorState,
  datesAppliedArray,
  setDateAppliedArray,
  dateError,
  setDateError,
  setPreviousFertilizerCategory,
  setPreviousNPKValues,
  setDateRequiedList,
  duplicatedDate,
  setDuplicateDate,
  selectedUnitOfMeasure,
  noDataOptionSelected,
  customFieldChangeHandler,
  isSubmitDisabled,
  onHoverDateApplied,
  setOnHoverDateApplied,
  mineralPercentageArray,
  fertilizertypeDropdownProps,
  animalTypeDropdownProps,
  manureTypeDropdownProps,
  unitOfMeasurementDropdownProps,
  applicationMethodDropdownProps,
  dateAppliedPickerProps,
  dateRequiedList,
  isInTesting,
  errorState,
  disableAllFieldActions = false,
  isCombinationUnique = true,
  makeFormDirty = () => null,
}) => {
  const modalCloseHandler = () => {
    setModalOpen({ flag: false, id: 0, data: null, currentRowId: 0 });
    setFormValue(NitrogenFertilizerFormInitialData);
    setErrorState(ModalInitialErrorState);
    setDateAppliedArray([null]);
    setDateError({ flag: false, indexArray: [] });
    setPreviousFertilizerCategory('');
    setPreviousNPKValues({
      nPercentage: '',
      pPercentage: '',
      kPercentage: '',
    });
    setDateRequiedList([]);
    setDuplicateDate({ flag: false, dupIndexArray: [] });
  };
  const { fertilizerTypeDropDown } = useContext(baselineDropdownContext);

  useEffect(() => {
    if (modalOpen.flag && modalOpen.data !== null) {
      const modalData = modalOpen.data.formData.filter(
        (data) => data.id === modalOpen.currentRowId,
      )[0];
      setFormValue(
        checkTernaryCondition(
          modalData,
          modalData,
          NitrogenFertilizerFormInitialData,
        ),
      );
    }
  }, [modalOpen]);
  const submitBtnHandler = () => {
    const fertilizerTypeVal = formValue.fertilizerCategory;
    let newDateRequiredList = [];
    datesAppliedArray.forEach((item, index) => {
      if (item === null) {
        newDateRequiredList.push(index);
      }
    });
    setDateRequiedList(newDateRequiredList);
    let hasError = false;
    const errorValues = {
      fertilizerType: formValue.fertilizerType === '',
      unitOfMeasure: selectedUnitOfMeasure === '',
      animalType:
        formValue.fertilizerType ===
          fertilizerTypeDropDown.find(
            (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
          )?.value && formValue.animalType === '',
      manureType:
        formValue.fertilizerType ===
          fertilizerTypeDropDown.find(
            (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
          )?.value && formValue.manureType === '',
      applicationMethod: formValue.applicationMethod === '',
      applicationRate: checkApplicationRateValues(formValue.applicationRate),
      datesApplied: newDateRequiredList.length > 0 || dateError.flag,
      hasInhibitor: formValue.hasInhibitor === '',
      nPercentage:
        formValue.nPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
      pPercentage:
        formValue.pPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
      kPercentage:
        formValue.kPercentage === '' &&
        fertilizerTypeVal === MODAL_CONTENT.synthetic,
    };
    setErrorState(
      checkTernaryCondition(
        noDataOptionSelected,
        ModalInitialErrorState,
        errorValues,
      ),
    );
    if (
      formValue.fertilizerType ===
      fertilizerTypeDropDown.find(
        (fertilizer) => fertilizer.label === MODAL_CONTENT.manure_label,
      )?.value
    ) {
      hasError = Object.values(errorValues).slice(0, 14).includes(true);
    } else {
      switch (fertilizerTypeVal) {
        case fertilizerTypeOptions[0].label[0]:
        case fertilizerTypeOptions[0].label[1]:
          hasError = Object.values(errorValues).slice(0, 14).includes(true);
          break;
        case fertilizerTypeOptions[1].label:
          hasError =
            Object.values(errorValues).slice(0, 10).includes(true) ||
            Object.values(errorValues).slice(11).includes(true);
          break;
        default:
          if (Object.values(errorValues).slice(0, 10).includes(true)) {
            hasError = true;
          }
      }
    }
    const isAnyDateInvalid = datesAppliedArray
      .map((item) => moment(item).format('YYYY-MM-DD'))
      .includes(MODAL_CONTENT.invalid_date);
    const notHasOverallError =
      hasError === false &&
      !dateError.flag &&
      !isAnyDateInvalid &&
      !duplicatedDate.flag;
    handleMainSubmit(notHasOverallError, noDataOptionSelected);
  };

  const handleMainSubmit = (notHasOverallError, noDataOptionSelected) => {
    if (notHasOverallError || noDataOptionSelected) {
      submitHandler(
        { ...formValue, unitOfMeasure: selectedUnitOfMeasure },
        checkTernaryCondition(modalOpen.id === 0, uniqueId(), modalOpen.id),
        BASELINE_MODAL_CONTENT.fertilizer_list,
      );
      modalCloseHandler();
      makeFormDirty();
    }
  };

  useEffect(() => {
    if (modalOpen.flag) {
      if (modalOpen.data?.formData?.length > 0) {
        if (modalOpen.data?.formData[0]?.datesApplied.length === 0) {
          setDateAppliedArray([null]);
        } else {
          setDateAppliedArray(modalOpen.data?.formData[0]?.datesApplied);
        }
      }
      if (modalOpen.data?.formData?.length === 0) {
        customFieldChangeHandler(formFieldNames.datesApplied, [null]);
      }
    }
  }, [modalOpen.flag]);

  return (
    <Dialog
      disableRestoreFocus
      open={modalOpen.flag}
      onClose={modalCloseHandler}
      sx={NitrogenModalDialogStyle}>
      <DialogTitle sx={{ ...DialogTitleStyle, ...flexSpaceBetween }}>
        <div>{MODAL_CONTENT.new_modal_heading}</div>
        <CloseIcon
          onClick={modalCloseHandler}
          sx={closeIconSx}
          data-testid="close-icon"
        />
      </DialogTitle>
      <DialogContent sx={DialogContentStyle}>
        {!isCombinationUnique && (
          <ErrorWrapper letterSpacing={STYLE_CONSTANTS.zero_rem}>
            {MODAL_CONTENT.unique_combination_error_heading}
          </ErrorWrapper>
        )}
        <NitrogenFertilizerContent
          fertilizertypeDropdownProps={fertilizertypeDropdownProps}
          noDataOptionSelected={noDataOptionSelected}
          fertilizerTypeDropDown={fertilizerTypeDropDown}
          formValue={formValue}
          animalTypeDropdownProps={animalTypeDropdownProps}
          manureTypeDropdownProps={manureTypeDropdownProps}
          datesAppliedArray={datesAppliedArray}
          dateAppliedPickerProps={dateAppliedPickerProps}
          setDateAppliedArray={setDateAppliedArray}
          customFieldChangeHandler={customFieldChangeHandler}
          dateRequiedList={dateRequiedList}
          setDateRequiedList={setDateRequiedList}
          isInTesting={isInTesting}
          setDuplicateDate={setDuplicateDate}
          disableAllFieldActions={disableAllFieldActions}
          duplicatedDate={duplicatedDate}
          setOnHoverDateApplied={setOnHoverDateApplied}
          onHoverDateApplied={onHoverDateApplied}
          errorState={errorState}
          setErrorState={setErrorState}
          dateError={dateError}
          applicationMethodDropdownProps={applicationMethodDropdownProps}
          unitOfMeasurementDropdownProps={unitOfMeasurementDropdownProps}
          mineralPercentageArray={mineralPercentageArray}
          isCombinationUnique={isCombinationUnique}
        />
      </DialogContent>
      <DialogActions
        style={{ ...DialogActionStyle, ...NitrogenModalDialogActionStyle }}>
        <Button
          disableElevation
          sx={[CancelBtnStyle, tertiaryButtonStyle]}
          onClick={modalCloseHandler}>
          {MODAL_CONTENT.cancel_btn_label}
        </Button>
        <Button
          disableElevation
          sx={[SubmitBtnStyle, primaryButtonStyle('0.516rem 0.906rem')]}
          disabled={isSubmitDisabled}
          onClick={submitBtnHandler}>
          {MODAL_CONTENT.submit_btn_label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NitrogentFertilizerModal.propTypes = {
  modalOpen: PropTypes.shape({
    flag: PropTypes.bool,
    id: PropTypes.number,
    data: PropTypes.object,
    currentRowId: PropTypes.number,
  }),
  setModalOpen: PropTypes.func,
  submitHandler: PropTypes.func,
  hasTestDefaultDate: PropTypes.bool,
  existingFertilizerTypeIds: PropTypes.array,
  formValue: PropTypes.shape({
    fertilizerCategory: PropTypes.string,
    fertilizerType: PropTypes.string,
    animalType: PropTypes.string,
    manureType: PropTypes.string,
    applicationMethod: PropTypes.string,
    applicationRate: PropTypes.string,
    datesApplied: PropTypes.array,
    hasInhibitor: PropTypes.string,
    nPercentage: PropTypes.string,
    pPercentage: PropTypes.string,
    kPercentage: PropTypes.string,
  }),
  setFormValue: PropTypes.func,
  setErrorState: PropTypes.func,
  datesAppliedArray: PropTypes.array,
  setDateAppliedArray: PropTypes.func,
  dateError: PropTypes.object,
  setDateError: PropTypes.func,
  setPreviousFertilizerCategory: PropTypes.func,
  setPreviousNPKValues: PropTypes.func,
  setDateRequiedList: PropTypes.func,
  duplicatedDate: PropTypes.object,
  setDuplicateDate: PropTypes.func,
  selectedUnitOfMeasure: PropTypes.string,
  noDataOptionSelected: PropTypes.bool,
  customFieldChangeHandler: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  onHoverDateApplied: PropTypes.func,
  setOnHoverDateApplied: PropTypes.func,
  mineralPercentageArray: PropTypes.array,
  fertilizertypeDropdownProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
  animalTypeDropdownProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
  manureTypeDropdownProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
  unitOfMeasurementDropdownProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
  applicationMethodDropdownProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
  }),
  dateAppliedPickerProps: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  dateRequiedList: PropTypes.array,
  isInTesting: PropTypes.bool,
  errorState: PropTypes.object,
  isCombinationUnique: PropTypes.bool,
  makeFormDirty: PropTypes.func,
  disableAllFieldActions: PropTypes.bool,
};
export default NitrogentFertilizerModal;
