import styled from 'styled-components';
import { ACTIVE_TAG_BG, ACTIVE_TAG_TEXT_COLOR } from 'theme/GlobalColors';

export const TitleWithLabels = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  max-width: 60rem;
  height: ${({ height }) => height};
`;

export const Label = styled.div`
  max-width: fit-content;
  max-height: 1.1875rem;
  padding: 0.156rem 0.25rem;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || ACTIVE_TAG_BG};
  border-radius: 0.25rem;
  font-size: 0.688rem;
  color: ${ACTIVE_TAG_TEXT_COLOR};
  display: flex;
  display: -webkit-flex;
  align-items: center;
`;
