import { checkTernaryCondition, isEmpty } from 'utils/helper';

export const MODAL_CONTENT = {
  title: 'Add contract for participant',
  project_cycle_dropdown_title:
    'Specify the project cycle start and end for which the contract will apply. ',
  date_placeholder: 'Select date',
  start: 'Start',
  end: 'End',
  start_primary_error: 'A start date selection is required.',
  end_primary_error: 'A end date selection is required.',
  start_secondary_error: 'Start date must be beyond current date.',
  end_secondary_error: 'End date must happen after the start date.',
  cancel: 'Cancel',
  submit: 'Submit',
  toast_success_message: 'Contract added successfully',
  toast_error_message: 'Failed to add the contract',
  upload_title: 'Upload contract',
  optional: '(Optional)',
  dropzone_info_text: 'Drag and drop contract file here or ',
  browse: ' browse',
  invalid_file_error: 'File not supported. Please upload a .pdf file.',
  limit_exceed_error: 'File size exceeds maximum of 100 MB allowed.',
  file_size_insufficient_error:
    'File size doesn’t meet minimum of 10 KB required.',
  incentive_rate_label: 'Set incentive rate per acre for project activities.',
  incentiveList: 'incentiveList',
  incentive_placeholder: '0.00',
  incentive_area_predefined_unit: 'acre',
  incentive_rate_required_label: 'Incentive rate required.',
  incentive_min_limit_error:
    'Must enter a valid dollar amount that is 1.00 or greater.',
  invalid_date: 'Invalid date',
  incentiveRate: 'incentiveRate',
  contractedUnits: 'contractedUnits',
  number_field_placeholder: '0.00',
  activities_table_heading:
    'For each planned project activity, provide the total contracted acres (optional) and incentive rates',
  activityTableHeaderTooltipContent:
    'If acreage values were entered for the recruitment-interested phase they will be pre-filled here and may be adjusted.',
  total_enrolled_acres_label:
    'Provide the total enrolled acres that project activities will be implemented on for project cycle ',
  total_enrolled_acres_placeholder: 'acres',
  enrolled_acres_required_error: 'Total acres for project cycle is required. ',
  enrolled_acres_min_limit_error: 'Acre amount must be greater than 0.',
  incentive_rate_required_error: 'Incentive rate required.',
  incentive_rate_min_limit_error:
    'Must enter a valid dollar amount that is 1.00 or greater.',
  project_cycle_instruction:
    'For contracts that apply to a single project cycle, the start and end cycles should be the same.',
  project_cycle_dropdown_placeholder: 'Select project cycle',
  project_cycle_required_error: 'A contract end cycle selection is required.',
  project_cycle_less_than_start_error:
    'Contract end cycle must happen after the contract start cycle.',
  contracted_acre_sum_error:
    'Contracted acres for each activity cannot exceed total enrolled acres.',
  contractedAcresAndIncentives: 'Contracted acres and incentive rates',
  residueAndTillageManagement: 'Residue + tillage management',
  edgeOfFieldPractices: 'Edge of field practices',
  soilCarbonAmendment: 'Soil carbon amendment',
  totalAcres: 'totalAcres',
  errorKey: 'error',
  coverCrop: 'Cover crop',
  nutrientManagement: 'Nutrient management',
  edgeOfField: 'Edge of field',
};

export const acceptedFilesInfo = {
  fileNotSupportedErrorText: 'File not supported. Please upload a .pdf file.',
  infoTextList: ['Files Supported: .pdf'],
  mimeType: 'pdf',
  extensions: ['.pdf'],
  maxFiles: 1,
  maxFileSize: 1024 * 1024 * 8, //8MB
};

export const ERROR_CODE = {
  FILE_TOO_LARGE: 'file-too-large',
  INVALID_FILE: 'file-invalid-type',
};

export const formatBytes = (bytes) => {
  const kilobyte = 1024;
  const megabyte = kilobyte * kilobyte;

  if (bytes < kilobyte) {
    return bytes + ' B';
  } else if (bytes < megabyte) {
    return bytes / kilobyte + ' KB';
  } else {
    return bytes / megabyte + ' MB';
  }
};

export const maxLimitError = (maxLimit) =>
  `File size exceeds maximum of ${maxLimit} allowed.`;

export const minLimitError = (minLimit) =>
  `File size doesn't meet minimum of ${minLimit} allowed`;

export const maxFilesError = () => `Only one file can be uploaded at a time.`;

export const getError = (errorCode, maxLimit, fileNotSupportedText) => {
  switch (errorCode) {
    case ERROR_CODE.FILE_TOO_LARGE:
      return maxLimitError(formatBytes(maxLimit));
    case ERROR_CODE.INVALID_FILE:
      return fileNotSupportedText;
    default:
      return 'Error';
  }
};

export const checkErrorArrayLength = (
  errorObject,
  incentiveListInForm,
  incentiveList,
  returnOnlyScreenError,
) => {
  if (returnOnlyScreenError) {
    return (
      errorObject.isRequired.length > 0 || errorObject.limitExceed.length > 0
    );
  } else {
    return (
      errorObject.isRequired.length === 0 &&
      errorObject.limitExceed.length === 0 &&
      incentiveListInForm.length > 0 &&
      incentiveListInForm.length === incentiveList.length &&
      !incentiveListInForm.includes(undefined)
    );
  }
};

export const inputStyleProps = {
  textAlignment: 'end',
  maxLength: 100,
  minWidth: '3.75rem',
  width: '3.75rem',
  lineHeight: '1.225rem',
  fontSize: '0.875rem',
  height: '1.75rem',
  noLabel: true,
  marginBottom: '0',
  marginTop: '0.25rem',
  rightSideLabelFormLabelMargin: '0',
  customStyles: { width: '3.75rem', marginBottom: '0' },
  customInputSx: {
    marginBottom: '0',
    textAlign: 'end',
  },
  display: 'flex',
  alignItems: 'center',
};

export const totalAcresInputProps = {
  minWidth: '3.75rem',
  width: '3.75rem',
  labelWidth: '31.75rem',
  placeholder: MODAL_CONTENT.total_enrolled_acres_placeholder,
  hasPredefinedUnit: true,
  predefinedUnit: 'total acres',
  customStyles: { width: '31.75rem' },
};

export const dropdownStyleProps = {
  height: '2rem',
  dropDownMinWidth: '13.75rem',
  minWidth: '13.75rem',
  labelMarginTop: '0',
  showLabelAsValue: true,
};

export const activityCategoryMapping = {
  'Edge of field practices': 'Edge of field',
  'Cover crop': 'Cover crop',
  'Nutrient management': 'Nutrient management',
  'Residue + tillage management': 'Residue + tillage management',
  'Soil carbon amendment': 'Soil carbon amendment',
};

export const TableHeadTooltip = () => {
  return (
    <div style={{ width: '13.75rem' }}>
      {MODAL_CONTENT.activityTableHeaderTooltipContent}
    </div>
  );
};

export const EdgeOfFieldTooltip = () => {
  return (
    <>
      <div>
        Edge of Field practice activities must be submitted in acres. Use the
        following equation to calculate the acreage for each Edge of Field
        practice:
      </div>
      <div>
        Acreage = ((Length in Ft. x Width in Ft.) * # of
        strips/borders/barriers/buffers) / 43,560 ft.
      </div>
      <div>
        Depending on the magnitude of the Edge of Field practice, total acreage
        could be less than 1 acre per field.
      </div>
      <div>
        Please check NRCS State Guidelines to ensure that the length and width
        of your planned practices align with the recommendations in your state.
      </div>
    </>
  );
};

export const showAlertIcon = (category, practice, errorConditions) => {
  const edgeOfFieldKey = checkTernaryCondition(
    errorConditions.unitKey === MODAL_CONTENT.contractedUnits,
    MODAL_CONTENT.edgeOfFieldPractices,
    MODAL_CONTENT.edgeOfField,
  );
  const errorStatus = practice?.[errorConditions.errorKey] === true;
  const unitKeyCondition =
    practice?.[errorConditions.unitKey] > 0 && errorStatus;

  const categoryErrorConditions = {
    [edgeOfFieldKey]: unitKeyCondition,
    [MODAL_CONTENT.residueAndTillageManagement]:
      errorConditions.residueAndTillageError,
    [MODAL_CONTENT.coverCrop]: unitKeyCondition,
    [MODAL_CONTENT.nutrientManagement]: unitKeyCondition,
    [MODAL_CONTENT.soilCarbonAmendment]: unitKeyCondition,
  };

  return categoryErrorConditions[category];
};

export const calculateActivityErrors = (
  activityPracticeData,
  totalEnrolledAcres,
  contracted = true,
) => {
  const filteredArray = Object.values(activityPracticeData).flatMap((item) =>
    checkTernaryCondition(!isEmpty(item), item, []),
  );
  let residueAndTillageSum = 0;
  let hasError = false;
  filteredArray.forEach((item) => {
    if (typeof item !== 'object' || isEmpty(item)) {
      return;
    }

    const contractedUnits =
      parseFloat(
        checkTernaryCondition(
          contracted,
          item.contractedUnits,
          item.pipelineUnits,
        ),
      ) || 0;
    const { activityCategory } = item;
    if (activityCategory === MODAL_CONTENT.residueAndTillageManagement) {
      residueAndTillageSum += contractedUnits;
    } else {
      item.error = contractedUnits > totalEnrolledAcres;
      hasError = hasError || item.error;
    }
  });
  const residueAndTillageError = residueAndTillageSum > totalEnrolledAcres;
  hasError = residueAndTillageError || hasError;
  // Update activityPracticeDataUpdate with error flag
  const updatedActivityPracticeData = Object.keys(activityPracticeData).reduce(
    (acc, key) => {
      const data = activityPracticeData[key];
      if (Array.isArray(data)) {
        acc[key] = data.map((item) => {
          if (filteredArray.includes(item)) {
            return { ...item, error: item.error };
          }
          return item;
        });
      } else {
        acc[key] = data; // or handle the case where data is not an array
      }
      return acc;
    },
    {},
  );
  return {
    residueAndTillageError,
    hasError,
    updatedActivityPracticeData,
  };
};

export const API_TYPE = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
};
