import styled from 'styled-components';
import {
  BLACK,
  BLUISH_CYAN,
  DARK_BLACK,
  NEW_HOVER_GREEN_BACKGROUND,
  ROW_HOVER_BG,
} from 'theme/GlobalColors';

export const TitleSx = {
  fontSize: '0.875rem',
  minHeight: '2.5rem',
  fontWeight: '700',
  '&:hover': { backgroundColor: ROW_HOVER_BG },
  '&.Mui-expanded': {
    minHeight: '2.5rem',
    maxHeight: '2.5rem',
  },
};
export const projectButtonStyle = () => {
  return {
    textTransform: 'none',
    height: '1.75rem',
    backgroundColor: 'white',
    color: BLUISH_CYAN,
    marginLeft: '1.5rem',
    fontSize: '.75rem',
    marginTop: '0.3rem',
    border: `1px solid ${BLUISH_CYAN}`,
    '&:hover': {
      backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
      color: BLUISH_CYAN,
    },
  };
};
export const accordionSx = {
  '&.MuiAccordionDetails-root': { padding: '0px' },
};
export const accordionStyle = {
  '&.MuiPaper-root-MuiAccordion-root.Mui-expanded': {
    margin: ' 0px',
  },
};
export const projectAccordionStyle = {
  maxWidth: '13.75rem',
  '&.MuiExpansionPanel-root:before': {
    display: 'none',
  },
};

export const ProjectListBox = styled.div`
  margin-top: 0rem;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
`;
export const projectMenuSx = {
  '&.Mui-expanded': {
    margin: '0px',
  },
  '&:before': {
    backgroundColor: 'transparent',
  },
};
export const accordionSummarySx = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: 'white',
};
export const addIconSx = { height: '1rem', width: '1rem' };
export const addIconStyle = {
  border: `1px solid ${BLUISH_CYAN}`,
  position: 'relative',
  zIndex: '2',
  padding: '1px',
  borderRadius: '20%',
  color: BLUISH_CYAN,
  '&:hover': {
    backgroundColor: NEW_HOVER_GREEN_BACKGROUND,
    color: BLUISH_CYAN,
  },
};
export const projectMenuSummaryStyle = {
  minHeight: '1.785rem',
  maxHeight: '1.785rem',
  '&.Mui-expanded': {
    minHeight: '1.785rem',
    maxHeight: '1.785rem',
  },
  '&:hover': { backgroundColor: ROW_HOVER_BG }, // Change background color to blue on hover
};
export const projectNameStyle = {
  fontSize: '0.813rem',
  color: DARK_BLACK,
  width: '8.75rem',
  paddingLeft: '0.75rem',
  textWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const ProjectButtonWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  align-items: center;
  gap: 1rem;
  color: ${DARK_BLACK};
`;
