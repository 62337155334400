import {
  checkTernaryCondition,
  replaceSpecialCharactersWithSingleCharacter,
  validatePhone,
} from 'utils/helper';

export const PAGE_CONTENT = {
  heading: 'Profile information',
  first_name_label: 'First name',
  first_name_placeholder: 'Type your first name',
  first_name_required_error: 'A first name is required.',
  last_name_label: 'Last name',
  last_name_placeholder: 'Type your last name',
  last_name_required_error: 'A last name is required.',
  email_label: 'Email',
  email_placeholder: 'Type your email address',
  email_required_error: 'A valid email address is required.',
  phone_label: 'Phone',
  phone_placeholder: 'Phone',
  phone_required_error: 'A valid phone number is required.',
  mailing_address_label: 'Mailing address',
  mailing_address_placeholder: 'Mailing Address',
  mailing_address_required_error: 'A mailing address is required.',
  city_label: 'City',
  city_placeholder: 'City',
  city_required_error: 'A city is required.',
  state_label: 'State',
  state_placeholder: 'State',
  state_required_error: 'State required.',
  zip_code_label: 'Zip code',
  zip_code_placeholder: 'Zip Code',
  zip_code_required_error: 'Zip code required.',
  primary_operator_label:
    'Are you the primary operator of the operation(s) you wish to enroll in this project?',
  row: 'row',
  primary_operator_error: 'The primary operator status selection is required.',
  communication_method_label: 'What’s your preferred communication method?',
  communication_method_helptext: 'Select all that apply',
  communication_method_error:
    'The preferred method of communication selection is required.',
  producer_label: 'Do you have a producer ID (CC ID) assigned by the FSA?',
  producer_required_error: 'The assigned CC ID status selection is required.',
  producer_id_label: 'Enter the Producer ID (CC ID)',
  producer_id_placeholder: 'Type producer ID',
  producer_id_required_error:
    'If yes was selected for having a producer ID then a producer ID (CC ID) is required.',
  gender_identity_label: 'Which gender identity do you identify with the most?',
  gender_identity_placeholder: 'Select gender',
  gender_identity_required_error: 'A gender identity selection is required.',
  race_or_ethnicity_label: 'What is your race / ethnicity?',
  race_or_ethnicity_placeholder: 'Select race / ethnicity',
  race_or_ethnicity_required_error: 'A race / ethnicity selection is required.',
  operation_enroll_label:
    'Which of the following apply to the farm(s) you are enrolling?',
  historically_label: 'Historically underserved status',
  historically_required_error:
    'A historically underserved status selection is required.',
  underserved_tooltip:
    'Underserved producers generally include beginning farmers, socially disadvantaged farmers, veteran farmers, and limited resource farmers; women farmers and producers growing specialty crops are generally also included in these categories.',
  lgbtq_owned_label: 'LGBTQIA+ owned',
  lgbtq_owned_required_error: 'A LGBTQIA+ owned status selection is required.',
  women_owned_label: 'Women-owned',
  women_owned_required_error: 'A women-owned status selection is required.',
  vetran_owned_label: 'Veteran-owned',
  vetran_owned_required_error: 'A veteran-owned status selection is required.',
  disabled_vetran_owned_label: 'Disabled veteran-owned',
  disabled_vetran_owned_required_error:
    'A disabled veteran-owned status selection is required.',
  disability_owned_label: 'Disability-owned',
  disability_owned_required_error:
    'A disibility-owned status selection is required.',
  small_business_label: 'Small business status',
  small_business_required_error:
    'A small business status selection is required.',
  tell_us_more_label: 'Can you tell us more?',
  project_heard_from_label: 'How did you hear about this project?',
  project_heard_from_instruction_text: 'You may select up to 3 options',
  project_heard_from_error:
    'Please select an option for of how you heard about this project.',
  motivation_label:
    'Please rank each option for the reasons why you are participating in this project.',
  motivation_error:
    'Please select an option for your motivation to participate in this project.',
  type_here: 'Type here',
  save_and_continue_button_label: 'Save and continue',
  save_and_close_button_label: 'Save and close',
  cancel_btn_label: 'Cancel',
  contained: 'contained',
  success: 'success',
  error: 'error',
  medium: 'medium',
  invalid_phone_number_error:
    'A phone number must follow a valid format. e.g., (555) 555-5555 (dashes and brackets not required).',
  success_toast_message: 'Form details saved successfully.',
  error_toast_message:
    'Form details failed to save due to technical error. Please try again.',
  IP: 'IP', //In progress (Can be used for Yellow Symbol means profile is incomplete)
  CP: 'CP', //Completed (Can be used for that green symbol which we have to show in UI)
  EP: 'EP', //Enrollment in progress
  IN: 'IN', //Interested
  EN: 'EN', //Enrolled
  IN_PROGRESS: 'In Progress',
  in_progress: 'in progress',
  COMPLETED: 'Completed',
  Enrollment_In_Progress: 'Enrollment In Progress',
  complete: 'complete',
  select_option: 'Select option',
  specify_others_label: 'Other (specify)',
  specify_others_placeholder: 'Specify others',
  specify_others_error: 'A other specification is required',
  other_specify: 'Other (specify)',
  addFieldConfirmText: 'Are you sure you want to add this field?',
  confirmationDecline: 'No',
  confirmationAccept: 'Yes',
  boundaryLoadingText: 'Field boundary loading...',
  boundaryExistsError: 'Field boundary is already selected earlier',
  overlappingBoundaryError: 'Field boundary overlapping with existing fields',
  acceptBtnText: 'Accept',
  deleteBtnText: 'Delete',
  environment_benefit_label: 'Environmental benefit',
  financial_benefit_label: 'Financial benefit',
  new_market_oportunity_label: 'New market opportunity',
  partnerships_or_networks_label: 'Partnerships or networks',
  motivation_help_text: 'Priority: 1= highest, 4=lowest',
  motivation_required_error:
    'Rank each option from 1 highest to 4 lowest, assigning each number once, reflecting your motivation to participate in this project.',
  county_label: 'County',
  county_placeholder: 'County',
  countyRequiredError: 'County required.',
  noNeed: 'No need',
};

export const STYLE_CONSTANTS = {
  input_font_size: '0.875rem',
  zero_rem: '0rem',
  zero_point_two_five_rem: '0.25rem',
  error_font_size: '0.688rem',
  zero_point_five_rem: '0.5rem',
  one_rem: '1rem',
  one_point_five_rem: '1.5rem',
  motivation_label_width: '12.625rem',
  motivation_checkbox_gap: '1.5rem',
  zero_point_seven_five_rem: '0.75rem',
  two_rem: '2rem',
  zero_point_three_seven_five_rem: '0.375rem',
  ten_rem: '10rem',
  three_point_seven_five_rem: '3.75rem',
  seven_point_five_rem: '7.5rem',
  eight_rem: '8rem',
  thirteen_point_seven_five_rem: '13.75rem',
};

export const FALSE = false;

export const checkFieldIsNotEmpty = (value) => {
  if (value !== null && value !== undefined) {
    return value.trim() && FALSE;
  } else {
    return FALSE;
  }
};

export const ratingList = [
  {
    itemLabel: '1',
    checked: false,
    isDisabled: false,
  },
  {
    itemLabel: '2',
    checked: false,
    isDisabled: false,
  },
  {
    itemLabel: '3',
    checked: false,
    isDisabled: false,
  },
  {
    itemLabel: '4',
    checked: false,
    isDisabled: false,
  },
];

export const validateFormFields = (formData, motivationList) => {
  const phoneValue = replaceSpecialCharactersWithSingleCharacter(
    /[()\-\s]/g,
    formData.phone,
    '',
  );
  return (
    formData.phone.trim() &&
    formData.mailingAddress?.trim() &&
    formData.city?.trim() &&
    formData.state?.trim() &&
    formData.zipCode?.trim() &&
    validateCommunicationMethod(formData.communicationMethod) &&
    formData.heardAboutTheProjectFrom?.trim() &&
    validateHeardAboutProjectSpecifyOthersField(
      formData.heardAboutTheProjectFromOptions,
      formData.heardAboutTheProjectFromOthers,
    ) &&
    validatePhone(phoneValue) &&
    motivationList.filter(
      (motivation) => motivation.participationMotivationScore === 0,
    ).length === 0
  );
};

export const validateHeardAboutProjectSpecifyOthersField = (
  options,
  othersValue,
) => {
  if (options.includes(PAGE_CONTENT.other_specify)) {
    return othersValue?.trim();
  } else {
    return true;
  }
};

export const getHeardAboutTheProjectFrom = (hearAboutProject) => {
  const finalArray = [];
  let nonMatchingItems = '';
  if (hearAboutProject !== null) {
    const hearAboutProjectArray = hearAboutProject
      .split(',')
      .map((item) => item.trim());
    hearAboutProjectArray.forEach((item) => {
      if (
        heardAboutTheProjectFromOptions.includes(item) &&
        !finalArray.includes(item)
      ) {
        finalArray.push(item);
      } else if (item !== '') {
        nonMatchingItems +=
          checkTernaryCondition(nonMatchingItems, ', ', '') + item;
      }
    });
  }
  return { finalArray, nonMatchingItems };
};

export const getMotivationToParticipate = (motivation) => {
  let nonMatchingItems = '';
  let matchingValue = '';
  if (motivation !== null) {
    const matchingOption = motivationToParticipateOptions.find(
      (option) => option.value === motivation,
    );
    if (matchingOption) {
      matchingValue = matchingOption.value;
    } else {
      matchingValue = PAGE_CONTENT.other_specify;
      nonMatchingItems = motivation;
    }
  }
  return { matchingValue, nonMatchingItems };
};

export const YesOrNoTypes = [
  {
    itemLabel: 'Yes',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'No',
    selected: false,
    isDisabled: false,
  },
  {
    itemLabel: 'I don’t know',
    selected: false,
    isDisabled: false,
  },
];

export const communicationMethodList = [
  {
    itemLabel: 'Email',
    checked: false,
  },
  {
    itemLabel: 'Text',
    checked: false,
  },
  {
    itemLabel: 'Phone',
    checked: false,
  },
];

export const genderOptions = [
  { label: 'Female', value: 'FEMALE' },
  { label: 'Male', value: 'MALE' },
  { label: 'Transgender Female', value: 'TRANSGENDER_FEMALE' },
  { label: 'Transgender Male', value: 'TRANSGENDER_MALE' },
  { label: 'Non-Conforming', value: 'NON_CONFORMING' },
  { label: 'Not Listed', value: 'NOT_LISTED' },
  { label: 'Prefer Not to Answer', value: 'PREFER_NOT_TO_ANSWER' },
];

export const raceOrEthnicityOptions = [
  {
    label: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  },
  { label: 'Asian', value: 'ASIAN' },
  { label: 'Black or African American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
  { label: 'Hispanic or Latino', value: 'HISPANIC_OR_LATINO' },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  },
  { label: 'White', value: 'WHITE' },
];

export const heardAboutTheProjectFromOptions = [
  'Commodity organizations',
  'Conferences',
  'Cooperative extension',
  'Digital communications and resources',
  'Education workshops',
  'Field days and town halls',
  'Existing partner networks',
  'Farm visits and one-on-one meetings',
  'General advertising',
  'Peer referrals and producer groups',
  'Phone calls',
  'Print communications and resources',
  'Retailers',
  'State agencies',
  'Targeted messaging using proprietary data',
  'Technical service providers',
  'Other (specify)',
];

export const motivationToParticipateOptions = [
  { value: 'Environmental benefit', label: 'Environmental benefit' },
  { value: 'Financial benefit', label: 'Financial benefit' },
  { value: 'New market opportunity', label: 'New market opportunity' },
  { value: 'Partnerships or networks', label: 'Partnerships or networks' },
  { value: 'Other (specify)', label: 'Other (specify)' },
];

export const validateCommunicationMethod = (communicationMethod) => {
  return communicationMethod.filter((method) => method.checked).length > 0;
};

export const checkArrayIsNotEmpty = (value) => {
  return !value.length > 0;
};

export const getCommnicationMethodList = (communicationMethod) => {
  let commMethodList = [];
  if (communicationMethod !== null) {
    commMethodList = communicationMethod.replace(/ /g, '').split(',');
  }
  return communicationMethodList.map((method) => ({
    itemLabel: method.itemLabel,
    checked: commMethodList.includes(method.itemLabel),
  }));
};

export const formModal = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mailingAddress: '',
  city: '',
  state: '',
  county: '',
  zipCode: '',
  communicationMethod: communicationMethodList,
  heardAboutTheProjectFromOptions: [],
  heardAboutTheProjectFrom: '',
  heardAboutTheProjectFromOthers: '',
  motivationToParticipate: '',
  motivationToParticipateOthers: '',
  environmentRankValue: '',
  financialBenefitRankValue: '',
  newMarketRankValue: '',
  partnershipsRankValue: '',
};

export const errorInitialState = {
  firstNameRequiredError: false,
  lastNameRequiredError: false,
  emailRequiredError: false,
  phoneRequiredError: false,
  inValidPhoneError: false,
  mailingAddressRequiredError: false,
  cityRequiredError: false,
  stateRequiredError: false,
  countyRequiredError: false,
  zipCodeRequiredError: false,
  communicationMethodRequiredError: false,
  heardAboutTheProjectFromRequiredError: false,
  heardAboutTheProjectFromRequiredOtherError: false,
  motivationToParticipateRequiredError: false,
  motivationToParticipateRequiredOtherError: false,
  environmentRankError: false,
  financialBenefitRankError: false,
  newMarketRankError: false,
  partnershipsRankError: false,
};
