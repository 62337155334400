export const MODAL_CONTENT = {
  heading: 'Fertilizer',
  copy_year_label: 'Copy year',
  copy_year_placeholder: 'Year',
  fertilizer_type_label: 'Fertilizer type',
  gals_acre: 'gals/acre',
  lbs_acre: 'lbs/acre',
  uom_field: 'unitOfMeasure',
  fertilizer_type_placeholder: 'Select fertilizer type',
  application_method_label: 'Application method',
  application_method_placeholder: 'Select Option',
  application_timing_label: 'Application timing',
  application_timing_placeholder: 'Select time',
  application_rate_label: 'Application rate',
  application_rate_placeholder: 'Type amount',
  unit_of_measure_label: 'Unit of measure',
  unit_of_measure_placeholder: 'Select unit type',
  measured_per_label: 'Measured per',
  measured_per_placeholder: 'Select',
  npk_percentage_label: 'NPK percentage',
  npk_percentage_placeholder: 'Type percentage',
  date_applied_label: 'Date applied',
  date_applied_placeholder: 'Select Date',
  submit_btn_label: 'Submit',
  cancel_btn_label: 'Cancel',
  date_applied: 'date applied',
  contains_inhibitor_label: 'Does the fertilizer contain an inhibitor?',
  percentage_place_holder: 'Type amount',
  c_percentage: 'C percentage',
  n_percentage: 'N',
  k_percentage: 'K',
  p_percentage: 'P',
  npk_label: 'Nitrogen (N), Phosphorus (P), and Potassium (K) content',
  fertilizer_type_error: 'Fertilizer type selection is required.',
  date_error: 'At least one date when the fertilizer was applied is required.',
  inhibitor_error: 'Fertilizer inhibitor selection is required.',
  application_method_error: 'Application method selection is required.',
  application_timing_error: 'Application timing selection is required.',
  annual_rate_error: 'Annual rate  is required.',
  uom_error: 'Unit of measure selection is required.',
  synthetic: 'Synthetic',
  n_percentage_error: 'N value is required.',
  p_percentage_error: 'P value is required.',
  k_percentage_error: 'K value is required.',
  c_percentage_error: 'C percentage is required.',
  animal_type_label: 'Animal type',
  animal_type_placeholder: 'Select animal type',
  manure_type_label: 'Manure type',
  manure_label: 'Manure',
  organic_label: 'Organic',
  solid_label: 'Solid',
  liquid_label: 'Liquid',
  manure_type_placeholder: 'Select manure type',
  animal_type_error: 'Animal type selection is required.',
  manure_type_error: 'Manure type selection is required.',
  rate_preDefined_unit: 'lbs/acre',
  date_error_label:
    'Date applied can only occur within the baseline year and up to 1 year prior.',
  fertilizer_area_label: 'Fertilizer area',
  fertilizer_area_placeholder: 'Type area',
  fertilizer_area_unit: 'acres',
  fertilizer_area_required_error: 'Fertilizer area is required.',
  fertilizer_area_exceed_error:
    'Fertilizer area can’t exceed total area of the field - {{field_area}} acres.',
  fertilizer_area_zero_error: 'Fertilizer area must be greater than 0.',
  noneOption: 'None',
  optional: '(Optional)',
  tooltip_content:
    'Please reference the fertilizer label or information provided by the manufacturer or supplier to determine the nitrogen, phosphorus, and potassium content of a specific fertilizer product applied',
  duplicate_date_error:
    'Date selected has already been applied. Please select a different date.',
  invalid_date: 'Invalid date',
  unique_combination_error_heading:
    'Please review the prior entries and update the details on the form to submit a new unique entry.',
  fertilizer_type_deletion_tooltip:
    'To change any of the disabled fields after saving, you must delete and re-enter the record.',
  new_modal_heading: 'Add fertilizer',
};

export const formFieldNames = {
  fertilizerType: 'fertilizerType',
  applicationMethod: 'applicationMethod',
  applicationRate: 'applicationRate',
  npkPercentage: 'npkPercentage',
  dateApplied: 'dateApplied',
  datesApplied: 'datesApplied',
  hasInhibitor: 'hasInhibitor',
  nPercentage: 'nPercentage',
  pPercentage: 'pPercentage',
  kPercentage: 'kPercentage',
  animalType: 'animalType',
  manureType: 'manureType',
};

export const NitrogenFertilizerFormInitialData = {
  fertilizerType: '',
  applicationMethod: '',
  unitOfMeasure: '',
  applicationRate: '',
  datesApplied: [null],
  nPercentage: '',
  pPercentage: '',
  kPercentage: '',
  hasInhibitor: '',
  fertilizerCategory: '',
  animalType: '',
  manureType: '',
};

export const fertilizerTypeOptions = [
  { label: ['Manure', 'Compost'], value: ['Manure', 'Compost'] },
  { label: 'Synthetic', value: 'Synthetic' },
];

export const unitOfMeasurementOptions = [
  { label: 'lbs/acre', value: 'lbs/acre' },
  { label: 'gals/acre', value: 'gals/acre' },
];

export const applicationMethodOptions = [
  { label: 'Banded', value: 'Banded' },
  { label: 'Broadcast', value: 'Broadcast' },
  { label: 'Injection', value: 'Injection' },
  { label: 'Irrigation', value: 'Irrigation' },
  { label: 'Surface application', value: 'Surface application' },
  {
    label: 'Surface application with tillage',
    value: 'Surface application with tillage',
  },
  { label: 'Variable rate', value: 'Variable rate' },
];
export const hasInhibitorList = [
  { itemLabel: 'Yes', value: 'Yes' },
  { itemLabel: 'No', value: 'No' },
];

export const ModalInitialErrorState = {
  fertilizerType: false,
  unitOfMeasure: false,
  applicationMethod: false,
  applicationRate: false,
  npkPercentage: false,
  datesApplied: false,
  nPercentage: false,
  pPercentage: false,
  kPercentage: false,
  hasInhibitor: false,
  animalType: false,
  manureType: false,
};
export const isValueNullorUndefined = (value) => {
  return value === undefined || value === null;
};
